/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/flags/service.proto" (package "rd.api.flags", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Value } from "../../flags/models_pb";
/**
 * @generated from protobuf message rd.api.flags.GetFlagsReq
 */
export interface GetFlagsReq {
}
/**
 * @generated from protobuf message rd.api.flags.GetFlagsRes
 */
export interface GetFlagsRes {
    /**
     * @generated from protobuf field: map<int32, rd.flags.Value> flags = 1;
     */
    flags: {
        [key: number]: Value;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class GetFlagsReq$Type extends MessageType<GetFlagsReq> {
    constructor() {
        super("rd.api.flags.GetFlagsReq", []);
    }
    create(value?: PartialMessage<GetFlagsReq>): GetFlagsReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFlagsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlagsReq): GetFlagsReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetFlagsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.flags.GetFlagsReq
 */
export const GetFlagsReq = new GetFlagsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlagsRes$Type extends MessageType<GetFlagsRes> {
    constructor() {
        super("rd.api.flags.GetFlagsRes", [
            { no: 1, name: "flags", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "message", T: () => Value } }
        ]);
    }
    create(value?: PartialMessage<GetFlagsRes>): GetFlagsRes {
        const message = { flags: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFlagsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlagsRes): GetFlagsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, rd.flags.Value> flags */ 1:
                    this.binaryReadMap1(message.flags, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetFlagsRes["flags"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetFlagsRes["flags"] | undefined, val: GetFlagsRes["flags"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = Value.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.flags.GetFlagsRes.flags");
            }
        }
        map[key ?? 0] = val ?? Value.create();
    }
    internalBinaryWrite(message: GetFlagsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, rd.flags.Value> flags = 1; */
        for (let k of Object.keys(message.flags)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k));
            writer.tag(2, WireType.LengthDelimited).fork();
            Value.internalBinaryWrite(message.flags[k as any], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.flags.GetFlagsRes
 */
export const GetFlagsRes = new GetFlagsRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.flags.FlagsService
 */
export const FlagsService = new ServiceType("rd.api.flags.FlagsService", [
    { name: "GetFlags", options: {}, I: GetFlagsReq, O: GetFlagsRes }
]);
