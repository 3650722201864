import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { TextInput, TextInputProps } from "components/TextInput"
import {
  addYears,
  format as formatDate,
  getYear,
  isEqual,
  isFuture,
  isPast,
  isToday,
  parse,
  setYear,
  subYears,
} from "date-fns"

export const DisabledMode = {
  None: "none",
  Past: "past",
  Future: "future",
} as const
export type DisabledModeT = (typeof DisabledMode)[keyof typeof DisabledMode]

export interface TextDateInputProps extends TextInputProps {
  date: Date | null
  onChangeDate: (d: Date | null) => void
  defaultRefDate: Date
  format?: string
  disabledMode?: DisabledModeT
}

export const TextDateInput = forwardRef<HTMLInputElement, TextDateInputProps>(
  (props, ref) => {
    const {
      date,
      onChangeDate,
      defaultRefDate,
      format: fmt = "MMM d, yyyy",
      onBlur: onBlurProp,
      disabledMode,
      ...otherProps
    } = props

    const useTextDateInputRes = useTextDateInput({
      ref,
      date,
      defaultRefDate,
      format: fmt,
      disabledMode,
      onChangeDate,
      onBlurProp,
    })

    return (
      <TextInput
        ref={useTextDateInputRes.innerRef}
        value={useTextDateInputRes.dateString}
        onChange={useTextDateInputRes.onChange}
        onBlur={useTextDateInputRes.onBlur}
        onKeyDown={useTextDateInputRes.onKeyDown}
        {...otherProps}
      />
    )
  }
)

interface UseTextDateInputProps {
  ref: ForwardedRef<HTMLInputElement>
  date: Date | null
  defaultRefDate: Date
  format: string
  disabledMode?: DisabledModeT
  onChangeDate: (d: Date | null) => void
  onBlurProp?: FocusEventHandler<HTMLInputElement>
}

export function useTextDateInput({
  ref,
  date,
  defaultRefDate,
  format: fmt,
  disabledMode,
  onChangeDate,
  onBlurProp,
}: UseTextDateInputProps) {
  const innerRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

  const [dateString, setDateString] = useState<string>(
    date ? formatDate(date, fmt) : ""
  )
  const [stagedDate, setStagedDate] = useState<Date | null>(date)

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDateString(e.target.value)

    if (!e.target.value) {
      setStagedDate(null)
      return
    }

    let parsed = parseDate(e.target.value, date ?? defaultRefDate)

    if (!parsed) {
      setStagedDate(date)
      return
    }

    if (disabledMode === "past" && isPast(parsed) && !isToday(parsed)) {
      parsed = setYear(parsed, getYear(new Date()))
      if (isPast(parsed)) {
        parsed = addYears(parsed, 1)
      }
    }

    if (disabledMode === "future" && isFuture(parsed) && !isToday(parsed)) {
      parsed = setYear(parsed, getYear(new Date()))
      if (isFuture(parsed)) {
        parsed = subYears(parsed, 1)
      }
    }

    setStagedDate(parsed)
  }

  useEffect(() => {
    const formatted = date ? formatDate(date, fmt) : ""

    if (dateString === formatted) {
      return
    }

    setDateString(formatted)
  }, [date])

  useEffect(() => {
    if (!date && !stagedDate) {
      return
    }

    if (date && stagedDate && isEqual(date, stagedDate)) {
      return
    }

    setStagedDate(date)
  }, [date])

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setDateString(stagedDate ? formatDate(stagedDate, fmt) : "")
    if (
      (!!date && !stagedDate) ||
      (!date && !!stagedDate) ||
      (stagedDate && date && !isEqual(stagedDate, date))
    ) {
      onChangeDate(stagedDate)
    }

    if (onBlurProp) {
      onBlurProp(e)
    }
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key !== "Enter") {
      return
    }

    if (!innerRef.current) {
      return
    }

    setDateString(stagedDate ? formatDate(stagedDate, fmt) : "")
    onChangeDate(stagedDate)
    innerRef.current.blur()
  }

  return {
    innerRef,
    dateString,
    onChange,
    onBlur,
    onKeyDown,
  }
}

// parseDate takes in arbitrary string input and a reference date and returns a Date.
// If the string is not parseable as a date, then it returns null. The reference date
// is used to fill in any info missing from the string input. For example, "4/23" is
// missing the year and time, so refDate will be used to populate those. This function
// relies on the parse function from date-fns (https://date-fns.org/v2.29.3/docs/parse).
export function parseDate(str: string, refDate: Date): Date | null {
  const formats = [
    "MMM d, yyyy", // Apr 3, 2023
    "MMM d yyyy", // Apr 3 2023
    "MMM do, yyyy", // Apr 3rd, 2023
    "MMM do yyyy", // Apr 3rd 2023
    "MMM dd, yyyy", // Apr 03, 2023
    "MMM dd yyyy", // Apr 03 2023

    "MMMM d, yyyy", // April 3, 2023
    "MMMM d yyyy", // April 3 2023
    "MMMM do, yyyy", // April 3rd, 2023
    "MMMM do yyyy", // April 3rd 2023
    "MMMM dd, yyyy", // April 03, 2023
    "MMMM dd yyyy", // April 03 2023

    "M/d/yy", // 4/3/23
    "M/d/yyyy", // 4/3/2023
    "MM/dd/yy", // 04/03/23
    "MM/dd/yyyy", // 04/03/2023

    "MMM d", // Apr 3
    "MMM do", // Apr 3rd
    "MMM dd", // Apr 03

    "MMMM d", // April 3
    "MMMM do", // April 3rd
    "MMMM dd", // April 03

    "M/d", // 4/3
    "MM/dd", // 04/03
  ]

  for (const f of formats) {
    const date = parse(str, f, refDate)

    if (!(date instanceof Date)) {
      continue
    }

    if (Number.isNaN(date.getTime())) {
      continue
    }

    return date
  }

  return null
}
