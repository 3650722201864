import { ComponentPropsWithRef, Fragment, ReactNode, forwardRef } from "react"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"

import { classNames } from "lib/classNames"

export interface TabItem {
  name: string
  panel: ReactNode
}

interface CardProps extends ComponentPropsWithRef<"div"> {
  tabItems?: TabItem[]
  showTabPanelsInCard?: boolean
  defaultIndex?: number
  indentedTabs?: boolean
}

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const {
    className,
    children,
    tabItems = [],
    showTabPanelsInCard,
    defaultIndex,
    indentedTabs,
    ...otherProps
  } = props

  return (
    <TabGroup as={Fragment} defaultIndex={defaultIndex}>
      <div
        ref={ref}
        className={classNames(
          "flex flex-1 flex-col border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:rounded",
          tabItems.length > 0 ? "overflow-hidden pb-0" : "",
          className
        )}
        {...otherProps}
      >
        {children}
        {tabItems.length > 0 && (
          <>
            {!showTabPanelsInCard && (
              <div className="-mx-4 mt-2 flex h-[1px] bg-gray-200 dark:bg-gray-600" />
            )}

            <TabList
              className={classNames(
                "flex border-transparent",
                indentedTabs ? "mx-4" : "",
                showTabPanelsInCard
                  ? "border-b border-b-gray-200 dark:border-b-gray-600"
                  : "-mx-4"
              )}
            >
              {tabItems.map((tabItem) => (
                <Tab
                  key={`tab-${tabItem.name}`}
                  className={({ selected }) =>
                    classNames(
                      "whitespace-nowrap border-b-2 px-4 py-3 text-base font-semibold focus:outline-none",
                      showTabPanelsInCard && "pt-0",
                      selected
                        ? "border-cobalt-400 text-cobalt-400 dark:border-cobalt-300 dark:text-cobalt-300"
                        : "border-transparent text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    )
                  }
                >
                  {tabItem.name}
                </Tab>
              ))}
            </TabList>

            {tabItems.length > 0 && showTabPanelsInCard && (
              <TabPanels className="flex flex-1 flex-col">
                {tabItems.map((tabItem) => (
                  <TabPanel
                    key={`panel-${tabItem.name}`}
                    className="flex flex-1 flex-col"
                  >
                    {tabItem.panel}
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </>
        )}
      </div>
      {tabItems.length > 0 && !showTabPanelsInCard && (
        <TabPanels className="mt-8">
          {tabItems.map((tabItem) => (
            <TabPanel key={`panel-${tabItem.name}`}>{tabItem.panel}</TabPanel>
          ))}
        </TabPanels>
      )}
    </TabGroup>
  )
})

Card.displayName = "Card"
