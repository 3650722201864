export function stytchErrorMessage(code: string): string {
  switch (code) {
    case "no_active_webauthn_registrations":
      return "There is no key associated with your account. Sign in via text message."
    case "too_many_requests":
      return "Too many sign in attempts. Please wait a few minutes."
    case "otp_code_not_found":
      return "Wrong code. Please try again."
    case "user_not_found":
      return "ID not found. Please try again."
    default:
      return "Something went wrong. Please try again."
  }
}
