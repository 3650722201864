/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "accounting/models.proto" (package "rd.accounting", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Sale_Return } from "../txn/models_pb";
import { LedgerEntry } from "../txn/models_pb";
import { Payout } from "../payments/models_pb";
import { BalanceTransaction } from "../payments/models_pb";
import { Company } from "../company/models_pb";
import { Location } from "../company/models_pb";
import { User } from "../user/models_pb";
import { UUID } from "../proto/uuid/models_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Account } from "../coa/models_pb";
import { OrderShipment } from "../txn/models_pb";
import { FinancialTransaction } from "../payments/models_pb";
import { Customer } from "../customer/models_pb";
import { Transfer } from "../txn/models_pb";
import { CloseOut } from "../txn/models_pb";
import { PettyCash } from "../txn/models_pb";
import { BillPayment } from "../txn/models_pb";
import { Sale } from "../txn/models_pb";
import { Money } from "../proto/money/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message rd.accounting.AccountingTransaction
 */
export interface AccountingTransaction {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money debit = 5;
     */
    debit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit = 6;
     */
    credit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money balance = 7;
     */
    balance?: Money;
    /**
     * @generated from protobuf field: rd.accounting.AccountingTransaction.Reference reference = 8;
     */
    reference: AccountingTransaction_Reference;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 9;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 10;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.PettyCash petty_cash = 11;
     */
    pettyCash?: PettyCash;
    /**
     * @generated from protobuf field: rd.txn.CloseOut closeout = 12;
     */
    closeout?: CloseOut;
    /**
     * @generated from protobuf field: rd.txn.Transfer transfer = 13;
     */
    transfer?: Transfer;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 14;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction external_transaction = 15;
     */
    externalTransaction?: ExternalTransaction;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction financial_transaction = 16;
     */
    financialTransaction?: FinancialTransaction;
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 17;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.coa.Account account = 18;
     */
    account?: Account;
}
/**
 * @generated from protobuf enum rd.accounting.AccountingTransaction.Reference
 */
export enum AccountingTransaction_Reference {
    /**
     * @generated from protobuf enum value: Undefined = 0;
     */
    Undefined = 0,
    /**
     * @generated from protobuf enum value: Sale = 1;
     */
    Sale = 1,
    /**
     * @generated from protobuf enum value: BillPayment = 2;
     */
    BillPayment = 2,
    /**
     * @generated from protobuf enum value: PettyCash = 3;
     */
    PettyCash = 3,
    /**
     * @generated from protobuf enum value: Closeout = 4;
     */
    Closeout = 4,
    /**
     * @generated from protobuf enum value: Transfer = 5;
     */
    Transfer = 5,
    /**
     * @generated from protobuf enum value: Customer = 6;
     */
    Customer = 6,
    /**
     * @generated from protobuf enum value: ExternalTransaction = 7;
     */
    ExternalTransaction = 7,
    /**
     * @generated from protobuf enum value: FinancialTransaction = 8;
     */
    FinancialTransaction = 8,
    /**
     * @generated from protobuf enum value: OrderShipment = 9;
     */
    OrderShipment = 9,
    /**
     * @generated from protobuf enum value: Account = 10;
     */
    Account = 10
}
/**
 * @generated from protobuf message rd.accounting.IncomeStatement
 */
export interface IncomeStatement {
    /**
     * @generated from protobuf field: rd.proto.money.Money income_total = 1;
     */
    incomeTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.IncomeStatement.AccountLineItem income_accounts = 2;
     */
    incomeAccounts: IncomeStatement_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money cogs_total = 3;
     */
    cogsTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.IncomeStatement.AccountLineItem cogs_accounts = 4;
     */
    cogsAccounts: IncomeStatement_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money operating_expenses_total = 5;
     */
    operatingExpensesTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.IncomeStatement.AccountLineItem operating_expenses_accounts = 6;
     */
    operatingExpensesAccounts: IncomeStatement_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_profit = 7;
     */
    grossProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal gross_profit_margin = 8;
     */
    grossProfitMargin?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_profit = 9;
     */
    netProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal net_profit_margin = 10;
     */
    netProfitMargin?: Decimal;
}
/**
 * @generated from protobuf message rd.accounting.IncomeStatement.AccountLineItem
 */
export interface IncomeStatement_AccountLineItem {
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 1;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.coa.Account account = 3;
     */
    account?: Account;
}
/**
 * @generated from protobuf message rd.accounting.BalanceSheet
 */
export interface BalanceSheet {
    /**
     * @generated from protobuf field: rd.proto.money.Money asset_total = 1;
     */
    assetTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.BalanceSheet.AccountLineItem asset_accounts = 2;
     */
    assetAccounts: BalanceSheet_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money liability_total = 3;
     */
    liabilityTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.BalanceSheet.AccountLineItem liability_accounts = 4;
     */
    liabilityAccounts: BalanceSheet_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money equity_total = 5;
     */
    equityTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.accounting.BalanceSheet.AccountLineItem equity_accounts = 6;
     */
    equityAccounts: BalanceSheet_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money liability_and_equity_total = 7;
     */
    liabilityAndEquityTotal?: Money;
}
/**
 * @generated from protobuf message rd.accounting.BalanceSheet.AccountLineItem
 */
export interface BalanceSheet_AccountLineItem {
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 1;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.coa.Account account = 2;
     */
    account?: Account;
}
/**
 * @generated from protobuf message rd.accounting.ExternalTransaction
 */
export interface ExternalTransaction {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 3;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 7;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: bool is_active = 8;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 9;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction.Type type = 10;
     */
    type: ExternalTransaction_Type;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp transacted_at = 11;
     */
    transactedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction.PaymentMethod payment_method = 12;
     */
    paymentMethod: ExternalTransaction_PaymentMethod;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 13;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 14;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: string description = 16;
     */
    description: string;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_chart_of_account_id = 17;
     */
    allocationsByChartOfAccountId: {
        [key: string]: Money;
    };
}
/**
 * @generated from protobuf enum rd.accounting.ExternalTransaction.Type
 */
export enum ExternalTransaction_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * (+ve) amount
     *
     * @generated from protobuf enum value: TYPE_DEPOSIT = 1;
     */
    DEPOSIT = 1,
    /**
     * (-ve) amount
     *
     * @generated from protobuf enum value: TYPE_PAYMENT = 2;
     */
    PAYMENT = 2
}
/**
 * @generated from protobuf enum rd.accounting.ExternalTransaction.PaymentMethod
 */
export enum ExternalTransaction_PaymentMethod {
    /**
     * @generated from protobuf enum value: PAYMENT_METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PAYMENT_METHOD_BANK_CASH = 1;
     */
    BANK_CASH = 1,
    /**
     * @generated from protobuf enum value: PAYMENT_METHOD_BANK_CHECK = 2;
     */
    BANK_CHECK = 2,
    /**
     * @generated from protobuf enum value: PAYMENT_METHOD_BANK_CARD = 3;
     */
    BANK_CARD = 3,
    /**
     * @generated from protobuf enum value: PAYMENT_METHOD_BANK_TRANSFER = 4;
     */
    BANK_TRANSFER = 4
}
/**
 * @generated from protobuf message rd.accounting.ExternalTransactionLog
 */
export interface ExternalTransactionLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction external_transaction = 4;
     */
    externalTransaction?: ExternalTransaction;
}
/**
 * @generated from protobuf message rd.accounting.JournalEntries
 */
export interface JournalEntries {
    /**
     * @generated from protobuf field: repeated rd.accounting.JournalEntries.AccountLineItem account_line_items = 1;
     */
    accountLineItems: JournalEntries_AccountLineItem[];
    /**
     * @generated from protobuf field: rd.proto.money.Money debit_total = 2;
     */
    debitTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit_total = 3;
     */
    creditTotal?: Money;
}
/**
 * @generated from protobuf message rd.accounting.JournalEntries.AccountLineItem
 */
export interface JournalEntries_AccountLineItem {
    /**
     * @generated from protobuf field: rd.proto.money.Money debit = 1;
     */
    debit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit = 2;
     */
    credit?: Money;
    /**
     * @generated from protobuf field: rd.coa.Account account = 3;
     */
    account?: Account;
}
/**
 * @generated from protobuf message rd.accounting.JournalEntriesPDFData
 */
export interface JournalEntriesPDFData {
    /**
     * @generated from protobuf field: repeated rd.accounting.JournalEntriesPDFData.AccountLineItem account_line_items = 1;
     */
    accountLineItems: JournalEntriesPDFData_AccountLineItem[];
    /**
     * @generated from protobuf field: string debit_total = 2;
     */
    debitTotal: string;
    /**
     * @generated from protobuf field: string credit_total = 3;
     */
    creditTotal: string;
    /**
     * @generated from protobuf field: string timestamp_range = 4;
     */
    timestampRange: string;
    /**
     * @generated from protobuf field: string location_name = 5;
     */
    locationName: string;
    /**
     * @generated from protobuf field: rd.company.Location location = 6;
     */
    location?: Location; // required by the header html template
    /**
     * @generated from protobuf field: rd.company.Company company = 7;
     */
    company?: Company; // required by the header html template
}
/**
 * @generated from protobuf message rd.accounting.JournalEntriesPDFData.AccountLineItem
 */
export interface JournalEntriesPDFData_AccountLineItem {
    /**
     * @generated from protobuf field: string debit_amount = 1;
     */
    debitAmount: string;
    /**
     * @generated from protobuf field: string credit_amount = 2;
     */
    creditAmount: string;
    /**
     * @generated from protobuf field: rd.coa.Account account = 3;
     */
    account?: Account;
}
/**
 * @generated from protobuf message rd.accounting.PayoutReconciliation
 */
export interface PayoutReconciliation {
    /**
     * @generated from protobuf field: repeated rd.accounting.PayoutReconciliation.Transaction transactions = 1;
     */
    transactions: PayoutReconciliation_Transaction[];
    /**
     * @generated from protobuf field: rd.proto.money.Money net_total = 2;
     */
    netTotal?: Money;
    /**
     * @generated from protobuf field: int32 net_total_count = 3;
     */
    netTotalCount: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_paid = 4;
     */
    netPaid?: Money;
    /**
     * @generated from protobuf field: int32 net_paid_count = 5;
     */
    netPaidCount: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_pending = 6;
     */
    netPending?: Money;
    /**
     * @generated from protobuf field: int32 net_pending_count = 7;
     */
    netPendingCount: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_in_transit = 8;
     */
    netInTransit?: Money;
    /**
     * @generated from protobuf field: int32 net_in_transit_count = 9;
     */
    netInTransitCount: number;
}
/**
 * @generated from protobuf message rd.accounting.PayoutReconciliation.Transaction
 */
export interface PayoutReconciliation_Transaction {
    /**
     * @generated from protobuf field: rd.accounting.PayoutReconciliation.Transaction.Type type = 1;
     */
    type: PayoutReconciliation_Transaction_Type;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 3;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.payments.BalanceTransaction balance_transaction = 4;
     */
    balanceTransaction?: BalanceTransaction;
    /**
     * @generated from protobuf field: rd.payments.Payout payout = 5;
     */
    payout?: Payout;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry payment_ledger_entry = 6;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry refund_ledger_entry = 7;
     */
    refundLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.proto.money.Money gross = 8;
     */
    gross?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 9;
     */
    fee?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money net = 10;
     */
    net?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp transacted_at = 11;
     */
    transactedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expected_at = 12;
     */
    expectedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 13;
     */
    saleReturn?: Sale_Return;
}
/**
 * @generated from protobuf enum rd.accounting.PayoutReconciliation.Transaction.Type
 */
export enum PayoutReconciliation_Transaction_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE_PAYMENT = 1;
     */
    SALE_PAYMENT = 1,
    /**
     * @generated from protobuf enum value: TYPE_SALE_REFUND = 2;
     */
    SALE_REFUND = 2,
    /**
     * @generated from protobuf enum value: TYPE_BILL_PAYMENT = 3;
     */
    BILL_PAYMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_BILL_REFUND = 4;
     */
    BILL_REFUND = 4
}
/**
 * @generated from protobuf enum rd.accounting.GeneralLedgerSortBy
 */
export enum GeneralLedgerSortBy {
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_DATE = 1;
     */
    DATE = 1,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_ACCOUNT_NUMBER = 2;
     */
    ACCOUNT_NUMBER = 2,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_ACCOUNT_NAME = 3;
     */
    ACCOUNT_NAME = 3,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_LOCATION_ID = 4;
     */
    LOCATION_ID = 4,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_DEBIT = 5;
     */
    DEBIT = 5,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_CREDIT = 6;
     */
    CREDIT = 6,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_TRANSACTION_ID = 7;
     */
    TRANSACTION_ID = 7,
    /**
     * @generated from protobuf enum value: GENERAL_LEDGER_SORT_BY_CUSTOMER_ID = 8;
     */
    CUSTOMER_ID = 8
}
// @generated message type with reflection information, may provide speed optimized methods
class AccountingTransaction$Type extends MessageType<AccountingTransaction> {
    constructor() {
        super("rd.accounting.AccountingTransaction", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "debit", kind: "message", T: () => Money },
            { no: 6, name: "credit", kind: "message", T: () => Money },
            { no: 7, name: "balance", kind: "message", T: () => Money },
            { no: 8, name: "reference", kind: "enum", T: () => ["rd.accounting.AccountingTransaction.Reference", AccountingTransaction_Reference] },
            { no: 9, name: "sale", kind: "message", T: () => Sale },
            { no: 10, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 11, name: "petty_cash", kind: "message", T: () => PettyCash },
            { no: 12, name: "closeout", kind: "message", T: () => CloseOut },
            { no: 13, name: "transfer", kind: "message", T: () => Transfer },
            { no: 14, name: "customer", kind: "message", T: () => Customer },
            { no: 15, name: "external_transaction", kind: "message", T: () => ExternalTransaction },
            { no: 16, name: "financial_transaction", kind: "message", T: () => FinancialTransaction },
            { no: 17, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 18, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<AccountingTransaction>): AccountingTransaction {
        const message = { description: "", reference: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountingTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountingTransaction): AccountingTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* rd.proto.money.Money debit */ 5:
                    message.debit = Money.internalBinaryRead(reader, reader.uint32(), options, message.debit);
                    break;
                case /* rd.proto.money.Money credit */ 6:
                    message.credit = Money.internalBinaryRead(reader, reader.uint32(), options, message.credit);
                    break;
                case /* rd.proto.money.Money balance */ 7:
                    message.balance = Money.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* rd.accounting.AccountingTransaction.Reference reference */ 8:
                    message.reference = reader.int32();
                    break;
                case /* rd.txn.Sale sale */ 9:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 10:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.PettyCash petty_cash */ 11:
                    message.pettyCash = PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.txn.CloseOut closeout */ 12:
                    message.closeout = CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeout);
                    break;
                case /* rd.txn.Transfer transfer */ 13:
                    message.transfer = Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                case /* rd.customer.Customer customer */ 14:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.accounting.ExternalTransaction external_transaction */ 15:
                    message.externalTransaction = ExternalTransaction.internalBinaryRead(reader, reader.uint32(), options, message.externalTransaction);
                    break;
                case /* rd.payments.FinancialTransaction financial_transaction */ 16:
                    message.financialTransaction = FinancialTransaction.internalBinaryRead(reader, reader.uint32(), options, message.financialTransaction);
                    break;
                case /* rd.txn.OrderShipment order_shipment */ 17:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.coa.Account account */ 18:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountingTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* rd.proto.money.Money debit = 5; */
        if (message.debit)
            Money.internalBinaryWrite(message.debit, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money credit = 6; */
        if (message.credit)
            Money.internalBinaryWrite(message.credit, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money balance = 7; */
        if (message.balance)
            Money.internalBinaryWrite(message.balance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.accounting.AccountingTransaction.Reference reference = 8; */
        if (message.reference !== 0)
            writer.tag(8, WireType.Varint).int32(message.reference);
        /* rd.txn.Sale sale = 9; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 10; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PettyCash petty_cash = 11; */
        if (message.pettyCash)
            PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CloseOut closeout = 12; */
        if (message.closeout)
            CloseOut.internalBinaryWrite(message.closeout, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Transfer transfer = 13; */
        if (message.transfer)
            Transfer.internalBinaryWrite(message.transfer, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 14; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.accounting.ExternalTransaction external_transaction = 15; */
        if (message.externalTransaction)
            ExternalTransaction.internalBinaryWrite(message.externalTransaction, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction financial_transaction = 16; */
        if (message.financialTransaction)
            FinancialTransaction.internalBinaryWrite(message.financialTransaction, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.OrderShipment order_shipment = 17; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.coa.Account account = 18; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.AccountingTransaction
 */
export const AccountingTransaction = new AccountingTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncomeStatement$Type extends MessageType<IncomeStatement> {
    constructor() {
        super("rd.accounting.IncomeStatement", [
            { no: 1, name: "income_total", kind: "message", T: () => Money },
            { no: 2, name: "income_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IncomeStatement_AccountLineItem },
            { no: 3, name: "cogs_total", kind: "message", T: () => Money },
            { no: 4, name: "cogs_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IncomeStatement_AccountLineItem },
            { no: 5, name: "operating_expenses_total", kind: "message", T: () => Money },
            { no: 6, name: "operating_expenses_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IncomeStatement_AccountLineItem },
            { no: 7, name: "gross_profit", kind: "message", T: () => Money },
            { no: 8, name: "gross_profit_margin", kind: "message", T: () => Decimal },
            { no: 9, name: "net_profit", kind: "message", T: () => Money },
            { no: 10, name: "net_profit_margin", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<IncomeStatement>): IncomeStatement {
        const message = { incomeAccounts: [], cogsAccounts: [], operatingExpensesAccounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IncomeStatement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncomeStatement): IncomeStatement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money income_total */ 1:
                    message.incomeTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.incomeTotal);
                    break;
                case /* repeated rd.accounting.IncomeStatement.AccountLineItem income_accounts */ 2:
                    message.incomeAccounts.push(IncomeStatement_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money cogs_total */ 3:
                    message.cogsTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.cogsTotal);
                    break;
                case /* repeated rd.accounting.IncomeStatement.AccountLineItem cogs_accounts */ 4:
                    message.cogsAccounts.push(IncomeStatement_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money operating_expenses_total */ 5:
                    message.operatingExpensesTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.operatingExpensesTotal);
                    break;
                case /* repeated rd.accounting.IncomeStatement.AccountLineItem operating_expenses_accounts */ 6:
                    message.operatingExpensesAccounts.push(IncomeStatement_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money gross_profit */ 7:
                    message.grossProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossProfit);
                    break;
                case /* rd.proto.decimal.Decimal gross_profit_margin */ 8:
                    message.grossProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.grossProfitMargin);
                    break;
                case /* rd.proto.money.Money net_profit */ 9:
                    message.netProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.netProfit);
                    break;
                case /* rd.proto.decimal.Decimal net_profit_margin */ 10:
                    message.netProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.netProfitMargin);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncomeStatement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money income_total = 1; */
        if (message.incomeTotal)
            Money.internalBinaryWrite(message.incomeTotal, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.IncomeStatement.AccountLineItem income_accounts = 2; */
        for (let i = 0; i < message.incomeAccounts.length; i++)
            IncomeStatement_AccountLineItem.internalBinaryWrite(message.incomeAccounts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cogs_total = 3; */
        if (message.cogsTotal)
            Money.internalBinaryWrite(message.cogsTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.IncomeStatement.AccountLineItem cogs_accounts = 4; */
        for (let i = 0; i < message.cogsAccounts.length; i++)
            IncomeStatement_AccountLineItem.internalBinaryWrite(message.cogsAccounts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money operating_expenses_total = 5; */
        if (message.operatingExpensesTotal)
            Money.internalBinaryWrite(message.operatingExpensesTotal, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.IncomeStatement.AccountLineItem operating_expenses_accounts = 6; */
        for (let i = 0; i < message.operatingExpensesAccounts.length; i++)
            IncomeStatement_AccountLineItem.internalBinaryWrite(message.operatingExpensesAccounts[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross_profit = 7; */
        if (message.grossProfit)
            Money.internalBinaryWrite(message.grossProfit, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal gross_profit_margin = 8; */
        if (message.grossProfitMargin)
            Decimal.internalBinaryWrite(message.grossProfitMargin, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money net_profit = 9; */
        if (message.netProfit)
            Money.internalBinaryWrite(message.netProfit, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal net_profit_margin = 10; */
        if (message.netProfitMargin)
            Decimal.internalBinaryWrite(message.netProfitMargin, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.IncomeStatement
 */
export const IncomeStatement = new IncomeStatement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncomeStatement_AccountLineItem$Type extends MessageType<IncomeStatement_AccountLineItem> {
    constructor() {
        super("rd.accounting.IncomeStatement.AccountLineItem", [
            { no: 1, name: "total", kind: "message", T: () => Money },
            { no: 3, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<IncomeStatement_AccountLineItem>): IncomeStatement_AccountLineItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IncomeStatement_AccountLineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncomeStatement_AccountLineItem): IncomeStatement_AccountLineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money total */ 1:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.coa.Account account */ 3:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncomeStatement_AccountLineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money total = 1; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.coa.Account account = 3; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.IncomeStatement.AccountLineItem
 */
export const IncomeStatement_AccountLineItem = new IncomeStatement_AccountLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BalanceSheet$Type extends MessageType<BalanceSheet> {
    constructor() {
        super("rd.accounting.BalanceSheet", [
            { no: 1, name: "asset_total", kind: "message", T: () => Money },
            { no: 2, name: "asset_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BalanceSheet_AccountLineItem },
            { no: 3, name: "liability_total", kind: "message", T: () => Money },
            { no: 4, name: "liability_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BalanceSheet_AccountLineItem },
            { no: 5, name: "equity_total", kind: "message", T: () => Money },
            { no: 6, name: "equity_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BalanceSheet_AccountLineItem },
            { no: 7, name: "liability_and_equity_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<BalanceSheet>): BalanceSheet {
        const message = { assetAccounts: [], liabilityAccounts: [], equityAccounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BalanceSheet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BalanceSheet): BalanceSheet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money asset_total */ 1:
                    message.assetTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.assetTotal);
                    break;
                case /* repeated rd.accounting.BalanceSheet.AccountLineItem asset_accounts */ 2:
                    message.assetAccounts.push(BalanceSheet_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money liability_total */ 3:
                    message.liabilityTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.liabilityTotal);
                    break;
                case /* repeated rd.accounting.BalanceSheet.AccountLineItem liability_accounts */ 4:
                    message.liabilityAccounts.push(BalanceSheet_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money equity_total */ 5:
                    message.equityTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.equityTotal);
                    break;
                case /* repeated rd.accounting.BalanceSheet.AccountLineItem equity_accounts */ 6:
                    message.equityAccounts.push(BalanceSheet_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money liability_and_equity_total */ 7:
                    message.liabilityAndEquityTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.liabilityAndEquityTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BalanceSheet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money asset_total = 1; */
        if (message.assetTotal)
            Money.internalBinaryWrite(message.assetTotal, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.BalanceSheet.AccountLineItem asset_accounts = 2; */
        for (let i = 0; i < message.assetAccounts.length; i++)
            BalanceSheet_AccountLineItem.internalBinaryWrite(message.assetAccounts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money liability_total = 3; */
        if (message.liabilityTotal)
            Money.internalBinaryWrite(message.liabilityTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.BalanceSheet.AccountLineItem liability_accounts = 4; */
        for (let i = 0; i < message.liabilityAccounts.length; i++)
            BalanceSheet_AccountLineItem.internalBinaryWrite(message.liabilityAccounts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money equity_total = 5; */
        if (message.equityTotal)
            Money.internalBinaryWrite(message.equityTotal, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.BalanceSheet.AccountLineItem equity_accounts = 6; */
        for (let i = 0; i < message.equityAccounts.length; i++)
            BalanceSheet_AccountLineItem.internalBinaryWrite(message.equityAccounts[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money liability_and_equity_total = 7; */
        if (message.liabilityAndEquityTotal)
            Money.internalBinaryWrite(message.liabilityAndEquityTotal, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.BalanceSheet
 */
export const BalanceSheet = new BalanceSheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BalanceSheet_AccountLineItem$Type extends MessageType<BalanceSheet_AccountLineItem> {
    constructor() {
        super("rd.accounting.BalanceSheet.AccountLineItem", [
            { no: 1, name: "total", kind: "message", T: () => Money },
            { no: 2, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<BalanceSheet_AccountLineItem>): BalanceSheet_AccountLineItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BalanceSheet_AccountLineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BalanceSheet_AccountLineItem): BalanceSheet_AccountLineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money total */ 1:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.coa.Account account */ 2:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BalanceSheet_AccountLineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money total = 1; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.coa.Account account = 2; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.BalanceSheet.AccountLineItem
 */
export const BalanceSheet_AccountLineItem = new BalanceSheet_AccountLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalTransaction$Type extends MessageType<ExternalTransaction> {
    constructor() {
        super("rd.accounting.ExternalTransaction", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "location_id", kind: "message", T: () => UUID },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "created_by", kind: "message", T: () => User },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "updated_by", kind: "message", T: () => User },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "amount", kind: "message", T: () => Money },
            { no: 10, name: "type", kind: "enum", T: () => ["rd.accounting.ExternalTransaction.Type", ExternalTransaction_Type, "TYPE_"] },
            { no: 11, name: "transacted_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "payment_method", kind: "enum", T: () => ["rd.accounting.ExternalTransaction.PaymentMethod", ExternalTransaction_PaymentMethod, "PAYMENT_METHOD_"] },
            { no: 13, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 14, name: "customer_id", kind: "message", T: () => UUID },
            { no: 16, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "allocations_by_chart_of_account_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<ExternalTransaction>): ExternalTransaction {
        const message = { isActive: false, type: 0, paymentMethod: 0, description: "", allocationsByChartOfAccountId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExternalTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExternalTransaction): ExternalTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 3:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 6:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User updated_by */ 7:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* rd.proto.money.Money amount */ 9:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.accounting.ExternalTransaction.Type type */ 10:
                    message.type = reader.int32();
                    break;
                case /* google.protobuf.Timestamp transacted_at */ 11:
                    message.transactedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.transactedAt);
                    break;
                case /* rd.accounting.ExternalTransaction.PaymentMethod payment_method */ 12:
                    message.paymentMethod = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 13:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 14:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* string description */ 16:
                    message.description = reader.string();
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_chart_of_account_id */ 17:
                    this.binaryReadMap17(message.allocationsByChartOfAccountId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap17(map: ExternalTransaction["allocationsByChartOfAccountId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ExternalTransaction["allocationsByChartOfAccountId"] | undefined, val: ExternalTransaction["allocationsByChartOfAccountId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.accounting.ExternalTransaction.allocations_by_chart_of_account_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: ExternalTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 3; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 6; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 7; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* rd.proto.money.Money amount = 9; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.accounting.ExternalTransaction.Type type = 10; */
        if (message.type !== 0)
            writer.tag(10, WireType.Varint).int32(message.type);
        /* google.protobuf.Timestamp transacted_at = 11; */
        if (message.transactedAt)
            Timestamp.internalBinaryWrite(message.transactedAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.accounting.ExternalTransaction.PaymentMethod payment_method = 12; */
        if (message.paymentMethod !== 0)
            writer.tag(12, WireType.Varint).int32(message.paymentMethod);
        /* rd.proto.uuid.UUID vendor_id = 13; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 14; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string description = 16; */
        if (message.description !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.description);
        /* map<string, rd.proto.money.Money> allocations_by_chart_of_account_id = 17; */
        for (let k of Object.keys(message.allocationsByChartOfAccountId)) {
            writer.tag(17, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsByChartOfAccountId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.ExternalTransaction
 */
export const ExternalTransaction = new ExternalTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalTransactionLog$Type extends MessageType<ExternalTransactionLog> {
    constructor() {
        super("rd.accounting.ExternalTransactionLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "external_transaction", kind: "message", T: () => ExternalTransaction }
        ]);
    }
    create(value?: PartialMessage<ExternalTransactionLog>): ExternalTransactionLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExternalTransactionLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExternalTransactionLog): ExternalTransactionLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.accounting.ExternalTransaction external_transaction */ 4:
                    message.externalTransaction = ExternalTransaction.internalBinaryRead(reader, reader.uint32(), options, message.externalTransaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExternalTransactionLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.accounting.ExternalTransaction external_transaction = 4; */
        if (message.externalTransaction)
            ExternalTransaction.internalBinaryWrite(message.externalTransaction, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.ExternalTransactionLog
 */
export const ExternalTransactionLog = new ExternalTransactionLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JournalEntries$Type extends MessageType<JournalEntries> {
    constructor() {
        super("rd.accounting.JournalEntries", [
            { no: 1, name: "account_line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JournalEntries_AccountLineItem },
            { no: 2, name: "debit_total", kind: "message", T: () => Money },
            { no: 3, name: "credit_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<JournalEntries>): JournalEntries {
        const message = { accountLineItems: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JournalEntries>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JournalEntries): JournalEntries {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.accounting.JournalEntries.AccountLineItem account_line_items */ 1:
                    message.accountLineItems.push(JournalEntries_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money debit_total */ 2:
                    message.debitTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.debitTotal);
                    break;
                case /* rd.proto.money.Money credit_total */ 3:
                    message.creditTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.creditTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JournalEntries, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.accounting.JournalEntries.AccountLineItem account_line_items = 1; */
        for (let i = 0; i < message.accountLineItems.length; i++)
            JournalEntries_AccountLineItem.internalBinaryWrite(message.accountLineItems[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money debit_total = 2; */
        if (message.debitTotal)
            Money.internalBinaryWrite(message.debitTotal, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money credit_total = 3; */
        if (message.creditTotal)
            Money.internalBinaryWrite(message.creditTotal, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.JournalEntries
 */
export const JournalEntries = new JournalEntries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JournalEntries_AccountLineItem$Type extends MessageType<JournalEntries_AccountLineItem> {
    constructor() {
        super("rd.accounting.JournalEntries.AccountLineItem", [
            { no: 1, name: "debit", kind: "message", T: () => Money },
            { no: 2, name: "credit", kind: "message", T: () => Money },
            { no: 3, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<JournalEntries_AccountLineItem>): JournalEntries_AccountLineItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JournalEntries_AccountLineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JournalEntries_AccountLineItem): JournalEntries_AccountLineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money debit */ 1:
                    message.debit = Money.internalBinaryRead(reader, reader.uint32(), options, message.debit);
                    break;
                case /* rd.proto.money.Money credit */ 2:
                    message.credit = Money.internalBinaryRead(reader, reader.uint32(), options, message.credit);
                    break;
                case /* rd.coa.Account account */ 3:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JournalEntries_AccountLineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money debit = 1; */
        if (message.debit)
            Money.internalBinaryWrite(message.debit, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money credit = 2; */
        if (message.credit)
            Money.internalBinaryWrite(message.credit, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.coa.Account account = 3; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.JournalEntries.AccountLineItem
 */
export const JournalEntries_AccountLineItem = new JournalEntries_AccountLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JournalEntriesPDFData$Type extends MessageType<JournalEntriesPDFData> {
    constructor() {
        super("rd.accounting.JournalEntriesPDFData", [
            { no: 1, name: "account_line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JournalEntriesPDFData_AccountLineItem },
            { no: 2, name: "debit_total", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "credit_total", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timestamp_range", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "location", kind: "message", T: () => Location },
            { no: 7, name: "company", kind: "message", T: () => Company }
        ]);
    }
    create(value?: PartialMessage<JournalEntriesPDFData>): JournalEntriesPDFData {
        const message = { accountLineItems: [], debitTotal: "", creditTotal: "", timestampRange: "", locationName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JournalEntriesPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JournalEntriesPDFData): JournalEntriesPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.accounting.JournalEntriesPDFData.AccountLineItem account_line_items */ 1:
                    message.accountLineItems.push(JournalEntriesPDFData_AccountLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string debit_total */ 2:
                    message.debitTotal = reader.string();
                    break;
                case /* string credit_total */ 3:
                    message.creditTotal = reader.string();
                    break;
                case /* string timestamp_range */ 4:
                    message.timestampRange = reader.string();
                    break;
                case /* string location_name */ 5:
                    message.locationName = reader.string();
                    break;
                case /* rd.company.Location location */ 6:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.company.Company company */ 7:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JournalEntriesPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.accounting.JournalEntriesPDFData.AccountLineItem account_line_items = 1; */
        for (let i = 0; i < message.accountLineItems.length; i++)
            JournalEntriesPDFData_AccountLineItem.internalBinaryWrite(message.accountLineItems[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string debit_total = 2; */
        if (message.debitTotal !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.debitTotal);
        /* string credit_total = 3; */
        if (message.creditTotal !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.creditTotal);
        /* string timestamp_range = 4; */
        if (message.timestampRange !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timestampRange);
        /* string location_name = 5; */
        if (message.locationName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locationName);
        /* rd.company.Location location = 6; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 7; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.JournalEntriesPDFData
 */
export const JournalEntriesPDFData = new JournalEntriesPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JournalEntriesPDFData_AccountLineItem$Type extends MessageType<JournalEntriesPDFData_AccountLineItem> {
    constructor() {
        super("rd.accounting.JournalEntriesPDFData.AccountLineItem", [
            { no: 1, name: "debit_amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "credit_amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<JournalEntriesPDFData_AccountLineItem>): JournalEntriesPDFData_AccountLineItem {
        const message = { debitAmount: "", creditAmount: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JournalEntriesPDFData_AccountLineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JournalEntriesPDFData_AccountLineItem): JournalEntriesPDFData_AccountLineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string debit_amount */ 1:
                    message.debitAmount = reader.string();
                    break;
                case /* string credit_amount */ 2:
                    message.creditAmount = reader.string();
                    break;
                case /* rd.coa.Account account */ 3:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JournalEntriesPDFData_AccountLineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string debit_amount = 1; */
        if (message.debitAmount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.debitAmount);
        /* string credit_amount = 2; */
        if (message.creditAmount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.creditAmount);
        /* rd.coa.Account account = 3; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.JournalEntriesPDFData.AccountLineItem
 */
export const JournalEntriesPDFData_AccountLineItem = new JournalEntriesPDFData_AccountLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PayoutReconciliation$Type extends MessageType<PayoutReconciliation> {
    constructor() {
        super("rd.accounting.PayoutReconciliation", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PayoutReconciliation_Transaction },
            { no: 2, name: "net_total", kind: "message", T: () => Money },
            { no: 3, name: "net_total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "net_paid", kind: "message", T: () => Money },
            { no: 5, name: "net_paid_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "net_pending", kind: "message", T: () => Money },
            { no: 7, name: "net_pending_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "net_in_transit", kind: "message", T: () => Money },
            { no: 9, name: "net_in_transit_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PayoutReconciliation>): PayoutReconciliation {
        const message = { transactions: [], netTotalCount: 0, netPaidCount: 0, netPendingCount: 0, netInTransitCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PayoutReconciliation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PayoutReconciliation): PayoutReconciliation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.accounting.PayoutReconciliation.Transaction transactions */ 1:
                    message.transactions.push(PayoutReconciliation_Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money net_total */ 2:
                    message.netTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.netTotal);
                    break;
                case /* int32 net_total_count */ 3:
                    message.netTotalCount = reader.int32();
                    break;
                case /* rd.proto.money.Money net_paid */ 4:
                    message.netPaid = Money.internalBinaryRead(reader, reader.uint32(), options, message.netPaid);
                    break;
                case /* int32 net_paid_count */ 5:
                    message.netPaidCount = reader.int32();
                    break;
                case /* rd.proto.money.Money net_pending */ 6:
                    message.netPending = Money.internalBinaryRead(reader, reader.uint32(), options, message.netPending);
                    break;
                case /* int32 net_pending_count */ 7:
                    message.netPendingCount = reader.int32();
                    break;
                case /* rd.proto.money.Money net_in_transit */ 8:
                    message.netInTransit = Money.internalBinaryRead(reader, reader.uint32(), options, message.netInTransit);
                    break;
                case /* int32 net_in_transit_count */ 9:
                    message.netInTransitCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PayoutReconciliation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.accounting.PayoutReconciliation.Transaction transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            PayoutReconciliation_Transaction.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money net_total = 2; */
        if (message.netTotal)
            Money.internalBinaryWrite(message.netTotal, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 net_total_count = 3; */
        if (message.netTotalCount !== 0)
            writer.tag(3, WireType.Varint).int32(message.netTotalCount);
        /* rd.proto.money.Money net_paid = 4; */
        if (message.netPaid)
            Money.internalBinaryWrite(message.netPaid, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 net_paid_count = 5; */
        if (message.netPaidCount !== 0)
            writer.tag(5, WireType.Varint).int32(message.netPaidCount);
        /* rd.proto.money.Money net_pending = 6; */
        if (message.netPending)
            Money.internalBinaryWrite(message.netPending, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 net_pending_count = 7; */
        if (message.netPendingCount !== 0)
            writer.tag(7, WireType.Varint).int32(message.netPendingCount);
        /* rd.proto.money.Money net_in_transit = 8; */
        if (message.netInTransit)
            Money.internalBinaryWrite(message.netInTransit, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 net_in_transit_count = 9; */
        if (message.netInTransitCount !== 0)
            writer.tag(9, WireType.Varint).int32(message.netInTransitCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.PayoutReconciliation
 */
export const PayoutReconciliation = new PayoutReconciliation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PayoutReconciliation_Transaction$Type extends MessageType<PayoutReconciliation_Transaction> {
    constructor() {
        super("rd.accounting.PayoutReconciliation.Transaction", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.accounting.PayoutReconciliation.Transaction.Type", PayoutReconciliation_Transaction_Type, "TYPE_"] },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 4, name: "balance_transaction", kind: "message", T: () => BalanceTransaction },
            { no: 5, name: "payout", kind: "message", T: () => Payout },
            { no: 6, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 7, name: "refund_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 8, name: "gross", kind: "message", T: () => Money },
            { no: 9, name: "fee", kind: "message", T: () => Money },
            { no: 10, name: "net", kind: "message", T: () => Money },
            { no: 11, name: "transacted_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "expected_at", kind: "message", T: () => Timestamp },
            { no: 13, name: "sale_return", kind: "message", T: () => Sale_Return }
        ]);
    }
    create(value?: PartialMessage<PayoutReconciliation_Transaction>): PayoutReconciliation_Transaction {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PayoutReconciliation_Transaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PayoutReconciliation_Transaction): PayoutReconciliation_Transaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.accounting.PayoutReconciliation.Transaction.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 3:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.payments.BalanceTransaction balance_transaction */ 4:
                    message.balanceTransaction = BalanceTransaction.internalBinaryRead(reader, reader.uint32(), options, message.balanceTransaction);
                    break;
                case /* rd.payments.Payout payout */ 5:
                    message.payout = Payout.internalBinaryRead(reader, reader.uint32(), options, message.payout);
                    break;
                case /* rd.txn.LedgerEntry payment_ledger_entry */ 6:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* rd.txn.LedgerEntry refund_ledger_entry */ 7:
                    message.refundLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.refundLedgerEntry);
                    break;
                case /* rd.proto.money.Money gross */ 8:
                    message.gross = Money.internalBinaryRead(reader, reader.uint32(), options, message.gross);
                    break;
                case /* rd.proto.money.Money fee */ 9:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                case /* rd.proto.money.Money net */ 10:
                    message.net = Money.internalBinaryRead(reader, reader.uint32(), options, message.net);
                    break;
                case /* google.protobuf.Timestamp transacted_at */ 11:
                    message.transactedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.transactedAt);
                    break;
                case /* google.protobuf.Timestamp expected_at */ 12:
                    message.expectedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expectedAt);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 13:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PayoutReconciliation_Transaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.accounting.PayoutReconciliation.Transaction.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 3; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.BalanceTransaction balance_transaction = 4; */
        if (message.balanceTransaction)
            BalanceTransaction.internalBinaryWrite(message.balanceTransaction, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Payout payout = 5; */
        if (message.payout)
            Payout.internalBinaryWrite(message.payout, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry payment_ledger_entry = 6; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry refund_ledger_entry = 7; */
        if (message.refundLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.refundLedgerEntry, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross = 8; */
        if (message.gross)
            Money.internalBinaryWrite(message.gross, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money fee = 9; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money net = 10; */
        if (message.net)
            Money.internalBinaryWrite(message.net, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp transacted_at = 11; */
        if (message.transactedAt)
            Timestamp.internalBinaryWrite(message.transactedAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expected_at = 12; */
        if (message.expectedAt)
            Timestamp.internalBinaryWrite(message.expectedAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 13; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.accounting.PayoutReconciliation.Transaction
 */
export const PayoutReconciliation_Transaction = new PayoutReconciliation_Transaction$Type();
