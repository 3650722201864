import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { Location } from "gen/company/models_pb"
import { TimestampRange } from "gen/proto/timestamprange/models_pb"

export interface OrdersSearchFiltersState {
  filters: {
    dateRange: TimestampRange | null
    locations: Location[]
  }
  searchQuery: string
  isReady: boolean
}

const initialState: OrdersSearchFiltersState = {
  filters: {
    dateRange: null,
    locations: [],
  },
  searchQuery: "",
  isReady: false,
}

export const ordersSearchSlice = createSlice({
  name: "ordersSearch",
  initialState,
  reducers: {
    resetOrdersSearchFilters: (state, action: PayloadAction<Location[]>) => {
      const filters = { ...initialState.filters, locations: action.payload }
      return {
        ...initialState,
        filters,
        isReady: state.isReady,
      }
    },
    setOrdersSearchDateRangeFilter: (
      state,
      action: PayloadAction<TimestampRange | null>
    ) => {
      state.filters.dateRange = action.payload
    },
    setOrdersSearchLocationsFilter: (
      state,
      action: PayloadAction<Location[]>
    ) => {
      state.filters.locations = action.payload
    },
    setOrdersSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    setOrdersSearchIsReady: (state, _: PayloadAction<undefined>) => {
      state.isReady = true
    },
  },
})

export const {
  resetOrdersSearchFilters,
  setOrdersSearchDateRangeFilter,
  setOrdersSearchLocationsFilter,
  setOrdersSearchQuery,
  setOrdersSearchIsReady,
} = ordersSearchSlice.actions

export const selectOrdersSearchDateRangeFilter = (state: AppState) =>
  state.ordersSearch.filters.dateRange
export const selectOrdersSearchLocationsFilter = (state: AppState) =>
  state.ordersSearch.filters.locations
export const selectOrdersSearchQuery = (state: AppState) =>
  state.ordersSearch.searchQuery
