import {
  DisabledModeT,
  useTextDateInput,
} from "components/TextDateInput/TextDateInput"
import { PopoverTextInput, PopoverTextInputProps } from "../PopoverTextInput"

import { forwardRef } from "react"

interface TextDateInputProps extends PopoverTextInputProps {
  date: Date | null
  onChangeDate: (date: Date | null) => void
  defaultRefDate: Date
  format?: string
  disabledMode?: DisabledModeT
}

export const TextDateInput = forwardRef<HTMLInputElement, TextDateInputProps>(
  (
    {
      onChangeDate,
      date,
      format = "MM/dd/yyyy",
      defaultRefDate,
      disabledMode,
      ...otherProps
    },
    ref
  ) => {
    const { innerRef, dateString, onChange, onBlur, onKeyDown } =
      useTextDateInput({
        ref,
        date,
        defaultRefDate,
        format,
        disabledMode,
        onChangeDate,
      })

    return (
      <PopoverTextInput
        ref={innerRef}
        value={dateString}
        onChange={onChange}
        className="h-8 w-[6.75rem]"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        {...otherProps}
      />
    )
  }
)
