import { HeroIconProps, IconSize } from "../HeroIcon/HeroIcon"

import { Button } from "../Button"
import { HTMLAttributes } from "react"
import { HeroIcon } from "../HeroIcon"
import { TrashIcon } from "@heroicons/react/outline"

interface FilterHeaderRowProps {
  label: string
  onClickDelete: () => void
}

export function FilterHeaderRow({
  label,
  onClickDelete,
}: FilterHeaderRowProps) {
  return (
    <div className="flex flex-row justify-between px-3 pt-2">
      <div className="flex flex-row items-center gap-2">
        <span>{label}</span>
      </div>
      <IconButton
        onClick={onClickDelete}
        Icon={TrashIcon}
        wrapperSize={IconSize.ExtraLarge}
        iconSize={IconSize.Small}
      />
    </div>
  )
}

type IconButtonProps = Pick<
  HeroIconProps,
  "Icon" | "iconSize" | "wrapperSize"
> &
  HTMLAttributes<HTMLButtonElement>

export function IconButton({
  Icon,
  iconSize,
  wrapperSize,
  ...otherProps
}: IconButtonProps) {
  return (
    <Button
      mode="secondary"
      className="text-secondary rounded-full border-none p-0"
      {...otherProps}
    >
      <HeroIcon Icon={Icon} iconSize={iconSize} wrapperSize={wrapperSize} />
    </Button>
  )
}
