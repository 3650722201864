import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import {
  Interval,
  addWeeks,
  eachDayOfInterval,
  format,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns"

import { DisabledModeT } from "components/TextDateInput/TextDateInput"
import { MouseEvent } from "react"
import { PartialInterval } from "lib/dates/interval"
import { TimestampRangePickerCalendarDay } from "./TimestampRangePickerCalendarDay"

interface TimestampRangePickerCalendarProps {
  selection: PartialInterval | null
  currentlySelecting: "from" | "to"
  onDateClicked: (date: Date) => void
  onClickPreviousMonth: () => void
  onClickNextMonth: () => void
  monthDisplayed: Date
  disabledMode: DisabledModeT
}

export function TimestampRangePickerCalendar({
  selection,
  currentlySelecting,
  onDateClicked,
  onClickPreviousMonth,
  onClickNextMonth,
  monthDisplayed,
  disabledMode,
}: TimestampRangePickerCalendarProps) {
  function onLeftClick(e: MouseEvent) {
    e.preventDefault()
    onClickPreviousMonth()
  }

  function onRightClick(e: MouseEvent) {
    e.preventDefault()
    onClickNextMonth()
  }

  return (
    <>
      <div className="flex flex-row justify-between">
        <button
          type="button"
          onMouseDown={onLeftClick}
          data-testid="decrement-month-button"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        <div className="text-xs font-bold uppercase tracking-wide">
          {format(monthDisplayed, "MMMM yyyy")}
        </div>
        <button
          type="button"
          onMouseDown={onRightClick}
          data-testid="increment-month-button"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-x-6 gap-y-2">
        {daysOfWeek.map(({ id, D }) => (
          <div
            key={id}
            className="select-none text-center text-sm font-semibold"
          >
            {D}
          </div>
        ))}

        {eachDayOfInterval(getDisplayDays(monthDisplayed)).map((day) => {
          return (
            <TimestampRangePickerCalendarDay
              key={day.toDateString()}
              day={day}
              monthDisplayed={monthDisplayed}
              selection={selection}
              currentlySelecting={currentlySelecting}
              onDateClicked={onDateClicked}
              disabledMode={disabledMode}
            />
          )
        })}
      </div>
    </>
  )
}

export function getDisplayDays(monthDisplayed: Date): Interval {
  const displayStart = startOfWeek(startOfMonth(monthDisplayed))
  return {
    start: displayStart,
    end: subDays(addWeeks(displayStart, 6), 1),
  }
}

export const daysOfWeek = [
  { id: "sunday", D: "S", Dd: "Su" },
  { id: "monday", D: "M", Dd: "Mo" },
  { id: "tuesday", D: "T", Dd: "Tu" },
  { id: "wednesday", D: "W", Dd: "We" },
  { id: "thursday", D: "T", Dd: "Th" },
  { id: "friday", D: "F", Dd: "Fr" },
  { id: "saturday", D: "S", Dd: "Sa" },
]
