import { ComponentPropsWithRef, MouseEventHandler, forwardRef } from "react"

import { KeyboardTile } from "../KeyboardTile"
import { LoadingSpinner } from "components/LoadingSpinner"
import { classNames } from "lib/classNames"

type ButtonMode =
  | "primary"
  | "primaryDarkBackground"
  | "secondary"
  | "positive"
  | "positiveDarkBackground"
  | "negative"
  | "destructive"
  | "selectedTab"
  | "tab"
  | "input"

export interface ButtonProps extends ComponentPropsWithRef<"button"> {
  mode: ButtonMode
  loading?: boolean
  keyboardShortcut?: string | string[]
  keyboardShortcutPosition?: "inButton" | "topRight"
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      mode,
      loading = false,
      onClick,
      keyboardShortcut,
      keyboardShortcutPosition,
      ...otherProps
    } = props

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation()

      if (loading) {
        return
      }

      if (onClick) {
        onClick(event)
      }
    }

    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          "min-h-10 group relative inline-flex items-center justify-center whitespace-nowrap rounded px-4 py-2.5 text-sm font-semibold",
          getClassNames(mode, loading),
          className
        )}
        onClick={handleClick}
        {...otherProps}
      >
        <LoadingSpinner
          pathClassName="opacity-50"
          className={classNames(!loading && "invisible", "absolute")}
        />
        <div className={classNames(loading && "invisible")}>{children}</div>
        {Array.isArray(keyboardShortcut) ? (
          <div className="absolute inset-y-2 right-2.5 hidden gap-x-1 group-hover:flex">
            {keyboardShortcut.map((key) => (
              <KeyboardTile
                key={key}
                color={getKeyboardTileColor(mode)}
                keyboardKey={key}
              />
            ))}
          </div>
        ) : keyboardShortcut ? (
          <KeyboardTile
            keyboardKey={keyboardShortcut}
            className={classNames(
              "absolute right-2.5 hidden group-hover:flex",
              keyboardShortcutPosition === "inButton" && "right-2.5",
              keyboardShortcutPosition === "topRight" && "-right-3 -top-3"
            )}
            {...(keyboardShortcutPosition === "inButton" && {
              color: getKeyboardTileColor(mode),
            })}
          />
        ) : null}
      </button>
    )
  }
)

function getClassNames(mode: ButtonMode, loading: boolean): string {
  switch (mode) {
    case "primary": {
      return classNames(
        "bg-cobalt-400 dark:bg-cobalt-300 text-white hover:bg-cobalt-300 dark:hover:bg-cobalt-400 active:bg-cobalt-600 dark:active:bg-cobalt-600",
        !loading &&
          "disabled:bg-gray-300 dark:disabled:bg-gray-600 disabled:text-gray-600 dark:disabled:text-gray-300"
      )
    }
    case "primaryDarkBackground": {
      return classNames(
        "bg-cobalt-300 text-white hover:bg-cobalt-400 active:bg-cobalt-600",
        !loading && "disabled:bg-gray-600 disabled:text-gray-300"
      )
    }
    case "secondary": {
      return classNames(
        "text-black dark:text-white border border-gray-300 dark:border-gray-500 bg-white dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-600 active:bg-white dark:active:bg-gray-800",
        !loading &&
          "disabled:bg-white dark:disabled:bg-gray-800 disabled:text-gray-500 dark:disabled:text-gray-400"
      )
    }
    case "negative": {
      return classNames(
        "text-red-500 border border-red-500 dark:border-red-300 bg-transparent hover:bg-red-50 dark:hover:bg-red-900 active:bg-red-100 dark:active:bg-red-800",
        !loading &&
          "disabled:bg-white dark:disabled:bg-gray-800 disabled:text-gray-500 dark:disabled:text-gray-400 disabled:border-gray-300 dark:disabled:border-gray-500"
      )
    }
    case "positive": {
      return classNames(
        "border border-cobalt-400 dark:border-cobalt-200 text-cobalt-400 dark:text-cobalt-200 bg-transparent hover:bg-cobalt-50 dark:hover:bg-cobalt-700 active:bg-cobalt-100 dark:active:bg-cobalt-600",
        !loading &&
          "disabled:bg-white dark:disabled:bg-gray-800 disabled:text-gray-500 dark:disabled:text-gray-400 disabled:border-gray-300 dark:disabled:border-gray-500"
      )
    }
    case "positiveDarkBackground": {
      return classNames(
        "border border-cobalt-200 text-cobalt-200 bg-transparent hover:bg-cobalt-700 active:bg-cobalt-600",
        !loading &&
          "disabled:bg-gray-800 disabled:text-gray-400 disabled:border-gray-500"
      )
    }
    case "destructive": {
      return classNames(
        "btn bg-red-600 dark:bg-red-700 text-white hover:bg-red-500 dark:hover:bg-red-800 active:bg-red-700 dark:active:bg-red-900",
        !loading &&
          "disabled:bg-gray-300 disabled:text-gray-600 dark:disabled:bg-gray-600 dark:disabled:text-gray-300"
      )
    }
    case "selectedTab": {
      return classNames(
        "bg-cobalt-100 dark:bg-cobalt-200 text-cobalt-400 dark:text-cobalt-700 hover:bg-cobalt-200 dark:hover:bg-cobalt-100 active:bg-cobalt-200 dark:active:bg-cobalt-100",
        !loading &&
          "disabled:bg-gray-300 dark:disabled:bg-gray-600 disabled:text-gray-600 dark:disabled:text-gray-300"
      )
    }
    case "tab": {
      return classNames(
        "text-gray-600 hover:bg-cobalt-200 hover:text-cobalt-400 dark:text-gray-300 dark:hover:bg-cobalt-100 dark:hover:text-cobalt-700",
        !loading && "disabled:hover:bg-white disabled:hover:text-gray-600"
      )
    }
    case "input": {
      return classNames(
        "text-secondary border border-primary surface-primary hover:surface-accent"
      )
    }
    default: {
      throw new Error("unexpected button mode!")
    }
  }
}

function getKeyboardTileColor(mode: ButtonMode) {
  switch (mode) {
    case "primaryDarkBackground":
      return "blue"
    case "positive":
      return "light-blue"
    default: {
      throw new Error("unexpected button mode!")
    }
  }
}

Button.displayName = "Button"
