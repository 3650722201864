import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
} from "react"
import { PopoverTextInput, PopoverTextInputProps } from "../PopoverTextInput"

import { UseComboboxResult } from "./useCombobox"
import { classNames } from "lib/classNames"
import { popoverRowClassName } from "../Popover"

export interface ComboboxProps<T> {
  useComboboxRes: UseComboboxResult<T>
  renderItem: (item: T, isHighlighted: boolean) => ReactNode
  renderEmptyState: () => ReactNode
  keyExtractor: (item: T) => string
  comboboxContainerProps?: ComboboxContainerProps
  comboboxInputContainerProps?: ComboboxInputContainerProps
  comboboxListProps?: ComboboxListProps
  comboboxListItemProps?: ComboboxListItemProps
  comboboxInputProps?: PopoverTextInputProps
}

export function Combobox<T>({
  renderItem,
  renderEmptyState,
  keyExtractor,
  comboboxContainerProps,
  comboboxInputContainerProps,
  comboboxListProps,
  comboboxListItemProps,
  comboboxInputProps,
  useComboboxRes: useComboboxReturnValue,
}: ComboboxProps<T>) {
  const {
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    isOpen,
    filteredItems,
  } = useComboboxReturnValue

  return (
    <ComboboxContainer {...comboboxContainerProps}>
      <ComboboxInputContainer {...comboboxInputContainerProps}>
        <PopoverTextInput {...getInputProps(comboboxInputProps)} />
      </ComboboxInputContainer>
      <ComboboxList {...getMenuProps(comboboxListProps)}>
        {isOpen &&
          (inputValue && filteredItems.length === 0 ? (
            <ComboboxListItem {...comboboxListItemProps}>
              {renderEmptyState()}
            </ComboboxListItem>
          ) : (
            filteredItems.map((item, index) => (
              <ComboboxListItem
                key={keyExtractor(item)}
                {...getItemProps({
                  item,
                  index,
                  ...comboboxListItemProps,
                })}
              >
                {renderItem(item, highlightedIndex === index)}
              </ComboboxListItem>
            ))
          ))}
      </ComboboxList>
    </ComboboxContainer>
  )
}

export function DefaultEmptyState() {
  return <div className={popoverRowClassName}>No results found</div>
}

interface DefaultRowProps<T> {
  item: T
  isHighlighted: boolean
  accessoryExtractor?: (item: T) => ReactNode
  valueExtractor: (item: T) => string
}
export function DefaultRow<T>({
  item,
  isHighlighted,
  valueExtractor,
  accessoryExtractor,
}: DefaultRowProps<T>) {
  const accessory = accessoryExtractor?.(item)

  return (
    <div
      className={classNames(
        highlightedRowClassName(isHighlighted),
        accessory ? "flex items-center gap-x-2" : undefined
      )}
    >
      {accessory}
      {valueExtractor(item)}
    </div>
  )
}
export function highlightedRowClassName(isHighlighted: boolean) {
  return classNames(popoverRowClassName, isHighlighted && "surface-accent")
}

type ComboboxContainerProps = ComponentPropsWithoutRef<"div">
function ComboboxContainer({
  children,
  className,
  ...props
}: ComboboxContainerProps) {
  return (
    <div className={classNames("w-60", className)} {...props}>
      {children}
    </div>
  )
}

type ComboboxInputContainerProps = ComponentPropsWithoutRef<"div">
function ComboboxInputContainer({
  children,
  className,
  ...props
}: ComboboxInputContainerProps) {
  return (
    <div className={classNames("p-2", className)} {...props}>
      {children}
    </div>
  )
}

type ComboboxListProps = ComponentPropsWithRef<"ul">
const ComboboxList = forwardRef<HTMLUListElement, ComboboxListProps>(
  ({ children, ...props }, ref) => {
    return (
      <ul ref={ref} {...props}>
        {children}
      </ul>
    )
  }
)

type ComboboxListItemProps = ComponentPropsWithRef<"li">
const ComboboxListItem = forwardRef<HTMLLIElement, ComboboxListItemProps>(
  ({ children, ...props }, ref) => {
    return (
      <li ref={ref} {...props}>
        {children}
      </li>
    )
  }
)
