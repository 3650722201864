import { AnchorProps } from "@headlessui/react/dist/internal/floating"
import { classNames } from "lib/classNames"

export const popoverClassName = classNames(
  "surface-primary",
  "border border-primary",
  "w-full",
  "rounded-lg",
  "shadow-light dark:shadow-dark",
  "overflow-hidden",
  "z-10"
)

export const popoverRowClassName = classNames(
  "text-primary text-sm",
  "surface-primary",
  "hover:surface-accent",
  "active:bg-border-primary-light active:dark:bg-border-primary-dark",
  "px-3 py-2",
  "cursor-pointer"
)

export const popoverPanelAnchor: AnchorProps = {
  to: "bottom start",
  gap: 4,
  padding: 4,
}
