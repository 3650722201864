import { TextButton } from "components/TextButton"
import { useSignInContext } from "./SignInContext"

export function SignInViaKeyButton() {
  const { setStep } = useSignInContext()

  const onClickSignInViaKey = () => {
    setStep("key")
  }

  return <TextButton onClick={onClickSignInViaKey}>Sign in via key</TextButton>
}
