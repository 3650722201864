/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "bub/models.proto" (package "rd.bub", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message rd.bub.Summary
 */
export interface Summary {
    /**
     * @generated from protobuf field: int32 create = 1;
     */
    create: number;
    /**
     * @generated from protobuf field: int32 edit = 2;
     */
    edit: number;
    /**
     * @generated from protobuf field: int32 inactive = 3;
     */
    inactive: number;
    /**
     * @generated from protobuf field: int32 unchanged = 4;
     */
    unchanged: number;
}
/**
 * @generated from protobuf message rd.bub.ParseError
 */
export interface ParseError {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
    /**
     * @generated from protobuf field: repeated string row_identifiers = 2;
     */
    rowIdentifiers: string[];
    /**
     * @generated from protobuf field: rd.bub.Summary summary = 3;
     */
    summary?: Summary;
}
/**
 * @generated from protobuf message rd.bub.UpsertError
 */
export interface UpsertError {
    /**
     * @generated from protobuf field: repeated string row_identifiers = 1;
     */
    rowIdentifiers: string[];
    /**
     * @generated from protobuf field: rd.bub.Summary summary = 2;
     */
    summary?: Summary;
}
// @generated message type with reflection information, may provide speed optimized methods
class Summary$Type extends MessageType<Summary> {
    constructor() {
        super("rd.bub.Summary", [
            { no: 1, name: "create", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "edit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "inactive", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "unchanged", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Summary>): Summary {
        const message = { create: 0, edit: 0, inactive: 0, unchanged: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Summary>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Summary): Summary {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 create */ 1:
                    message.create = reader.int32();
                    break;
                case /* int32 edit */ 2:
                    message.edit = reader.int32();
                    break;
                case /* int32 inactive */ 3:
                    message.inactive = reader.int32();
                    break;
                case /* int32 unchanged */ 4:
                    message.unchanged = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Summary, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 create = 1; */
        if (message.create !== 0)
            writer.tag(1, WireType.Varint).int32(message.create);
        /* int32 edit = 2; */
        if (message.edit !== 0)
            writer.tag(2, WireType.Varint).int32(message.edit);
        /* int32 inactive = 3; */
        if (message.inactive !== 0)
            writer.tag(3, WireType.Varint).int32(message.inactive);
        /* int32 unchanged = 4; */
        if (message.unchanged !== 0)
            writer.tag(4, WireType.Varint).int32(message.unchanged);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.bub.Summary
 */
export const Summary = new Summary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParseError$Type extends MessageType<ParseError> {
    constructor() {
        super("rd.bub.ParseError", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "row_identifiers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "summary", kind: "message", T: () => Summary }
        ]);
    }
    create(value?: PartialMessage<ParseError>): ParseError {
        const message = { excelBytes: new Uint8Array(0), rowIdentifiers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParseError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParseError): ParseError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                case /* repeated string row_identifiers */ 2:
                    message.rowIdentifiers.push(reader.string());
                    break;
                case /* rd.bub.Summary summary */ 3:
                    message.summary = Summary.internalBinaryRead(reader, reader.uint32(), options, message.summary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParseError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        /* repeated string row_identifiers = 2; */
        for (let i = 0; i < message.rowIdentifiers.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.rowIdentifiers[i]);
        /* rd.bub.Summary summary = 3; */
        if (message.summary)
            Summary.internalBinaryWrite(message.summary, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.bub.ParseError
 */
export const ParseError = new ParseError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertError$Type extends MessageType<UpsertError> {
    constructor() {
        super("rd.bub.UpsertError", [
            { no: 1, name: "row_identifiers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "summary", kind: "message", T: () => Summary }
        ]);
    }
    create(value?: PartialMessage<UpsertError>): UpsertError {
        const message = { rowIdentifiers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertError): UpsertError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string row_identifiers */ 1:
                    message.rowIdentifiers.push(reader.string());
                    break;
                case /* rd.bub.Summary summary */ 2:
                    message.summary = Summary.internalBinaryRead(reader, reader.uint32(), options, message.summary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string row_identifiers = 1; */
        for (let i = 0; i < message.rowIdentifiers.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.rowIdentifiers[i]);
        /* rd.bub.Summary summary = 2; */
        if (message.summary)
            Summary.internalBinaryWrite(message.summary, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.bub.UpsertError
 */
export const UpsertError = new UpsertError$Type();
