import { OrderShipment_Status as OrderShipmentStatus } from "gen/txn/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function orderShipment(id?: PBUUID, status?: OrderShipmentStatus) {
  const statusString = cleanStatus(
    OrderShipmentStatus[status ?? OrderShipmentStatus.RECEIVED]
  )
  return `/pos/purchase-orders/${UUID.idString(id)}?status=${statusString}`
}
