import { Preset } from "lib/dates/presets"
import { classNames } from "lib/classNames"

interface TimestampRangePickerPresetSidebarProps {
  selectedPreset: Preset | null
  presetsToDisplay: Preset[]
  onSelectPreset: (preset: Preset) => void
}

export function TimestampRangePickerPresetSidebar({
  selectedPreset,
  presetsToDisplay,
  onSelectPreset,
}: TimestampRangePickerPresetSidebarProps) {
  return (
    <div className="-my-4 flex flex-col gap-y-4 border-l border-l-gray-200 p-4 dark:border-l-gray-600">
      {presetsToDisplay.map((preset) => {
        return (
          <div
            key={preset.id}
            className={classNames(
              selectedPreset?.id === preset.id
                ? "text-cobalt-400 dark:text-cobalt-300 font-semibold"
                : "text-gray-600 hover:text-black dark:text-gray-300 hover:dark:text-white",
              "cursor-pointer whitespace-nowrap text-base"
            )}
            data-testid={`${preset.id}-date-range-picker`}
            onMouseDown={() => onSelectPreset(preset)}
          >
            {preset.displayText}
          </div>
        )
      })}
    </div>
  )
}
