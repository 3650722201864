import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { Location } from "gen/company/models_pb"
import { UUID } from "gen/proto/uuid/models_pb"
import { authApiSlice } from "features/api/auth"
import { companyApiSlice } from "features/api/company"

type AuthState = {
  companyId: UUID | null
  locations: Location[] | null
  location: Location | null
  defaultLocationId: UUID | null

  lastUsedStaffExternalID: string | null
  lastUsedSignInMethod: "key" | "text-message" | null
}

export const initialState: AuthState = {
  companyId: null,
  locations: null,
  location: null,
  defaultLocationId: null,

  lastUsedStaffExternalID: null,
  lastUsedSignInMethod: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLocation(state, action: PayloadAction<Location | null>) {
      state.location = action.payload
    },
    setLoggedInStaffCode(state, action: PayloadAction<string>) {
      state.lastUsedStaffExternalID = action.payload
    },
    clearState() {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      companyApiSlice.endpoints.getCompanyForSubdomain.matchFulfilled,
      (state, { payload }) => {
        state.companyId = payload.id ?? null
        state.locations = payload.locations ?? null
        state.defaultLocationId = payload.defaultLocationId ?? null
      }
    )
    builder.addMatcher(
      authApiSlice.endpoints.authenticateOneTimePassword.matchFulfilled,
      (state) => {
        state.lastUsedSignInMethod = "text-message"
      }
    )
    builder.addMatcher(
      authApiSlice.endpoints.authenticateWebAuthn.matchFulfilled,
      (state) => {
        state.lastUsedSignInMethod = "key"
      }
    )
  },
})

export const { setLocation, setLoggedInStaffCode, clearState } =
  authSlice.actions

export const selectCompanyId = (state: AppState) => state.auth.companyId
export const selectLocation = (state: AppState) => {
  if (!state.auth.location && state.auth.defaultLocationId) {
    for (const loc of state.auth.locations ?? []) {
      if (UUID.equals(loc.id, state.auth.defaultLocationId)) {
        return loc
      }
    }
  }
  return state.auth.location
}
export const selectLocationWithoutDefault = (state: AppState) => {
  return state.auth.location
}
export const selectLastUsedStaffExternalID = (state: AppState) =>
  state.auth.lastUsedStaffExternalID
export const selectLastUsedSignInMethod = (state: AppState) =>
  state.auth.lastUsedSignInMethod
