import {
  Checkbox as HeadlessCheckbox,
  CheckboxProps as HeadlessCheckboxProps,
} from "@headlessui/react"

import { CheckIcon } from "./CheckIcon"
import { checkboxClassNames } from "../styles"
import { classNames } from "lib/classNames"
import { forwardRef } from "react"

export interface CheckboxProps
  extends Omit<HeadlessCheckboxProps, "className"> {
  className?: string
}

export const Checkbox = forwardRef<HTMLElement, CheckboxProps>((props, ref) => {
  const { className, checked, ...otherProps } = props

  return (
    <HeadlessCheckbox
      ref={ref}
      checked={checked}
      className={({ hover, checked, disabled }) =>
        classNames(checkboxClassNames({ checked, hover, disabled }), className)
      }
      {...otherProps}
    >
      {({ checked, disabled }) => (
        <CheckIcon checked={checked} disabled={disabled} />
      )}
    </HeadlessCheckbox>
  )
})

Checkbox.displayName = "Checkbox"
