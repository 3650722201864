import { CheckIcon } from "./CheckIcon"
import { HTMLAttributes } from "react"
import { checkboxClassNames } from "../styles"
import { classNames } from "lib/classNames"

export interface CheckboxReadOnlyProps extends HTMLAttributes<HTMLSpanElement> {
  checked: boolean
  disabled?: boolean
}

export function CheckboxReadOnly({
  checked,
  disabled,
  className,
  ...otherProps
}: CheckboxReadOnlyProps) {
  return (
    <span
      className={classNames(
        checkboxClassNames({ checked, disabled }),
        className
      )}
      role="checkbox"
      aria-checked={checked}
      aria-disabled={disabled}
      data-disabled={disabled}
      {...otherProps}
    >
      <CheckIcon checked={checked} disabled={disabled} />
    </span>
  )
}
