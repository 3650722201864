import {
  DisabledMode,
  DisabledModeT,
} from "components/TextDateInput/TextDateInput"
import {
  endOfDay,
  getDate,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfDay,
} from "date-fns"

import { PartialInterval } from "lib/dates/interval"
import { classNames } from "lib/classNames"

export type VisualStateT =
  | "disabled"
  | "highlighted"
  | "muted"
  | "plain"
  | "bolded"
  | "left-bolded"
  | "right-bolded"

export function TimestampRangePickerCalendarDay({
  day,
  selection,
  currentlySelecting,
  onDateClicked,
  monthDisplayed,
  disabledMode,
}: {
  day: Date
  selection: PartialInterval | null
  currentlySelecting: "from" | "to"
  onDateClicked: (clicked: Date) => void
  monthDisplayed: Date
  disabledMode: DisabledModeT
}) {
  const visualState = getVisualState(
    day,
    selection,
    currentlySelecting,
    monthDisplayed,
    disabledMode
  )

  return (
    <button
      type="button"
      key={day.toDateString()}
      className={classNames(
        "select-none text-center text-sm",
        visualStyles[visualState]
      )}
      disabled={visualState === "disabled"}
      onMouseDown={(e) => {
        e.preventDefault()
        onDateClicked(day)
      }}
    >
      {getDate(day)}
    </button>
  )
}

export function getVisualState(
  day: Date,
  selection: PartialInterval | null,
  currentlySelecting: "from" | "to",
  monthDisplayed: Date,
  disabledMode: DisabledModeT
): VisualStateT {
  function isSelectable(day: Date): boolean {
    if (disabledMode === DisabledMode.Future && isFuture(startOfDay(day))) {
      return false
    }

    if (disabledMode === DisabledMode.Past && isPast(endOfDay(day))) {
      return false
    }

    // Prevent setting the `to` field to an invalid range if the `from` field
    // is already set.
    if (selection?.start && currentlySelecting === "to") {
      return isSameDay(selection.start, day) || isBefore(selection.start, day)
    }

    return true
  }

  function isStartBoundary(day: Date): boolean {
    return !!selection?.start && isSameDay(day, selection.start)
  }

  function isEndBoundary(day: Date): boolean {
    return !!selection?.end && isSameDay(day, selection.end)
  }

  function isWithinSelection(day: Date): boolean {
    return (
      !!selection?.start &&
      !!selection?.end &&
      selection.isValid() &&
      isWithinInterval(day, { start: selection.start, end: selection.end })
    )
  }

  function isOutsideDisplayedMonth(day: Date): boolean {
    return !isSameMonth(day, monthDisplayed)
  }

  if (!isSelectable(day)) return "disabled"
  if (isStartBoundary(day) && isEndBoundary(day)) return "bolded"
  if (isStartBoundary(day)) return "left-bolded"
  if (isEndBoundary(day)) return "right-bolded"
  if (isWithinSelection(day)) return "highlighted"
  if (isOutsideDisplayedMonth(day)) return "muted"
  return "plain"
}

const visualStyles: { [State in VisualStateT]: string } = {
  disabled: "text-gray-500 dark:text-gray-300",
  muted: "text-gray-600 dark:text-gray-200",
  bolded: "text-cobalt-400 dark:text-cobalt-200 font-semibold",
  "left-bolded": "text-cobalt-400 dark:text-cobalt-200 font-semibold",
  "right-bolded": "text-cobalt-400 dark:text-cobalt-200 font-semibold",
  highlighted: "text-cobalt-200 dark:text-cobalt-200",
  plain: "text-black dark:text-white",
}
