import { Button } from "components/Button"
import { EnterIDLabel } from "./EnterIDLabel"
import { ErrorMessage } from "./ErrorMessage"
import { LocationsCombobox } from "./LocationsCombobox"
import { SignInViaKeyButton } from "./SignInViaKeyButton"
import { SingleCharacterInput } from "components/SingleCharacterInput"
import { useStaffExternalIDForm } from "./useStaffExternalIDForm"

interface StepTextMessageProps {
  onSubmit: (staffExternalID: string) => void
  isSubmitting: boolean
  errorMessage: string | null
}

export function StepTextMessage(props: StepTextMessageProps) {
  const { onSubmit, isSubmitting, errorMessage } = props

  const {
    staffExternalID,
    onChangeStaffExternalID,
    onKeyDownStaffExternalID,
    handleSubmit,
  } = useStaffExternalIDForm(onSubmit)

  return (
    <>
      <LocationsCombobox />
      <EnterIDLabel />
      <div className="flex flex-col gap-y-2">
        <SingleCharacterInput
          data-testid="id-input"
          className="self-center"
          autoFocus
          value={staffExternalID}
          onKeyDown={onKeyDownStaffExternalID}
          onChange={onChangeStaffExternalID}
          isLoading={isSubmitting}
        />
        <ErrorMessage message={errorMessage} />
      </div>
      <Button mode="primary" onClick={handleSubmit} disabled={!staffExternalID}>
        Send text message
      </Button>
      <SignInViaKeyButton />
    </>
  )
}
