import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { tierFilterKey } from "features/pos/customers/CustomersV2/useCustomersFilters/useTierFilter"

export function customer(id?: PBUUID): string {
  return `/pos/customers/${UUID.idString(id)}`
}

export function customers(): string {
  return "/pos/customers?active=2"
}

export function customerTier(pricingTierIdString?: string): string {
  return `${customers()}&${tierFilterKey}=${pricingTierIdString}`
}
