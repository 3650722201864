// abs returns the absolute value of a bigint.
export function abs(x: bigint): bigint {
  if (x < 0) {
    return -x
  }

  return x
}

// numberWithCommas inserts commas where appropriate.
export function numberWithCommas(x: number | string) {
  const num = x.toString()
  if (num.includes(".")) {
    return num.replace(/\B(?=(\d{3})+(?!\d)(?=\.\d+$))/g, ",")
  }
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
