/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/vendors/service.proto" (package "rd.api.vendors", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { VendorsService } from "./service_pb";
import type { DeactivateVendorContactRes } from "./service_pb";
import type { DeactivateVendorContactReq } from "./service_pb";
import type { UpdateVendorContactRes } from "./service_pb";
import type { UpdateVendorContactReq } from "./service_pb";
import type { CreateVendorContactRes } from "./service_pb";
import type { CreateVendorContactReq } from "./service_pb";
import type { DuplicateVendorRes } from "./service_pb";
import type { DuplicateVendorReq } from "./service_pb";
import type { UpdateVendorAccountsByLocationRes } from "./service_pb";
import type { UpdateVendorAccountsByLocationReq } from "./service_pb";
import type { UpsertVendorBankAccountRes } from "./service_pb";
import type { UpsertVendorBankAccountReq } from "./service_pb";
import type { CreateVendorWithBankAccountRes } from "./service_pb";
import type { CreateVendorWithBankAccountReq } from "./service_pb";
import type { DeleteVendorBankPaymentMethodRes } from "./service_pb";
import type { DeleteVendorBankPaymentMethodReq } from "./service_pb";
import type { SaveVendorBankPaymentMethodRes } from "./service_pb";
import type { SaveVendorBankPaymentMethodReq } from "./service_pb";
import type { ListVendorsForCompanyRes } from "./service_pb";
import type { ListVendorsForCompanyReq } from "./service_pb";
import type { GetVendorRes } from "./service_pb";
import type { GetVendorReq } from "./service_pb";
import type { UpdateVendorIsActiveRes } from "./service_pb";
import type { UpdateVendorIsActiveReq } from "./service_pb";
import type { UpdateVendorNotesRes } from "./service_pb";
import type { UpdateVendorNotesReq } from "./service_pb";
import type { UpdateVendorInformationRes } from "./service_pb";
import type { UpdateVendorInformationReq } from "./service_pb";
import type { BulkUpsertVendorsRes } from "./service_pb";
import type { BulkUpsertVendorsReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateVendorRes } from "./service_pb";
import type { CreateVendorReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.api.vendors.VendorsService
 */
export interface IVendorsServiceClient {
    /**
     * Vendor
     *
     * @generated from protobuf rpc: CreateVendor(rd.api.vendors.CreateVendorReq) returns (rd.api.vendors.CreateVendorRes);
     */
    createVendor(input: CreateVendorReq, options?: RpcOptions): UnaryCall<CreateVendorReq, CreateVendorRes>;
    /**
     * @generated from protobuf rpc: BulkUpsertVendors(rd.api.vendors.BulkUpsertVendorsReq) returns (rd.api.vendors.BulkUpsertVendorsRes);
     */
    bulkUpsertVendors(input: BulkUpsertVendorsReq, options?: RpcOptions): UnaryCall<BulkUpsertVendorsReq, BulkUpsertVendorsRes>;
    /**
     * @generated from protobuf rpc: UpdateVendorInformation(rd.api.vendors.UpdateVendorInformationReq) returns (rd.api.vendors.UpdateVendorInformationRes);
     */
    updateVendorInformation(input: UpdateVendorInformationReq, options?: RpcOptions): UnaryCall<UpdateVendorInformationReq, UpdateVendorInformationRes>;
    /**
     * @generated from protobuf rpc: UpdateVendorNotes(rd.api.vendors.UpdateVendorNotesReq) returns (rd.api.vendors.UpdateVendorNotesRes);
     */
    updateVendorNotes(input: UpdateVendorNotesReq, options?: RpcOptions): UnaryCall<UpdateVendorNotesReq, UpdateVendorNotesRes>;
    /**
     * @generated from protobuf rpc: UpdateVendorIsActive(rd.api.vendors.UpdateVendorIsActiveReq) returns (rd.api.vendors.UpdateVendorIsActiveRes);
     */
    updateVendorIsActive(input: UpdateVendorIsActiveReq, options?: RpcOptions): UnaryCall<UpdateVendorIsActiveReq, UpdateVendorIsActiveRes>;
    /**
     * @generated from protobuf rpc: GetVendor(rd.api.vendors.GetVendorReq) returns (rd.api.vendors.GetVendorRes);
     */
    getVendor(input: GetVendorReq, options?: RpcOptions): UnaryCall<GetVendorReq, GetVendorRes>;
    /**
     * @generated from protobuf rpc: ListVendorsForCompany(rd.api.vendors.ListVendorsForCompanyReq) returns (rd.api.vendors.ListVendorsForCompanyRes);
     */
    listVendorsForCompany(input: ListVendorsForCompanyReq, options?: RpcOptions): UnaryCall<ListVendorsForCompanyReq, ListVendorsForCompanyRes>;
    /**
     * @generated from protobuf rpc: SaveVendorBankPaymentMethod(rd.api.vendors.SaveVendorBankPaymentMethodReq) returns (rd.api.vendors.SaveVendorBankPaymentMethodRes);
     */
    saveVendorBankPaymentMethod(input: SaveVendorBankPaymentMethodReq, options?: RpcOptions): UnaryCall<SaveVendorBankPaymentMethodReq, SaveVendorBankPaymentMethodRes>;
    /**
     * @generated from protobuf rpc: DeleteVendorBankPaymentMethod(rd.api.vendors.DeleteVendorBankPaymentMethodReq) returns (rd.api.vendors.DeleteVendorBankPaymentMethodRes);
     */
    deleteVendorBankPaymentMethod(input: DeleteVendorBankPaymentMethodReq, options?: RpcOptions): UnaryCall<DeleteVendorBankPaymentMethodReq, DeleteVendorBankPaymentMethodRes>;
    /**
     * @generated from protobuf rpc: CreateVendorWithBankAccount(rd.api.vendors.CreateVendorWithBankAccountReq) returns (rd.api.vendors.CreateVendorWithBankAccountRes);
     */
    createVendorWithBankAccount(input: CreateVendorWithBankAccountReq, options?: RpcOptions): UnaryCall<CreateVendorWithBankAccountReq, CreateVendorWithBankAccountRes>;
    /**
     * @generated from protobuf rpc: UpsertVendorBankAccount(rd.api.vendors.UpsertVendorBankAccountReq) returns (rd.api.vendors.UpsertVendorBankAccountRes);
     */
    upsertVendorBankAccount(input: UpsertVendorBankAccountReq, options?: RpcOptions): UnaryCall<UpsertVendorBankAccountReq, UpsertVendorBankAccountRes>;
    /**
     * @generated from protobuf rpc: UpdateVendorAccountsByLocation(rd.api.vendors.UpdateVendorAccountsByLocationReq) returns (rd.api.vendors.UpdateVendorAccountsByLocationRes);
     */
    updateVendorAccountsByLocation(input: UpdateVendorAccountsByLocationReq, options?: RpcOptions): UnaryCall<UpdateVendorAccountsByLocationReq, UpdateVendorAccountsByLocationRes>;
    /**
     * @generated from protobuf rpc: DuplicateVendor(rd.api.vendors.DuplicateVendorReq) returns (rd.api.vendors.DuplicateVendorRes);
     */
    duplicateVendor(input: DuplicateVendorReq, options?: RpcOptions): UnaryCall<DuplicateVendorReq, DuplicateVendorRes>;
    /**
     * Vendor Contact
     *
     * @generated from protobuf rpc: CreateVendorContact(rd.api.vendors.CreateVendorContactReq) returns (rd.api.vendors.CreateVendorContactRes);
     */
    createVendorContact(input: CreateVendorContactReq, options?: RpcOptions): UnaryCall<CreateVendorContactReq, CreateVendorContactRes>;
    /**
     * @generated from protobuf rpc: UpdateVendorContact(rd.api.vendors.UpdateVendorContactReq) returns (rd.api.vendors.UpdateVendorContactRes);
     */
    updateVendorContact(input: UpdateVendorContactReq, options?: RpcOptions): UnaryCall<UpdateVendorContactReq, UpdateVendorContactRes>;
    /**
     * @generated from protobuf rpc: DeactivateVendorContact(rd.api.vendors.DeactivateVendorContactReq) returns (rd.api.vendors.DeactivateVendorContactRes);
     */
    deactivateVendorContact(input: DeactivateVendorContactReq, options?: RpcOptions): UnaryCall<DeactivateVendorContactReq, DeactivateVendorContactRes>;
}
/**
 * @generated from protobuf service rd.api.vendors.VendorsService
 */
export class VendorsServiceClient implements IVendorsServiceClient, ServiceInfo {
    typeName = VendorsService.typeName;
    methods = VendorsService.methods;
    options = VendorsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Vendor
     *
     * @generated from protobuf rpc: CreateVendor(rd.api.vendors.CreateVendorReq) returns (rd.api.vendors.CreateVendorRes);
     */
    createVendor(input: CreateVendorReq, options?: RpcOptions): UnaryCall<CreateVendorReq, CreateVendorRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateVendorReq, CreateVendorRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkUpsertVendors(rd.api.vendors.BulkUpsertVendorsReq) returns (rd.api.vendors.BulkUpsertVendorsRes);
     */
    bulkUpsertVendors(input: BulkUpsertVendorsReq, options?: RpcOptions): UnaryCall<BulkUpsertVendorsReq, BulkUpsertVendorsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkUpsertVendorsReq, BulkUpsertVendorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorInformation(rd.api.vendors.UpdateVendorInformationReq) returns (rd.api.vendors.UpdateVendorInformationRes);
     */
    updateVendorInformation(input: UpdateVendorInformationReq, options?: RpcOptions): UnaryCall<UpdateVendorInformationReq, UpdateVendorInformationRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateVendorInformationReq, UpdateVendorInformationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorNotes(rd.api.vendors.UpdateVendorNotesReq) returns (rd.api.vendors.UpdateVendorNotesRes);
     */
    updateVendorNotes(input: UpdateVendorNotesReq, options?: RpcOptions): UnaryCall<UpdateVendorNotesReq, UpdateVendorNotesRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateVendorNotesReq, UpdateVendorNotesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorIsActive(rd.api.vendors.UpdateVendorIsActiveReq) returns (rd.api.vendors.UpdateVendorIsActiveRes);
     */
    updateVendorIsActive(input: UpdateVendorIsActiveReq, options?: RpcOptions): UnaryCall<UpdateVendorIsActiveReq, UpdateVendorIsActiveRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateVendorIsActiveReq, UpdateVendorIsActiveRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVendor(rd.api.vendors.GetVendorReq) returns (rd.api.vendors.GetVendorRes);
     */
    getVendor(input: GetVendorReq, options?: RpcOptions): UnaryCall<GetVendorReq, GetVendorRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetVendorReq, GetVendorRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListVendorsForCompany(rd.api.vendors.ListVendorsForCompanyReq) returns (rd.api.vendors.ListVendorsForCompanyRes);
     */
    listVendorsForCompany(input: ListVendorsForCompanyReq, options?: RpcOptions): UnaryCall<ListVendorsForCompanyReq, ListVendorsForCompanyRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListVendorsForCompanyReq, ListVendorsForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SaveVendorBankPaymentMethod(rd.api.vendors.SaveVendorBankPaymentMethodReq) returns (rd.api.vendors.SaveVendorBankPaymentMethodRes);
     */
    saveVendorBankPaymentMethod(input: SaveVendorBankPaymentMethodReq, options?: RpcOptions): UnaryCall<SaveVendorBankPaymentMethodReq, SaveVendorBankPaymentMethodRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveVendorBankPaymentMethodReq, SaveVendorBankPaymentMethodRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVendorBankPaymentMethod(rd.api.vendors.DeleteVendorBankPaymentMethodReq) returns (rd.api.vendors.DeleteVendorBankPaymentMethodRes);
     */
    deleteVendorBankPaymentMethod(input: DeleteVendorBankPaymentMethodReq, options?: RpcOptions): UnaryCall<DeleteVendorBankPaymentMethodReq, DeleteVendorBankPaymentMethodRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteVendorBankPaymentMethodReq, DeleteVendorBankPaymentMethodRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateVendorWithBankAccount(rd.api.vendors.CreateVendorWithBankAccountReq) returns (rd.api.vendors.CreateVendorWithBankAccountRes);
     */
    createVendorWithBankAccount(input: CreateVendorWithBankAccountReq, options?: RpcOptions): UnaryCall<CreateVendorWithBankAccountReq, CreateVendorWithBankAccountRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateVendorWithBankAccountReq, CreateVendorWithBankAccountRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertVendorBankAccount(rd.api.vendors.UpsertVendorBankAccountReq) returns (rd.api.vendors.UpsertVendorBankAccountRes);
     */
    upsertVendorBankAccount(input: UpsertVendorBankAccountReq, options?: RpcOptions): UnaryCall<UpsertVendorBankAccountReq, UpsertVendorBankAccountRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertVendorBankAccountReq, UpsertVendorBankAccountRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorAccountsByLocation(rd.api.vendors.UpdateVendorAccountsByLocationReq) returns (rd.api.vendors.UpdateVendorAccountsByLocationRes);
     */
    updateVendorAccountsByLocation(input: UpdateVendorAccountsByLocationReq, options?: RpcOptions): UnaryCall<UpdateVendorAccountsByLocationReq, UpdateVendorAccountsByLocationRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateVendorAccountsByLocationReq, UpdateVendorAccountsByLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DuplicateVendor(rd.api.vendors.DuplicateVendorReq) returns (rd.api.vendors.DuplicateVendorRes);
     */
    duplicateVendor(input: DuplicateVendorReq, options?: RpcOptions): UnaryCall<DuplicateVendorReq, DuplicateVendorRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<DuplicateVendorReq, DuplicateVendorRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Vendor Contact
     *
     * @generated from protobuf rpc: CreateVendorContact(rd.api.vendors.CreateVendorContactReq) returns (rd.api.vendors.CreateVendorContactRes);
     */
    createVendorContact(input: CreateVendorContactReq, options?: RpcOptions): UnaryCall<CreateVendorContactReq, CreateVendorContactRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateVendorContactReq, CreateVendorContactRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorContact(rd.api.vendors.UpdateVendorContactReq) returns (rd.api.vendors.UpdateVendorContactRes);
     */
    updateVendorContact(input: UpdateVendorContactReq, options?: RpcOptions): UnaryCall<UpdateVendorContactReq, UpdateVendorContactRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateVendorContactReq, UpdateVendorContactRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateVendorContact(rd.api.vendors.DeactivateVendorContactReq) returns (rd.api.vendors.DeactivateVendorContactRes);
     */
    deactivateVendorContact(input: DeactivateVendorContactReq, options?: RpcOptions): UnaryCall<DeactivateVendorContactReq, DeactivateVendorContactRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateVendorContactReq, DeactivateVendorContactRes>("unary", this._transport, method, opt, input);
    }
}
