import { FilterType, OptionT, TextFilterT } from "../Filter/Filter"
import { UseFilterProps, UseFilterRes } from "./types"
import { useCallback, useMemo } from "react"

import { useRouter } from "next/router"

export function useFilter<TValue, TSelected = TValue>({
  key,
  label,
  values,
  sort,
  keyExtractor,
  labelExtractor,
  autoFilterOptions = true,
  selectedTransformer,
}: UseFilterProps<TValue, TSelected>): UseFilterRes<TSelected> {
  const router = useRouter()

  const queryParam = router.query[key]
  const normalizedQueryParam = useMemo<string[]>(() => {
    if (queryParam === undefined) {
      return []
    }
    return Array.isArray(queryParam) ? queryParam : [queryParam]
  }, [queryParam])

  const selectedOptionKeys = useMemo<Set<string>>(() => {
    return new Set(normalizedQueryParam)
  }, [normalizedQueryParam])

  const selected = useMemo<TSelected[]>(() => {
    const selectedValues = values.filter((value) =>
      selectedOptionKeys.has(keyExtractor(value))
    )
    return selectedTransformer
      ? selectedValues.map(selectedTransformer)
      : (selectedValues as unknown as TSelected[])
  }, [values, selectedOptionKeys, keyExtractor, selectedTransformer])

  const options = useMemo<OptionT[]>(() => {
    const sorted = sort ? [...values].sort(sort) : values
    return sorted.map((value) => ({
      key: keyExtractor(value),
      value: labelExtractor(value),
    }))
  }, [values, keyExtractor, labelExtractor, sort])

  function onSelectOption(option: OptionT) {
    const newQuery = { ...router.query }

    if (selectedOptionKeys.has(option.key)) {
      const filtered = normalizedQueryParam.filter((k) => k !== option.key)
      if (filtered.length > 0) {
        newQuery[key] = filtered
      } else {
        delete newQuery[key]
      }
    } else {
      newQuery[key] = [...normalizedQueryParam, option.key]
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    )
  }

  const onClear = useCallback(() => {
    if (normalizedQueryParam.length === 0) {
      return
    }

    const newQuery = { ...router.query }
    delete newQuery[key]
    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    )
  }, [router, normalizedQueryParam, key])

  const filter: TextFilterT = {
    type: FilterType.Text,
    key,
    value: label,
    options,
    selectedOptionKeys,
    onSelectOption,
    onClear,
    autoFilterOptions,
  }

  return { filter, selected }
}
