import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { CardReader } from "gen/payments/models_pb"

type CardReadersState = {
  selectedCardReader: CardReader | null
}

const initialState: CardReadersState = {
  selectedCardReader: null,
}

export const cardReadersSlice = createSlice({
  name: "cardReaders",
  initialState,
  reducers: {
    setSelectedCardReader: (
      state,
      action: PayloadAction<CardReader | null>
    ) => {
      state.selectedCardReader = action.payload
    },
  },
})

export const { setSelectedCardReader } = cardReadersSlice.actions

export const selectCardReader = (state: AppState) =>
  state.cardReaders.selectedCardReader
