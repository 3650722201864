import { Combobox, ComboboxProps } from "components/Combobox"

import { UUID } from "uuid-rd"
import { Vendor } from "gen/vendors/models_pb"
import { useListVendorsForCompanyQuery } from "features/api/vendor"

type VendorComboboxProps = Omit<
  ComboboxProps<Vendor>,
  "items" | "keyExtractor" | "valueExtractor"
> & {
  isBold?: boolean
  enableClear?: boolean
}

const CLEAR_VENDOR_SELECTION = "Clear selection"

export function VendorCombobox({
  onSelectItem,
  enableClear = false,
  isBold = false,
  ...props
}: VendorComboboxProps) {
  const { data: listProductVendorsData } = useListVendorsForCompanyQuery({
    filterActive: true,
  })
  let sortedVendors: Vendor[] = []
  if (enableClear) {
    sortedVendors = [
      Vendor.create({ name: CLEAR_VENDOR_SELECTION }),
      ...[...(listProductVendorsData?.vendors ?? [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    ]
  } else {
    sortedVendors = [...(listProductVendorsData?.vendors ?? [])].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
  }

  const handleSelectItem = (vendor: Vendor | null) => {
    if (vendor?.name === CLEAR_VENDOR_SELECTION) {
      onSelectItem(null)
    } else {
      onSelectItem(vendor)
    }
  }

  return (
    <Combobox<Vendor>
      items={sortedVendors}
      onSelectItem={handleSelectItem}
      keyExtractor={(vendor) =>
        vendor.id ? UUID.fromPB(vendor.id).toString() : ""
      }
      valueExtractor={(vendor) => vendor.name}
      isBold={isBold}
      {...props}
    />
  )
}
