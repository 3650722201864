import { Location, Staff } from "gen/company/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { BoolFilter } from "gen/search/service/service_pb"
import { Customer } from "gen/customer/models_pb"
import { PurchaseMethod_Method as PurchaseMethod } from "gen/txn/models_pb"
import { TimestampRange } from "gen/proto/timestamprange/models_pb"
import { TintColor } from "gen/tintcolor/models_pb"

export interface SalesSearchFiltersState {
  filters: {
    dateRange: TimestampRange | null
    customers: Customer[]
    locations: Location[]
    clerks: Staff[]
    tintColors: TintColor[]
    hasNonTaxableProducts: BoolFilter[]
    purchaseMethod: PurchaseMethod
  }
  searchQuery: string
}

const initialState: SalesSearchFiltersState = {
  filters: {
    dateRange: null,
    customers: [],
    locations: [],
    clerks: [],
    tintColors: [],
    hasNonTaxableProducts: [],
    purchaseMethod: PurchaseMethod.UNDEFINED,
  },
  searchQuery: "",
}

export const salesSearchSlice = createSlice({
  name: "salesSearch",
  initialState,
  reducers: {
    resetSalesSearchFilters: (_, action: PayloadAction<Location[]>) => {
      const filters = { ...initialState.filters, locations: action.payload }
      return {
        ...initialState,
        filters,
      }
    },
    setSalesSearchDateRangeFilter: (
      state,
      action: PayloadAction<TimestampRange | null>
    ) => {
      state.filters.dateRange = action.payload
    },
    setSalesSearchCustomersFilter: (
      state,
      action: PayloadAction<Customer[]>
    ) => {
      state.filters.customers = action.payload
    },
    setSalesSearchLocationsFilter: (
      state,
      action: PayloadAction<Location[]>
    ) => {
      state.filters.locations = action.payload
    },
    setSalesSearchClerksFilter: (state, action: PayloadAction<Staff[]>) => {
      state.filters.clerks = action.payload
    },
    setSalesSearchTintColorsFilter: (
      state,
      action: PayloadAction<TintColor[]>
    ) => {
      state.filters.tintColors = action.payload
    },
    setSalesSearchHasNonTaxableProductsFilter: (
      state,
      action: PayloadAction<BoolFilter[]>
    ) => {
      state.filters.hasNonTaxableProducts = action.payload
    },
    setSalesSearchPurchaseMethodFilter: (
      state,
      action: PayloadAction<PurchaseMethod>
    ) => {
      state.filters.purchaseMethod = action.payload ?? PurchaseMethod.UNDEFINED
    },
    setSalesSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
  },
})

export const {
  resetSalesSearchFilters,
  setSalesSearchDateRangeFilter,
  setSalesSearchCustomersFilter,
  setSalesSearchLocationsFilter,
  setSalesSearchClerksFilter,
  setSalesSearchTintColorsFilter,
  setSalesSearchHasNonTaxableProductsFilter,
  setSalesSearchPurchaseMethodFilter,
  setSalesSearchQuery,
} = salesSearchSlice.actions

export const selectSalesSearchDateRangeFilter = (state: AppState) =>
  state.salesSearch.filters.dateRange
export const selectSalesSearchCustomersFilter = (state: AppState) =>
  state.salesSearch.filters.customers
export const selectSalesSearchLocationsFilter = (state: AppState) =>
  state.salesSearch.filters.locations
export const selectSalesSearchClerksFilter = (state: AppState) =>
  state.salesSearch.filters.clerks
export const selectSalesSearchTintColorsFilter = (state: AppState) =>
  state.salesSearch.filters.tintColors
export const selectSalesSearchHasNonTaxableProductsFilter = (state: AppState) =>
  state.salesSearch.filters.hasNonTaxableProducts
export const selectSalesSearchPurchaseMethod = (state: AppState) =>
  state.salesSearch.filters.purchaseMethod
export const selectSalesSearchQuery = (state: AppState) =>
  state.salesSearch.searchQuery
