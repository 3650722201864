import { ComponentProps, HTMLAttributes } from "react"

import { classNames } from "lib/classNames"

export enum IconSize {
  ExtraSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  ExtraLarge = "xl",
  ExtraExtraLarge = "xxl",
}

export type HeroIconT = (props: ComponentProps<"svg">) => JSX.Element

export interface HeroIconProps extends HTMLAttributes<HTMLDivElement> {
  wrapperSize?: IconSize
  iconSize?: IconSize
  Icon: HeroIconT
  className?: string
}

export function HeroIcon({
  iconSize = IconSize.Medium,
  wrapperSize,
  Icon,
  className,
  ...otherProps
}: HeroIconProps) {
  const extraSmallClassName = "h-3 w-3"
  const smallClassName = "h-4 w-4"
  const mediumClassName = "h-5 w-5"
  const largeClassName = "h-6 w-6"
  const extraLargeClassName = "h-8 w-8"
  const extraExtraLargeClassName = "h-16 w-16"
  const classBySize: Record<IconSize, string> = {
    [IconSize.ExtraSmall]: extraSmallClassName,
    [IconSize.Small]: smallClassName,
    [IconSize.Medium]: mediumClassName,
    [IconSize.Large]: largeClassName,
    [IconSize.ExtraLarge]: extraLargeClassName,
    [IconSize.ExtraExtraLarge]: extraExtraLargeClassName,
  }

  if (!Icon) {
    return null
  }

  return (
    <div
      className={classNames(
        classBySize[wrapperSize ?? iconSize],
        "flex items-center justify-center",
        className
      )}
      {...otherProps}
    >
      <Icon className={classBySize[iconSize]} />
    </div>
  )
}
