// @ts-strict-ignore
import { Money } from "money"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { SaleProduct } from "gen/txn/models_pb"
import { Tier } from "gen/price/models_pb"
import { UUID } from "uuid-rd"
import { stringify } from "lib/stringify"

export const fixTxnProductsOrders = (
  products: Record<string, { position: number; productId?: PBUUID }>
) => {
  const productsList = Object.values(products)
  const updatedProductsList = productsList
    .sort((a, b) => a.position - b.position)
    .map((product, idx) => ({
      ...product,
      position: idx + 1, // 1-based position
    }))

  const updatedProducts = {}
  for (const updatedProduct of updatedProductsList) {
    if (!updatedProduct.productId) {
      continue
    }
    const id = UUID.fromPB(updatedProduct.productId).toString()
    updatedProducts[id] = updatedProduct
  }

  return updatedProducts
}

export const fixTxnProductsListOrder = (
  products: { position: number; productId?: PBUUID }[]
) =>
  [...products]
    .sort((a, b) => a.position - b.position)
    .map((product, idx) => ({
      ...product,
      position: idx + 1, // 1-based position
    }))

export function fixSaleProductsOrders(products: Record<string, SaleProduct[]>) {
  const saleProductLists = Object.values(products)
  let allProducts: SaleProduct[] = []
  for (const productList of saleProductLists) {
    allProducts = [...allProducts, ...productList]
  }

  const updatedProductsList = allProducts
    .sort((a, b) => a.position - b.position)
    .map((product, idx) => ({
      ...product,
      position: idx + 1, // 1-based position
    }))

  const updatedProducts = {}
  for (const updatedProduct of updatedProductsList) {
    if (!updatedProduct.productId) {
      continue
    }
    const id = UUID.fromPB(updatedProduct.productId).toString()
    const variantProducts = updatedProducts[id]
    updatedProducts[id] = variantProducts
      ? [...variantProducts, updatedProduct]
      : [updatedProduct]
  }

  return updatedProducts
}

export const checkProductsEquality = (
  productsA: { productId?: PBUUID }[],
  productsB: { productId?: PBUUID }[]
) => {
  if (productsA.length !== productsB.length) {
    return false
  }
  productsA.sort((pa, pb) => UUID.comparePB(pa.productId, pb.productId))
  productsB.sort((pa, pb) => UUID.comparePB(pa.productId, pb.productId))
  return stringify(productsA) === stringify(productsB)
}

export function getCardDeclineCodeMessage(code: string) {
  switch (code) {
    case "authentication_required":
      return "The card was declined as the transaction requires authentication."
    case "approve_with_id":
      return "The payment can't be authorized."
    case "call_issuer":
      return "The card was declined for an unknown reason."
    case "card_not_supported":
      return "The card does not support this type of purchase."
    case "card_velocity_exceeded":
      return "The customer has exceeded the balance or credit limit available on their card."
    case "currency_not_supported":
      return "The card does not support the specified currency."
    case "do_not_honor":
      return "The card was declined for an unknown reason."
    case "do_not_try_again":
      return "The card was declined for an unknown reason."
    case "duplicate_transaction":
      return "A transaction with identical amount and credit card information was submitted very recently."
    case "expired_card":
      return "The card has expired."
    case "fraudulent":
      return "The payment was declined because Stripe suspects that it's fraudulent."
    case "generic_decline":
      return "The card was declined for an unknown reason or possibly triggered by a blocked payment rule."
    case "incorrect_number":
      return "The card number is incorrect."
    case "incorrect_cvc":
      return "The CVC number is incorrect."
    case "incorrect_pin":
      return "The PIN entered is incorrect."
    case "incorrect_zip":
      return "The postal code is incorrect."
    case "insufficient_funds":
      return "The card has insufficient funds to complete the purchase."
    case "invalid_account":
      return "The card, or account the card is connected to, is invalid."
    case "invalid_amount":
      return "The payment amount is invalid, or exceeds the amount that's allowed."
    case "invalid_cvc":
      return "The CVC number is incorrect."
    case "invalid_expiry_month":
      return "The expiration month is invalid."
    case "invalid_expiry_year":
      return "The expiration year is invalid."
    case "invalid_number":
      return "The card number is incorrect."
    case "invalid_pin":
      return "The PIN entered is incorrect."
    case "issuer_not_available":
      return "The card issuer couldn't be reached, so the payment couldn't be authorized."
    case "lost_card":
      return "The payment was declined because the card is reported lost."
    case "merchant_blacklist":
      return "The payment was declined because it matches a value on the Stripe user's block list."
    case "new_account_information_available":
      return "The card, or account the card is connected to, is invalid."
    case "no_action_taken":
      return "The card was declined for an unknown reason."
    case "not_permitted":
      return "The payment isn't permitted."
    case "offline_pin_required":
      return "The card was declined because it requires a PIN."
    case "online_or_offline_pin_required":
      return "The card was declined as it requires a PIN."
    case "pickup_card":
      return "The customer can't use this card to make this payment."
    case "pin_try_exceeded":
      return "The allowable number of PIN tries was exceeded."
    case "processing_error":
      return "An error occurred while processing the card."
    case "reenter_transaction":
      return "The payment couldn't be processed by the issuer for an unknown reason."
    case "restricted_card":
      return "The customer can't use this card to make this payment."
    case "revocation_of_all_authorizations":
      return "The card was declined for an unknown reason."
    case "revocation_of_authorization":
      return "The card was declined for an unknown reason."
    case "security_violation":
      return "The card was declined for an unknown reason."
    case "service_not_allowed":
      return "The card was declined for an unknown reason."
    case "stolen_card":
      return "The payment was declined because the card is reported stolen."
    case "stop_payment_order":
      return "The card was declined for an unknown reason."
    case "transaction_not_allowed":
      return "The card was declined for an unknown reason."
    case "try_again_later":
      return "The card was declined for an unknown reason."
    case "withdrawal_count_limit_exceeded":
      return "The customer has exceeded the balance or credit limit available on their card."
    default:
      return "Payment failed for an unknown reason."
  }
}

export function getProductUnitPriceV2(
  tierPrices: Tier[],
  pricingTierIdString: string
): Money {
  return (
    Money.fromPB(
      tierPrices.find((tier: Tier) => tier.tierIdString === pricingTierIdString)
        ?.price
    ) ?? Money.zero()
  )
}
