// @ts-strict-ignore
import { UUID } from "uuid-rd"
import { isPlainObject } from "@reduxjs/toolkit"

export function stringify(s: unknown): string {
  return JSON.stringify(s, (_, value) => {
    switch (true) {
      case isPlainObject(value):
        return Object.keys(value)
          .sort()
          .reduce((acc, key) => {
            acc[key] = value[key]
            return acc
          }, {})
      case typeof value === "bigint":
        return value.toString()
      default:
        return value
    }
  })
}

// fixParsedId extracts a valid PBUUID after parsing a UUID
// with JSON.parse that was stringified using the above
// custom stringify function
export function fixParsedId(idObj?: { bytes: object }) {
  if (!idObj?.bytes) {
    return undefined
  }

  const bytes = new Uint8Array(Object.values(idObj.bytes))
  return UUID.fromBytes(bytes).toPB()
}
