import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Transfer_Status as TransferStatus } from "gen/txn/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function transfer(id?: PBUUID, status?: TransferStatus): string {
  if (!status) {
    return `/pos/transfers/${UUID.idString(id)}`
  }
  const statusString = cleanStatus(TransferStatus[status])
  return `/pos/transfers/${UUID.idString(id)}?status=${statusString}`
}
