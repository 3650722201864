import { Button, ButtonProps } from "../Button"
import { HeroIcon, HeroIconT, IconSize } from "../HeroIcon"

import { ChevronDownIcon } from "@heroicons/react/outline"
import { classNames } from "lib/classNames"
import { forwardRef } from "react"

interface DropdownButtonProps extends ButtonProps {
  icon: HeroIconT
  label: string
  hideDropdownIcon?: boolean
}

export const DropdownButton = forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(({ icon, label, hideDropdownIcon, className, ...buttonProps }, ref) => {
  return (
    <Button
      ref={ref}
      className={classNames("flex flex-row items-center gap-1", className)}
      {...buttonProps}
    >
      <HeroIcon iconSize={IconSize.Small} Icon={icon} />
      {label}
      {!hideDropdownIcon && (
        <HeroIcon iconSize={IconSize.Small} Icon={ChevronDownIcon} />
      )}
    </Button>
  )
})
