// @ts-strict-ignore
import { Flag, Value } from "gen/flags/models_pb"
import { GetFlagsReq, GetFlagsRes } from "gen/api/flags/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { FlagsServiceClient } from "gen/api/flags/service_pb.client"
import { listEnumValues } from "@protobuf-ts/runtime"

const f = new FlagsServiceClient(transport)

export const flagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlags: builder.query<GetFlagsRes, GetFlagsReq>({
      query: (r) => f.getFlags(r),
    }),
  }),
})

export const { useGetFlagsQuery, useLazyGetFlagsQuery } = flagsApiSlice

type UseFlagsRes = {
  [key in Exclude<keyof typeof Flag, "UNDEFINED">]: Value
} & {
  isLoading: boolean
}

const defaultFlagValue: Value = {
  boolVal: false,
  stringVal: "",
  intVal: 0n,
}

// useFlags is a custom hook to simplify using feature flags
// from the frontend.
export function useFlags(): UseFlagsRes {
  const { data, isLoading } = useGetFlagsQuery({})

  const res: UseFlagsRes = { isLoading } as UseFlagsRes
  for (const { name, number } of listEnumValues(Flag)) {
    if (!data) {
      res[name] = defaultFlagValue
      continue
    }

    res[name] = data.flags[number] ?? defaultFlagValue
  }
  return res
}
