/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/auth/service.proto" (package "rd.api.auth", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Staff } from "../../company/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.api.auth.RegisterWebAuthnStartReq
 */
export interface RegisterWebAuthnStartReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID staff_id = 1;
     */
    staffId?: UUID;
}
/**
 * @generated from protobuf message rd.api.auth.RegisterWebAuthnStartRes
 */
export interface RegisterWebAuthnStartRes {
    /**
     * @generated from protobuf field: string public_key_credential_creation_options = 1;
     */
    publicKeyCredentialCreationOptions: string;
}
/**
 * @generated from protobuf message rd.api.auth.RegisterWebAuthnReq
 */
export interface RegisterWebAuthnReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID staff_id = 1;
     */
    staffId?: UUID;
    /**
     * @generated from protobuf field: string public_key_credential = 2;
     */
    publicKeyCredential: string;
}
/**
 * @generated from protobuf message rd.api.auth.RegisterWebAuthnRes
 */
export interface RegisterWebAuthnRes {
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnStartReq
 */
export interface AuthenticateWebAuthnStartReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string staff_code = 2;
     */
    staffCode: string;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnStartRes
 */
export interface AuthenticateWebAuthnStartRes {
    /**
     * @generated from protobuf field: string public_key_credential_request_options = 1;
     */
    publicKeyCredentialRequestOptions: string;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnMultiUserStartReq
 */
export interface AuthenticateWebAuthnMultiUserStartReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnMultiUserStartRes
 */
export interface AuthenticateWebAuthnMultiUserStartRes {
    /**
     * @generated from protobuf field: string public_key_credential_request_options = 1;
     */
    publicKeyCredentialRequestOptions: string;
    /**
     * @generated from protobuf field: repeated string pubkey_ids = 2;
     */
    pubkeyIds: string[];
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnReq
 */
export interface AuthenticateWebAuthnReq {
    /**
     * @generated from protobuf field: string public_key_credential = 1;
     */
    publicKeyCredential: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateWebAuthnRes
 */
export interface AuthenticateWebAuthnRes {
    /**
     * @generated from protobuf field: rd.company.Staff staff = 1;
     */
    staff?: Staff;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_started_at = 2;
     */
    sessionStartedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_expires_at = 3;
     */
    sessionExpiresAt?: Timestamp;
}
/**
 * @generated from protobuf message rd.api.auth.SendOneTimePasswordSMSReq
 */
export interface SendOneTimePasswordSMSReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string staff_code = 2;
     */
    staffCode: string;
}
/**
 * @generated from protobuf message rd.api.auth.SendOneTimePasswordSMSRes
 */
export interface SendOneTimePasswordSMSRes {
    /**
     * @generated from protobuf field: string phone_id = 1;
     */
    phoneId: string;
    /**
     * @generated from protobuf field: string phone_last_4 = 2;
     */
    phoneLast4: string;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateOneTimePasswordReq
 */
export interface AuthenticateOneTimePasswordReq {
    /**
     * @generated from protobuf field: string phone_id = 1;
     */
    phoneId: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 3;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateOneTimePasswordRes
 */
export interface AuthenticateOneTimePasswordRes {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_started_at = 2;
     */
    sessionStartedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_expires_at = 3;
     */
    sessionExpiresAt?: Timestamp;
}
/**
 * @generated from protobuf message rd.api.auth.ExtendSessionReq
 */
export interface ExtendSessionReq {
}
/**
 * @generated from protobuf message rd.api.auth.ExtendSessionRes
 */
export interface ExtendSessionRes {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_extended_at = 2;
     */
    sessionExtendedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp session_expires_at = 3;
     */
    sessionExpiresAt?: Timestamp;
}
/**
 * @generated from protobuf message rd.api.auth.RevokeSessionReq
 */
export interface RevokeSessionReq {
}
/**
 * @generated from protobuf message rd.api.auth.RevokeSessionRes
 */
export interface RevokeSessionRes {
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateNoltReq
 */
export interface AuthenticateNoltReq {
}
/**
 * @generated from protobuf message rd.api.auth.AuthenticateNoltRes
 */
export interface AuthenticateNoltRes {
    /**
     * @generated from protobuf field: string jwt = 1;
     */
    jwt: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RegisterWebAuthnStartReq$Type extends MessageType<RegisterWebAuthnStartReq> {
    constructor() {
        super("rd.api.auth.RegisterWebAuthnStartReq", [
            { no: 1, name: "staff_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<RegisterWebAuthnStartReq>): RegisterWebAuthnStartReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterWebAuthnStartReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterWebAuthnStartReq): RegisterWebAuthnStartReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID staff_id */ 1:
                    message.staffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.staffId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterWebAuthnStartReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID staff_id = 1; */
        if (message.staffId)
            UUID.internalBinaryWrite(message.staffId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RegisterWebAuthnStartReq
 */
export const RegisterWebAuthnStartReq = new RegisterWebAuthnStartReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterWebAuthnStartRes$Type extends MessageType<RegisterWebAuthnStartRes> {
    constructor() {
        super("rd.api.auth.RegisterWebAuthnStartRes", [
            { no: 1, name: "public_key_credential_creation_options", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterWebAuthnStartRes>): RegisterWebAuthnStartRes {
        const message = { publicKeyCredentialCreationOptions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterWebAuthnStartRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterWebAuthnStartRes): RegisterWebAuthnStartRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key_credential_creation_options */ 1:
                    message.publicKeyCredentialCreationOptions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterWebAuthnStartRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key_credential_creation_options = 1; */
        if (message.publicKeyCredentialCreationOptions !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKeyCredentialCreationOptions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RegisterWebAuthnStartRes
 */
export const RegisterWebAuthnStartRes = new RegisterWebAuthnStartRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterWebAuthnReq$Type extends MessageType<RegisterWebAuthnReq> {
    constructor() {
        super("rd.api.auth.RegisterWebAuthnReq", [
            { no: 1, name: "staff_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "public_key_credential", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<RegisterWebAuthnReq>): RegisterWebAuthnReq {
        const message = { publicKeyCredential: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterWebAuthnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterWebAuthnReq): RegisterWebAuthnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID staff_id */ 1:
                    message.staffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.staffId);
                    break;
                case /* string public_key_credential */ 2:
                    message.publicKeyCredential = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterWebAuthnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID staff_id = 1; */
        if (message.staffId)
            UUID.internalBinaryWrite(message.staffId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string public_key_credential = 2; */
        if (message.publicKeyCredential !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.publicKeyCredential);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RegisterWebAuthnReq
 */
export const RegisterWebAuthnReq = new RegisterWebAuthnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterWebAuthnRes$Type extends MessageType<RegisterWebAuthnRes> {
    constructor() {
        super("rd.api.auth.RegisterWebAuthnRes", []);
    }
    create(value?: PartialMessage<RegisterWebAuthnRes>): RegisterWebAuthnRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterWebAuthnRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterWebAuthnRes): RegisterWebAuthnRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RegisterWebAuthnRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RegisterWebAuthnRes
 */
export const RegisterWebAuthnRes = new RegisterWebAuthnRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnStartReq$Type extends MessageType<AuthenticateWebAuthnStartReq> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnStartReq", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "staff_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnStartReq>): AuthenticateWebAuthnStartReq {
        const message = { staffCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnStartReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnStartReq): AuthenticateWebAuthnStartReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string staff_code */ 2:
                    message.staffCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnStartReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string staff_code = 2; */
        if (message.staffCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.staffCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnStartReq
 */
export const AuthenticateWebAuthnStartReq = new AuthenticateWebAuthnStartReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnStartRes$Type extends MessageType<AuthenticateWebAuthnStartRes> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnStartRes", [
            { no: 1, name: "public_key_credential_request_options", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnStartRes>): AuthenticateWebAuthnStartRes {
        const message = { publicKeyCredentialRequestOptions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnStartRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnStartRes): AuthenticateWebAuthnStartRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key_credential_request_options */ 1:
                    message.publicKeyCredentialRequestOptions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnStartRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key_credential_request_options = 1; */
        if (message.publicKeyCredentialRequestOptions !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKeyCredentialRequestOptions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnStartRes
 */
export const AuthenticateWebAuthnStartRes = new AuthenticateWebAuthnStartRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnMultiUserStartReq$Type extends MessageType<AuthenticateWebAuthnMultiUserStartReq> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnMultiUserStartReq", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnMultiUserStartReq>): AuthenticateWebAuthnMultiUserStartReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnMultiUserStartReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnMultiUserStartReq): AuthenticateWebAuthnMultiUserStartReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnMultiUserStartReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnMultiUserStartReq
 */
export const AuthenticateWebAuthnMultiUserStartReq = new AuthenticateWebAuthnMultiUserStartReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnMultiUserStartRes$Type extends MessageType<AuthenticateWebAuthnMultiUserStartRes> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnMultiUserStartRes", [
            { no: 1, name: "public_key_credential_request_options", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pubkey_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnMultiUserStartRes>): AuthenticateWebAuthnMultiUserStartRes {
        const message = { publicKeyCredentialRequestOptions: "", pubkeyIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnMultiUserStartRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnMultiUserStartRes): AuthenticateWebAuthnMultiUserStartRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key_credential_request_options */ 1:
                    message.publicKeyCredentialRequestOptions = reader.string();
                    break;
                case /* repeated string pubkey_ids */ 2:
                    message.pubkeyIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnMultiUserStartRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key_credential_request_options = 1; */
        if (message.publicKeyCredentialRequestOptions !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKeyCredentialRequestOptions);
        /* repeated string pubkey_ids = 2; */
        for (let i = 0; i < message.pubkeyIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.pubkeyIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnMultiUserStartRes
 */
export const AuthenticateWebAuthnMultiUserStartRes = new AuthenticateWebAuthnMultiUserStartRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnReq$Type extends MessageType<AuthenticateWebAuthnReq> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnReq", [
            { no: 1, name: "public_key_credential", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "company_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnReq>): AuthenticateWebAuthnReq {
        const message = { publicKeyCredential: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnReq): AuthenticateWebAuthnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key_credential */ 1:
                    message.publicKeyCredential = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key_credential = 1; */
        if (message.publicKeyCredential !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKeyCredential);
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnReq
 */
export const AuthenticateWebAuthnReq = new AuthenticateWebAuthnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateWebAuthnRes$Type extends MessageType<AuthenticateWebAuthnRes> {
    constructor() {
        super("rd.api.auth.AuthenticateWebAuthnRes", [
            { no: 1, name: "staff", kind: "message", T: () => Staff },
            { no: 2, name: "session_started_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "session_expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AuthenticateWebAuthnRes>): AuthenticateWebAuthnRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateWebAuthnRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateWebAuthnRes): AuthenticateWebAuthnRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Staff staff */ 1:
                    message.staff = Staff.internalBinaryRead(reader, reader.uint32(), options, message.staff);
                    break;
                case /* google.protobuf.Timestamp session_started_at */ 2:
                    message.sessionStartedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionStartedAt);
                    break;
                case /* google.protobuf.Timestamp session_expires_at */ 3:
                    message.sessionExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionExpiresAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateWebAuthnRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Staff staff = 1; */
        if (message.staff)
            Staff.internalBinaryWrite(message.staff, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp session_started_at = 2; */
        if (message.sessionStartedAt)
            Timestamp.internalBinaryWrite(message.sessionStartedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp session_expires_at = 3; */
        if (message.sessionExpiresAt)
            Timestamp.internalBinaryWrite(message.sessionExpiresAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateWebAuthnRes
 */
export const AuthenticateWebAuthnRes = new AuthenticateWebAuthnRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendOneTimePasswordSMSReq$Type extends MessageType<SendOneTimePasswordSMSReq> {
    constructor() {
        super("rd.api.auth.SendOneTimePasswordSMSReq", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "staff_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<SendOneTimePasswordSMSReq>): SendOneTimePasswordSMSReq {
        const message = { staffCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendOneTimePasswordSMSReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendOneTimePasswordSMSReq): SendOneTimePasswordSMSReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string staff_code */ 2:
                    message.staffCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendOneTimePasswordSMSReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string staff_code = 2; */
        if (message.staffCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.staffCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.SendOneTimePasswordSMSReq
 */
export const SendOneTimePasswordSMSReq = new SendOneTimePasswordSMSReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendOneTimePasswordSMSRes$Type extends MessageType<SendOneTimePasswordSMSRes> {
    constructor() {
        super("rd.api.auth.SendOneTimePasswordSMSRes", [
            { no: 1, name: "phone_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "phone_last_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendOneTimePasswordSMSRes>): SendOneTimePasswordSMSRes {
        const message = { phoneId: "", phoneLast4: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendOneTimePasswordSMSRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendOneTimePasswordSMSRes): SendOneTimePasswordSMSRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string phone_id */ 1:
                    message.phoneId = reader.string();
                    break;
                case /* string phone_last_4 */ 2:
                    message.phoneLast4 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendOneTimePasswordSMSRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string phone_id = 1; */
        if (message.phoneId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.phoneId);
        /* string phone_last_4 = 2; */
        if (message.phoneLast4 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.phoneLast4);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.SendOneTimePasswordSMSRes
 */
export const SendOneTimePasswordSMSRes = new SendOneTimePasswordSMSRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateOneTimePasswordReq$Type extends MessageType<AuthenticateOneTimePasswordReq> {
    constructor() {
        super("rd.api.auth.AuthenticateOneTimePasswordReq", [
            { no: 1, name: "phone_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "company_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<AuthenticateOneTimePasswordReq>): AuthenticateOneTimePasswordReq {
        const message = { phoneId: "", code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateOneTimePasswordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateOneTimePasswordReq): AuthenticateOneTimePasswordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string phone_id */ 1:
                    message.phoneId = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 3:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateOneTimePasswordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string phone_id = 1; */
        if (message.phoneId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.phoneId);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* rd.proto.uuid.UUID company_id = 3; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateOneTimePasswordReq
 */
export const AuthenticateOneTimePasswordReq = new AuthenticateOneTimePasswordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateOneTimePasswordRes$Type extends MessageType<AuthenticateOneTimePasswordRes> {
    constructor() {
        super("rd.api.auth.AuthenticateOneTimePasswordRes", [
            { no: 2, name: "session_started_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "session_expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AuthenticateOneTimePasswordRes>): AuthenticateOneTimePasswordRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateOneTimePasswordRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateOneTimePasswordRes): AuthenticateOneTimePasswordRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp session_started_at */ 2:
                    message.sessionStartedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionStartedAt);
                    break;
                case /* google.protobuf.Timestamp session_expires_at */ 3:
                    message.sessionExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionExpiresAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateOneTimePasswordRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp session_started_at = 2; */
        if (message.sessionStartedAt)
            Timestamp.internalBinaryWrite(message.sessionStartedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp session_expires_at = 3; */
        if (message.sessionExpiresAt)
            Timestamp.internalBinaryWrite(message.sessionExpiresAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateOneTimePasswordRes
 */
export const AuthenticateOneTimePasswordRes = new AuthenticateOneTimePasswordRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtendSessionReq$Type extends MessageType<ExtendSessionReq> {
    constructor() {
        super("rd.api.auth.ExtendSessionReq", []);
    }
    create(value?: PartialMessage<ExtendSessionReq>): ExtendSessionReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExtendSessionReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtendSessionReq): ExtendSessionReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ExtendSessionReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.ExtendSessionReq
 */
export const ExtendSessionReq = new ExtendSessionReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtendSessionRes$Type extends MessageType<ExtendSessionRes> {
    constructor() {
        super("rd.api.auth.ExtendSessionRes", [
            { no: 2, name: "session_extended_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "session_expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ExtendSessionRes>): ExtendSessionRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExtendSessionRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtendSessionRes): ExtendSessionRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp session_extended_at */ 2:
                    message.sessionExtendedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionExtendedAt);
                    break;
                case /* google.protobuf.Timestamp session_expires_at */ 3:
                    message.sessionExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sessionExpiresAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExtendSessionRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp session_extended_at = 2; */
        if (message.sessionExtendedAt)
            Timestamp.internalBinaryWrite(message.sessionExtendedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp session_expires_at = 3; */
        if (message.sessionExpiresAt)
            Timestamp.internalBinaryWrite(message.sessionExpiresAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.ExtendSessionRes
 */
export const ExtendSessionRes = new ExtendSessionRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeSessionReq$Type extends MessageType<RevokeSessionReq> {
    constructor() {
        super("rd.api.auth.RevokeSessionReq", []);
    }
    create(value?: PartialMessage<RevokeSessionReq>): RevokeSessionReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeSessionReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeSessionReq): RevokeSessionReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RevokeSessionReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RevokeSessionReq
 */
export const RevokeSessionReq = new RevokeSessionReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeSessionRes$Type extends MessageType<RevokeSessionRes> {
    constructor() {
        super("rd.api.auth.RevokeSessionRes", []);
    }
    create(value?: PartialMessage<RevokeSessionRes>): RevokeSessionRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeSessionRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeSessionRes): RevokeSessionRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RevokeSessionRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.RevokeSessionRes
 */
export const RevokeSessionRes = new RevokeSessionRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateNoltReq$Type extends MessageType<AuthenticateNoltReq> {
    constructor() {
        super("rd.api.auth.AuthenticateNoltReq", []);
    }
    create(value?: PartialMessage<AuthenticateNoltReq>): AuthenticateNoltReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateNoltReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateNoltReq): AuthenticateNoltReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuthenticateNoltReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateNoltReq
 */
export const AuthenticateNoltReq = new AuthenticateNoltReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateNoltRes$Type extends MessageType<AuthenticateNoltRes> {
    constructor() {
        super("rd.api.auth.AuthenticateNoltRes", [
            { no: 1, name: "jwt", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateNoltRes>): AuthenticateNoltRes {
        const message = { jwt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateNoltRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateNoltRes): AuthenticateNoltRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string jwt */ 1:
                    message.jwt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateNoltRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string jwt = 1; */
        if (message.jwt !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jwt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.auth.AuthenticateNoltRes
 */
export const AuthenticateNoltRes = new AuthenticateNoltRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.auth.AuthService
 */
export const AuthService = new ServiceType("rd.api.auth.AuthService", [
    { name: "RegisterWebAuthnStart", options: {}, I: RegisterWebAuthnStartReq, O: RegisterWebAuthnStartRes },
    { name: "RegisterWebAuthn", options: {}, I: RegisterWebAuthnReq, O: RegisterWebAuthnRes },
    { name: "AuthenticateWebAuthnStart", options: {}, I: AuthenticateWebAuthnStartReq, O: AuthenticateWebAuthnStartRes },
    { name: "AuthenticateWebAuthnMultiUserStart", options: {}, I: AuthenticateWebAuthnMultiUserStartReq, O: AuthenticateWebAuthnMultiUserStartRes },
    { name: "AuthenticateWebAuthn", options: {}, I: AuthenticateWebAuthnReq, O: AuthenticateWebAuthnRes },
    { name: "SendOneTimePasswordSMS", options: {}, I: SendOneTimePasswordSMSReq, O: SendOneTimePasswordSMSRes },
    { name: "AuthenticateOneTimePassword", options: {}, I: AuthenticateOneTimePasswordReq, O: AuthenticateOneTimePasswordRes },
    { name: "ExtendSession", options: {}, I: ExtendSessionReq, O: ExtendSessionRes },
    { name: "RevokeSession", options: {}, I: RevokeSessionReq, O: RevokeSessionRes },
    { name: "AuthenticateNolt", options: {}, I: AuthenticateNoltReq, O: AuthenticateNoltRes }
]);
