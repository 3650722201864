import { ExclamationCircleIcon } from "@heroicons/react/solid"

interface ErrorMessageProps {
  message: string | null
}

export function ErrorMessage(props: ErrorMessageProps) {
  const { message } = props

  if (!message) return null

  return (
    <div className="flex flex-row justify-center gap-x-2">
      <ExclamationCircleIcon className="h-6 w-6 text-red-400 dark:text-red-600" />
      <span className="text-red-700 dark:text-red-400">{message}</span>
    </div>
  )
}
