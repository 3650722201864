import { UseIdFilterProps, UseIdFilterRes } from "./types"
import { useCallback, useMemo } from "react"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { useFilter } from "./useFilter"

export function useIdFilter<TValue extends { id?: PBUUID }>(
  props: UseIdFilterProps<TValue>
): UseIdFilterRes {
  const keyExtractor = useCallback(({ id }: TValue) => UUID.idString(id), [])

  const selectedTransformer = useCallback(({ id }: TValue) => id, [])

  const res = useFilter({
    ...props,
    keyExtractor,
    selectedTransformer,
  })

  const selected = useMemo<PBUUID[]>(
    () => res.selected.filter((id): id is PBUUID => !!id),
    [res.selected]
  )

  return {
    ...res,
    selected,
  }
}
