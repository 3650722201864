function normalizeText(text: string) {
  return text.toLowerCase().trim()
}

function filterItemString(itemValue: string, filterInputValue: string) {
  return normalizeText(itemValue).includes(normalizeText(filterInputValue))
}

interface UseItemFilterProps<T> {
  items: T[]
  filterInputValue: string
  valueExtractor: (item: T) => string
  autoFilter?: boolean
}

export function filterItems<T>({
  items,
  filterInputValue,
  valueExtractor,
  autoFilter,
}: UseItemFilterProps<T>) {
  if (!autoFilter) {
    return items
  }

  return items.filter((item) =>
    filterItemString(valueExtractor(item), filterInputValue)
  )
}
