import { Combobox, ComboboxProps } from "components/Combobox"

import { PricingTiers_Tier as PricingTiersTier } from "gen/company/models_pb"
import { useListPricingTiersForCompanyQuery } from "features/api/company"

type PricingTierComboboxProps = Omit<
  ComboboxProps<PricingTiersTier>,
  "items" | "keyExtractor" | "valueExtractor"
>

export function PricingTierCombobox({
  selectedItem,
  onSelectItem,
  ...props
}: PricingTierComboboxProps) {
  const { data: listPricingTiersData } = useListPricingTiersForCompanyQuery({})
  const items = [...(listPricingTiersData?.tiers ?? [])].sort((a, b) => {
    if (a.rank !== b.rank) {
      return a.rank - b.rank
    }
    return a.name.localeCompare(b.name)
  })
  return (
    <Combobox<PricingTiersTier>
      items={items}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      keyExtractor={(tier) => tier.idString ?? ""}
      valueExtractor={(tier) => `Tier ${tier.rank}: ${tier.name}`}
      isBold={props.isBold}
      className="w-full"
      optionsClassName="z-50"
      inputClassName={props.inputClassName}
      {...props}
    />
  )
}
