import { useAppSelector, useInterval } from "app/hooks"
import {
  useExtendSessionMutation,
  useRevokeSessionMutation,
} from "features/api/auth"

import { Cookies } from "lib/cookies/cookies"
import { differenceInSeconds } from "date-fns"
import { selectCompanyId } from "features/auth/authSlice"
import { useRouter } from "next/router"
import { useState } from "react"

export const useSession = () => {
  const companyId = useAppSelector(selectCompanyId)
  const [extendSession, { error: extendSessionError }] =
    useExtendSessionMutation()
  const [
    revokeSession,
    { isLoading: isRevokingSession, error: revokeSessionError },
  ] = useRevokeSessionMutation()

  const [isExtendingSession, setIsExtendingSession] = useState(false)
  const [isSessionTimeoutModalOpen, setIsSessionTimeoutModalOpen] =
    useState(false)

  const router = useRouter()

  useInterval(
    () => {
      const { sessionExpiresDate } = Cookies.fromDocument(document)

      if (!sessionExpiresDate) {
        if (isSessionTimeoutModalOpen) {
          setIsSessionTimeoutModalOpen(false)
        }
        return
      }

      const now = new Date()
      const secondsUntilTokenExpiration = differenceInSeconds(
        sessionExpiresDate,
        now
      )

      if (secondsUntilTokenExpiration <= 0) {
        setIsSessionTimeoutModalOpen(false)
      } else if (
        secondsUntilTokenExpiration < 30 &&
        !isSessionTimeoutModalOpen &&
        !isExtendingSession
      ) {
        setIsSessionTimeoutModalOpen(true)
      }
    },
    isSessionTimeoutModalOpen ? 1000 : 10000
  )

  const throttledExtendSession = async () => {
    if (isSessionTimeoutModalOpen || isExtendingSession) {
      return
    }

    const { sessionUpdatedDate } = Cookies.fromDocument(document)
    const { sessionExpiresDate } = Cookies.fromDocument(document)

    if (!sessionUpdatedDate) {
      return
    }

    const now = new Date()
    const secondsSinceSessionUpdate = differenceInSeconds(
      now,
      sessionUpdatedDate
    )

    const secondsUntilTokenExpiration = sessionExpiresDate
      ? differenceInSeconds(sessionExpiresDate, now)
      : 0

    if (secondsSinceSessionUpdate < 30) {
      return
    }
    if (secondsUntilTokenExpiration > 120) {
      return
    }

    try {
      setIsExtendingSession(true)

      await extendSession({ companyId: companyId! }).unwrap()
    } catch {
      // swallow error; handled above
    } finally {
      setIsExtendingSession(false)
    }
  }

  const extendSessionAndCloseSessionTimeoutModal = async () => {
    try {
      setIsExtendingSession(true)

      await extendSession({ companyId: companyId! }).unwrap()

      if (isSessionTimeoutModalOpen) {
        setIsSessionTimeoutModalOpen(false)
      }
    } catch {
      // swallow error; handled above
    } finally {
      setIsExtendingSession(false)
    }
  }

  const signOutAndCloseSessionTimeoutModal = async () => {
    try {
      await revokeSession({}).unwrap()

      if (isSessionTimeoutModalOpen) {
        setIsSessionTimeoutModalOpen(false)
      }

      router.push("/auth/sign-in")
    } catch {
      // swallow error; handled above
    }
  }

  return {
    isExtendingSession,
    throttledExtendSession,
    extendSessionError,
    isSessionTimeoutModalOpen,
    extendSessionAndCloseSessionTimeoutModal,
    isRevokingSession,
    signOutAndCloseSessionTimeoutModal,
    revokeSessionError,
  }
}
