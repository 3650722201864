import { CheckIcon as HeroIconCheckIcon } from "@heroicons/react/solid"
import { classNames } from "lib/classNames"

interface CheckIconProps {
  checked: boolean
  disabled?: boolean
}

export function CheckIcon({ checked, disabled }: CheckIconProps) {
  return (
    <HeroIconCheckIcon
      className={classNames(
        "h-4 w-4 opacity-0",
        checked && "opacity-100",
        "text-white",
        disabled && "text-border-primary-light dark:text-border-primary-dark"
      )}
    />
  )
}
