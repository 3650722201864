import { Format, Timestamp } from "lib/timestamp/timestamp"
import {
  endOfDay,
  formatISO,
  isBefore,
  isSameDay,
  parseISO,
  startOfDay,
} from "date-fns"

import { Timestamp as TimestampPB } from "gen/google/protobuf/timestamp_pb"
import { TimestampRange as TimestampRangePB } from "gen/proto/timestamprange/models_pb"

export class TimestampRange {
  static humanize(tr?: TimestampRangePB): string {
    const f = Format.DATE

    if (!tr) {
      return ""
    }

    if (!tr.start || !tr.end) {
      return ""
    }

    if (isSameDay(TimestampPB.toDate(tr.start), TimestampPB.toDate(tr.end))) {
      return Timestamp.format(f, tr.end)
    }

    return `${Timestamp.format(f, tr.start)} - ${Timestamp.format(f, tr.end)}`
  }

  static toQueryParam(tr: TimestampRangePB | null) {
    if (!tr) {
      return ""
    }

    if (!tr.start || !tr.end) {
      return ""
    }

    if (isSameDay(TimestampPB.toDate(tr.start), TimestampPB.toDate(tr.end))) {
      return formatISO(TimestampPB.toDate(tr.start))
    }

    return `${formatISO(TimestampPB.toDate(tr.start))}/${formatISO(
      TimestampPB.toDate(tr.end)
    )}`
  }

  static fromQueryParam(qp: string): TimestampRangePB | null {
    const [start, end] = qp.split("/")

    if (!start && !end) {
      return null
    }

    if (start && !end) {
      return {
        start: TimestampPB.fromDate(startOfDay(parseISO(start))),
        end: TimestampPB.fromDate(endOfDay(parseISO(start))),
      }
    }

    return {
      start: TimestampPB.fromDate(parseISO(start)),
      end: TimestampPB.fromDate(parseISO(end)),
    }
  }

  // fromPartialQueryParam reads from a query param string `qp` that
  // may have incomplete start/end dates. While we are in the process of
  // moving to a future where query params hold all filter information, this
  // function should be used for all future "dates inside query params" work
  static fromPartialQueryParam(
    dateParam: string | string[] | undefined
  ): TimestampRangePB | null {
    if (!dateParam || typeof dateParam !== "string") {
      return null
    }

    const [startStr, endStr] = decodeURIComponent(dateParam).split("/")

    if (!startStr && !endStr) {
      return null
    }

    const start = startStr
      ? TimestampPB.fromDate(parseISO(startStr))
      : undefined
    const end = endStr ? TimestampPB.fromDate(parseISO(endStr)) : undefined

    return {
      start,
      end,
    }
  }

  // toPartialQueryParam generates a query param string that may have
  // incomplete start/end dates. While we are in the process of moving
  // to a future where query params hold all filter information, this
  // function should be used for all future "dates inside query params" work
  static toPartialQueryParam(tr: TimestampRangePB | null) {
    if (!tr?.start && !tr?.end) {
      return ""
    }

    const start = tr.start ? formatISO(TimestampPB.toDate(tr.start)) : ""
    const end = tr.end ? formatISO(TimestampPB.toDate(tr.end)) : ""

    return encodeURIComponent(`${start}/${end}`)
  }

  static isValid(tr: TimestampRangePB | null): boolean {
    if (!tr) {
      return false
    }

    if (!tr.start || !tr.end) {
      return false
    }

    return isBefore(TimestampPB.toDate(tr.start), TimestampPB.toDate(tr.end))
  }
}
