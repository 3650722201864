import {
  SearchInventoryCountProductsReq_OrderBy as ReqOrderBy,
  BulkExportInventoryCountProductsReq_OrderBy as SearchOrderBy,
} from "gen/search/service/service_pb"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { stringify } from "lib/stringify"

export const checkProductsEquality = (
  productsA: { id?: PBUUID }[],
  productsB: { id?: PBUUID }[]
) => {
  if (!productsA && !productsB) {
    return true
  }
  if (!productsA || !productsB) {
    return false
  }
  if (productsA.length !== productsB.length) {
    return false
  }
  productsA.sort((pa, pb) => UUID.comparePB(pa.id, pb.id))
  productsB.sort((pa, pb) => UUID.comparePB(pa.id, pb.id))
  return stringify(productsA) === stringify(productsB)
}

export function convertOrderBy(o: ReqOrderBy) {
  switch (o) {
    case ReqOrderBy.NAME: {
      return SearchOrderBy.NAME
    }
    case ReqOrderBy.ON_HAND: {
      return SearchOrderBy.ON_HAND
    }
    case ReqOrderBy.VARIANCE: {
      return SearchOrderBy.VARIANCE
    }
    case ReqOrderBy.DOLLAR_VARIANCE: {
      return SearchOrderBy.DOLLAR_VARIANCE
    }
    default:
      return SearchOrderBy.NONE
  }
}
