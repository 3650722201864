import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from "react"

export function useStaffExternalIDForm(
  onSubmit: (staffExternalID: string) => void
) {
  const [staffExternalID, setStaffExternalID] = useState("")

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debouncedSubmit = useCallback(
    (id: string) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        onSubmit(id)
      }, 500)
    },
    [onSubmit]
  )

  const onChangeStaffExternalID: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const newCode = e.target.value.toUpperCase()
        setStaffExternalID(newCode)
        if (newCode) {
          debouncedSubmit(newCode)
        }
      },
      [debouncedSubmit]
    )

  const handleSubmit = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    onSubmit(staffExternalID)
  }, [onSubmit, staffExternalID])

  const onKeyDownStaffExternalID: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key === "Enter") {
          handleSubmit()
        }
      },
      [handleSubmit]
    )

  return {
    staffExternalID,
    onKeyDownStaffExternalID,
    onChangeStaffExternalID,
    handleSubmit,
  }
}
