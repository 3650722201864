import { Button } from "components/Button"
import { LocationsCombobox } from "./LocationsCombobox"
import { SignInViaTextMessageButton } from "./SignInViaTextMessageButton"

interface StepKeyProps {
  onClickScanKey: () => void
}

export function StepKey(props: StepKeyProps) {
  const { onClickScanKey } = props

  return (
    <>
      <LocationsCombobox />
      <Button mode="primary" onClick={onClickScanKey}>
        Scan key
      </Button>
      <SignInViaTextMessageButton />
    </>
  )
}
