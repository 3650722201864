import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { Printer } from "gen/printer/models_pb"

type PrintersState = {
  selectedPrinter: Printer | null
}

const initialState: PrintersState = {
  selectedPrinter: null,
}

export const printersSlice = createSlice({
  name: "printers",
  initialState,
  reducers: {
    setSelectedPrinter: (state, action: PayloadAction<Printer | null>) => {
      state.selectedPrinter = action.payload
    },
  },
})

export const { setSelectedPrinter } = printersSlice.actions

export const selectPrinter = (state: AppState) => state.printers.selectedPrinter
