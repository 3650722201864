import { isBefore, isSameSecond } from "date-fns"

import { Timestamp as TimestampPB } from "gen/google/protobuf/timestamp_pb"
import { TimestampRange as TimestampRangePB } from "gen/proto/timestamprange/models_pb"

export class PartialInterval {
  readonly start?: Date
  readonly end?: Date

  constructor({ start, end }: { start?: Date; end?: Date }) {
    this.start = start
    this.end = end
  }

  toTimestampRangePB(): TimestampRangePB {
    return PartialInterval.toTimestampRangePB(this)
  }

  isValid() {
    return isFullInterval(this)
  }

  isEmpty(): boolean {
    return !this.start && !this.end
  }

  isEqual(other: PartialInterval | null): boolean {
    return isEqualInterval(this, other)
  }

  static toTimestampRangePB(interval: PartialInterval): TimestampRangePB {
    return TimestampRangePB.create({
      start: interval.start ? TimestampPB.fromDate(interval.start) : undefined,
      end: interval.end ? TimestampPB.fromDate(interval.end) : undefined,
    })
  }

  static fromTimestampRangePB(range: TimestampRangePB): PartialInterval {
    return new PartialInterval({
      start: range.start && TimestampPB.toDate(range.start),
      end: range.end && TimestampPB.toDate(range.end),
    })
  }
}

export function isEqualInterval(
  left: PartialInterval | null,
  right: PartialInterval | null
) {
  if (!left && !right) return true // null intervals are considered equal
  // note: we use isSameSecond instead of isEqual because we serialize dates with second precision, and
  // want this comparison to work with both deserialized and non-serialized dates.
  return (
    isSameDate(left?.start, right?.start) && isSameDate(left?.end, right?.end)
  )
}

function isSameDate(a: Date | undefined, b: Date | undefined) {
  if (!a && !b) {
    return true
  }

  if (!a || !b) {
    return false
  }

  return isSameSecond(a, b)
}

export function isFullInterval(
  interval: PartialInterval
): interval is FullInterval {
  return (
    !!interval.start && !!interval.end && isBefore(interval.start, interval.end)
  )
}

export class FullInterval extends PartialInterval {
  readonly start: Date
  readonly end: Date

  constructor({ start, end }: { start: Date; end: Date }) {
    super({ start, end })
    // TODO(rkofman): this should not be necessary IFF we use `declare`
    // (see: https://www.typescriptlang.org/docs/handbook/2/classes.html#type-only-field-declarations)
    // But that currently throws a build error that would need to be debugged.
    // see also: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#the-usedefineforclassfields-flag-and-the-declare-property-modifier
    this.start = start
    this.end = end
  }
}

export function newFullIntervalFromTimestampRange(
  range: TimestampRangePB | null
): FullInterval | null {
  if (!range) {
    return null
  }
  const partial = PartialInterval.fromTimestampRangePB(range)
  if (!isFullInterval(partial)) {
    return null
  }
  return partial
}
