import { UseIdStringFilterProps, UseIdStringFilterRes } from "./types"

import { useCallback } from "react"
import { useFilter } from "./useFilter"

export function useIdStringFilter<TValue extends { idString: string }>(
  props: UseIdStringFilterProps<TValue>
): UseIdStringFilterRes {
  const idStringExtractor = useCallback(({ idString }: TValue) => idString, [])

  return useFilter({
    ...props,
    keyExtractor: idStringExtractor,
    selectedTransformer: idStringExtractor,
  })
}
