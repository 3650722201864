// @ts-strict-ignore
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import "../styles/globals.css"
import "@fortawesome/fontawesome-svg-core/styles.css"

import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react"
import Router, { useRouter } from "next/router"
import store, { persistor } from "../app/store"
import { useAppDispatch, useInterval } from "app/hooks"

import type { AppProps } from "next/app"
import { Cookies } from "lib/cookies/cookies"
import ErrorBoundary from "components/ErrorPage/ErrorBoundary"
import { ErrorPage } from "components/ErrorPage"
import { LoadingSpinner } from "components/LoadingSpinner"
import { NextPage } from "next"
import { PersistGate } from "redux-persist/integration/react"
import { PreviousRouteContextProvider } from "app/PreviousRouteContext"
import { Provider } from "react-redux"
import { SaleStatusFormatted } from "features/pos/sales/helpers"
import Script from "next/script"
import SessionTimeoutModal from "features/auth/SessionTimeoutModal"
import { SessionTimeoutSignIn } from "features/auth/SessionTimeoutSignIn"
import { UUID } from "uuid-rd"
import { config } from "@fortawesome/fontawesome-svg-core"
import { isApiError } from "features/api/apiSlice"
import { setColorScheme } from "features/theme/themeSlice"
import { toast } from "lib/toast"
import { useBarcodeScanner } from "hooks/useBarcodeScanner"
import useKeyboardShortcut from "hooks/useKeyboardShortcut"
import { useLazyGetCompanyForSubdomainQuery } from "features/api/company"
import { useLazyGetScannedSaleQuery } from "features/api/sale"
import { useSession } from "hooks/useSession"

config.autoAddCss = false

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const sessionExtendingEventTypes = [
  "click",
  "scroll",
  "keypress",
  "mousemove",
  "mousedown",
  "load",
]

if (
  typeof window !== undefined && // ensures in browser
  !!process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
  !!process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
  !!process.env.NEXT_PUBLIC_DATADOG_SITE &&
  (process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
    process.env.NEXT_PUBLIC_ENABLE_DATADOG_RUM === "true")
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    service: "web",
    env: process.env.NEXT_PUBLIC_VERCEL_ENV ?? "development",
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    allowedTracingUrls: [
      (url) => url.startsWith(process.env.NEXT_PUBLIC_RUNDOO_API_BASE_URL),
      (url) =>
        url.includes(
          process.env.NEXT_PUBLIC_RUNDOO_SEARCH_API_BASE_URL.replace(
            "https://",
            ""
          )
        ),
    ],
  })
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const dispatch = useAppDispatch()
  const [isTimedOutModalOpen, setIsTimedOutModalOpen] = useState<boolean>(false)

  const router = useRouter()

  const [
    getCompanyForSubdomain,
    {
      error: getCompanyForSubdomainError,
      originalArgs: getCompanyForSubdomainArgs,
    },
  ] = useLazyGetCompanyForSubdomainQuery()

  const {
    isRevokingSession,
    isExtendingSession,
    throttledExtendSession,
    isSessionTimeoutModalOpen,
    signOutAndCloseSessionTimeoutModal,
    extendSessionAndCloseSessionTimeoutModal,
    extendSessionError,
    revokeSessionError,
  } = useSession()

  useEffect(() => {
    let subdomain = window.location.hostname.split(".")[0]

    if (subdomain === "localhost") {
      subdomain = "rdo"
    }

    getCompanyForSubdomain(subdomain, true)
  }, [getCompanyForSubdomain])

  useEffect(() => {
    dispatch(
      setColorScheme(
        document.documentElement.classList.contains("dark") ? "dark" : "light"
      )
    )
  }, [dispatch])

  useInterval(
    () => {
      const { sessionExpiresDate } = Cookies.fromDocument(document)

      const routeRequiresAuth = router.pathname.split("/")[1] !== "auth"

      if (routeRequiresAuth && !sessionExpiresDate) {
        if (!isTimedOutModalOpen) {
          setIsTimedOutModalOpen(true)
        }
      } else {
        setIsTimedOutModalOpen(false)
      }
    },
    isSessionTimeoutModalOpen || isTimedOutModalOpen ? 500 : 10000
  )

  useEffect(() => {
    for (const eventType of sessionExtendingEventTypes) {
      document.addEventListener(eventType, throttledExtendSession)
    }

    return () => {
      for (const eventType of sessionExtendingEventTypes) {
        document.removeEventListener(eventType, throttledExtendSession)
      }
    }
  }, [throttledExtendSession])

  useKeyboardShortcut(
    "esc",
    (event) => {
      if (event.target instanceof HTMLElement) {
        if (
          event?.target?.tagName === "INPUT" ||
          event?.target?.tagName === "SELECT" ||
          event?.target?.tagName === "TEXTAREA"
        ) {
          event?.target.blur()
        } else if (router.pathname.startsWith("/auth")) {
          setIsTimedOutModalOpen(false)
          router.push("/auth/sign-in")
        }
      }
    },
    {
      enableOnFormTags: true,
    }
  )

  const [getScannedSale] = useLazyGetScannedSaleQuery()

  const onScan = useCallback(
    async (code: string) => {
      if (!code.endsWith("-S")) {
        return
      }

      const { sessionExpiresDate } = Cookies.fromDocument(document)
      if (!sessionExpiresDate) {
        return
      }

      try {
        const { sale } = await getScannedSale({ identifier: code }).unwrap()
        if (sale.id) {
          const url = `/pos/sales/${UUID.fromPB(sale.id).toString()}?status=${
            sale?.status &&
            SaleStatusFormatted.get(sale.status)
              ?.toLowerCase()
              .replace(" ", "-")
          }`
          Router.push(url)
        }
      } catch {
        toast.error(`Sale ${code} not found`)
      }
    },
    [getScannedSale]
  )

  useBarcodeScanner({ onScan })

  const getLayout = Component.getLayout ?? ((page) => page)

  if (
    isApiError(getCompanyForSubdomainError) &&
    getCompanyForSubdomainError.code === "not_found"
  ) {
    return (
      <ErrorPage
        message={`Subdomain ${getCompanyForSubdomainArgs} not found.`}
      />
    )
  }

  if (!router.isReady) {
    // When router isn't ready, we can't access url parameters from the Next.js router.
    // Next.js should be able to get to a ready state _very quickly_ on first render, so
    // blocking app rendering globally on it shouldn't break our user experience.
    // see https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <LoadingSpinner size={"large"} className="w-30 h-30" />
        </div>
      </div>
    )
  }

  return (
    <>
      {getLayout(<Component {...pageProps} />)}
      <SessionTimeoutModal
        open={isSessionTimeoutModalOpen}
        onStayLoggedIn={extendSessionAndCloseSessionTimeoutModal}
        isExtendingSession={isExtendingSession}
        onSignOut={signOutAndCloseSessionTimeoutModal}
        isSigningOut={isRevokingSession}
        onClose={() => {}}
        showErrorBanner={!!extendSessionError || !!revokeSessionError}
      />
      {isTimedOutModalOpen && <SessionTimeoutSignIn />}
    </>
  )
}

const queryClient = new QueryClient()

export default function MyApp(props: AppPropsWithLayout) {
  return (
    <>
      <Script src="/scripts/colorScheme.js" strategy="beforeInteractive" />
      <Script src="/scripts/loadIntercom.js" />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <PreviousRouteContextProvider>
                <App {...props} />
              </PreviousRouteContextProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}
