import { Component, ErrorInfo, PropsWithChildren } from "react"

import { ErrorPage } from "./ErrorPage"
import { datadogRum } from "@datadog/browser-rum"

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = info.componentStack ?? undefined
    renderingError.cause = error

    datadogRum.addError(renderingError)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
