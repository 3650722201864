import React, { HTMLAttributes, MouseEventHandler, ReactNode } from "react"

import { DuplicateIcon } from "@heroicons/react/outline"
import { classNames } from "lib/classNames"
import { toast } from "lib/toast"

interface CopyableFieldProps extends HTMLAttributes<HTMLDivElement> {
  value: string
  children: ReactNode
  copyLabel?: string
}

export const CopyableField: React.FC<CopyableFieldProps> = ({
  value,
  children,
  className,
  copyLabel = "Content",
  ...otherProps
}) => {
  const handleCopy: MouseEventHandler<SVGSVGElement> = async (e) => {
    e.stopPropagation()
    try {
      await navigator.clipboard.writeText(value)
      toast.copy(`${copyLabel} copied`)
    } catch {
      toast.error("Failed to copy")
    }
  }

  if (!value) {
    return null
  }

  return (
    <div className={classNames("flex items-center", className)} {...otherProps}>
      {children}
      <DuplicateIcon
        className="ml-1 h-4 w-4 cursor-pointer"
        onClick={handleCopy}
      />
    </div>
  )
}
