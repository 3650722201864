import { Text } from "./text"

export const US_STATES = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const

// prefix & suffix for barcode scanning
export const SCAN_PREFIX_SUFFIX = "|"

// Max length for product identifiers
export const MAX_IDENTIFIER_CHARACTERS = 80

export function supportNumber(format: "display" | "E.164" = "display"): string {
  if (format === "E.164") {
    return "+16503343205"
  }
  return "(650) 334-3205"
}

export const MAX_SEARCH_INPUT_LENGTH = 100

export const EXCEL_EXTENSION = ".xlsx"

export function financeTermString(
  isCapitalized?: boolean,
  isPlural?: boolean
): string {
  let s = "finance term"
  if (isCapitalized) {
    s = Text.fromString(s).capitalize()
  }
  if (isPlural) {
    s = s + "s"
  }
  return s
}

export const SEARCH_INPUT_FILTERS_MIN_LENGTH = 3
