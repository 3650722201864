import { CredentialRequestOptionsJSON, get } from "@github/webauthn-json"
import { useCallback, useState } from "react"

export function useWebauthn() {
  const [error, setError] = useState<Error | null>(null)

  const getPubKeyCredWithAssertion = useCallback(
    async (requestJSON: CredentialRequestOptionsJSON) => {
      try {
        setError(null)
        return await get(requestJSON)
      } catch (error) {
        setError(error instanceof Error ? error : new Error(String(error)))
        throw error
      }
    },
    []
  )

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  return { getPubKeyCredWithAssertion, error, clearError }
}
