import {
  Combobox,
  ComboboxProps,
  DefaultEmptyState,
  DefaultRow,
} from "components/TableV2/Combobox/Combobox"

import { ReactNode } from "react"
import { useCombobox } from "components/TableV2/Combobox/useCombobox"

export interface FilteredComboboxProps<T> {
  items: T[]
  keyExtractor: (item: T) => string
  valueExtractor: (item: T) => string
  accessoryExtractor?: (item: T) => ReactNode
  onSelect: (item: T) => void
  comboboxProps?: Partial<ComboboxProps<T>>
}

export function FilteredCombobox<T>({
  items,
  keyExtractor,
  valueExtractor,
  accessoryExtractor,
  onSelect,
  comboboxProps,
}: FilteredComboboxProps<T>) {
  const useComboboxRes = useCombobox<T>({
    items,
    keyExtractor,
    valueExtractor,
    onSelect,
    autoFilter: true,
  })

  return (
    <Combobox
      useComboboxRes={useComboboxRes}
      renderItem={(item, isHighlighted) => (
        <DefaultRow
          item={item}
          isHighlighted={isHighlighted}
          valueExtractor={valueExtractor}
          accessoryExtractor={accessoryExtractor}
        />
      )}
      keyExtractor={(item) => keyExtractor(item)}
      renderEmptyState={DefaultEmptyState}
      {...comboboxProps}
    />
  )
}
