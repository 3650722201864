/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "payments/models.proto" (package "rd.payments", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Address } from "../proto/address/models_pb";
import { UUID } from "../proto/uuid/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Money } from "../proto/money/models_pb";
// Shared

/**
 * BankAccount represents a bank account with basic details
 *
 * @generated from protobuf message rd.payments.BankAccount
 */
export interface BankAccount {
    /**
     * @generated from protobuf field: string bank_name = 1;
     */
    bankName: string;
    /**
     * @generated from protobuf field: string last_4 = 2;
     */
    last4: string;
}
/**
 * @generated from protobuf message rd.payments.BankPaymentMethod
 */
export interface BankPaymentMethod {
    /**
     * @generated from protobuf field: string stripe_payment_method_id = 1;
     */
    stripePaymentMethodId: string;
    /**
     * @generated from protobuf field: string bank_name = 2;
     */
    bankName: string;
    /**
     * @generated from protobuf field: string last_4 = 3;
     */
    last4: string;
    /**
     * @generated from protobuf field: string routing_number = 4;
     */
    routingNumber: string;
}
// Payments

/**
 * ManuallyEnteredCard represents a keyed-in card at a point-of-sale.
 *
 * @generated from protobuf message rd.payments.ManuallyEnteredCard
 */
export interface ManuallyEnteredCard {
    /**
     * @generated from protobuf field: string card_number = 1;
     */
    cardNumber: string;
    /**
     * @generated from protobuf field: int32 expiration_month = 2;
     */
    expirationMonth: number;
    /**
     * @generated from protobuf field: int32 expiration_year = 3;
     */
    expirationYear: number;
    /**
     * @generated from protobuf field: string cvc = 4;
     */
    cvc: string;
    /**
     * @generated from protobuf field: string zip_code = 5;
     */
    zipCode: string;
}
/**
 * CardPaymentReceiptInfo contains the receipt info extracted from a Stripe
 * payment intent.
 *
 * @generated from protobuf message rd.payments.CardPaymentReceiptInfo
 */
export interface CardPaymentReceiptInfo {
    /**
     * @generated from protobuf field: string account_type = 1;
     */
    accountType: string;
    /**
     * @generated from protobuf field: string application_name = 2;
     */
    applicationName: string;
    /**
     * @generated from protobuf field: string dedicated_file_name = 3;
     */
    dedicatedFileName: string;
}
/**
 * Cart represents a collection of items rung up at a point-of-sale.
 *
 * @generated from protobuf message rd.payments.Cart
 */
export interface Cart {
    /**
     * @generated from protobuf field: repeated rd.payments.Cart.Item items = 1;
     */
    items: Cart_Item[];
}
/**
 * @generated from protobuf message rd.payments.Cart.Item
 */
export interface Cart_Item {
    /**
     * @generated from protobuf field: int64 price_cents = 1;
     */
    priceCents: bigint;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: int64 quantity = 3;
     */
    quantity: bigint;
}
/**
 * Card represents a debit/credit card.
 *
 * @generated from protobuf message rd.payments.Card
 */
export interface Card {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string brand = 2;
     */
    brand: string;
    /**
     * @generated from protobuf field: string last_4 = 3;
     */
    last4: string;
    /**
     * @generated from protobuf field: int32 expiration_month = 4;
     */
    expirationMonth: number;
    /**
     * @generated from protobuf field: int32 expiration_year = 5;
     */
    expirationYear: number;
    /**
     * @generated from protobuf field: bool is_primary = 6;
     */
    isPrimary: boolean;
    /**
     * @generated from protobuf field: string nickname = 7;
     */
    nickname: string;
}
/**
 * CardReader represents a Stripe card reader.
 *
 * @generated from protobuf message rd.payments.CardReader
 */
export interface CardReader {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string serial_number = 3;
     */
    serialNumber: string;
    /**
     * @generated from protobuf field: bool online = 4;
     */
    online: boolean;
}
/**
 * @generated from protobuf message rd.payments.Payment
 */
export interface Payment {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: rd.payments.Payment.Status status = 2;
     */
    status: Payment_Status;
    /**
     * @generated from protobuf field: rd.proto.money.Money application_fee_amount = 3;
     */
    applicationFeeAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount_received = 4;
     */
    amountReceived?: Money;
    /**
     * @generated from protobuf field: rd.payments.Card card = 5;
     */
    card?: Card;
}
/**
 * @generated from protobuf enum rd.payments.Payment.Status
 */
export enum Payment_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_REQUIRES_PAYMENT_METHOD = 1;
     */
    REQUIRES_PAYMENT_METHOD = 1,
    /**
     * @generated from protobuf enum value: STATUS_REQUIRES_CONFIRMATION = 2;
     */
    REQUIRES_CONFIRMATION = 2,
    /**
     * @generated from protobuf enum value: STATUS_REQUIRES_ACTION = 3;
     */
    REQUIRES_ACTION = 3,
    /**
     * @generated from protobuf enum value: STATUS_PROCESSING = 4;
     */
    PROCESSING = 4,
    /**
     * @generated from protobuf enum value: STATUS_REQUIRES_CAPTURE = 5;
     */
    REQUIRES_CAPTURE = 5,
    /**
     * @generated from protobuf enum value: STATUS_CANCELED = 6;
     */
    CANCELED = 6,
    /**
     * @generated from protobuf enum value: STATUS_SUCCEEDED = 7;
     */
    SUCCEEDED = 7
}
/**
 * @generated from protobuf message rd.payments.FinancialConnectionsAccount
 */
export interface FinancialConnectionsAccount {
    /**
     * @generated from protobuf field: string stripe_financial_connections_account_id = 1;
     */
    stripeFinancialConnectionsAccountId: string;
    /**
     * @generated from protobuf field: rd.payments.BankAccount bank_account = 2;
     */
    bankAccount?: BankAccount;
    /**
     * @generated from protobuf field: rd.payments.FinancialConnectionsAccount.Balance balance = 3;
     */
    balance?: FinancialConnectionsAccount_Balance;
    /**
     * @generated from protobuf field: string display_name = 4;
     */
    displayName: string;
}
/**
 * @generated from protobuf message rd.payments.FinancialConnectionsAccount.Balance
 */
export interface FinancialConnectionsAccount_Balance {
    /**
     * @generated from protobuf field: rd.proto.money.Money current = 1;
     */
    current?: Money; // posted funds only
    /**
     * @generated from protobuf field: rd.proto.money.Money available = 2;
     */
    available?: Money; // pending and posted funds
    /**
     * @generated from protobuf field: google.protobuf.Timestamp as_of = 3;
     */
    asOf?: Timestamp;
    /**
     * @generated from protobuf field: bool isRefreshed = 4;
     */
    isRefreshed: boolean;
}
// Banking

/**
 * FinancialAccount represents a financial account
 *
 * @generated from protobuf message rd.payments.FinancialAccount
 */
export interface FinancialAccount {
    /**
     * @generated from protobuf field: string stripe_financial_account_id = 1;
     */
    stripeFinancialAccountId: string;
    /**
     * @generated from protobuf field: bool is_onboarded = 2;
     */
    isOnboarded: boolean;
    /**
     * @generated from protobuf field: rd.payments.FinancialAccount.Balance balance = 3;
     */
    balance?: FinancialAccount_Balance;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.payments.FinancialAccount.FinancialAddress financial_address = 5;
     */
    financialAddress?: FinancialAccount_FinancialAddress;
}
/**
 * @generated from protobuf message rd.payments.FinancialAccount.Balance
 */
export interface FinancialAccount_Balance {
    /**
     * @generated from protobuf field: rd.proto.money.Money cash = 1;
     */
    cash?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money inbound_pending = 2;
     */
    inboundPending?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money outbound_pending = 3;
     */
    outboundPending?: Money;
}
/**
 * @generated from protobuf message rd.payments.FinancialAccount.FinancialAddress
 */
export interface FinancialAccount_FinancialAddress {
    /**
     * @generated from protobuf field: string account_holder_name = 1;
     */
    accountHolderName: string;
    /**
     * @generated from protobuf field: string account_number = 2;
     */
    accountNumber: string;
    /**
     * @generated from protobuf field: string bank_name = 3;
     */
    bankName: string;
    /**
     * @generated from protobuf field: string routing_number = 4;
     */
    routingNumber: string;
    /**
     * @generated from protobuf field: repeated rd.payments.FinancialAccount.FinancialAddress.Network supported_networks = 5;
     */
    supportedNetworks: FinancialAccount_FinancialAddress_Network[];
}
/**
 * @generated from protobuf enum rd.payments.FinancialAccount.FinancialAddress.Network
 */
export enum FinancialAccount_FinancialAddress_Network {
    /**
     * @generated from protobuf enum value: NETWORK_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: NETWORK_ACH = 1;
     */
    ACH = 1,
    /**
     * @generated from protobuf enum value: NETWORK_US_DOMESTIC_WIRE = 2;
     */
    US_DOMESTIC_WIRE = 2
}
/**
 * IssuingCard represents an issued card
 *
 * @generated from protobuf message rd.payments.IssuingCard
 */
export interface IssuingCard {
    /**
     * @generated from protobuf field: string stripe_issuing_card_id = 1;
     */
    stripeIssuingCardId: string;
    /**
     * @generated from protobuf field: string brand = 2;
     */
    brand: string;
    /**
     * @generated from protobuf field: string last_4 = 3;
     */
    last4: string;
    /**
     * @generated from protobuf field: int32 expiration_month = 4;
     */
    expirationMonth: number;
    /**
     * @generated from protobuf field: int32 expiration_year = 5;
     */
    expirationYear: number;
    /**
     * @generated from protobuf field: string cardholder_name = 6;
     */
    cardholderName: string;
    /**
     * @generated from protobuf field: string full_number = 7;
     */
    fullNumber: string;
    /**
     * @generated from protobuf field: string cvv = 8;
     */
    cvv: string;
}
/**
 * FinancialTransaction represents a financial transaction
 *
 * @generated from protobuf message rd.payments.FinancialTransaction
 */
export interface FinancialTransaction {
    /**
     * @generated from protobuf field: string stripe_transaction_id = 2;
     */
    stripeTransactionId: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 3;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp; // DB-only
    /**
     * @generated from protobuf field: google.protobuf.Timestamp opened_at = 5;
     */
    openedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp posted_at = 6;
     */
    postedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp voided_at = 7;
     */
    voidedAt?: Timestamp;
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string;
    /**
     * @generated from protobuf field: string stripe_financial_account_id = 9;
     */
    stripeFinancialAccountId: string;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.Type type = 10;
     */
    type: FinancialTransaction_Type;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.Status status = 11;
     */
    status: FinancialTransaction_Status;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.CreditReversal credit_reversal = 12;
     */
    creditReversal?: FinancialTransaction_CreditReversal;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.DebitReversal debit_reversal = 13;
     */
    debitReversal?: FinancialTransaction_DebitReversal;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.InboundTransfer inbound_transfer = 14;
     */
    inboundTransfer?: FinancialTransaction_InboundTransfer;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.IssuingAuthorization issuing_authorization = 15;
     */
    issuingAuthorization?: FinancialTransaction_IssuingAuthorization;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.OutboundPayment outbound_payment = 16;
     */
    outboundPayment?: FinancialTransaction_OutboundPayment;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedCredit received_credit = 17;
     */
    receivedCredit?: FinancialTransaction_ReceivedCredit;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedDebit received_debit = 18;
     */
    receivedDebit?: FinancialTransaction_ReceivedDebit;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 19;
     */
    companyId?: UUID; // DB-only
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 20;
     */
    updatedAt?: Timestamp; // DB-only
    /**
     * DB-only
     *
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 22;
     */
    locationId?: UUID; // DB-only
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_chart_of_account_id = 23;
     */
    allocationsByChartOfAccountId: {
        [key: string]: Money;
    }; // DB-only
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.CreditReversal
 */
export interface FinancialTransaction_CreditReversal {
    /**
     * @generated from protobuf field: string stripe_credit_reversal_id = 1;
     */
    stripeCreditReversalId: string;
    /**
     * @generated from protobuf field: string stripe_received_credit_id = 2;
     */
    stripeReceivedCreditId: string;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedCredit received_credit = 3;
     */
    receivedCredit?: FinancialTransaction_ReceivedCredit;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.DebitReversal
 */
export interface FinancialTransaction_DebitReversal {
    /**
     * @generated from protobuf field: string stripe_debit_reversal_id = 1;
     */
    stripeDebitReversalId: string;
    /**
     * @generated from protobuf field: string stripe_received_debit_id = 2;
     */
    stripeReceivedDebitId: string;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedDebit received_debit = 3;
     */
    receivedDebit?: FinancialTransaction_ReceivedDebit;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.InboundTransfer
 */
export interface FinancialTransaction_InboundTransfer {
    /**
     * @generated from protobuf field: string stripe_inbound_transfer_id = 1;
     */
    stripeInboundTransferId: string;
    /**
     * @generated from protobuf field: string billing_name = 2;
     */
    billingName: string;
    /**
     * @generated from protobuf field: rd.payments.BankAccount origin_account = 3;
     */
    originAccount?: BankAccount;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.IssuingAuthorization
 */
export interface FinancialTransaction_IssuingAuthorization {
    /**
     * @generated from protobuf field: string stripe_issuing_authorization_id = 1;
     */
    stripeIssuingAuthorizationId: string;
    /**
     * @generated from protobuf field: rd.payments.IssuingCard issuing_card = 2;
     */
    issuingCard?: IssuingCard;
    /**
     * @generated from protobuf field: string merchant_name = 3;
     */
    merchantName: string;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.OutboundPayment
 */
export interface FinancialTransaction_OutboundPayment {
    /**
     * @generated from protobuf field: string stripe_outbound_payment_id = 1;
     */
    stripeOutboundPaymentId: string;
    /**
     * @generated from protobuf field: rd.payments.BankAccount destination_account = 2;
     */
    destinationAccount?: BankAccount;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 3;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string stripe_customer_id = 4;
     */
    stripeCustomerId: string; // associated to rundoo vendor
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expected_arrival_date = 5;
     */
    expectedArrivalDate?: Timestamp;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.ReceivedCredit
 */
export interface FinancialTransaction_ReceivedCredit {
    /**
     * @generated from protobuf field: string stripe_received_credit_id = 1;
     */
    stripeReceivedCreditId: string;
    /**
     * @generated from protobuf field: string billing_name = 2;
     */
    billingName: string;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedCredit.Type type = 3;
     */
    type: FinancialTransaction_ReceivedCredit_Type;
    /**
     * @generated from protobuf field: rd.payments.BankAccount bank_account = 4;
     */
    bankAccount?: BankAccount;
    /**
     * @generated from protobuf field: rd.payments.IssuingCard issuing_card = 5;
     */
    issuingCard?: IssuingCard;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedCredit.Payout payout = 6;
     */
    payout?: FinancialTransaction_ReceivedCredit_Payout;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.ReceivedCredit.Payout
 */
export interface FinancialTransaction_ReceivedCredit_Payout {
    /**
     * @generated from protobuf field: bool automatic = 1;
     */
    automatic: boolean;
    /**
     * @generated from protobuf field: string stripe_payout_id = 2;
     */
    stripePayoutId: string;
}
/**
 * @generated from protobuf enum rd.payments.FinancialTransaction.ReceivedCredit.Type
 */
export enum FinancialTransaction_ReceivedCredit_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_BANK_ACCOUNT = 1;
     */
    BANK_ACCOUNT = 1,
    /**
     * @generated from protobuf enum value: TYPE_ISSUING_CARD = 2;
     */
    ISSUING_CARD = 2,
    /**
     * @generated from protobuf enum value: TYPE_PAYOUT = 3;
     */
    PAYOUT = 3,
    /**
     * @generated from protobuf enum value: TYPE_CHECK = 4;
     */
    CHECK = 4
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.ReceivedDebit
 */
export interface FinancialTransaction_ReceivedDebit {
    /**
     * @generated from protobuf field: string stripe_received_debit_id = 1;
     */
    stripeReceivedDebitId: string;
    /**
     * @generated from protobuf field: string billing_name = 2;
     */
    billingName: string;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedDebit.Type type = 3;
     */
    type: FinancialTransaction_ReceivedDebit_Type;
    /**
     * @generated from protobuf field: rd.payments.BankAccount bank_account = 4;
     */
    bankAccount?: BankAccount;
    /**
     * @generated from protobuf field: rd.payments.IssuingCard issuing_card = 5;
     */
    issuingCard?: IssuingCard;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction.ReceivedDebit.Check check = 6;
     */
    check?: FinancialTransaction_ReceivedDebit_Check;
}
/**
 * @generated from protobuf message rd.payments.FinancialTransaction.ReceivedDebit.Check
 */
export interface FinancialTransaction_ReceivedDebit_Check {
    /**
     * @generated from protobuf field: rd.proto.address.Address billing_address = 1;
     */
    billingAddress?: Address;
}
/**
 * @generated from protobuf enum rd.payments.FinancialTransaction.ReceivedDebit.Type
 */
export enum FinancialTransaction_ReceivedDebit_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_BANK_ACCOUNT = 1;
     */
    BANK_ACCOUNT = 1,
    /**
     * @generated from protobuf enum value: TYPE_BALANCE = 2;
     */
    BALANCE = 2,
    /**
     * @generated from protobuf enum value: TYPE_ISSUING_CARD = 3;
     */
    ISSUING_CARD = 3,
    /**
     * @generated from protobuf enum value: TYPE_CHECK = 4;
     */
    CHECK = 4
}
/**
 * @generated from protobuf enum rd.payments.FinancialTransaction.Type
 */
export enum FinancialTransaction_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_CREDIT_REVERSAL = 1;
     */
    CREDIT_REVERSAL = 1,
    /**
     * @generated from protobuf enum value: TYPE_DEBIT_REVERSAL = 2;
     */
    DEBIT_REVERSAL = 2,
    /**
     * @generated from protobuf enum value: TYPE_INBOUND_TRANSFER = 3;
     */
    INBOUND_TRANSFER = 3,
    /**
     * @generated from protobuf enum value: TYPE_ISSUING_AUTHORIZATION = 4;
     */
    ISSUING_AUTHORIZATION = 4,
    /**
     * @generated from protobuf enum value: TYPE_OUTBOUND_PAYMENT = 5;
     */
    OUTBOUND_PAYMENT = 5,
    /**
     * @generated from protobuf enum value: TYPE_RECEIVED_CREDIT = 6;
     */
    RECEIVED_CREDIT = 6,
    /**
     * @generated from protobuf enum value: TYPE_RECEIVED_DEBIT = 7;
     */
    RECEIVED_DEBIT = 7
}
/**
 * @generated from protobuf enum rd.payments.FinancialTransaction.Status
 */
export enum FinancialTransaction_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_OPEN = 1;
     */
    OPEN = 1,
    /**
     * @generated from protobuf enum value: STATUS_POSTED = 2;
     */
    POSTED = 2,
    /**
     * @generated from protobuf enum value: STATUS_VOID = 3;
     */
    VOID = 3
}
/**
 * FinancialTransactionLog represents a log of 'update' and 'insert' operations on the
 * FinancialTransaction table
 *
 * @generated from protobuf message rd.payments.FinancialTransactionLog
 */
export interface FinancialTransactionLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction financial_transaction = 2;
     */
    financialTransaction?: FinancialTransaction;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
}
/**
 * Payout represents money moving from a Stripe Balance to an external account
 * https://docs.stripe.com/api/payouts/object
 *
 * @generated from protobuf message rd.payments.Payout
 */
export interface Payout {
    /**
     * @generated from protobuf field: string stripe_payout_id = 1;
     */
    stripePayoutId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp arrival_at = 2;
     */
    arrivalAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.payments.Payout.Status status = 3;
     */
    status: Payout_Status;
    /**
     * @generated from protobuf field: rd.payments.Payout.ReconciliationStatus reconciliation_status = 4;
     */
    reconciliationStatus: Payout_ReconciliationStatus;
}
/**
 * @generated from protobuf enum rd.payments.Payout.Status
 */
export enum Payout_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_PENDING = 1;
     */
    PENDING = 1,
    /**
     * @generated from protobuf enum value: STATUS_IN_TRANSIT = 2;
     */
    IN_TRANSIT = 2,
    /**
     * @generated from protobuf enum value: STATUS_PAID = 3;
     */
    PAID = 3,
    /**
     * @generated from protobuf enum value: STATUS_CANCELED = 4;
     */
    CANCELED = 4,
    /**
     * @generated from protobuf enum value: STATUS_FAILED = 5;
     */
    FAILED = 5
}
/**
 * @generated from protobuf enum rd.payments.Payout.ReconciliationStatus
 */
export enum Payout_ReconciliationStatus {
    /**
     * @generated from protobuf enum value: RECONCILIATION_STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: RECONCILIATION_STATUS_NOT_APPLICABLE = 1;
     */
    NOT_APPLICABLE = 1,
    /**
     * @generated from protobuf enum value: RECONCILIATION_STATUS_IN_PROGRESS = 2;
     */
    IN_PROGRESS = 2,
    /**
     * @generated from protobuf enum value: RECONCILIATION_STATUS_COMPLETED = 3;
     */
    COMPLETED = 3
}
/**
 * @generated from protobuf message rd.payments.BalanceTransaction
 */
export interface BalanceTransaction {
    /**
     * @generated from protobuf field: string stripe_balance_transaction_id = 1;
     */
    stripeBalanceTransactionId: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_amount = 2;
     */
    grossAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 3;
     */
    fee?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_amount = 4;
     */
    netAmount?: Money;
    /**
     * @generated from protobuf field: rd.payments.BalanceTransaction.Type type = 5;
     */
    type: BalanceTransaction_Type;
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 6;
     */
    stripePaymentIntentId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 9;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp stripe_created_at = 10;
     */
    stripeCreatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 11;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string stripe_payout_id = 12;
     */
    stripePayoutId: string;
    /**
     * @generated from protobuf field: string stripe_connect_account_id = 13;
     */
    stripeConnectAccountId: string;
    /**
     * @generated from protobuf field: string stripe_refund_id = 14;
     */
    stripeRefundId: string;
}
/**
 * @generated from protobuf enum rd.payments.BalanceTransaction.Type
 */
export enum BalanceTransaction_Type {
    /**
     * Undefined status.
     *
     * @generated from protobuf enum value: Undefined = 0;
     */
    Undefined = 0,
    /**
     * An adjustment to a Stripe balance.
     *
     * @generated from protobuf enum value: Adjustment = 1;
     */
    Adjustment = 1,
    /**
     * A cash advance from Stripe.
     *
     * @generated from protobuf enum value: Advance = 2;
     */
    Advance = 2,
    /**
     * Funds added to a Stripe balance.
     *
     * @generated from protobuf enum value: Funding = 3;
     */
    Funding = 3,
    /**
     * An early repayment of an advance.
     *
     * @generated from protobuf enum value: AnticipationRepayment = 4;
     */
    AnticipationRepayment = 4,
    /**
     * An application fee.
     *
     * @generated from protobuf enum value: ApplicationFee = 5;
     */
    ApplicationFee = 5,
    /**
     * A refund of an application fee.
     *
     * @generated from protobuf enum value: ApplicationFeeRefund = 6;
     */
    ApplicationFeeRefund = 6,
    /**
     * A charge to a customer.
     *
     * @generated from protobuf enum value: Charge = 7;
     */
    Charge = 7,
    /**
     * A transfer to connect collections.
     *
     * @generated from protobuf enum value: ConnectCollectionTransfer = 8;
     */
    ConnectCollectionTransfer = 8,
    /**
     * A contribution to a Stripe balance.
     *
     * @generated from protobuf enum value: Contribution = 9;
     */
    Contribution = 9,
    /**
     * A hold placed on a Stripe balance.
     *
     * @generated from protobuf enum value: IssuingAuthorizationHold = 10;
     */
    IssuingAuthorizationHold = 10,
    /**
     * A release of a hold on a Stripe balance.
     *
     * @generated from protobuf enum value: IssuingAuthorizationRelease = 11;
     */
    IssuingAuthorizationRelease = 11,
    /**
     * A dispute associated with an issuing transaction.
     *
     * @generated from protobuf enum value: IssuingDispute = 12;
     */
    IssuingDispute = 12,
    /**
     * An issuing transaction.
     *
     * @generated from protobuf enum value: IssuingTransaction = 13;
     */
    IssuingTransaction = 13,
    /**
     * A payment to a customer.
     *
     * @generated from protobuf enum value: Payment = 14;
     */
    Payment = 14,
    /**
     * A refund for a failed payment.
     *
     * @generated from protobuf enum value: PaymentFailureRefund = 15;
     */
    PaymentFailureRefund = 15,
    /**
     * A refund for a payment.
     *
     * @generated from protobuf enum value: PaymentRefund = 16;
     */
    PaymentRefund = 16,
    /**
     * A payout to a recipient.
     *
     * @generated from protobuf enum value: Payout = 17;
     */
    Payout = 17,
    /**
     * A cancellation of a payout.
     *
     * @generated from protobuf enum value: PayoutCancel = 18;
     */
    PayoutCancel = 18,
    /**
     * A failure of a payout.
     *
     * @generated from protobuf enum value: PayoutFailure = 19;
     */
    PayoutFailure = 19,
    /**
     * A refund.
     *
     * @generated from protobuf enum value: Refund = 20;
     */
    Refund = 20,
    /**
     * A failure of a refund.
     *
     * @generated from protobuf enum value: RefundFailure = 21;
     */
    RefundFailure = 21,
    /**
     * A transaction that reserves funds.
     *
     * @generated from protobuf enum value: ReserveTransaction = 22;
     */
    ReserveTransaction = 22,
    /**
     * Funds reserved for a future transaction.
     *
     * @generated from protobuf enum value: ReservedFunds = 23;
     */
    ReservedFunds = 23,
    /**
     * A Stripe fee.
     *
     * @generated from protobuf enum value: StripeFee = 24;
     */
    StripeFee = 24,
    /**
     * A Stripe FX fee.
     *
     * @generated from protobuf enum value: StripeFxFee = 25;
     */
    StripeFxFee = 25,
    /**
     * A tax fee.
     *
     * @generated from protobuf enum value: TaxFee = 26;
     */
    TaxFee = 26,
    /**
     * Funds added to a Stripe balance via a top-up.
     *
     * @generated from protobuf enum value: Topup = 27;
     */
    Topup = 27,
    /**
     * A reversal of a top-up.
     *
     * @generated from protobuf enum value: TopupReversal = 28;
     */
    TopupReversal = 28,
    /**
     * A transfer between Stripe accounts.
     *
     * @generated from protobuf enum value: Transfer = 29;
     */
    Transfer = 29,
    /**
     * A cancellation of a transfer.
     *
     * @generated from protobuf enum value: TransferCancel = 30;
     */
    TransferCancel = 30,
    /**
     * A failure of a transfer.
     *
     * @generated from protobuf enum value: TransferFailure = 31;
     */
    TransferFailure = 31,
    /**
     * Refunds for a transfer.
     *
     * @generated from protobuf enum value: TransferRefund = 32;
     */
    TransferRefund = 32
}
/**
 * @generated from protobuf message rd.payments.BalanceTransactionLog
 */
export interface BalanceTransactionLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.payments.BalanceTransaction balance_transaction = 2;
     */
    balanceTransaction?: BalanceTransaction;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class BankAccount$Type extends MessageType<BankAccount> {
    constructor() {
        super("rd.payments.BankAccount", [
            { no: 1, name: "bank_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "last_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BankAccount>): BankAccount {
        const message = { bankName: "", last4: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BankAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BankAccount): BankAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string bank_name */ 1:
                    message.bankName = reader.string();
                    break;
                case /* string last_4 */ 2:
                    message.last4 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BankAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string bank_name = 1; */
        if (message.bankName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.bankName);
        /* string last_4 = 2; */
        if (message.last4 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.last4);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.BankAccount
 */
export const BankAccount = new BankAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BankPaymentMethod$Type extends MessageType<BankPaymentMethod> {
    constructor() {
        super("rd.payments.BankPaymentMethod", [
            { no: 1, name: "stripe_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bank_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "routing_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BankPaymentMethod>): BankPaymentMethod {
        const message = { stripePaymentMethodId: "", bankName: "", last4: "", routingNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BankPaymentMethod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BankPaymentMethod): BankPaymentMethod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_payment_method_id */ 1:
                    message.stripePaymentMethodId = reader.string();
                    break;
                case /* string bank_name */ 2:
                    message.bankName = reader.string();
                    break;
                case /* string last_4 */ 3:
                    message.last4 = reader.string();
                    break;
                case /* string routing_number */ 4:
                    message.routingNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BankPaymentMethod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_payment_method_id = 1; */
        if (message.stripePaymentMethodId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripePaymentMethodId);
        /* string bank_name = 2; */
        if (message.bankName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bankName);
        /* string last_4 = 3; */
        if (message.last4 !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.last4);
        /* string routing_number = 4; */
        if (message.routingNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.routingNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.BankPaymentMethod
 */
export const BankPaymentMethod = new BankPaymentMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManuallyEnteredCard$Type extends MessageType<ManuallyEnteredCard> {
    constructor() {
        super("rd.payments.ManuallyEnteredCard", [
            { no: 1, name: "card_number", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 2, name: "expiration_month", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 3, name: "expiration_year", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 4, name: "cvc", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 5, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } }
        ]);
    }
    create(value?: PartialMessage<ManuallyEnteredCard>): ManuallyEnteredCard {
        const message = { cardNumber: "", expirationMonth: 0, expirationYear: 0, cvc: "", zipCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManuallyEnteredCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManuallyEnteredCard): ManuallyEnteredCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string card_number */ 1:
                    message.cardNumber = reader.string();
                    break;
                case /* int32 expiration_month */ 2:
                    message.expirationMonth = reader.int32();
                    break;
                case /* int32 expiration_year */ 3:
                    message.expirationYear = reader.int32();
                    break;
                case /* string cvc */ 4:
                    message.cvc = reader.string();
                    break;
                case /* string zip_code */ 5:
                    message.zipCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManuallyEnteredCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string card_number = 1; */
        if (message.cardNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cardNumber);
        /* int32 expiration_month = 2; */
        if (message.expirationMonth !== 0)
            writer.tag(2, WireType.Varint).int32(message.expirationMonth);
        /* int32 expiration_year = 3; */
        if (message.expirationYear !== 0)
            writer.tag(3, WireType.Varint).int32(message.expirationYear);
        /* string cvc = 4; */
        if (message.cvc !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cvc);
        /* string zip_code = 5; */
        if (message.zipCode !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.zipCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.ManuallyEnteredCard
 */
export const ManuallyEnteredCard = new ManuallyEnteredCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardPaymentReceiptInfo$Type extends MessageType<CardPaymentReceiptInfo> {
    constructor() {
        super("rd.payments.CardPaymentReceiptInfo", [
            { no: 1, name: "account_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "application_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "dedicated_file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CardPaymentReceiptInfo>): CardPaymentReceiptInfo {
        const message = { accountType: "", applicationName: "", dedicatedFileName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CardPaymentReceiptInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CardPaymentReceiptInfo): CardPaymentReceiptInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string account_type */ 1:
                    message.accountType = reader.string();
                    break;
                case /* string application_name */ 2:
                    message.applicationName = reader.string();
                    break;
                case /* string dedicated_file_name */ 3:
                    message.dedicatedFileName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CardPaymentReceiptInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string account_type = 1; */
        if (message.accountType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountType);
        /* string application_name = 2; */
        if (message.applicationName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.applicationName);
        /* string dedicated_file_name = 3; */
        if (message.dedicatedFileName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dedicatedFileName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.CardPaymentReceiptInfo
 */
export const CardPaymentReceiptInfo = new CardPaymentReceiptInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Cart$Type extends MessageType<Cart> {
    constructor() {
        super("rd.payments.Cart", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Cart_Item }
        ]);
    }
    create(value?: PartialMessage<Cart>): Cart {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Cart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Cart): Cart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.payments.Cart.Item items */ 1:
                    message.items.push(Cart_Item.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Cart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.payments.Cart.Item items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Cart_Item.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.Cart
 */
export const Cart = new Cart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Cart_Item$Type extends MessageType<Cart_Item> {
    constructor() {
        super("rd.payments.Cart.Item", [
            { no: 1, name: "price_cents", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "quantity", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Cart_Item>): Cart_Item {
        const message = { priceCents: 0n, description: "", quantity: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Cart_Item>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Cart_Item): Cart_Item {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 price_cents */ 1:
                    message.priceCents = reader.int64().toBigInt();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* int64 quantity */ 3:
                    message.quantity = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Cart_Item, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 price_cents = 1; */
        if (message.priceCents !== 0n)
            writer.tag(1, WireType.Varint).int64(message.priceCents);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* int64 quantity = 3; */
        if (message.quantity !== 0n)
            writer.tag(3, WireType.Varint).int64(message.quantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.Cart.Item
 */
export const Cart_Item = new Cart_Item$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Card$Type extends MessageType<Card> {
    constructor() {
        super("rd.payments.Card", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expiration_month", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 5, name: "expiration_year", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 6, name: "is_primary", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Card>): Card {
        const message = { id: "", brand: "", last4: "", expirationMonth: 0, expirationYear: 0, isPrimary: false, nickname: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Card>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Card): Card {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string brand */ 2:
                    message.brand = reader.string();
                    break;
                case /* string last_4 */ 3:
                    message.last4 = reader.string();
                    break;
                case /* int32 expiration_month */ 4:
                    message.expirationMonth = reader.int32();
                    break;
                case /* int32 expiration_year */ 5:
                    message.expirationYear = reader.int32();
                    break;
                case /* bool is_primary */ 6:
                    message.isPrimary = reader.bool();
                    break;
                case /* string nickname */ 7:
                    message.nickname = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Card, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string brand = 2; */
        if (message.brand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand);
        /* string last_4 = 3; */
        if (message.last4 !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.last4);
        /* int32 expiration_month = 4; */
        if (message.expirationMonth !== 0)
            writer.tag(4, WireType.Varint).int32(message.expirationMonth);
        /* int32 expiration_year = 5; */
        if (message.expirationYear !== 0)
            writer.tag(5, WireType.Varint).int32(message.expirationYear);
        /* bool is_primary = 6; */
        if (message.isPrimary !== false)
            writer.tag(6, WireType.Varint).bool(message.isPrimary);
        /* string nickname = 7; */
        if (message.nickname !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.nickname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.Card
 */
export const Card = new Card$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardReader$Type extends MessageType<CardReader> {
    constructor() {
        super("rd.payments.CardReader", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "online", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CardReader>): CardReader {
        const message = { id: "", label: "", serialNumber: "", online: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CardReader>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CardReader): CardReader {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string serial_number */ 3:
                    message.serialNumber = reader.string();
                    break;
                case /* bool online */ 4:
                    message.online = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CardReader, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string serial_number = 3; */
        if (message.serialNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.serialNumber);
        /* bool online = 4; */
        if (message.online !== false)
            writer.tag(4, WireType.Varint).bool(message.online);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.CardReader
 */
export const CardReader = new CardReader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Payment$Type extends MessageType<Payment> {
    constructor() {
        super("rd.payments.Payment", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["rd.payments.Payment.Status", Payment_Status, "STATUS_"] },
            { no: 3, name: "application_fee_amount", kind: "message", T: () => Money },
            { no: 4, name: "amount_received", kind: "message", T: () => Money },
            { no: 5, name: "card", kind: "message", T: () => Card }
        ]);
    }
    create(value?: PartialMessage<Payment>): Payment {
        const message = { id: "", status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Payment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Payment): Payment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* rd.payments.Payment.Status status */ 2:
                    message.status = reader.int32();
                    break;
                case /* rd.proto.money.Money application_fee_amount */ 3:
                    message.applicationFeeAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.applicationFeeAmount);
                    break;
                case /* rd.proto.money.Money amount_received */ 4:
                    message.amountReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.amountReceived);
                    break;
                case /* rd.payments.Card card */ 5:
                    message.card = Card.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Payment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* rd.payments.Payment.Status status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* rd.proto.money.Money application_fee_amount = 3; */
        if (message.applicationFeeAmount)
            Money.internalBinaryWrite(message.applicationFeeAmount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount_received = 4; */
        if (message.amountReceived)
            Money.internalBinaryWrite(message.amountReceived, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card card = 5; */
        if (message.card)
            Card.internalBinaryWrite(message.card, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.Payment
 */
export const Payment = new Payment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialConnectionsAccount$Type extends MessageType<FinancialConnectionsAccount> {
    constructor() {
        super("rd.payments.FinancialConnectionsAccount", [
            { no: 1, name: "stripe_financial_connections_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bank_account", kind: "message", T: () => BankAccount },
            { no: 3, name: "balance", kind: "message", T: () => FinancialConnectionsAccount_Balance },
            { no: 4, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinancialConnectionsAccount>): FinancialConnectionsAccount {
        const message = { stripeFinancialConnectionsAccountId: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialConnectionsAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialConnectionsAccount): FinancialConnectionsAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_financial_connections_account_id */ 1:
                    message.stripeFinancialConnectionsAccountId = reader.string();
                    break;
                case /* rd.payments.BankAccount bank_account */ 2:
                    message.bankAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.bankAccount);
                    break;
                case /* rd.payments.FinancialConnectionsAccount.Balance balance */ 3:
                    message.balance = FinancialConnectionsAccount_Balance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* string display_name */ 4:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialConnectionsAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_financial_connections_account_id = 1; */
        if (message.stripeFinancialConnectionsAccountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeFinancialConnectionsAccountId);
        /* rd.payments.BankAccount bank_account = 2; */
        if (message.bankAccount)
            BankAccount.internalBinaryWrite(message.bankAccount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialConnectionsAccount.Balance balance = 3; */
        if (message.balance)
            FinancialConnectionsAccount_Balance.internalBinaryWrite(message.balance, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string display_name = 4; */
        if (message.displayName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialConnectionsAccount
 */
export const FinancialConnectionsAccount = new FinancialConnectionsAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialConnectionsAccount_Balance$Type extends MessageType<FinancialConnectionsAccount_Balance> {
    constructor() {
        super("rd.payments.FinancialConnectionsAccount.Balance", [
            { no: 1, name: "current", kind: "message", T: () => Money },
            { no: 2, name: "available", kind: "message", T: () => Money },
            { no: 3, name: "as_of", kind: "message", T: () => Timestamp },
            { no: 4, name: "isRefreshed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FinancialConnectionsAccount_Balance>): FinancialConnectionsAccount_Balance {
        const message = { isRefreshed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialConnectionsAccount_Balance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialConnectionsAccount_Balance): FinancialConnectionsAccount_Balance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money current */ 1:
                    message.current = Money.internalBinaryRead(reader, reader.uint32(), options, message.current);
                    break;
                case /* rd.proto.money.Money available */ 2:
                    message.available = Money.internalBinaryRead(reader, reader.uint32(), options, message.available);
                    break;
                case /* google.protobuf.Timestamp as_of */ 3:
                    message.asOf = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.asOf);
                    break;
                case /* bool isRefreshed */ 4:
                    message.isRefreshed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialConnectionsAccount_Balance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money current = 1; */
        if (message.current)
            Money.internalBinaryWrite(message.current, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money available = 2; */
        if (message.available)
            Money.internalBinaryWrite(message.available, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp as_of = 3; */
        if (message.asOf)
            Timestamp.internalBinaryWrite(message.asOf, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool isRefreshed = 4; */
        if (message.isRefreshed !== false)
            writer.tag(4, WireType.Varint).bool(message.isRefreshed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialConnectionsAccount.Balance
 */
export const FinancialConnectionsAccount_Balance = new FinancialConnectionsAccount_Balance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialAccount$Type extends MessageType<FinancialAccount> {
    constructor() {
        super("rd.payments.FinancialAccount", [
            { no: 1, name: "stripe_financial_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_onboarded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "balance", kind: "message", T: () => FinancialAccount_Balance },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "financial_address", kind: "message", T: () => FinancialAccount_FinancialAddress }
        ]);
    }
    create(value?: PartialMessage<FinancialAccount>): FinancialAccount {
        const message = { stripeFinancialAccountId: "", isOnboarded: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialAccount): FinancialAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_financial_account_id */ 1:
                    message.stripeFinancialAccountId = reader.string();
                    break;
                case /* bool is_onboarded */ 2:
                    message.isOnboarded = reader.bool();
                    break;
                case /* rd.payments.FinancialAccount.Balance balance */ 3:
                    message.balance = FinancialAccount_Balance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.payments.FinancialAccount.FinancialAddress financial_address */ 5:
                    message.financialAddress = FinancialAccount_FinancialAddress.internalBinaryRead(reader, reader.uint32(), options, message.financialAddress);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_financial_account_id = 1; */
        if (message.stripeFinancialAccountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeFinancialAccountId);
        /* bool is_onboarded = 2; */
        if (message.isOnboarded !== false)
            writer.tag(2, WireType.Varint).bool(message.isOnboarded);
        /* rd.payments.FinancialAccount.Balance balance = 3; */
        if (message.balance)
            FinancialAccount_Balance.internalBinaryWrite(message.balance, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialAccount.FinancialAddress financial_address = 5; */
        if (message.financialAddress)
            FinancialAccount_FinancialAddress.internalBinaryWrite(message.financialAddress, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialAccount
 */
export const FinancialAccount = new FinancialAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialAccount_Balance$Type extends MessageType<FinancialAccount_Balance> {
    constructor() {
        super("rd.payments.FinancialAccount.Balance", [
            { no: 1, name: "cash", kind: "message", T: () => Money },
            { no: 2, name: "inbound_pending", kind: "message", T: () => Money },
            { no: 3, name: "outbound_pending", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<FinancialAccount_Balance>): FinancialAccount_Balance {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialAccount_Balance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialAccount_Balance): FinancialAccount_Balance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money cash */ 1:
                    message.cash = Money.internalBinaryRead(reader, reader.uint32(), options, message.cash);
                    break;
                case /* rd.proto.money.Money inbound_pending */ 2:
                    message.inboundPending = Money.internalBinaryRead(reader, reader.uint32(), options, message.inboundPending);
                    break;
                case /* rd.proto.money.Money outbound_pending */ 3:
                    message.outboundPending = Money.internalBinaryRead(reader, reader.uint32(), options, message.outboundPending);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialAccount_Balance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money cash = 1; */
        if (message.cash)
            Money.internalBinaryWrite(message.cash, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money inbound_pending = 2; */
        if (message.inboundPending)
            Money.internalBinaryWrite(message.inboundPending, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money outbound_pending = 3; */
        if (message.outboundPending)
            Money.internalBinaryWrite(message.outboundPending, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialAccount.Balance
 */
export const FinancialAccount_Balance = new FinancialAccount_Balance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialAccount_FinancialAddress$Type extends MessageType<FinancialAccount_FinancialAddress> {
    constructor() {
        super("rd.payments.FinancialAccount.FinancialAddress", [
            { no: 1, name: "account_holder_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bank_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "routing_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "supported_networks", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.payments.FinancialAccount.FinancialAddress.Network", FinancialAccount_FinancialAddress_Network, "NETWORK_"] }
        ]);
    }
    create(value?: PartialMessage<FinancialAccount_FinancialAddress>): FinancialAccount_FinancialAddress {
        const message = { accountHolderName: "", accountNumber: "", bankName: "", routingNumber: "", supportedNetworks: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialAccount_FinancialAddress>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialAccount_FinancialAddress): FinancialAccount_FinancialAddress {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string account_holder_name */ 1:
                    message.accountHolderName = reader.string();
                    break;
                case /* string account_number */ 2:
                    message.accountNumber = reader.string();
                    break;
                case /* string bank_name */ 3:
                    message.bankName = reader.string();
                    break;
                case /* string routing_number */ 4:
                    message.routingNumber = reader.string();
                    break;
                case /* repeated rd.payments.FinancialAccount.FinancialAddress.Network supported_networks */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.supportedNetworks.push(reader.int32());
                    else
                        message.supportedNetworks.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialAccount_FinancialAddress, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string account_holder_name = 1; */
        if (message.accountHolderName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountHolderName);
        /* string account_number = 2; */
        if (message.accountNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountNumber);
        /* string bank_name = 3; */
        if (message.bankName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bankName);
        /* string routing_number = 4; */
        if (message.routingNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.routingNumber);
        /* repeated rd.payments.FinancialAccount.FinancialAddress.Network supported_networks = 5; */
        if (message.supportedNetworks.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.supportedNetworks.length; i++)
                writer.int32(message.supportedNetworks[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialAccount.FinancialAddress
 */
export const FinancialAccount_FinancialAddress = new FinancialAccount_FinancialAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IssuingCard$Type extends MessageType<IssuingCard> {
    constructor() {
        super("rd.payments.IssuingCard", [
            { no: 1, name: "stripe_issuing_card_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expiration_month", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 5, name: "expiration_year", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "rd.sanitize.log": true } },
            { no: 6, name: "cardholder_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "full_number", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 8, name: "cvv", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } }
        ]);
    }
    create(value?: PartialMessage<IssuingCard>): IssuingCard {
        const message = { stripeIssuingCardId: "", brand: "", last4: "", expirationMonth: 0, expirationYear: 0, cardholderName: "", fullNumber: "", cvv: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IssuingCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IssuingCard): IssuingCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_issuing_card_id */ 1:
                    message.stripeIssuingCardId = reader.string();
                    break;
                case /* string brand */ 2:
                    message.brand = reader.string();
                    break;
                case /* string last_4 */ 3:
                    message.last4 = reader.string();
                    break;
                case /* int32 expiration_month */ 4:
                    message.expirationMonth = reader.int32();
                    break;
                case /* int32 expiration_year */ 5:
                    message.expirationYear = reader.int32();
                    break;
                case /* string cardholder_name */ 6:
                    message.cardholderName = reader.string();
                    break;
                case /* string full_number */ 7:
                    message.fullNumber = reader.string();
                    break;
                case /* string cvv */ 8:
                    message.cvv = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IssuingCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_issuing_card_id = 1; */
        if (message.stripeIssuingCardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeIssuingCardId);
        /* string brand = 2; */
        if (message.brand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand);
        /* string last_4 = 3; */
        if (message.last4 !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.last4);
        /* int32 expiration_month = 4; */
        if (message.expirationMonth !== 0)
            writer.tag(4, WireType.Varint).int32(message.expirationMonth);
        /* int32 expiration_year = 5; */
        if (message.expirationYear !== 0)
            writer.tag(5, WireType.Varint).int32(message.expirationYear);
        /* string cardholder_name = 6; */
        if (message.cardholderName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cardholderName);
        /* string full_number = 7; */
        if (message.fullNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fullNumber);
        /* string cvv = 8; */
        if (message.cvv !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cvv);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.IssuingCard
 */
export const IssuingCard = new IssuingCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction$Type extends MessageType<FinancialTransaction> {
    constructor() {
        super("rd.payments.FinancialTransaction", [
            { no: 2, name: "stripe_transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "message", T: () => Money },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "opened_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "posted_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "voided_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "stripe_financial_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "type", kind: "enum", T: () => ["rd.payments.FinancialTransaction.Type", FinancialTransaction_Type, "TYPE_"] },
            { no: 11, name: "status", kind: "enum", T: () => ["rd.payments.FinancialTransaction.Status", FinancialTransaction_Status, "STATUS_"] },
            { no: 12, name: "credit_reversal", kind: "message", T: () => FinancialTransaction_CreditReversal },
            { no: 13, name: "debit_reversal", kind: "message", T: () => FinancialTransaction_DebitReversal },
            { no: 14, name: "inbound_transfer", kind: "message", T: () => FinancialTransaction_InboundTransfer },
            { no: 15, name: "issuing_authorization", kind: "message", T: () => FinancialTransaction_IssuingAuthorization },
            { no: 16, name: "outbound_payment", kind: "message", T: () => FinancialTransaction_OutboundPayment },
            { no: 17, name: "received_credit", kind: "message", T: () => FinancialTransaction_ReceivedCredit },
            { no: 18, name: "received_debit", kind: "message", T: () => FinancialTransaction_ReceivedDebit },
            { no: 19, name: "company_id", kind: "message", T: () => UUID },
            { no: 20, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 22, name: "location_id", kind: "message", T: () => UUID },
            { no: 23, name: "allocations_by_chart_of_account_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction>): FinancialTransaction {
        const message = { stripeTransactionId: "", description: "", stripeFinancialAccountId: "", type: 0, status: 0, allocationsByChartOfAccountId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction): FinancialTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_transaction_id */ 2:
                    message.stripeTransactionId = reader.string();
                    break;
                case /* rd.proto.money.Money amount */ 3:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp opened_at */ 5:
                    message.openedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.openedAt);
                    break;
                case /* google.protobuf.Timestamp posted_at */ 6:
                    message.postedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.postedAt);
                    break;
                case /* google.protobuf.Timestamp voided_at */ 7:
                    message.voidedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.voidedAt);
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* string stripe_financial_account_id */ 9:
                    message.stripeFinancialAccountId = reader.string();
                    break;
                case /* rd.payments.FinancialTransaction.Type type */ 10:
                    message.type = reader.int32();
                    break;
                case /* rd.payments.FinancialTransaction.Status status */ 11:
                    message.status = reader.int32();
                    break;
                case /* rd.payments.FinancialTransaction.CreditReversal credit_reversal */ 12:
                    message.creditReversal = FinancialTransaction_CreditReversal.internalBinaryRead(reader, reader.uint32(), options, message.creditReversal);
                    break;
                case /* rd.payments.FinancialTransaction.DebitReversal debit_reversal */ 13:
                    message.debitReversal = FinancialTransaction_DebitReversal.internalBinaryRead(reader, reader.uint32(), options, message.debitReversal);
                    break;
                case /* rd.payments.FinancialTransaction.InboundTransfer inbound_transfer */ 14:
                    message.inboundTransfer = FinancialTransaction_InboundTransfer.internalBinaryRead(reader, reader.uint32(), options, message.inboundTransfer);
                    break;
                case /* rd.payments.FinancialTransaction.IssuingAuthorization issuing_authorization */ 15:
                    message.issuingAuthorization = FinancialTransaction_IssuingAuthorization.internalBinaryRead(reader, reader.uint32(), options, message.issuingAuthorization);
                    break;
                case /* rd.payments.FinancialTransaction.OutboundPayment outbound_payment */ 16:
                    message.outboundPayment = FinancialTransaction_OutboundPayment.internalBinaryRead(reader, reader.uint32(), options, message.outboundPayment);
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedCredit received_credit */ 17:
                    message.receivedCredit = FinancialTransaction_ReceivedCredit.internalBinaryRead(reader, reader.uint32(), options, message.receivedCredit);
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedDebit received_debit */ 18:
                    message.receivedDebit = FinancialTransaction_ReceivedDebit.internalBinaryRead(reader, reader.uint32(), options, message.receivedDebit);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 19:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 20:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 22:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_chart_of_account_id */ 23:
                    this.binaryReadMap23(message.allocationsByChartOfAccountId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap23(map: FinancialTransaction["allocationsByChartOfAccountId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FinancialTransaction["allocationsByChartOfAccountId"] | undefined, val: FinancialTransaction["allocationsByChartOfAccountId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.payments.FinancialTransaction.allocations_by_chart_of_account_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: FinancialTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_transaction_id = 2; */
        if (message.stripeTransactionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripeTransactionId);
        /* rd.proto.money.Money amount = 3; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp opened_at = 5; */
        if (message.openedAt)
            Timestamp.internalBinaryWrite(message.openedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp posted_at = 6; */
        if (message.postedAt)
            Timestamp.internalBinaryWrite(message.postedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp voided_at = 7; */
        if (message.voidedAt)
            Timestamp.internalBinaryWrite(message.voidedAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* string stripe_financial_account_id = 9; */
        if (message.stripeFinancialAccountId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.stripeFinancialAccountId);
        /* rd.payments.FinancialTransaction.Type type = 10; */
        if (message.type !== 0)
            writer.tag(10, WireType.Varint).int32(message.type);
        /* rd.payments.FinancialTransaction.Status status = 11; */
        if (message.status !== 0)
            writer.tag(11, WireType.Varint).int32(message.status);
        /* rd.payments.FinancialTransaction.CreditReversal credit_reversal = 12; */
        if (message.creditReversal)
            FinancialTransaction_CreditReversal.internalBinaryWrite(message.creditReversal, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.DebitReversal debit_reversal = 13; */
        if (message.debitReversal)
            FinancialTransaction_DebitReversal.internalBinaryWrite(message.debitReversal, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.InboundTransfer inbound_transfer = 14; */
        if (message.inboundTransfer)
            FinancialTransaction_InboundTransfer.internalBinaryWrite(message.inboundTransfer, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.IssuingAuthorization issuing_authorization = 15; */
        if (message.issuingAuthorization)
            FinancialTransaction_IssuingAuthorization.internalBinaryWrite(message.issuingAuthorization, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.OutboundPayment outbound_payment = 16; */
        if (message.outboundPayment)
            FinancialTransaction_OutboundPayment.internalBinaryWrite(message.outboundPayment, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.ReceivedCredit received_credit = 17; */
        if (message.receivedCredit)
            FinancialTransaction_ReceivedCredit.internalBinaryWrite(message.receivedCredit, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.ReceivedDebit received_debit = 18; */
        if (message.receivedDebit)
            FinancialTransaction_ReceivedDebit.internalBinaryWrite(message.receivedDebit, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 19; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 20; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 22; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_chart_of_account_id = 23; */
        for (let k of Object.keys(message.allocationsByChartOfAccountId)) {
            writer.tag(23, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsByChartOfAccountId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction
 */
export const FinancialTransaction = new FinancialTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_CreditReversal$Type extends MessageType<FinancialTransaction_CreditReversal> {
    constructor() {
        super("rd.payments.FinancialTransaction.CreditReversal", [
            { no: 1, name: "stripe_credit_reversal_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stripe_received_credit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "received_credit", kind: "message", T: () => FinancialTransaction_ReceivedCredit }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_CreditReversal>): FinancialTransaction_CreditReversal {
        const message = { stripeCreditReversalId: "", stripeReceivedCreditId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_CreditReversal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_CreditReversal): FinancialTransaction_CreditReversal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_credit_reversal_id */ 1:
                    message.stripeCreditReversalId = reader.string();
                    break;
                case /* string stripe_received_credit_id */ 2:
                    message.stripeReceivedCreditId = reader.string();
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedCredit received_credit */ 3:
                    message.receivedCredit = FinancialTransaction_ReceivedCredit.internalBinaryRead(reader, reader.uint32(), options, message.receivedCredit);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_CreditReversal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_credit_reversal_id = 1; */
        if (message.stripeCreditReversalId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeCreditReversalId);
        /* string stripe_received_credit_id = 2; */
        if (message.stripeReceivedCreditId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripeReceivedCreditId);
        /* rd.payments.FinancialTransaction.ReceivedCredit received_credit = 3; */
        if (message.receivedCredit)
            FinancialTransaction_ReceivedCredit.internalBinaryWrite(message.receivedCredit, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.CreditReversal
 */
export const FinancialTransaction_CreditReversal = new FinancialTransaction_CreditReversal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_DebitReversal$Type extends MessageType<FinancialTransaction_DebitReversal> {
    constructor() {
        super("rd.payments.FinancialTransaction.DebitReversal", [
            { no: 1, name: "stripe_debit_reversal_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stripe_received_debit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "received_debit", kind: "message", T: () => FinancialTransaction_ReceivedDebit }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_DebitReversal>): FinancialTransaction_DebitReversal {
        const message = { stripeDebitReversalId: "", stripeReceivedDebitId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_DebitReversal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_DebitReversal): FinancialTransaction_DebitReversal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_debit_reversal_id */ 1:
                    message.stripeDebitReversalId = reader.string();
                    break;
                case /* string stripe_received_debit_id */ 2:
                    message.stripeReceivedDebitId = reader.string();
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedDebit received_debit */ 3:
                    message.receivedDebit = FinancialTransaction_ReceivedDebit.internalBinaryRead(reader, reader.uint32(), options, message.receivedDebit);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_DebitReversal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_debit_reversal_id = 1; */
        if (message.stripeDebitReversalId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeDebitReversalId);
        /* string stripe_received_debit_id = 2; */
        if (message.stripeReceivedDebitId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripeReceivedDebitId);
        /* rd.payments.FinancialTransaction.ReceivedDebit received_debit = 3; */
        if (message.receivedDebit)
            FinancialTransaction_ReceivedDebit.internalBinaryWrite(message.receivedDebit, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.DebitReversal
 */
export const FinancialTransaction_DebitReversal = new FinancialTransaction_DebitReversal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_InboundTransfer$Type extends MessageType<FinancialTransaction_InboundTransfer> {
    constructor() {
        super("rd.payments.FinancialTransaction.InboundTransfer", [
            { no: 1, name: "stripe_inbound_transfer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "billing_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "origin_account", kind: "message", T: () => BankAccount }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_InboundTransfer>): FinancialTransaction_InboundTransfer {
        const message = { stripeInboundTransferId: "", billingName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_InboundTransfer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_InboundTransfer): FinancialTransaction_InboundTransfer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_inbound_transfer_id */ 1:
                    message.stripeInboundTransferId = reader.string();
                    break;
                case /* string billing_name */ 2:
                    message.billingName = reader.string();
                    break;
                case /* rd.payments.BankAccount origin_account */ 3:
                    message.originAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.originAccount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_InboundTransfer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_inbound_transfer_id = 1; */
        if (message.stripeInboundTransferId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeInboundTransferId);
        /* string billing_name = 2; */
        if (message.billingName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.billingName);
        /* rd.payments.BankAccount origin_account = 3; */
        if (message.originAccount)
            BankAccount.internalBinaryWrite(message.originAccount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.InboundTransfer
 */
export const FinancialTransaction_InboundTransfer = new FinancialTransaction_InboundTransfer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_IssuingAuthorization$Type extends MessageType<FinancialTransaction_IssuingAuthorization> {
    constructor() {
        super("rd.payments.FinancialTransaction.IssuingAuthorization", [
            { no: 1, name: "stripe_issuing_authorization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "issuing_card", kind: "message", T: () => IssuingCard },
            { no: 3, name: "merchant_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_IssuingAuthorization>): FinancialTransaction_IssuingAuthorization {
        const message = { stripeIssuingAuthorizationId: "", merchantName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_IssuingAuthorization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_IssuingAuthorization): FinancialTransaction_IssuingAuthorization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_issuing_authorization_id */ 1:
                    message.stripeIssuingAuthorizationId = reader.string();
                    break;
                case /* rd.payments.IssuingCard issuing_card */ 2:
                    message.issuingCard = IssuingCard.internalBinaryRead(reader, reader.uint32(), options, message.issuingCard);
                    break;
                case /* string merchant_name */ 3:
                    message.merchantName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_IssuingAuthorization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_issuing_authorization_id = 1; */
        if (message.stripeIssuingAuthorizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeIssuingAuthorizationId);
        /* rd.payments.IssuingCard issuing_card = 2; */
        if (message.issuingCard)
            IssuingCard.internalBinaryWrite(message.issuingCard, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string merchant_name = 3; */
        if (message.merchantName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.merchantName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.IssuingAuthorization
 */
export const FinancialTransaction_IssuingAuthorization = new FinancialTransaction_IssuingAuthorization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_OutboundPayment$Type extends MessageType<FinancialTransaction_OutboundPayment> {
    constructor() {
        super("rd.payments.FinancialTransaction.OutboundPayment", [
            { no: 1, name: "stripe_outbound_payment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "destination_account", kind: "message", T: () => BankAccount },
            { no: 3, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 4, name: "stripe_customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "expected_arrival_date", kind: "message", T: () => Timestamp },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_OutboundPayment>): FinancialTransaction_OutboundPayment {
        const message = { stripeOutboundPaymentId: "", stripeCustomerId: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_OutboundPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_OutboundPayment): FinancialTransaction_OutboundPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_outbound_payment_id */ 1:
                    message.stripeOutboundPaymentId = reader.string();
                    break;
                case /* rd.payments.BankAccount destination_account */ 2:
                    message.destinationAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.destinationAccount);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 3:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string stripe_customer_id */ 4:
                    message.stripeCustomerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp expected_arrival_date */ 5:
                    message.expectedArrivalDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expectedArrivalDate);
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_OutboundPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_outbound_payment_id = 1; */
        if (message.stripeOutboundPaymentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeOutboundPaymentId);
        /* rd.payments.BankAccount destination_account = 2; */
        if (message.destinationAccount)
            BankAccount.internalBinaryWrite(message.destinationAccount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 3; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_customer_id = 4; */
        if (message.stripeCustomerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.stripeCustomerId);
        /* google.protobuf.Timestamp expected_arrival_date = 5; */
        if (message.expectedArrivalDate)
            Timestamp.internalBinaryWrite(message.expectedArrivalDate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.OutboundPayment
 */
export const FinancialTransaction_OutboundPayment = new FinancialTransaction_OutboundPayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_ReceivedCredit$Type extends MessageType<FinancialTransaction_ReceivedCredit> {
    constructor() {
        super("rd.payments.FinancialTransaction.ReceivedCredit", [
            { no: 1, name: "stripe_received_credit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "billing_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["rd.payments.FinancialTransaction.ReceivedCredit.Type", FinancialTransaction_ReceivedCredit_Type, "TYPE_"] },
            { no: 4, name: "bank_account", kind: "message", T: () => BankAccount },
            { no: 5, name: "issuing_card", kind: "message", T: () => IssuingCard },
            { no: 6, name: "payout", kind: "message", T: () => FinancialTransaction_ReceivedCredit_Payout }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_ReceivedCredit>): FinancialTransaction_ReceivedCredit {
        const message = { stripeReceivedCreditId: "", billingName: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_ReceivedCredit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_ReceivedCredit): FinancialTransaction_ReceivedCredit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_received_credit_id */ 1:
                    message.stripeReceivedCreditId = reader.string();
                    break;
                case /* string billing_name */ 2:
                    message.billingName = reader.string();
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedCredit.Type type */ 3:
                    message.type = reader.int32();
                    break;
                case /* rd.payments.BankAccount bank_account */ 4:
                    message.bankAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.bankAccount);
                    break;
                case /* rd.payments.IssuingCard issuing_card */ 5:
                    message.issuingCard = IssuingCard.internalBinaryRead(reader, reader.uint32(), options, message.issuingCard);
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedCredit.Payout payout */ 6:
                    message.payout = FinancialTransaction_ReceivedCredit_Payout.internalBinaryRead(reader, reader.uint32(), options, message.payout);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_ReceivedCredit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_received_credit_id = 1; */
        if (message.stripeReceivedCreditId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeReceivedCreditId);
        /* string billing_name = 2; */
        if (message.billingName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.billingName);
        /* rd.payments.FinancialTransaction.ReceivedCredit.Type type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* rd.payments.BankAccount bank_account = 4; */
        if (message.bankAccount)
            BankAccount.internalBinaryWrite(message.bankAccount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.IssuingCard issuing_card = 5; */
        if (message.issuingCard)
            IssuingCard.internalBinaryWrite(message.issuingCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.ReceivedCredit.Payout payout = 6; */
        if (message.payout)
            FinancialTransaction_ReceivedCredit_Payout.internalBinaryWrite(message.payout, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.ReceivedCredit
 */
export const FinancialTransaction_ReceivedCredit = new FinancialTransaction_ReceivedCredit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_ReceivedCredit_Payout$Type extends MessageType<FinancialTransaction_ReceivedCredit_Payout> {
    constructor() {
        super("rd.payments.FinancialTransaction.ReceivedCredit.Payout", [
            { no: 1, name: "automatic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "stripe_payout_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_ReceivedCredit_Payout>): FinancialTransaction_ReceivedCredit_Payout {
        const message = { automatic: false, stripePayoutId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_ReceivedCredit_Payout>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_ReceivedCredit_Payout): FinancialTransaction_ReceivedCredit_Payout {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool automatic */ 1:
                    message.automatic = reader.bool();
                    break;
                case /* string stripe_payout_id */ 2:
                    message.stripePayoutId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_ReceivedCredit_Payout, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool automatic = 1; */
        if (message.automatic !== false)
            writer.tag(1, WireType.Varint).bool(message.automatic);
        /* string stripe_payout_id = 2; */
        if (message.stripePayoutId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripePayoutId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.ReceivedCredit.Payout
 */
export const FinancialTransaction_ReceivedCredit_Payout = new FinancialTransaction_ReceivedCredit_Payout$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_ReceivedDebit$Type extends MessageType<FinancialTransaction_ReceivedDebit> {
    constructor() {
        super("rd.payments.FinancialTransaction.ReceivedDebit", [
            { no: 1, name: "stripe_received_debit_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "billing_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["rd.payments.FinancialTransaction.ReceivedDebit.Type", FinancialTransaction_ReceivedDebit_Type, "TYPE_"] },
            { no: 4, name: "bank_account", kind: "message", T: () => BankAccount },
            { no: 5, name: "issuing_card", kind: "message", T: () => IssuingCard },
            { no: 6, name: "check", kind: "message", T: () => FinancialTransaction_ReceivedDebit_Check }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_ReceivedDebit>): FinancialTransaction_ReceivedDebit {
        const message = { stripeReceivedDebitId: "", billingName: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_ReceivedDebit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_ReceivedDebit): FinancialTransaction_ReceivedDebit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_received_debit_id */ 1:
                    message.stripeReceivedDebitId = reader.string();
                    break;
                case /* string billing_name */ 2:
                    message.billingName = reader.string();
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedDebit.Type type */ 3:
                    message.type = reader.int32();
                    break;
                case /* rd.payments.BankAccount bank_account */ 4:
                    message.bankAccount = BankAccount.internalBinaryRead(reader, reader.uint32(), options, message.bankAccount);
                    break;
                case /* rd.payments.IssuingCard issuing_card */ 5:
                    message.issuingCard = IssuingCard.internalBinaryRead(reader, reader.uint32(), options, message.issuingCard);
                    break;
                case /* rd.payments.FinancialTransaction.ReceivedDebit.Check check */ 6:
                    message.check = FinancialTransaction_ReceivedDebit_Check.internalBinaryRead(reader, reader.uint32(), options, message.check);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_ReceivedDebit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_received_debit_id = 1; */
        if (message.stripeReceivedDebitId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeReceivedDebitId);
        /* string billing_name = 2; */
        if (message.billingName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.billingName);
        /* rd.payments.FinancialTransaction.ReceivedDebit.Type type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* rd.payments.BankAccount bank_account = 4; */
        if (message.bankAccount)
            BankAccount.internalBinaryWrite(message.bankAccount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.IssuingCard issuing_card = 5; */
        if (message.issuingCard)
            IssuingCard.internalBinaryWrite(message.issuingCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction.ReceivedDebit.Check check = 6; */
        if (message.check)
            FinancialTransaction_ReceivedDebit_Check.internalBinaryWrite(message.check, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.ReceivedDebit
 */
export const FinancialTransaction_ReceivedDebit = new FinancialTransaction_ReceivedDebit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransaction_ReceivedDebit_Check$Type extends MessageType<FinancialTransaction_ReceivedDebit_Check> {
    constructor() {
        super("rd.payments.FinancialTransaction.ReceivedDebit.Check", [
            { no: 1, name: "billing_address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<FinancialTransaction_ReceivedDebit_Check>): FinancialTransaction_ReceivedDebit_Check {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransaction_ReceivedDebit_Check>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransaction_ReceivedDebit_Check): FinancialTransaction_ReceivedDebit_Check {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.address.Address billing_address */ 1:
                    message.billingAddress = Address.internalBinaryRead(reader, reader.uint32(), options, message.billingAddress);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransaction_ReceivedDebit_Check, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.address.Address billing_address = 1; */
        if (message.billingAddress)
            Address.internalBinaryWrite(message.billingAddress, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransaction.ReceivedDebit.Check
 */
export const FinancialTransaction_ReceivedDebit_Check = new FinancialTransaction_ReceivedDebit_Check$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancialTransactionLog$Type extends MessageType<FinancialTransactionLog> {
    constructor() {
        super("rd.payments.FinancialTransactionLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "financial_transaction", kind: "message", T: () => FinancialTransaction },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<FinancialTransactionLog>): FinancialTransactionLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancialTransactionLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancialTransactionLog): FinancialTransactionLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.payments.FinancialTransaction financial_transaction */ 2:
                    message.financialTransaction = FinancialTransaction.internalBinaryRead(reader, reader.uint32(), options, message.financialTransaction);
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancialTransactionLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.FinancialTransaction financial_transaction = 2; */
        if (message.financialTransaction)
            FinancialTransaction.internalBinaryWrite(message.financialTransaction, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.FinancialTransactionLog
 */
export const FinancialTransactionLog = new FinancialTransactionLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Payout$Type extends MessageType<Payout> {
    constructor() {
        super("rd.payments.Payout", [
            { no: 1, name: "stripe_payout_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "arrival_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "status", kind: "enum", T: () => ["rd.payments.Payout.Status", Payout_Status, "STATUS_"] },
            { no: 4, name: "reconciliation_status", kind: "enum", T: () => ["rd.payments.Payout.ReconciliationStatus", Payout_ReconciliationStatus, "RECONCILIATION_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<Payout>): Payout {
        const message = { stripePayoutId: "", status: 0, reconciliationStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Payout>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Payout): Payout {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_payout_id */ 1:
                    message.stripePayoutId = reader.string();
                    break;
                case /* google.protobuf.Timestamp arrival_at */ 2:
                    message.arrivalAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.arrivalAt);
                    break;
                case /* rd.payments.Payout.Status status */ 3:
                    message.status = reader.int32();
                    break;
                case /* rd.payments.Payout.ReconciliationStatus reconciliation_status */ 4:
                    message.reconciliationStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Payout, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_payout_id = 1; */
        if (message.stripePayoutId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripePayoutId);
        /* google.protobuf.Timestamp arrival_at = 2; */
        if (message.arrivalAt)
            Timestamp.internalBinaryWrite(message.arrivalAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Payout.Status status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* rd.payments.Payout.ReconciliationStatus reconciliation_status = 4; */
        if (message.reconciliationStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.reconciliationStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.Payout
 */
export const Payout = new Payout$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BalanceTransaction$Type extends MessageType<BalanceTransaction> {
    constructor() {
        super("rd.payments.BalanceTransaction", [
            { no: 1, name: "stripe_balance_transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "gross_amount", kind: "message", T: () => Money },
            { no: 3, name: "fee", kind: "message", T: () => Money },
            { no: 4, name: "net_amount", kind: "message", T: () => Money },
            { no: 5, name: "type", kind: "enum", T: () => ["rd.payments.BalanceTransaction.Type", BalanceTransaction_Type] },
            { no: 6, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "company_id", kind: "message", T: () => UUID },
            { no: 10, name: "stripe_created_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "stripe_payout_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "stripe_connect_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "stripe_refund_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BalanceTransaction>): BalanceTransaction {
        const message = { stripeBalanceTransactionId: "", type: 0, stripePaymentIntentId: "", stripePayoutId: "", stripeConnectAccountId: "", stripeRefundId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BalanceTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BalanceTransaction): BalanceTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_balance_transaction_id */ 1:
                    message.stripeBalanceTransactionId = reader.string();
                    break;
                case /* rd.proto.money.Money gross_amount */ 2:
                    message.grossAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossAmount);
                    break;
                case /* rd.proto.money.Money fee */ 3:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                case /* rd.proto.money.Money net_amount */ 4:
                    message.netAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.netAmount);
                    break;
                case /* rd.payments.BalanceTransaction.Type type */ 5:
                    message.type = reader.int32();
                    break;
                case /* string stripe_payment_intent_id */ 6:
                    message.stripePaymentIntentId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 9:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* google.protobuf.Timestamp stripe_created_at */ 10:
                    message.stripeCreatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.stripeCreatedAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 11:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string stripe_payout_id */ 12:
                    message.stripePayoutId = reader.string();
                    break;
                case /* string stripe_connect_account_id */ 13:
                    message.stripeConnectAccountId = reader.string();
                    break;
                case /* string stripe_refund_id */ 14:
                    message.stripeRefundId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BalanceTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_balance_transaction_id = 1; */
        if (message.stripeBalanceTransactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeBalanceTransactionId);
        /* rd.proto.money.Money gross_amount = 2; */
        if (message.grossAmount)
            Money.internalBinaryWrite(message.grossAmount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money fee = 3; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money net_amount = 4; */
        if (message.netAmount)
            Money.internalBinaryWrite(message.netAmount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.BalanceTransaction.Type type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* string stripe_payment_intent_id = 6; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        /* google.protobuf.Timestamp created_at = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 9; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp stripe_created_at = 10; */
        if (message.stripeCreatedAt)
            Timestamp.internalBinaryWrite(message.stripeCreatedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 11; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_payout_id = 12; */
        if (message.stripePayoutId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.stripePayoutId);
        /* string stripe_connect_account_id = 13; */
        if (message.stripeConnectAccountId !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.stripeConnectAccountId);
        /* string stripe_refund_id = 14; */
        if (message.stripeRefundId !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.stripeRefundId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.BalanceTransaction
 */
export const BalanceTransaction = new BalanceTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BalanceTransactionLog$Type extends MessageType<BalanceTransactionLog> {
    constructor() {
        super("rd.payments.BalanceTransactionLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "balance_transaction", kind: "message", T: () => BalanceTransaction },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<BalanceTransactionLog>): BalanceTransactionLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BalanceTransactionLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BalanceTransactionLog): BalanceTransactionLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.payments.BalanceTransaction balance_transaction */ 2:
                    message.balanceTransaction = BalanceTransaction.internalBinaryRead(reader, reader.uint32(), options, message.balanceTransaction);
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BalanceTransactionLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.BalanceTransaction balance_transaction = 2; */
        if (message.balanceTransaction)
            BalanceTransaction.internalBinaryWrite(message.balanceTransaction, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.payments.BalanceTransactionLog
 */
export const BalanceTransactionLog = new BalanceTransactionLog$Type();
