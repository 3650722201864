import { Dialog, DialogPanel } from "@headlessui/react"
import { FunctionComponent, MutableRefObject, PropsWithChildren } from "react"

import { classNames } from "lib/classNames"

export interface ModalProps {
  open: boolean
  onClose: () => void
  size?: "sm" | "md" | "lg"
  topPaddingOnly?: boolean
  lessRoundedBorders?: boolean
  className?: string
  blurBackground?: boolean
  initialFocus?: MutableRefObject<HTMLElement | null>
  scrollable?: boolean
}

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = (
  props
) => {
  const {
    open,
    onClose,
    initialFocus,
    blurBackground,
    children,
    size = "sm",
    lessRoundedBorders,
    topPaddingOnly,
    scrollable = true,
    className,
  } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      initialFocus={initialFocus}
      className={classNames("z-50", className)}
    >
      <div
        className={classNames(
          "dark:bg-gray-1000 fixed inset-0 z-50 bg-gray-500 bg-opacity-75 dark:bg-opacity-75",
          blurBackground && "backdrop-blur-sm"
        )}
        aria-hidden="true"
      />

      <div
        className={
          "fixed inset-0 z-50 flex w-screen items-center justify-center p-4"
        }
      >
        <DialogPanel
          className={classNames(
            "max-h-full w-full rounded-lg bg-white p-6 shadow-xl dark:bg-gray-800",
            scrollable && "overflow-y-auto",
            size === "sm" && "max-w-[640px]",
            size === "md" && "max-w-[800px]",
            size === "lg" && "max-w-[1000px]",
            lessRoundedBorders && "rounded",
            topPaddingOnly && "p-0 pt-6"
          )}
        >
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  )
}
