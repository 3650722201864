import {
  BulkExportCustomersReq,
  BulkExportCustomersRes,
  BulkExportInventoryCountProductsReq,
  BulkExportInventoryCountProductsRes,
  BulkExportProductLabelsReq,
  BulkExportProductLabelsRes,
  BulkExportProductsReq,
  BulkExportProductsRes,
  BulkExportVendorsReq,
  BulkExportVendorsRes,
  GetAccountPaymentAdjustmentsCSVReq,
  GetAccountPaymentAdjustmentsCSVRes,
  GetAccountPaymentAdjustmentsPDFReq,
  GetAccountPaymentAdjustmentsPDFRes,
  GetAccountPaymentAdjustmentsReq,
  GetAccountPaymentAdjustmentsRes,
  GetAdditionalFeesCSVReq,
  GetAdditionalFeesCSVRes,
  GetAdditionalFeesPDFReq,
  GetAdditionalFeesPDFRes,
  GetAdditionalFeesReq,
  GetAdditionalFeesRes,
  GetAgingDetailCSVReq,
  GetAgingDetailCSVRes,
  GetAgingDetailPDFReq,
  GetAgingDetailPDFRes,
  GetAgingDetailReq,
  GetAgingDetailRes,
  GetCashRegisterCSVReq,
  GetCashRegisterCSVRes,
  GetCashRegisterPDFReq,
  GetCashRegisterPDFRes,
  GetCashRegisterReq,
  GetCashRegisterRes,
  GetNewAccountsOpenedCSVReq,
  GetNewAccountsOpenedCSVRes,
  GetNewAccountsOpenedPDFReq,
  GetNewAccountsOpenedPDFRes,
  GetNewAccountsOpenedReq,
  GetNewAccountsOpenedRes,
  GetPaymentsCSVReq,
  GetPaymentsCSVRes,
  GetPaymentsPDFReq,
  GetPaymentsPDFRes,
  GetPaymentsReq,
  GetPaymentsRes,
  GetPriceEditsCSVReq,
  GetPriceEditsCSVRes,
  GetPriceEditsPDFReq,
  GetPriceEditsPDFRes,
  GetPriceEditsReq,
  GetPriceEditsRes,
  GetProfitAndLossPDFReq,
  GetProfitAndLossPDFRes,
  GetProfitAndLossReq,
  GetProfitAndLossRes,
  GetPurchaseJournalCSVReq,
  GetPurchaseJournalCSVRes,
  GetPurchaseJournalPDFReq,
  GetPurchaseJournalPDFRes,
  GetPurchaseJournalReq,
  GetPurchaseJournalRes,
  GetSalesByCustomerCSVReq,
  GetSalesByCustomerCSVRes,
  GetSalesByCustomerPDFReq,
  GetSalesByCustomerPDFRes,
  GetSalesByCustomerReq,
  GetSalesByCustomerRes,
  GetSalesByProductCSVReq,
  GetSalesByProductCSVRes,
  GetSalesByProductPDFReq,
  GetSalesByProductPDFRes,
  GetSalesByProductReq,
  GetSalesByProductRes,
  GetSalesCSVReq,
  GetSalesCSVRes,
  GetSalesPDFReq,
  GetSalesPDFRes,
  GetSalesReq,
  GetSalesRes,
  GetSoldProductsCSVReq,
  GetSoldProductsCSVRes,
  GetSoldProductsPDFReq,
  GetSoldProductsPDFRes,
  GetSoldProductsReq,
  GetSoldProductsRes,
  SearchBillPaymentsRes_Result as SearchBillPaymentResult,
  SearchBillPaymentsReq,
  SearchBillPaymentsRes,
  SearchCashTransactionsRes_Result as SearchCashTransactionResult,
  SearchCashTransactionsReq,
  SearchCashTransactionsRes,
  SearchCustomPriceRulesReq,
  SearchCustomPriceRulesRes,
  SearchCustomPriceRulesRes_Result as SearchCustomPriceRulesResult,
  SearchCustomersV2Req,
  SearchCustomersV2Res,
  SearchCustomersV2Res_Result as SearchCustomersV2Result,
  SearchExcludedInventoryCountProductsReq,
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsRes_Result as SearchExcludedInventoryCountProductsResult,
  SearchExternalTransactionsReq,
  SearchExternalTransactionsRes,
  SearchExternalTransactionsRes_Result as SearchExternalTransactionsResult,
  SearchFinancialTransactionsReq,
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsRes_Result as SearchFinancialTransactionsResult,
  SearchInventoryChangesReq,
  SearchInventoryChangesRes,
  SearchInventoryChangesRes_Result as SearchInventoryChangesResult,
  SearchInventoryCountProductsReq,
  SearchInventoryCountProductsRes,
  SearchJobsReq,
  SearchJobsRes,
  SearchJobsRes_Result as SearchJobsResult,
  SearchOrderShipmentsForReconciliationReq,
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationRes_Result as SearchOrderShipmentsForReconciliationResult,
  SearchOrderShipmentsReq,
  SearchOrderShipmentsRes,
  SearchOrderShipmentsRes_Result as SearchOrderShipmentsResult,
  SearchOrdersReq,
  SearchOrdersRes,
  SearchOrdersRes_Result as SearchOrdersResult,
  SearchOutstandingSalesReq,
  SearchOutstandingSalesRes,
  SearchOutstandingSalesRes_Result as SearchOutstandingSalesResult,
  SearchPriceTierRulesReq,
  SearchPriceTierRulesRes,
  SearchPriceTierRulesRes_Result as SearchPriceTierRulesResult,
  SearchProductsV2Req,
  SearchProductsV2Res,
  SearchProductsV2Res_Result as SearchProductsV2Result,
  SearchRefundedBillPaymentsRes_Result as SearchRefundedBillPaymentResult,
  SearchRefundedBillPaymentsReq,
  SearchRefundedBillPaymentsRes,
  SearchReturnsReq,
  SearchReturnsRes,
  SearchReturnsRes_Result as SearchReturnsResult,
  SearchSalesV2Req,
  SearchSalesV2Res,
  SearchSalesV2Res_Result as SearchSalesV2Result,
  SearchTagsReq,
  SearchTagsRes,
  SearchTagsRes_Result,
  SearchTintColorsReq,
  SearchTintColorsRes,
  SearchTintColorsRes_Result as SearchTintColorsResult,
  SearchTransfersReq,
  SearchTransfersRes,
  SearchTransfersRes_Result as SearchTransfersResult,
  SearchVendorsReq,
  SearchVendorsRes,
  SearchVendorsRes_Result as SearchVendorsResult,
} from "gen/search/service/service_pb"
import {
  BulkServiceClient,
  SearchServiceClient,
  SearchalyticsServiceClient,
} from "gen/search/service/service_pb.client"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from "react"
import {
  GetCustomerAgingCSVReq,
  GetCustomerAgingCSVRes,
  GetCustomerAgingPDFReq,
  GetCustomerAgingPDFRes,
  GetCustomerAgingReq,
  GetCustomerAgingRes,
  GetSalesByDayCSVReq,
  GetSalesByDayCSVRes,
  GetSalesByDayPDFReq,
  GetSalesByDayPDFRes,
  GetSalesByDayReq,
  GetSalesByDayRes,
} from "gen/api/analytics/service_pb"
import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryResult,
  hashKey,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc"
import {
  SerializableReq,
  restoreSerializableReq,
} from "features/pos/analytics/Reports/configuration/helpers"
import {
  selectExternalTransactionKey,
  selectFinancialTransactionKey,
} from "./searchSlice"

import { ApiError } from "./apiSlice"
import { SearchInventoryCountProductsRes_Result as SearchInventoryCountProductsResult } from "gen/search/service/service_pb"
import { TwirpFetchTransport } from "@protobuf-ts/twirp-transport"
import { stringify } from "lib/stringify"
import { useAppSelector } from "app/hooks"

interface PageParam {
  cursor: string
  offset: number
}
export type UseSearchQueryHookArg<Request extends { query: string }> =
  Partial<Request>

type UseSearchQueryHookBaseResult<T> = {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  results: T[]
}

type UsePaginatedSearchQueryHookBaseResult<T> =
  UseSearchQueryHookBaseResult<T> & {
    loadNext: () => void
  }

type UsePaginatedSearchQueryHookResult<Response, Result> =
  UseInfiniteQueryResult<InfiniteData<Response, PageParam>, Error> &
    UsePaginatedSearchQueryHookBaseResult<Result>

type UseUnpaginatedSearchQueryHookResult<Response, Result> = UseQueryResult<
  Response,
  Error
> &
  UseSearchQueryHookBaseResult<Result>

function useServiceClient<
  T extends
    | BulkServiceClient
    | SearchServiceClient
    | SearchalyticsServiceClient,
>(ClientConstructor: new (transport: TwirpFetchTransport) => T): T {
  const subdomain = window.location.hostname.split(".")[0]
  const baseUrl =
    `${process.env.NEXT_PUBLIC_RUNDOO_SEARCH_API_BASE_URL}/twirp`.replace(
      "https://",
      `https://${subdomain}.`
    )
  return new ClientConstructor(
    new TwirpFetchTransport({
      baseUrl,
      fetchInit: { credentials: "include" },
      sendJson: true,
      jsonOptions: { ignoreUnknownFields: true },
    })
  )
}

function useBulkClient(): BulkServiceClient {
  return useServiceClient(BulkServiceClient)
}

function useSearchClient(): SearchServiceClient {
  return useServiceClient(SearchServiceClient)
}

function useSearchalyticsClient(): SearchalyticsServiceClient {
  return useServiceClient(SearchalyticsServiceClient)
}

function useBulkMutation<Req extends object, Res extends object>(
  key: keyof BulkServiceClient
) {
  const client = useBulkClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)

  return useMutation<Res, ApiError, Req>({
    mutationFn: async (req: Req): Promise<Res> => {
      const res = await fn(req)
      return res.response
    },
  })
}

export function useBulkExportCustomers() {
  return useBulkMutation<BulkExportCustomersReq, BulkExportCustomersRes>(
    "bulkExportCustomers"
  )
}
export function useBulkExportInventoryCountProducts() {
  return useBulkMutation<
    BulkExportInventoryCountProductsReq,
    BulkExportInventoryCountProductsRes
  >("bulkExportInventoryCountProducts")
}

export function useBulkExportProducts() {
  return useBulkMutation<BulkExportProductsReq, BulkExportProductsRes>(
    "bulkExportProducts"
  )
}

export function useBulkExportProductLabels() {
  return useBulkMutation<
    BulkExportProductLabelsReq,
    BulkExportProductLabelsRes
  >("bulkExportProductLabels")
}

export function useBulkExportVendors() {
  return useBulkMutation<BulkExportVendorsReq, BulkExportVendorsRes>(
    "bulkExportVendors"
  )
}

function usePaginatedSearch<
  SearchReq extends { query: string },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
    cursor: string
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean,
  reloadKey?: string
): UsePaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const client = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )

  const [query, setQuery] = useState<string>(arg?.query ?? "")
  const deferredQuery = useDeferredValue(query)

  const res = useInfiniteQuery<
    SearchRes,
    Error,
    InfiniteData<SearchRes, PageParam>,
    QueryKey,
    PageParam
  >({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery, reloadKey],
    queryKeyHashFn: (queryKey) => {
      const serializable = queryKey.map((key) => stringify(key))
      return hashKey(serializable)
    },
    initialPageParam: {
      cursor: "",
      offset: 0,
    },
    queryFn: async ({ pageParam }) => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
        query: deferredQuery,
        cursor: pageParam.cursor,
        offset: pageParam.offset,
      }
      const { response } = await fn(req)
      return response
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.cursor
        ? {
            cursor: lastPage.cursor,
            offset: allPages.flatMap((x) => x.results).length,
          }
        : undefined,
    placeholderData: keepPreviousData,
    enabled: !skip,
  })

  const { hasNextPage, fetchNextPage } = res
  const loadNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  const results = res.data?.pages.flatMap((page) => page.results) ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
    loadNext,
  }
}

function useUnpaginatedSearch<
  SearchReq extends { query: string },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean
): UseUnpaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const client = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )
  const [query, setQuery] = useState<string>("")
  const deferredQuery = useDeferredValue(query)

  const res = useQuery({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery],
    queryFn: async () => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
        query: deferredQuery,
      }
      const { response } = await fn(req)
      return response
    },
    placeholderData: keepPreviousData,
    enabled: !skip,
  })

  const results = res.data?.results ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
  }
}

export function useSearchBills(
  arg?: UseSearchQueryHookArg<SearchBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchBillPaymentsRes,
  SearchBillPaymentResult
> {
  return usePaginatedSearch(
    "searchBillPayments",
    "bill-payments",
    SearchBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchRefundedBills(
  arg?: UseSearchQueryHookArg<SearchRefundedBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchRefundedBillPaymentsRes,
  SearchRefundedBillPaymentResult
> {
  return usePaginatedSearch(
    "searchRefundedBillPayments",
    "refunded-bill-payments",
    SearchRefundedBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchCustomersV2(
  arg?: UseSearchQueryHookArg<SearchCustomersV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomersV2Res,
  SearchCustomersV2Result
> {
  return usePaginatedSearch(
    "searchCustomersV2",
    "customers",
    SearchCustomersV2Req,
    arg,
    skip
  )
}

export function useSearchInventoryCountProducts(
  arg?: UseSearchQueryHookArg<
    Omit<SearchInventoryCountProductsReq, "offset" | "cursor">
  >,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryCountProductsRes,
  SearchInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchInventoryCountProducts",
    "inventory-count-products",
    SearchInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useInvalidateSearchInventoryCountProducts(): () => Promise<void> {
  const queryClient = useQueryClient()
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["search", "inventory-count-products"],
      }),
    [queryClient]
  )
}

export function useSearchExcludedInventoryCountProducts(
  arg?: UseSearchQueryHookArg<SearchExcludedInventoryCountProductsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchExcludedInventoryCountProducts",
    "excluded-inventory-count-products",
    SearchExcludedInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useSearchOrders(
  arg?: UseSearchQueryHookArg<SearchOrdersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchOrdersRes, SearchOrdersResult> {
  return usePaginatedSearch(
    "searchOrders",
    "orders",
    SearchOrdersReq,
    arg,
    skip
  )
}

export function useSearchOrderShipments(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsRes,
  SearchOrderShipmentsResult
> {
  return usePaginatedSearch(
    "searchOrderShipments",
    "order-shipments",
    SearchOrderShipmentsReq,
    arg,
    skip
  )
}

export function useSearchProductsV2(
  arg?: UseSearchQueryHookArg<SearchProductsV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchProductsV2Res,
  SearchProductsV2Result
> {
  return usePaginatedSearch(
    "searchProductsV2",
    "products",
    SearchProductsV2Req,
    arg,
    skip
  )
}

export function useSearchCustomPriceRules(
  arg?: UseSearchQueryHookArg<SearchCustomPriceRulesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomPriceRulesRes,
  SearchCustomPriceRulesResult
> {
  return usePaginatedSearch(
    "searchCustomPriceRules",
    "custom-price-rules",
    SearchCustomPriceRulesReq,
    arg,
    skip
  )
}

export function useSearchPriceTierRules(
  arg?: UseSearchQueryHookArg<SearchPriceTierRulesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchPriceTierRulesRes,
  SearchPriceTierRulesResult
> {
  return usePaginatedSearch(
    "searchPriceTierRules",
    "price-tier-rules",
    SearchPriceTierRulesReq,
    arg,
    skip
  )
}

export function useSearchSalesV2(
  arg?: UseSearchQueryHookArg<SearchSalesV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchSalesV2Res, SearchSalesV2Result> {
  return usePaginatedSearch(
    "searchSalesV2",
    "sales",
    SearchSalesV2Req,
    arg,
    skip
  )
}

export function useSearchReturns(
  arg?: UseSearchQueryHookArg<SearchReturnsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchReturnsRes, SearchReturnsResult> {
  return usePaginatedSearch(
    "searchReturns",
    "returns",
    SearchReturnsReq,
    arg,
    skip
  )
}

export function useSearchOutstandingSales(
  arg?: UseSearchQueryHookArg<SearchOutstandingSalesReq>,
  skip?: boolean
): UseUnpaginatedSearchQueryHookResult<
  SearchOutstandingSalesRes,
  SearchOutstandingSalesResult
> {
  return useUnpaginatedSearch(
    "searchOutstandingSales",
    "outstanding-sales",
    SearchOutstandingSalesReq,
    arg,
    skip
  )
}

export function useSearchTransfers(
  arg?: UseSearchQueryHookArg<SearchTransfersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchTransfersRes,
  SearchTransfersResult
> {
  return usePaginatedSearch(
    "searchTransfers",
    "transfers",
    SearchTransfersReq,
    arg,
    skip
  )
}

export function useSearchVendors(
  arg?: UseSearchQueryHookArg<SearchVendorsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchVendorsRes, SearchVendorsResult> {
  return usePaginatedSearch(
    "searchVendors",
    "vendors",
    SearchVendorsReq,
    arg,
    skip
  )
}

export function useSearchTintColors(): UseUnpaginatedSearchQueryHookResult<
  SearchTintColorsRes,
  SearchTintColorsResult
> {
  return useUnpaginatedSearch(
    "searchTintColors",
    "tint-colors",
    SearchTintColorsReq
  )
}

export function useSearchCashTransactions(
  arg?: UseSearchQueryHookArg<SearchCashTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCashTransactionsRes,
  SearchCashTransactionResult
> {
  return usePaginatedSearch(
    "searchCashTransactions",
    "cash-transactions",
    SearchCashTransactionsReq,
    arg,
    skip
  )
}

export function useSearchFinancialTransactions(
  arg?: UseSearchQueryHookArg<SearchFinancialTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsResult
> {
  const reloadKey = useAppSelector(selectFinancialTransactionKey)

  return usePaginatedSearch(
    "searchFinancialTransactions",
    "financial-transactions",
    SearchFinancialTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchExternalTransactions(
  arg?: UseSearchQueryHookArg<SearchExternalTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExternalTransactionsRes,
  SearchExternalTransactionsResult
> {
  const reloadKey = useAppSelector(selectExternalTransactionKey)

  return usePaginatedSearch(
    "searchExternalTransactions",
    "external-transactions",
    SearchExternalTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchOrderShipmentsForReconciliation(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsForReconciliationReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationResult
> {
  return usePaginatedSearch(
    "searchOrderShipmentsForReconciliation",
    "order-shipments-for-reconciliation",
    SearchOrderShipmentsForReconciliationReq,
    arg,
    skip
  )
}

export function useSearchInventoryChanges(
  arg?: UseSearchQueryHookArg<SearchInventoryChangesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryChangesRes,
  SearchInventoryChangesResult
> {
  return usePaginatedSearch(
    "searchInventoryChanges",
    "inventory-changes",
    SearchInventoryChangesReq,
    arg,
    skip
  )
}

export function useSearchJobs(
  arg?: UseSearchQueryHookArg<SearchJobsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchJobsRes, SearchJobsResult> {
  return usePaginatedSearch("searchJobs", "jobs", SearchJobsReq, arg, skip)
}

export function useSearchTags(
  arg?: UseSearchQueryHookArg<SearchTagsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchTagsRes, SearchTagsRes_Result> {
  return usePaginatedSearch("searchTags", "tags", SearchTagsReq, arg, skip)
}

const SEARCHALYTICS_STALE_TIME = 60_000
function useSearchalytics<Req extends object, Res extends object>(
  key: keyof SearchalyticsServiceClient,
  queryKey: string,
  arg: SerializableReq<Req>,
  skip?: boolean
): UseQueryResult<Res, Error> {
  const client = useSearchalyticsClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)

  const res = useQuery({
    queryKey: ["searchalytics", queryKey, arg],
    queryFn: async () => {
      const { response } = await fn(restoreSerializableReq(arg) as Req)
      return response
    },
    placeholderData: keepPreviousData,
    enabled: !skip,
    staleTime: SEARCHALYTICS_STALE_TIME,
  })

  return res
}

export function useSearchalyticsGetSalesByDay(
  arg: SerializableReq<GetSalesByDayReq>,
  skip?: boolean
): UseQueryResult<GetSalesByDayRes, Error> {
  return useSearchalytics("getSalesByDay", "sales-by-day", arg, skip)
}

export function useLazySearchalyticsGetSalesByDayPDF(
  arg: SerializableReq<GetSalesByDayPDFReq>
): UseQueryResult<GetSalesByDayPDFRes, Error> {
  return useSearchalytics("getSalesByDayPDF", "sales-by-day-pdf", arg, true)
}

export function useLazySearchalyticsGetSalesByDayCSV(
  arg: SerializableReq<GetSalesByDayCSVReq>
): UseQueryResult<GetSalesByDayCSVRes, Error> {
  return useSearchalytics("getSalesByDayCSV", "sales-by-day-csv", arg, true)
}

export function useSearchalyticsGetCashRegister(
  arg: SerializableReq<GetCashRegisterReq>,
  skip?: boolean
): UseQueryResult<GetCashRegisterRes, Error> {
  return useSearchalytics("getCashRegister", "cash-register", arg, skip)
}

export function useLazySearchalyticsGetCashRegisterPDF(
  arg: SerializableReq<GetCashRegisterPDFReq>
): UseQueryResult<GetCashRegisterPDFRes, Error> {
  return useSearchalytics("getCashRegisterPDF", "cash-register-pdf", arg, true)
}
export function useLazySearchalyticsGetCashRegisterCSV(
  arg: SerializableReq<GetCashRegisterCSVReq>
): UseQueryResult<GetCashRegisterCSVRes, Error> {
  return useSearchalytics("getCashRegisterCSV", "cash-register-csv", arg, true)
}

export function useSearchalyticsGetSales(
  arg: SerializableReq<GetSalesReq>,
  skip?: boolean
): UseQueryResult<GetSalesRes, Error> {
  return useSearchalytics("getSales", "sales", arg, skip)
}

export function useLazySearchalyticsGetSalesPDF(
  arg: SerializableReq<GetSalesPDFReq>
): UseQueryResult<GetSalesPDFRes, Error> {
  return useSearchalytics("getSalesPDF", "sales-pdf", arg, true)
}
export function useLazySearchalyticsGetSalesCSV(
  arg: SerializableReq<GetSalesCSVReq>
): UseQueryResult<GetSalesCSVRes, Error> {
  return useSearchalytics("getSalesCSV", "sales-csv", arg, true)
}

export function useSearchalyticsGetPayments(
  arg: SerializableReq<GetPaymentsReq>,
  skip?: boolean
): UseQueryResult<GetPaymentsRes, Error> {
  return useSearchalytics("getPayments", "payments", arg, skip)
}

export function useLazySearchalyticsGetPaymentsPDF(
  arg: SerializableReq<GetPaymentsPDFReq>
): UseQueryResult<GetPaymentsPDFRes, Error> {
  return useSearchalytics("getPaymentsPDF", "payments-pdf", arg, true)
}
export function useLazySearchalyticsGetPaymentsCSV(
  arg: SerializableReq<GetPaymentsCSVReq>
): UseQueryResult<GetPaymentsCSVRes, Error> {
  return useSearchalytics("getPaymentsCSV", "payments-csv", arg, true)
}

export function useSearchalyticsGetSoldProducts(
  arg: SerializableReq<GetSoldProductsReq>,
  skip?: boolean
): UseQueryResult<GetSoldProductsRes, Error> {
  return useSearchalytics("getSoldProducts", "sold-products", arg, skip)
}

export function useLazySearchalyticsGetSoldProductsPDF(
  arg: SerializableReq<GetSoldProductsPDFReq>
): UseQueryResult<GetSoldProductsPDFRes, Error> {
  return useSearchalytics("getSoldProductsPDF", "sold-products-pdf", arg, true)
}

export function useLazySearchalyticsGetSoldProductsCSV(
  arg: SerializableReq<GetSoldProductsCSVReq>
): UseQueryResult<GetSoldProductsCSVRes, Error> {
  return useSearchalytics("getSoldProductsCSV", "sold-products-csv", arg, true)
}

export function useSearchalyticsGetSalesByCustomer(
  arg: SerializableReq<GetSalesByCustomerReq>,
  skip?: boolean
): UseQueryResult<GetSalesByCustomerRes, Error> {
  return useSearchalytics("getSalesByCustomer", "sales-by-customer", arg, skip)
}

export function useLazySearchalyticsGetSalesByCustomerPDF(
  arg: SerializableReq<GetSalesByCustomerPDFReq>
): UseQueryResult<GetSalesByCustomerPDFRes, Error> {
  return useSearchalytics(
    "getSalesByCustomerPDF",
    "sales-by-customer-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetSalesByCustomerCSV(
  arg: SerializableReq<GetSalesByCustomerCSVReq>
): UseQueryResult<GetSalesByCustomerCSVRes, Error> {
  return useSearchalytics(
    "getSalesByCustomerCSV",
    "sales-by-customer-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetSalesByProduct(
  arg: SerializableReq<GetSalesByProductReq>,
  skip?: boolean
): UseQueryResult<GetSalesByProductRes, Error> {
  return useSearchalytics("getSalesByProduct", "sales-by-product", arg, skip)
}

export function useLazySearchalyticsGetSalesByProductPDF(
  arg: SerializableReq<GetSalesByProductPDFReq>
): UseQueryResult<GetSalesByProductPDFRes, Error> {
  return useSearchalytics(
    "getSalesByProductPDF",
    "sales-by-product-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetSalesByProductCSV(
  arg: SerializableReq<GetSalesByProductCSVReq>
): UseQueryResult<GetSalesByProductCSVRes, Error> {
  return useSearchalytics(
    "getSalesByProductCSV",
    "sales-by-product-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetProfitAndLoss(
  arg: SerializableReq<GetProfitAndLossReq>,
  skip?: boolean
): UseQueryResult<GetProfitAndLossRes, Error> {
  return useSearchalytics("getProfitAndLoss", "profit-and-loss", arg, skip)
}

export function useLazySearchalyticsGetProfitAndLossPDF(
  arg: SerializableReq<GetProfitAndLossPDFReq>
): UseQueryResult<GetProfitAndLossPDFRes, Error> {
  return useSearchalytics(
    "getProfitAndLossPDF",
    "profit-and-loss-pdf",
    arg,
    true
  )
}

export function useSearchalyticsGetCustomerAging(
  arg: SerializableReq<GetCustomerAgingReq>,
  skip?: boolean
): UseQueryResult<GetCustomerAgingRes, Error> {
  return useSearchalytics("getCustomerAging", "customer-aging", arg, skip)
}

export function useLazySearchalyticsGetCustomerAgingPDF(
  arg: SerializableReq<GetCustomerAgingPDFReq>
): UseQueryResult<GetCustomerAgingPDFRes, Error> {
  return useSearchalytics(
    "getCustomerAgingPDF",
    "customer-aging-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetCustomerAgingCSV(
  arg: SerializableReq<GetCustomerAgingCSVReq>
): UseQueryResult<GetCustomerAgingCSVRes, Error> {
  return useSearchalytics(
    "getCustomerAgingCSV",
    "customer-aging-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetPriceEdits(
  arg: SerializableReq<GetPriceEditsReq>,
  skip?: boolean
): UseQueryResult<GetPriceEditsRes, Error> {
  return useSearchalytics("getPriceEdits", "price-edits", arg, skip)
}

export function useLazySearchalyticsGetPriceEditsPDF(
  arg: SerializableReq<GetPriceEditsPDFReq>
): UseQueryResult<GetPriceEditsPDFRes, Error> {
  return useSearchalytics("getPriceEditsPDF", "price-edits-pdf", arg, true)
}

export function useLazySearchalyticsGetPriceEditsCSV(
  arg: SerializableReq<GetPriceEditsCSVReq>
): UseQueryResult<GetPriceEditsCSVRes, Error> {
  return useSearchalytics("getPriceEditsCSV", "price-edits-csv", arg, true)
}

export function useSearchalyticsGetNewAccountsOpened(
  arg: SerializableReq<GetNewAccountsOpenedReq>,
  skip?: boolean
): UseQueryResult<GetNewAccountsOpenedRes, Error> {
  return useSearchalytics(
    "getNewAccountsOpened",
    "new-accounts-opened",
    arg,
    skip
  )
}

export function useLazySearchalyticsGetNewAccountsOpenedPDF(
  arg: SerializableReq<GetNewAccountsOpenedPDFReq>
): UseQueryResult<GetNewAccountsOpenedPDFRes, Error> {
  return useSearchalytics(
    "getNewAccountsOpenedPDF",
    "new-accounts-opened-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetNewAccountsOpenedCSV(
  arg: SerializableReq<GetNewAccountsOpenedCSVReq>
): UseQueryResult<GetNewAccountsOpenedCSVRes, Error> {
  return useSearchalytics(
    "getNewAccountsOpenedCSV",
    "new-accounts-opened-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetAgingDetail(
  arg: SerializableReq<GetAgingDetailReq>,
  skip?: boolean
): UseQueryResult<GetAgingDetailRes, Error> {
  return useSearchalytics("getAgingDetail", "aging-detail", arg, skip)
}

export function useLazySearchalyticsGetAgingDetailPDF(
  arg: SerializableReq<GetAgingDetailPDFReq>
): UseQueryResult<GetAgingDetailPDFRes, Error> {
  return useSearchalytics("getAgingDetailPDF", "aging-detail-pdf", arg, true)
}

export function useLazySearchalyticsGetAgingDetailCSV(
  arg: SerializableReq<GetAgingDetailCSVReq>
): UseQueryResult<GetAgingDetailCSVRes, Error> {
  return useSearchalytics("getAgingDetailCSV", "aging-detail-csv", arg, true)
}

export function useSearchalyticsGetPurchaseJournal(
  arg: SerializableReq<GetPurchaseJournalReq>,
  skip?: boolean
): UseQueryResult<GetPurchaseJournalRes, Error> {
  return useSearchalytics("getPurchaseJournal", "purchase-journal", arg, skip)
}

export function useLazySearchalyticsGetPurchaseJournalPDF(
  arg: SerializableReq<GetPurchaseJournalPDFReq>
): UseQueryResult<GetPurchaseJournalPDFRes, Error> {
  return useSearchalytics(
    "getPurchaseJournalPDF",
    "purchase-journal-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetPurchaseJournalCSV(
  arg: SerializableReq<GetPurchaseJournalCSVReq>
): UseQueryResult<GetPurchaseJournalCSVRes, Error> {
  return useSearchalytics(
    "getPurchaseJournalCSV",
    "purchase-journal-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetAccountPaymentAdjustments(
  arg: SerializableReq<GetAccountPaymentAdjustmentsReq>,
  skip?: boolean
): UseQueryResult<GetAccountPaymentAdjustmentsRes, Error> {
  return useSearchalytics(
    "getAccountPaymentAdjustments",
    "account-payment-adjustments",
    arg,
    skip
  )
}

export function useLazySearchalyticsGetAccountPaymentAdjustmentsPDF(
  arg: SerializableReq<GetAccountPaymentAdjustmentsPDFReq>
): UseQueryResult<GetAccountPaymentAdjustmentsPDFRes, Error> {
  return useSearchalytics(
    "getAccountPaymentAdjustmentsPDF",
    "account-payment-adjustments-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetAccountPaymentAdjustmentsCSV(
  arg: SerializableReq<GetAccountPaymentAdjustmentsCSVReq>
): UseQueryResult<GetAccountPaymentAdjustmentsCSVRes, Error> {
  return useSearchalytics(
    "getAccountPaymentAdjustmentsCSV",
    "account-payment-adjustments-csv",
    arg,
    true
  )
}

export function useSearchalyticsGetAdditionalFees(
  arg: SerializableReq<GetAdditionalFeesReq>,
  skip?: boolean
): UseQueryResult<GetAdditionalFeesRes, Error> {
  return useSearchalytics("getAdditionalFees", "additional-fees", arg, skip)
}

export function useLazySearchalyticsGetAdditionalFeesPDF(
  arg: SerializableReq<GetAdditionalFeesPDFReq>
): UseQueryResult<GetAdditionalFeesPDFRes, Error> {
  return useSearchalytics(
    "getAdditionalFeesPDF",
    "additional-fees-pdf",
    arg,
    true
  )
}

export function useLazySearchalyticsGetAdditionalFeesCSV(
  arg: SerializableReq<GetAdditionalFeesCSVReq>
): UseQueryResult<GetAdditionalFeesCSVRes, Error> {
  return useSearchalytics(
    "getAdditionalFeesCSV",
    "additional-fees-csv",
    arg,
    true
  )
}
