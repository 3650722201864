import { Combobox, ComboboxProps } from "components/Combobox"

import { ProductCategory } from "gen/company/models_pb"
import { UUID } from "uuid-rd"
import { useListProductCategoriesForCompanyV2Query } from "features/api/company"

type CategoryType = "department" | "class" | "fineline"

type CategoryComboboxProps = Omit<
  ComboboxProps<ProductCategory>,
  "items" | "keyExtractor" | "valueExtractor"
> & {
  type: CategoryType
  isBold?: boolean
  inputClassName?: string
  enableClear?: boolean
}

const CLEAR_CATEGORY_SELECTION = "Clear selection"

export function CategoryCombobox({
  enableClear = false,
  onSelectItem,
  ...props
}: CategoryComboboxProps) {
  const { data: listCategoriesData } =
    useListProductCategoriesForCompanyV2Query({
      filterActive: true,
    })
  let items: ProductCategory[] = []
  switch (props.type) {
    case "department":
      items = [...(listCategoriesData?.departments ?? [])]
      break
    case "class":
      items = [...(listCategoriesData?.classes ?? [])]
      break
    case "fineline":
      items = [...(listCategoriesData?.finelines ?? [])]
      break
    default:
      throw new Error(`Unknown category type: ${props.type}`)
  }
  items.sort((a, b) => a.name.localeCompare(b.name))
  if (enableClear) {
    items.unshift(ProductCategory.create({ name: CLEAR_CATEGORY_SELECTION }))
  }

  const handleSelectItem = (category: ProductCategory | null) => {
    if (category?.name === CLEAR_CATEGORY_SELECTION) {
      onSelectItem(null)
    } else {
      onSelectItem(category)
    }
  }

  return (
    <Combobox<ProductCategory>
      items={items}
      keyExtractor={(category) =>
        category.id ? UUID.fromPB(category.id).toString() : ""
      }
      onSelectItem={handleSelectItem}
      valueExtractor={(category) => category.name}
      isBold={props.isBold}
      className="w-full"
      optionsClassName="z-50"
      inputClassName={props.inputClassName}
      {...props}
    />
  )
}
