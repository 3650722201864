import { Button } from "components/Button"
import { EnterIDLabel } from "./EnterIDLabel"
import { ErrorMessage } from "./ErrorMessage"
import { LocationsCombobox } from "./LocationsCombobox"
import { SignInViaTextMessageButton } from "./SignInViaTextMessageButton"
import { SingleCharacterInput } from "components/SingleCharacterInput"
import { useStaffExternalIDForm } from "./useStaffExternalIDForm"

interface StepKeyIDProps {
  onSubmit: (staffExternalID: string) => void
  isSubmitting: boolean
  errorMessage: string | null
}

export function StepKeyID(props: StepKeyIDProps) {
  const { onSubmit, isSubmitting, errorMessage } = props

  const {
    staffExternalID,
    onChangeStaffExternalID,
    onKeyDownStaffExternalID,
    handleSubmit,
  } = useStaffExternalIDForm(onSubmit)

  return (
    <>
      <LocationsCombobox />
      <EnterIDLabel />
      <div className="flex flex-col gap-y-2">
        <SingleCharacterInput
          className="self-center"
          value={staffExternalID}
          onKeyDown={onKeyDownStaffExternalID}
          onChange={onChangeStaffExternalID}
          isLoading={isSubmitting}
          autoFocus
        />
        <ErrorMessage message={errorMessage} />
      </div>
      <Button mode="primary" onClick={handleSubmit} disabled={!staffExternalID}>
        Scan key
      </Button>
      <SignInViaTextMessageButton />
    </>
  )
}
