import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { popoverClassName, popoverPanelAnchor } from "../Popover"

import { FilterButton } from "./FilterButton/FilterButton"
import { FilterHeaderRow } from "./FilterHeaderRow"
import { FilteredCombobox } from "../Combobox/FilteredCombobox/FilteredCombobox"
import { FullInterval } from "lib/dates/interval"
import { IntervalPicker } from "../IntervalPicker/IntervalPicker"
import { MultiCombobox } from "../MultiCombobox/MultiCombobox"
import { Preset } from "lib/dates/presets"
import { classNames } from "lib/classNames"

interface BaseFilterT {
  key: string
  value: string
  onClear: () => void
}

export const FilterType = {
  Text: "text",
  Date: "date",
} as const
export type FilterType = (typeof FilterType)[keyof typeof FilterType]

export interface TextFilterT extends BaseFilterT {
  type: typeof FilterType.Text
  options: OptionT[]
  selectedOptionKeys: Set<string>
  onSelectOption: (option: OptionT) => void
  autoFilterOptions?: boolean
}

interface DateFilterT extends BaseFilterT {
  type: typeof FilterType.Date
  defaultPreset: Preset
  presetsToDisplay: Preset[]
  selectedDateRange: FullInterval | null
  onSelectDateRange: (dateRange: FullInterval) => void
}

export type FilterT = TextFilterT | DateFilterT

export interface OptionT {
  key: string
  value: string
}

export interface FilterProps {
  filters: FilterT[]
  selectedFilter: FilterT | null
  onSelectFilterKey: (key: string) => void
  onDeleteFilter: () => void
}

export function Filter(props: FilterProps) {
  const popoverPanelClassName = classNames(popoverClassName, "w-fit")

  return (
    <Popover>
      <PopoverButton as={FilterButton} filter={props.selectedFilter} />
      <PopoverPanel
        anchor={popoverPanelAnchor}
        className={popoverPanelClassName}
        focus
      >
        {({ close }) => <FilterPanel {...props} close={close} />}
      </PopoverPanel>
    </Popover>
  )
}

interface FilterPanelProps extends FilterProps {
  close: () => void
}

function FilterPanel({
  selectedFilter,
  onSelectFilterKey,
  onDeleteFilter,
  filters,
  close,
}: FilterPanelProps) {
  if (!selectedFilter) {
    return (
      <FilteredCombobox
        items={filters}
        keyExtractor={(filter) => filter.key}
        valueExtractor={(filter) => filter.value}
        onSelect={(filter) => onSelectFilterKey(filter.key)}
        comboboxProps={{
          comboboxInputProps: {
            placeholder: "Filter by...",
          },
        }}
      />
    )
  }

  switch (selectedFilter.type) {
    case "text":
      return (
        <div>
          <FilterHeaderRow
            label={selectedFilter.value}
            onClickDelete={() => {
              onDeleteFilter()
              close()
            }}
          />
          <MultiCombobox
            keyExtractor={(option) => option.key}
            items={selectedFilter.options}
            valueExtractor={(option) => option.value}
            onSelect={selectedFilter.onSelectOption}
            selectedItemKeys={selectedFilter.selectedOptionKeys}
            autoFilter={selectedFilter.autoFilterOptions}
          />
        </div>
      )
    case "date":
      return (
        <IntervalPicker
          publishedInterval={selectedFilter.selectedDateRange}
          onChangePublishedInterval={(range) => {
            if (!range) {
              onDeleteFilter()
            } else {
              selectedFilter.onSelectDateRange(range)
            }
            close()
          }}
          presetsToDisplay={selectedFilter.presetsToDisplay}
        />
      )
  }
}
