import { FullInterval, PartialInterval } from "./interval"
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns"

type PresetId =
  | "today"
  | "yesterday"
  | "wtd"
  | "last-week"
  | "mtd"
  | "last-month"
  | "ytd"
  | "last-year"
  | "all-time"

const presetPriorityOrder: Record<PresetId, number> = {
  today: 9,
  yesterday: 8,
  wtd: 7,
  "last-week": 6,
  mtd: 5,
  "last-month": 4,
  ytd: 3,
  "last-year": 2,
  "all-time": 1,
}

export class Preset {
  readonly id: PresetId
  readonly displayText: string
  readonly rangeFunc: () => FullInterval | null
  constructor({
    id,
    displayText,
    rangeFunc,
  }: {
    id: PresetId
    displayText: string
    rangeFunc: () => FullInterval | null
  }) {
    this.id = id
    this.displayText = displayText
    this.rangeFunc = rangeFunc
  }

  matches(interval: PartialInterval): boolean {
    if (!interval || !interval.isValid()) return false
    if (this.id == "all-time") return false // we currently never match "all time"
    return interval.isEqual(this.rangeFunc())
  }
}

export function matchingPreset(
  presets: Preset[],
  interval: PartialInterval | null
): Preset | null {
  if (!interval) {
    return null
  }
  return (
    presets
      .toSorted((a, b) => presetPriorityOrder[a.id] - presetPriorityOrder[b.id])
      .find((preset) => preset.rangeFunc()?.isEqual(interval)) ?? null
  )
}

export function listDateRangePresets(includeAllTime: boolean): Preset[] {
  return dateRangePresets.filter((preset) =>
    includeAllTime ? true : preset.id !== "all-time"
  )
}

export const TODAY_PRESET = new Preset({
  id: "today",
  displayText: "Today",
  rangeFunc: () =>
    new FullInterval({
      start: startOfToday(),
      end: endOfToday(),
    }),
})
const YESTERDAY_PRESET = new Preset({
  id: "yesterday",
  displayText: "Yesterday",
  rangeFunc: () =>
    new FullInterval({
      start: startOfYesterday(),
      end: endOfYesterday(),
    }),
})
const WEEK_TO_DATE_PRESET = new Preset({
  id: "wtd",
  displayText: "Week to date",
  rangeFunc: () =>
    new FullInterval({
      start: startOfWeek(new Date()),
      end: endOfToday(),
    }),
})
const LAST_WEEK_PRESET = new Preset({
  id: "last-week",
  displayText: "Last week",
  rangeFunc: () => {
    const dayLastWeek = subWeeks(new Date(), 1)
    return new FullInterval({
      start: startOfWeek(dayLastWeek),
      end: endOfWeek(dayLastWeek),
    })
  },
})
const MONTH_TO_DATE_PRESET = new Preset({
  id: "mtd",
  displayText: "Month to date",
  rangeFunc: () =>
    new FullInterval({
      start: startOfMonth(new Date()),
      end: endOfToday(),
    }),
})
const LAST_MONTH_PRESET = new Preset({
  id: "last-month",
  displayText: "Last month",
  rangeFunc: () => {
    const dayLastMonth = subMonths(new Date(), 1)
    return new FullInterval({
      start: startOfMonth(dayLastMonth),
      end: endOfMonth(dayLastMonth),
    })
  },
})
const YEAR_TO_DATE_PRESET = new Preset({
  id: "ytd",
  displayText: "Year to date",
  rangeFunc: () =>
    new FullInterval({
      start: startOfYear(new Date()),
      end: endOfToday(),
    }),
})
const LAST_YEAR_PRESET = new Preset({
  id: "last-year",
  displayText: "Last year",
  rangeFunc: () => {
    const dayLastYear = subYears(new Date(), 1)
    return new FullInterval({
      start: startOfYear(dayLastYear),
      end: endOfYear(dayLastYear),
    })
  },
})
const ALL_TIME_PRESET = new Preset({
  id: "all-time",
  displayText: "All time",
  rangeFunc: () => null,
})

const dateRangePresets: Preset[] = [
  TODAY_PRESET,
  YESTERDAY_PRESET,
  WEEK_TO_DATE_PRESET,
  LAST_WEEK_PRESET,
  MONTH_TO_DATE_PRESET,
  LAST_MONTH_PRESET,
  YEAR_TO_DATE_PRESET,
  LAST_YEAR_PRESET,
  ALL_TIME_PRESET,
]
