const storage_key = "rundoo_pubkeyhistory"
const max_storage_size = 50

// PubkeyHistory manages a list of recent successful pubkey logins in local
// storage. Ids are stored as url-encoded strings: they are unambiguous (no
// padding) and the WebAuthn spec says to use this encoding. However, Stytch
// sends std-encoded strings, so this class handles both by converting
// std-encoded strings to url-encoded ones.
export class PubkeyHistory {
  // empty clears the stored list of pubkeys.
  static empty() {
    localStorage.removeItem(storage_key)
  }

  // listKeys returns the stored list of pubkeys.
  static listKeys(): string[] {
    const keys: string[] = JSON.parse(localStorage[storage_key] ?? "[]")
    if (typeof keys !== "object") {
      return []
    }
    return keys
  }

  // addKey stores a successful login s in the list of pubkeys, moving it to
  // the most recent slot if it already exists. s is assumed to be a valid
  // std-encoded or url-encoded base64 string and will always be stored as
  // url-encoded.
  static addKey(s: string) {
    const enc = urlencode(s)
    const keys = PubkeyHistory.listKeys()
    localStorage[storage_key] = JSON.stringify(
      [enc].concat(keys.filter((v) => v !== enc)).slice(0, max_storage_size)
    )
  }

  // sortByRecent returns pubkeys converted to url-encoded base64 strings and
  // sorted by their most recent successful login, with those that have never
  // been used appearing last. If max>0, the returned list is truncated to at
  // most max values.
  static sortByRecent(pubkeys: string[], max: number): string[] {
    const keys = PubkeyHistory.listKeys()
    const res = pubkeys
      .map((v) => urlencode(v))
      .sort((a, b) => {
        const ai = keys.indexOf(a)
        const bi = keys.indexOf(b)
        if (ai == -1 && bi == -1) {
          return a.localeCompare(b)
        }
        if (ai == -1) {
          return 1
        }
        if (bi == -1) {
          return -1
        }
        return ai - bi
      })

    if (max < 1) {
      return res
    }
    return res.slice(0, max)
  }
}

// urlencode converts s, which is assumed to be either url- or std-encoded,
// into a url-encoded string by converting '+' to '-', '/' to '_', and removing
// any padding if present.
function urlencode(s: string): string {
  return s.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "")
}
