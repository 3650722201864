import { HTMLAttributes } from "react"
import { classNames } from "lib/classNames"

type CheckboxLabelledRowProps = HTMLAttributes<HTMLDivElement>

export function CheckboxLabelledRow({
  className,
  ...otherProps
}: CheckboxLabelledRowProps) {
  return (
    <div
      className={classNames(
        "text-primary flex items-center gap-2 text-sm",
        className
      )}
      {...otherProps}
    />
  )
}
