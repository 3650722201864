/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "txn/models.proto" (package "rd.txn", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ManuallyEnteredCard } from "../payments/models_pb";
import { TimestampRange } from "../proto/timestamprange/models_pb";
import { Product } from "../product/models_pb";
import { Contacts_Contact as Contacts_Contact$ } from "../vendors/models_pb";
import { Vendor } from "../vendors/models_pb";
import { Card } from "../payments/models_pb";
import { CashRegister } from "../company/models_pb";
import { TintColor } from "../tintcolor/models_pb";
import { Barcode } from "../pdf/barcode/models_pb";
import { CardPaymentReceiptInfo } from "../payments/models_pb";
import { TaxRate } from "../company/models_pb";
import { Location } from "../company/models_pb";
import { Company } from "../company/models_pb";
import { JobSite } from "../customer/models_pb";
import { PurchaseOrder } from "../customer/models_pb";
import { Contacts_Contact } from "../customer/models_pb";
import { Customer } from "../customer/models_pb";
import { FractionalMoney } from "../proto/money/models_pb";
import { Money } from "../proto/money/models_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Sale represents a transaction in which a supplier sells
 * a product or products to a customer.
 *
 * @generated from protobuf message rd.txn.Sale
 */
export interface Sale {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_job_site_id = 6;
     */
    customerJobSiteId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_purchase_order_id = 7;
     */
    customerPurchaseOrderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 8;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.user.User created_by = 9;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User quoted_by = 10;
     */
    quotedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User sold_by = 11;
     */
    soldBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 12;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User voided_by = 13;
     */
    voidedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User will_called_by = 14;
     */
    willCalledBy?: User;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp quoted_at = 15;
     */
    quotedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp quote_expires_at = 16;
     */
    quoteExpiresAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp will_called_at = 17;
     */
    willCalledAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp voided_at = 18;
     */
    voidedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sold_at = 19;
     */
    soldAt?: Timestamp;
    /**
     * @generated from protobuf field: string identifier = 20;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.Sale.Status status = 21;
     */
    status: Sale_Status;
    /**
     * @generated from protobuf field: string sale_notes = 22;
     */
    saleNotes: string;
    /**
     * @generated from protobuf field: repeated rd.txn.Sale.Return returns = 25;
     */
    returns: Sale_Return[]; // append-only
    /**
     * @generated from protobuf field: string customer_contact_id_string = 26;
     */
    customerContactIdString: string;
    /**
     * @generated from protobuf field: string signature_url = 27;
     */
    signatureUrl: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal tax_rate = 28;
     */
    taxRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tax_rate_id = 29;
     */
    taxRateId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 30;
     */
    subtotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 31;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total = 32;
     */
    adjustedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 33;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 34;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 35;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_early_payment_discount_adjustment = 36;
     */
    totalEarlyPaymentDiscountAdjustment?: Money; // positive
}
/**
 * @generated from protobuf message rd.txn.Sale.Return
 */
export interface Sale_Return {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp returned_at = 2;
     */
    returnedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User returned_by = 3;
     */
    returnedBy?: User;
    /**
     * @generated from protobuf field: repeated rd.txn.SaleProduct sale_products_returned = 4;
     */
    saleProductsReturned: SaleProduct[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 5;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 6;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 7;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 8;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 9;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_job_site_id = 10;
     */
    customerJobSiteId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_purchase_order_id = 11;
     */
    customerPurchaseOrderId?: UUID;
    /**
     * @generated from protobuf field: string notes = 13;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 14;
     */
    subtotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 15;
     */
    total?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> additional_fees_by_name = 16;
     */
    additionalFeesByName: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 17;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 18;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax = 19;
     */
    salesTax?: Money;
    /**
     * @generated from protobuf field: string contact_id_string = 20;
     */
    contactIdString: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money rounding_adjustment = 21;
     */
    roundingAdjustment?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money early_payment_discount_adjustment = 22;
     */
    earlyPaymentDiscountAdjustment?: Money; // positive
}
/**
 * @generated from protobuf enum rd.txn.Sale.Status
 */
export enum Sale_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * @generated from protobuf enum value: STATUS_QUOTED = 2;
     */
    QUOTED = 2,
    /**
     * @generated from protobuf enum value: STATUS_WILL_CALLED = 3;
     */
    WILL_CALLED = 3,
    /**
     * @generated from protobuf enum value: STATUS_VOIDED = 4;
     */
    VOIDED = 4,
    /**
     * @generated from protobuf enum value: STATUS_SOLD = 5;
     */
    SOLD = 5,
    /**
     * @generated from protobuf enum value: STATUS_PAID = 6;
     */
    PAID = 6
}
/**
 * ReturnIdentifierMapping stores return identifier assignments, one row per identifier.
 * the id is derived from the company id and the identifier string.
 *
 * @generated from protobuf message rd.txn.ReturnIdentifierMapping
 */
export interface ReturnIdentifierMapping {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 2;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 3;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 4;
     */
    identifier: string;
}
/**
 * SaleLog represents a change of a Sale.
 *
 * @generated from protobuf message rd.txn.SaleLog
 */
export interface SaleLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 4;
     */
    sale?: Sale;
}
/**
 * SaleProduct represents a product that has been sold as part of a sale
 * from a company to a customer. The is_active field being false indicates the
 * the product was removed from a non-terminal sale. The created_at, sold_at,
 * and location_id are particularly useful for simplifying the product sales
 * analytics query.
 *
 * @generated from protobuf message rd.txn.SaleProduct
 */
export interface SaleProduct {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 2;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 3;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID tint_color_id = 6;
     */
    tintColorId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity = 7;
     */
    quantity: number;
    /**
     * @generated from protobuf field: int32 quantity_returned = 8;
     */
    quantityReturned: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 9;
     */
    price?: Money; // per item
    /**
     * @generated from protobuf field: string additional_fee_name = 10;
     */
    additionalFeeName: string;
    /**
     * @generated from protobuf field: string notes = 12;
     */
    notes: string;
    /**
     * @generated from protobuf field: string custom_tint_color = 13;
     */
    customTintColor: string;
    /**
     * @generated from protobuf field: int32 position = 14;
     */
    position: number; // 1-indexed
    /**
     * @generated from protobuf field: bool is_price_edited = 15;
     */
    isPriceEdited: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money tier_1_price = 16;
     */
    tier1Price?: Money; // per item
    /**
     * @generated from protobuf field: bool is_taxed = 17;
     */
    isTaxed: boolean;
    /**
     * @generated from protobuf field: bool is_fee_taxed = 18;
     */
    isFeeTaxed: boolean;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID colorx_tinted_product_id = 19;
     */
    colorxTintedProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 20;
     */
    costV2?: FractionalMoney; // per item
    /**
     * @generated from protobuf field: bool is_active = 21;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 22;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 23;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sold_at = 24;
     */
    soldAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 25;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: bool is_cost_edited = 26;
     */
    isCostEdited: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney additional_fee_v2 = 27;
     */
    additionalFeeV2?: FractionalMoney; // per item
}
/**
 * SaleProductLog represents a change to a SaleProduct.
 *
 * @generated from protobuf message rd.txn.SaleProductLog
 */
export interface SaleProductLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.txn.SaleProduct sale_product = 4;
     */
    saleProduct?: SaleProduct;
}
/**
 * SalePDFData represents the data required to fill the html
 * template for a sale
 *
 * @generated from protobuf message rd.txn.SalePDFData
 */
export interface SalePDFData {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact primary_contact = 5;
     */
    primaryContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.user.User last_action_staff = 6;
     */
    lastActionStaff?: User;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_action_time = 7;
     */
    lastActionTime?: Timestamp;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 8;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 9;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: rd.company.Company company = 10;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 11;
     */
    location?: Location;
    /**
     * @generated from protobuf field: repeated rd.txn.SalePDFData.Product products = 12;
     */
    products: SalePDFData_Product[];
    /**
     * @generated from protobuf field: rd.proto.money.Money sub_total = 14;
     */
    subTotal?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> fee_totals_by_name = 15;
     */
    feeTotalsByName: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax_total = 16;
     */
    salesTaxTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 17;
     */
    total?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_at = 18;
     */
    dueAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money remaining_balance = 19;
     */
    remainingBalance?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 20;
     */
    expiresAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 21;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 23;
     */
    cashReceived?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_change = 24;
     */
    cashChange?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money refunded_total = 25;
     */
    refundedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total = 26;
     */
    adjustedTotal?: Money;
    /**
     * @generated from protobuf field: bool show_signature_line = 27;
     */
    showSignatureLine: boolean;
    /**
     * @generated from protobuf field: repeated rd.txn.SalePDFData.PurchaseMethod purchase_methods_without_cash = 28;
     */
    purchaseMethodsWithoutCash: SalePDFData_PurchaseMethod[];
    /**
     * @generated from protobuf field: bool show_cash_refunded = 29;
     */
    showCashRefunded: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money bill_payments_total = 30;
     */
    billPaymentsTotal?: Money;
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 31;
     */
    taxRate?: TaxRate;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 33;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: string card_surcharge_rate_string = 34;
     */
    cardSurchargeRateString: string;
    /**
     * @generated from protobuf field: repeated rd.payments.CardPaymentReceiptInfo receipt_info = 35;
     */
    receiptInfo: CardPaymentReceiptInfo[];
    /**
     * @generated from protobuf field: rd.pdf.Barcode barcode = 36;
     */
    barcode?: Barcode;
    /**
     * @generated from protobuf field: bool show_prices = 37;
     */
    showPrices: boolean;
}
/**
 * @generated from protobuf message rd.txn.SalePDFData.Product
 */
export interface SalePDFData_Product {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.tintcolor.TintColor tint_color = 4;
     */
    tintColor?: TintColor;
    /**
     * @generated from protobuf field: rd.proto.money.Money sub_total = 5;
     */
    subTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money returned_sub_total = 6;
     */
    returnedSubTotal?: Money;
    /**
     * @generated from protobuf field: string custom_tint_color = 9;
     */
    customTintColor: string;
    /**
     * @generated from protobuf field: string notes = 10;
     */
    notes: string;
    /**
     * @generated from protobuf field: int32 quantity = 11;
     */
    quantity: number;
    /**
     * @generated from protobuf field: string price = 12;
     */
    price: string;
    /**
     * @generated from protobuf field: bool show_tier_1_price = 13;
     */
    showTier1Price: boolean;
    /**
     * @generated from protobuf field: string tier_1_price = 14;
     */
    tier1Price: string;
    /**
     * @generated from protobuf field: bool show_quantity_returned = 15;
     */
    showQuantityReturned: boolean;
    /**
     * @generated from protobuf field: int32 quantity_returned = 16;
     */
    quantityReturned: number;
}
/**
 * @generated from protobuf message rd.txn.SalePDFData.PurchaseMethod
 */
export interface SalePDFData_PurchaseMethod {
    /**
     * @generated from protobuf field: string method = 1;
     */
    method: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 2;
     */
    amount?: Money;
}
/**
 * LedgerEntry represents a monetary transaction on a set of sales, either
 * payments or refunds. Total is non-duplicative because it may be greater than
 * the sum of values of allocations by sale, resulting in an account credit.
 * NOTE: This model is migrating to rd/ledger/models.proto. Please keep this
 * model in sync with the ledger model for the existing fields and model
 *
 * @generated from protobuf message rd.txn.LedgerEntry
 */
export interface LedgerEntry {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID; // for job accounts, this is the parent customer id
    /**
     * @generated from protobuf field: rd.user.User created_by = 6;
     */
    createdBy?: User;
    /**
     * Amount is the dollar amount being transacted.
     * the sign is from the customer's balance perspective, i.e. how much they
     * owe:
     * - Purchases, Refunds, Reverse Discounts & Financing Charges are positive (increasing balance).
     * - Write Offs, Returns, Discounts & Payments are negative (decreasing balance).
     * - Allocations have zero amount and only record the allocation of payments to charges.
     * - A discount ledger entry will be recorded when a discount action is performed.
     * - A reverse ledger entry will be recorded when a discount action is reversed
     *
     * @generated from protobuf field: rd.proto.money.Money amount = 7;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Type type = 8;
     */
    type: LedgerEntry_Type;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Purchase purchase = 9;
     */
    purchase?: LedgerEntry_Purchase;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Return return = 10;
     */
    return?: LedgerEntry_Return;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Payment payment = 11;
     */
    payment?: LedgerEntry_Payment;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.WriteOff write_off = 12;
     */
    writeOff?: LedgerEntry_WriteOff;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Refund refund = 13;
     */
    refund?: LedgerEntry_Refund;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 14;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.PettyCash petty_cash = 15;
     */
    pettyCash?: LedgerEntry_PettyCash;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.CloseOut close_out = 16;
     */
    closeOut?: LedgerEntry_CloseOut;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Allocation allocation = 17;
     */
    allocation?: LedgerEntry_Allocation;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Discount discount = 18;
     */
    discount?: LedgerEntry_Discount;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.ReverseDiscount reverse_discount = 19;
     */
    reverseDiscount?: LedgerEntry_ReverseDiscount;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID job_id = 37;
     */
    jobId?: UUID; // customer id for job accounts
}
/**
 * a potential discount is used to record one type of discount that can be potentially applied to
 * the payment of a purchase.
 *
 * @generated from protobuf message rd.txn.LedgerEntry.PotentialDiscount
 */
export interface LedgerEntry_PotentialDiscount {
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.PotentialDiscount.Type type = 1;
     */
    type: LedgerEntry_PotentialDiscount_Type; // used to specify a discount type
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 3;
     */
    rate?: Decimal; // indicate the discount rate
    /**
     * @generated from protobuf field: string term_name = 4;
     */
    termName: string; // the name of a discount term
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 5;
     */
    expiresAt?: Timestamp; // specify when the discount expires
}
/**
 * for discount type
 *
 * @generated from protobuf enum rd.txn.LedgerEntry.PotentialDiscount.Type
 */
export enum LedgerEntry_PotentialDiscount_Type {
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_UNDEFINED = 0;
     */
    DISCOUNT_TYPE_UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_EARLY_FULL_PAYMENT = 1;
     */
    DISCOUNT_TYPE_EARLY_FULL_PAYMENT = 1
}
/**
 * Purchases are giving inventory to customers
 *
 * @generated from protobuf message rd.txn.LedgerEntry.Purchase
 */
export interface LedgerEntry_Purchase {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_at = 2;
     */
    dueAt?: Timestamp;
    /**
     * @generated from protobuf field: repeated rd.txn.LedgerEntry.PotentialDiscount potential_discounts = 3;
     */
    potentialDiscounts: LedgerEntry_PotentialDiscount[]; // the discounts that could be applied to payments
}
/**
 * Returns are customers giving inventory back
 *
 * @generated from protobuf message rd.txn.LedgerEntry.Return
 */
export interface LedgerEntry_Return {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 2;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: int64 return_index = 3;
     */
    returnIndex: bigint; // index of return in Sale.Returns
}
/**
 * Payments are monetary payments from customers
 *
 * @generated from protobuf message rd.txn.LedgerEntry.Payment
 */
export interface LedgerEntry_Payment {
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Payment.Method method = 1;
     */
    method: LedgerEntry_Payment_Method;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> purchase_allocations_by_id = 2;
     */
    purchaseAllocationsById: {
        [key: string]: Money;
    }; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 3;
     */
    financingChargeAllocation?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 4;
     */
    cashReceived?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 5;
     */
    stripePaymentIntentId: string;
    /**
     * @generated from protobuf field: string check_number = 6;
     */
    checkNumber: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 7;
     */
    billPaymentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_fee = 8;
     */
    cardFee?: Money;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 9;
     */
    cashRegister?: CashRegister;
    /**
     * @generated from protobuf field: rd.payments.Card card = 10;
     */
    card?: Card;
    /**
     * Sale payment: surcharge (and tax) are counted towards LE amount via purchase allocation
     * Bill payment: surcharge (and tax) are counted towards LE amount directly
     *
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 11;
     */
    cardSurcharge?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 12;
     */
    cardSurchargeTax?: Money; // same sign convention as amt
}
/**
 * @generated from protobuf enum rd.txn.LedgerEntry.Payment.Method
 */
export enum LedgerEntry_Payment_Method {
    /**
     * @generated from protobuf enum value: METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: METHOD_CHECK = 2;
     */
    CHECK = 2,
    /**
     * @generated from protobuf enum value: METHOD_CARD_MANUALLY_ENTERED = 3;
     */
    CARD_MANUALLY_ENTERED = 3,
    /**
     * @generated from protobuf enum value: METHOD_CARD_SAVED = 4;
     */
    CARD_SAVED = 4,
    /**
     * @generated from protobuf enum value: METHOD_CARD_PRESENT = 5;
     */
    CARD_PRESENT = 5,
    /**
     * @generated from protobuf enum value: METHOD_CARD_CUSTOMER_ENTERED = 6;
     */
    CARD_CUSTOMER_ENTERED = 6
}
/**
 * WriteOffs are stores forgiving a portion of a customer's balance
 *
 * @generated from protobuf message rd.txn.LedgerEntry.WriteOff
 */
export interface LedgerEntry_WriteOff {
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> purchase_allocations_by_id = 1;
     */
    purchaseAllocationsById: {
        [key: string]: Money;
    }; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 2;
     */
    financingChargeAllocation?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 3;
     */
    billPaymentId?: UUID;
}
/**
 * Refunds are monetary payments to customers
 *
 * @generated from protobuf message rd.txn.LedgerEntry.Refund
 */
export interface LedgerEntry_Refund {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 1;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID return_ledger_entry_id = 2;
     */
    returnLedgerEntryId?: UUID; // nil if bill payment refund
    /**
     * @generated from protobuf field: string stripe_refund_id = 3;
     */
    stripeRefundId: string;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Refund.Method method = 4;
     */
    method: LedgerEntry_Refund_Method;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 5;
     */
    cashRegister?: CashRegister;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 6;
     */
    cardSurcharge?: Money; // positive
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 7;
     */
    cardSurchargeTax?: Money; // positive
}
/**
 * @generated from protobuf enum rd.txn.LedgerEntry.Refund.Method
 */
export enum LedgerEntry_Refund_Method {
    /**
     * @generated from protobuf enum value: METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: METHOD_CARD = 2;
     */
    CARD = 2,
    /**
     * @generated from protobuf enum value: METHOD_CHECK = 3;
     */
    CHECK = 3
}
/**
 * Petty cash represents a deposit or withdrawal of cash to the register for company use.
 * Amount in Petty cash represents the monetary value of a withdrawal or a deposit.
 * +ve indicates a deposit -ve; -ve indicates a withdrawal
 *
 * @generated from protobuf message rd.txn.LedgerEntry.PettyCash
 */
export interface LedgerEntry_PettyCash {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID petty_cash_id = 1;
     */
    pettyCashId?: UUID;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 2;
     */
    cashRegister?: CashRegister;
}
/**
 * Close out represents a close out of the register in which total cash is counted.
 * Amount in Close out represents the amount over or short after a close out.
 * +ve indicates an over while -ve indicates a short
 *
 * @generated from protobuf message rd.txn.LedgerEntry.CloseOut
 */
export interface LedgerEntry_CloseOut {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID close_out_id = 1;
     */
    closeOutId?: UUID;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 2;
     */
    cashRegister?: CashRegister;
}
/**
 * @generated from protobuf message rd.txn.LedgerEntry.Allocation
 */
export interface LedgerEntry_Allocation {
    /**
     * @generated from protobuf field: rd.proto.money.Money allocation_amount = 1;
     */
    allocationAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 2;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 3;
     */
    purchaseLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID write_off_ledger_entry_id = 4;
     */
    writeOffLedgerEntryId?: UUID;
}
/**
 * @generated from protobuf message rd.txn.LedgerEntry.Discount
 */
export interface LedgerEntry_Discount {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID; // the associated purchase ledger entry id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_location_id = 3;
     */
    purchaseLocationId?: UUID; // the location where the related purchase occurs.
    /**
     * This is used for facilitating accounting.
     *
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 4;
     */
    billPaymentId?: UUID; // the bill payment that created the discount
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 5;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 6;
     */
    returnIdentifier: string; // identifier of return in Sale.Returns
}
/**
 * @generated from protobuf message rd.txn.LedgerEntry.ReverseDiscount
 */
export interface LedgerEntry_ReverseDiscount {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID; // the associated purchase ledger entry id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID discount_ledger_entry_id = 3;
     */
    discountLedgerEntryId?: UUID; // refer to the discount that need to be reversed
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_location_id = 5;
     */
    purchaseLocationId?: UUID; // the location where the related purchase occurs.
    /**
     * This is used for facilitating accounting.
     *
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 6;
     */
    billPaymentId?: UUID; // the bill payment refund that created the reverse discount
}
/**
 * @generated from protobuf enum rd.txn.LedgerEntry.Type
 */
export enum LedgerEntry_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_PURCHASE = 1;
     */
    PURCHASE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_PAYMENT = 3;
     */
    PAYMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_WRITE_OFF = 4;
     */
    WRITE_OFF = 4,
    /**
     * @generated from protobuf enum value: TYPE_REFUND = 5;
     */
    REFUND = 5,
    /**
     * @generated from protobuf enum value: TYPE_FINANCING_CHARGE = 6;
     */
    FINANCING_CHARGE = 6,
    /**
     * @generated from protobuf enum value: TYPE_PETTY_CASH = 7;
     */
    PETTY_CASH = 7,
    /**
     * @generated from protobuf enum value: TYPE_CLOSE_OUT = 8;
     */
    CLOSE_OUT = 8,
    /**
     * @generated from protobuf enum value: TYPE_DISCOUNT = 10;
     */
    DISCOUNT = 10,
    /**
     * @generated from protobuf enum value: TYPE_REVERSE_DISCOUNT = 11;
     */
    REVERSE_DISCOUNT = 11
}
/**
 * Order represents a purchase order or "PO" placed by
 * a supplier to order more inventory from a vendor.
 *
 * @generated from protobuf message rd.txn.Order
 */
export interface Order {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 4;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User ordered_by = 5;
     */
    orderedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User received_by = 6;
     */
    receivedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 7;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User voided_by = 8;
     */
    voidedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 9;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 10;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 11;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp requested_delivery_at = 12;
     */
    requestedDeliveryAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp ordered_at = 13;
     */
    orderedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp received_at = 14;
     */
    receivedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp voided_at = 15;
     */
    voidedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.txn.Order.Status status = 16;
     */
    status: Order_Status;
    /**
     * @generated from protobuf field: string identifier = 17;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string order_notes = 18;
     */
    orderNotes: string;
    /**
     * @generated from protobuf field: string internal_notes = 19;
     */
    internalNotes: string;
    /**
     * @generated from protobuf field: map<string, rd.txn.Order.Product> products_by_id = 20;
     */
    productsById: {
        [key: string]: Order_Product;
    };
    /**
     * @generated from protobuf field: rd.txn.Order.Type type = 21;
     */
    type: Order_Type;
}
/**
 * @generated from protobuf message rd.txn.Order.Product
 */
export interface Order_Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity_ordered = 2;
     */
    quantityOrdered: number;
    /**
     * @generated from protobuf field: int32 quantity_received = 4;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money additional_fee_when_received = 5;
     */
    additionalFeeWhenReceived?: Money;
    /**
     * @generated from protobuf field: int32 position = 6;
     */
    position: number;
    /**
     * @generated from protobuf field: string notes = 7;
     */
    notes: string;
    /**
     * @generated from protobuf field: string additional_fee_name_when_received = 8;
     */
    additionalFeeNameWhenReceived: string;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 9;
     */
    costV2?: FractionalMoney;
}
/**
 * @generated from protobuf enum rd.txn.Order.Status
 */
export enum Order_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * @generated from protobuf enum value: STATUS_ORDERED = 2;
     */
    ORDERED = 2,
    /**
     * @generated from protobuf enum value: STATUS_RECEIVED = 3;
     */
    RECEIVED = 3,
    /**
     * @generated from protobuf enum value: STATUS_VOIDED = 4;
     */
    VOIDED = 4
}
/**
 * @generated from protobuf enum rd.txn.Order.Type
 */
export enum Order_Type {
    /**
     * @generated from protobuf enum value: TYPE_EMAIL = 0;
     */
    EMAIL = 0,
    /**
     * @generated from protobuf enum value: TYPE_EDI = 1;
     */
    EDI = 1
}
/**
 * OrderLog represents a change of an Order.
 *
 * @generated from protobuf message rd.txn.OrderLog
 */
export interface OrderLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.txn.Order order = 4;
     */
    order?: Order;
}
/**
 * OrderShipment represents a partial or
 * full shipment of a purchase order.
 *
 * @generated from protobuf message rd.txn.OrderShipment
 */
export interface OrderShipment {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 2;
     */
    orderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 3;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: map<string, rd.txn.OrderShipment.Product> products_by_id = 6;
     */
    productsById: {
        [key: string]: OrderShipment_Product;
    };
    /**
     * @generated from protobuf field: int32 total_received = 7;
     */
    totalReceived: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp received_at = 8;
     */
    receivedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User received_by = 9;
     */
    receivedBy?: User;
    /**
     * @generated from protobuf field: string notes = 10;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 11;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 12;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 13;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.txn.OrderShipment.CostAdjustment cost_adjustments = 14;
     */
    costAdjustments: OrderShipment_CostAdjustment[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID financial_transaction_id = 15;
     */
    financialTransactionId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID external_transaction_id = 16;
     */
    externalTransactionId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp marked_paid_at = 17;
     */
    markedPaidAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User marked_paid_by = 18;
     */
    markedPaidBy?: User;
    /**
     * @generated from protobuf field: rd.txn.OrderShipment.Status status = 19;
     */
    status: OrderShipment_Status;
    /**
     * @generated from protobuf field: string pay_to = 20;
     */
    payTo: string;
    /**
     * @generated from protobuf field: rd.txn.InvoiceDetails invoice_details = 21;
     */
    invoiceDetails?: InvoiceDetails;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp vouchered_at = 22;
     */
    voucheredAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User vouchered_by = 23;
     */
    voucheredBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 24;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 25;
     */
    updatedAt?: Timestamp;
}
/**
 * @generated from protobuf message rd.txn.OrderShipment.Product
 */
export interface OrderShipment_Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity_received = 2;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: string additional_fee_name = 4;
     */
    additionalFeeName: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money additional_fee_amount = 5;
     */
    additionalFeeAmount?: Money;
    /**
     * @generated from protobuf field: int32 position = 6;
     */
    position: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 7;
     */
    costV2?: FractionalMoney;
}
/**
 * CostAdjustment can either be a fee or discount
 *
 * @generated from protobuf message rd.txn.OrderShipment.CostAdjustment
 */
export interface OrderShipment_CostAdjustment {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID chart_of_account_id = 1;
     */
    chartOfAccountId?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 3;
     */
    amount?: Money; // fee always (+) and discount always (-)
}
/**
 * @generated from protobuf enum rd.txn.OrderShipment.Status
 */
export enum OrderShipment_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_RECEIVED = 1;
     */
    RECEIVED = 1,
    /**
     * @generated from protobuf enum value: STATUS_PAID = 2;
     */
    PAID = 2,
    /**
     * @generated from protobuf enum value: STATUS_VOUCHERED = 3;
     */
    VOUCHERED = 3
}
/**
 * @generated from protobuf message rd.txn.InvoiceDetails
 */
export interface InvoiceDetails {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp invoice_date = 1;
     */
    invoiceDate?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_date = 2;
     */
    dueDate?: Timestamp;
    /**
     * @generated from protobuf field: string invoice_number = 3;
     */
    invoiceNumber: string;
}
/**
 * OrderShipmentLog represents a change of an OrderShipment
 *
 * @generated from protobuf message rd.txn.OrderShipmentLog
 */
export interface OrderShipmentLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 4;
     */
    orderShipment?: OrderShipment;
}
/**
 * OrderPDFData represents the data required to fill the html
 * template for a purchase order
 *
 * @generated from protobuf message rd.txn.OrderPDFData
 */
export interface OrderPDFData {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.Order order = 2;
     */
    order?: Order;
    /**
     * @generated from protobuf field: rd.company.Company company = 3;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 4;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 5;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.vendors.Contacts.Contact vendor_contact = 6;
     */
    vendorContact?: Contacts_Contact$;
    /**
     * @generated from protobuf field: repeated rd.txn.OrderPDFData.VendorProduct products = 7;
     */
    products: OrderPDFData_VendorProduct[];
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 9;
     */
    total?: Money;
    /**
     * @generated from protobuf field: string account_number = 10;
     */
    accountNumber: string;
}
/**
 * @generated from protobuf message rd.txn.OrderPDFData.VendorProduct
 */
export interface OrderPDFData_VendorProduct {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 4;
     */
    total?: Money;
    /**
     * @generated from protobuf field: int32 quantity_ordered = 5;
     */
    quantityOrdered: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee_cost = 6;
     */
    feeCost?: Money;
    /**
     * @generated from protobuf field: string notes = 7;
     */
    notes: string;
    /**
     * @generated from protobuf field: int32 quantity_received = 8;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 9;
     */
    costV2?: FractionalMoney;
}
/**
 * UpsertOrderError stores invalid upc products for an EDI order that has invalid products
 *
 * @generated from protobuf message rd.txn.UpsertOrderError
 */
export interface UpsertOrderError {
    /**
     * @generated from protobuf field: repeated rd.product.Product invalid_upc_products = 1;
     */
    invalidUpcProducts: Product[];
}
/**
 * Transfer represents a transaction type in which inventory
 * is moved between locations.
 *
 * @generated from protobuf message rd.txn.Transfer
 */
export interface Transfer {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User sent_by = 6;
     */
    sentBy?: User;
    /**
     * @generated from protobuf field: rd.user.User received_by = 7;
     */
    receivedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User voided_by = 8;
     */
    voidedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID from_location_id = 9;
     */
    fromLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID to_location_id = 10;
     */
    toLocationId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sent_at = 11;
     */
    sentAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp received_at = 12;
     */
    receivedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp voided_at = 13;
     */
    voidedAt?: Timestamp;
    /**
     * @generated from protobuf field: string identifier = 14;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.Transfer.Status status = 15;
     */
    status: Transfer_Status;
    /**
     * @generated from protobuf field: map<string, rd.txn.Transfer.Product> products_by_id = 16;
     */
    productsById: {
        [key: string]: Transfer_Product;
    };
    /**
     * @generated from protobuf field: int64 total_cost_cents = 17;
     */
    totalCostCents: bigint;
    /**
     * @generated from protobuf field: string send_notes = 18;
     */
    sendNotes: string;
    /**
     * @generated from protobuf field: string receive_notes = 19;
     */
    receiveNotes: string;
}
/**
 * @generated from protobuf message rd.txn.Transfer.Product
 */
export interface Transfer_Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity_sent = 2;
     */
    quantitySent: number;
    /**
     * @generated from protobuf field: int32 quantity_received = 3;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money additional_fee_when_received = 5;
     */
    additionalFeeWhenReceived?: Money;
    /**
     * @generated from protobuf field: int32 position = 6;
     */
    position: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 7;
     */
    costV2?: FractionalMoney;
}
/**
 * @generated from protobuf enum rd.txn.Transfer.Status
 */
export enum Transfer_Status {
    /**
     * @generated from protobuf enum value: STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * @generated from protobuf enum value: STATUS_SENT = 2;
     */
    SENT = 2,
    /**
     * @generated from protobuf enum value: STATUS_RECEIVED = 3;
     */
    RECEIVED = 3,
    /**
     * @generated from protobuf enum value: STATUS_VOIDED = 4;
     */
    VOIDED = 4
}
/**
 * TransferLog represents a change of a Transfer.
 *
 * @generated from protobuf message rd.txn.TransferLog
 */
export interface TransferLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.txn.Transfer transfer = 4;
     */
    transfer?: Transfer;
}
/**
 * TransferPDFData represents the data required to fill the html
 * template for a transfer
 *
 * @generated from protobuf message rd.txn.TransferPDFData
 */
export interface TransferPDFData {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.Transfer transfer = 2;
     */
    transfer?: Transfer;
    /**
     * @generated from protobuf field: rd.company.Company company = 3;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 4;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.company.Location to_location = 5;
     */
    toLocation?: Location;
    /**
     * @generated from protobuf field: repeated rd.txn.TransferPDFData.Product products = 6;
     */
    products: TransferPDFData_Product[];
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 7;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.txn.TransferPDFData.Product
 */
export interface TransferPDFData_Product {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 4;
     */
    total?: Money;
    /**
     * @generated from protobuf field: int32 quantity = 5;
     */
    quantity: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee_cost = 7;
     */
    feeCost?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 8;
     */
    costV2?: FractionalMoney;
}
/**
 * BillPayment represents a bill payment made by a customer that
 * goes toward the outstanding balance on their charge account.
 *
 * @generated from protobuf message rd.txn.BillPayment
 */
export interface BillPayment {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 4;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 5;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 6;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 7;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 8;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string notes = 9;
     */
    notes: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp refunded_at = 10;
     */
    refundedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User refunded_by = 11;
     */
    refundedBy?: User;
    /**
     * @generated from protobuf field: string refund_notes = 12;
     */
    refundNotes: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 13;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal tax_rate = 14;
     */
    taxRate?: Decimal;
}
/**
 * BillPaymentPDFData represents the data required to fill the html
 * template for a bill payment
 *
 * @generated from protobuf message rd.txn.BillPaymentPDFData
 */
export interface BillPaymentPDFData {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 2;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.company.Company company = 3;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 4;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 5;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact primary_contact = 6;
     */
    primaryContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance customer_balance = 7;
     */
    customerBalance?: CustomerBalance;
    /**
     * @generated from protobuf field: repeated rd.txn.BillPaymentPDFData.Sale sales = 8;
     */
    sales: BillPaymentPDFData_Sale[];
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 10;
     */
    financingChargeAllocation?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_allocation = 11;
     */
    salesAllocation?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit_allocation = 12;
     */
    creditAllocation?: Money;
    /**
     * @generated from protobuf field: string payment_method = 13;
     */
    paymentMethod: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money payment_amount = 14;
     */
    paymentAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 15;
     */
    cashReceived?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_change = 16;
     */
    cashChange?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 17;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 18;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: rd.payments.CardPaymentReceiptInfo receipt_info = 19;
     */
    receiptInfo?: CardPaymentReceiptInfo;
}
/**
 * @generated from protobuf message rd.txn.BillPaymentPDFData.Sale
 */
export interface BillPaymentPDFData_Sale {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string location_name = 2;
     */
    locationName: string;
    /**
     * @generated from protobuf field: string customer_name = 3;
     */
    customerName: string;
    /**
     * @generated from protobuf field: string purchase_order = 4;
     */
    purchaseOrder: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sold_at = 5;
     */
    soldAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount_allocated = 6;
     */
    amountAllocated?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 7;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money subtotal = 8;
     */
    subtotal?: Money;
}
/**
 * CustomerBalance represents the amount a given customer owes
 * a company. These are stored in the database & updated every
 * time a ledger entry is inserted for the given customer.
 *
 * @generated from protobuf message rd.txn.CustomerBalance
 */
export interface CustomerBalance {
    /**
     * @generated from protobuf field: rd.proto.money.Money credits = 1;
     */
    credits?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money current = 2;
     */
    current?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_1_to_30 = 3;
     */
    late1To30?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_31_to_60 = 4;
     */
    late31To60?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_61_plus = 5;
     */
    late61Plus?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charges = 6;
     */
    financingCharges?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> outstanding_purchases = 7;
     */
    outstandingPurchases: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 8;
     */
    id?: UUID; // customer id
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 9;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 10;
     */
    companyId?: UUID;
}
/**
 * CustomerAgingLedger represents the amount a given customer owes
 * a company, broken down by purchase ID maps and net financing
 * charges owed
 *
 * @generated from protobuf message rd.txn.CustomerAgingLedger
 */
export interface CustomerAgingLedger {
    /**
     * @generated from protobuf field: rd.proto.money.Money credits = 1;
     */
    credits?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> current = 2;
     */
    current: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> late_1_to_30 = 3;
     */
    late1To30: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> late_31_to_60 = 4;
     */
    late31To60: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> late_61_plus = 5;
     */
    late61Plus: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charges = 6;
     */
    financingCharges?: Money;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> outstanding_purchases = 7;
     */
    outstandingPurchases: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 8;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 9;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 10;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.txn.CustomerSaleHistoryPDFData
 */
export interface CustomerSaleHistoryPDFData {
    /**
     * @generated from protobuf field: rd.company.Company company = 1;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 3;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact primary_contact = 4;
     */
    primaryContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 5;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.txn.CustomerSaleHistoryPDFData.Sale sales = 6;
     */
    sales: CustomerSaleHistoryPDFData_Sale[];
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_total = 7;
     */
    salesTotal?: Money;
    /**
     * @generated from protobuf field: repeated rd.customer.PurchaseOrder purchase_orders = 8;
     */
    purchaseOrders: PurchaseOrder[];
    /**
     * @generated from protobuf field: rd.proto.money.Money remaining_balance_total = 9;
     */
    remainingBalanceTotal?: Money;
}
/**
 * @generated from protobuf message rd.txn.CustomerSaleHistoryPDFData.Sale
 */
export interface CustomerSaleHistoryPDFData_Sale {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string location_name = 2;
     */
    locationName: string;
    /**
     * @generated from protobuf field: string purchase_order = 3;
     */
    purchaseOrder: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sold_at = 4;
     */
    soldAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_at = 5;
     */
    dueAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money remaining_balance = 7;
     */
    remainingBalance?: Money;
}
/**
 * PettyCash represents a transaction type in which cash is withdrawn or deposited
 * to the register for company use.
 *
 * @generated from protobuf message rd.txn.PettyCash
 */
export interface PettyCash {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: string identifier = 4;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 6;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 7;
     */
    companyId?: UUID;
}
/**
 * CloseOut represents a transaction in which a register is closed
 * out by counting and submitting the total cash collected.
 *
 * @generated from protobuf message rd.txn.CloseOut
 */
export interface CloseOut {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 5;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 6;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string notes = 7;
     */
    notes: string;
    /**
     * @generated from protobuf field: int32 counted_100_dollar_bills = 8;
     */
    counted100DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_50_dollar_bills = 9;
     */
    counted50DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_20_dollar_bills = 10;
     */
    counted20DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_10_dollar_bills = 11;
     */
    counted10DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_5_dollar_bills = 12;
     */
    counted5DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_1_dollar_bills = 13;
     */
    counted1DollarBills: number;
    /**
     * @generated from protobuf field: int32 counted_25_cent_coins = 14;
     */
    counted25CentCoins: number;
    /**
     * @generated from protobuf field: int32 counted_10_cent_coins = 15;
     */
    counted10CentCoins: number;
    /**
     * @generated from protobuf field: int32 counted_5_cent_coins = 16;
     */
    counted5CentCoins: number;
    /**
     * @generated from protobuf field: int32 counted_1_cent_coins = 17;
     */
    counted1CentCoins: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money float = 18;
     */
    float?: Money;
}
/**
 * PurchaseMethod represents the orignal purchase method for
 * a sale. The card or check number will be included based on the purchase
 * method.
 *
 * @generated from protobuf message rd.txn.PurchaseMethod
 */
export interface PurchaseMethod {
    /**
     * @generated from protobuf field: rd.txn.PurchaseMethod.Method method = 1;
     */
    method: PurchaseMethod_Method;
    /**
     * @generated from protobuf field: rd.payments.Card card = 2;
     */
    card?: Card;
    /**
     * @generated from protobuf field: string check_number = 3;
     */
    checkNumber: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 4;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 5;
     */
    cashReceived?: Money;
    /**
     * @generated from protobuf field: string name = 6;
     */
    name: string;
}
/**
 * @generated from protobuf enum rd.txn.PurchaseMethod.Method
 */
export enum PurchaseMethod_Method {
    /**
     * @generated from protobuf enum value: METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: METHOD_CARD = 1;
     */
    CARD = 1,
    /**
     * @generated from protobuf enum value: METHOD_CHECK = 2;
     */
    CHECK = 2,
    /**
     * @generated from protobuf enum value: METHOD_CASH = 3;
     */
    CASH = 3,
    /**
     * @generated from protobuf enum value: METHOD_ACCOUNT = 4;
     */
    ACCOUNT = 4
}
/**
 * PaymentMetadata represents all of the information needed to
 * make a split or full payment
 *
 * @generated from protobuf message rd.txn.PaymentMetadata
 */
export interface PaymentMetadata {
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry.Payment.Method payment_method = 1;
     */
    paymentMethod: LedgerEntry_Payment_Method;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 2;
     */
    amount?: Money; // positive
    /**
     * @generated from protobuf field: bool should_save_card = 3;
     */
    shouldSaveCard: boolean;
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 4;
     */
    stripePaymentIntentId: string;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.Cash cash = 5;
     */
    cash?: PaymentMetadata_Cash;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.Check check = 6;
     */
    check?: PaymentMetadata_Check;
    /**
     * @generated from protobuf field: rd.payments.ManuallyEnteredCard manually_entered_card = 7;
     */
    manuallyEnteredCard?: ManuallyEnteredCard;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.SavedCard saved_card = 8;
     */
    savedCard?: PaymentMetadata_SavedCard;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata.PresentCard present_card = 9;
     */
    presentCard?: PaymentMetadata_PresentCard;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 10;
     */
    cardSurcharge?: Money; // positive
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 11;
     */
    cardSurchargeTax?: Money; // positive
    /**
     * @generated from protobuf field: string card_nickname = 12;
     */
    cardNickname: string;
}
/**
 * @generated from protobuf message rd.txn.PaymentMetadata.Cash
 */
export interface PaymentMetadata_Cash {
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 1;
     */
    cashReceived?: Money;
}
/**
 * @generated from protobuf message rd.txn.PaymentMetadata.Check
 */
export interface PaymentMetadata_Check {
    /**
     * @generated from protobuf field: string check_number = 1;
     */
    checkNumber: string;
}
/**
 * @generated from protobuf message rd.txn.PaymentMetadata.SavedCard
 */
export interface PaymentMetadata_SavedCard {
    /**
     * @generated from protobuf field: string stripe_payment_method_id = 1;
     */
    stripePaymentMethodId: string;
}
/**
 * @generated from protobuf message rd.txn.PaymentMetadata.PresentCard
 */
export interface PaymentMetadata_PresentCard {
    /**
     * @generated from protobuf field: string stripe_reader_id = 1;
     */
    stripeReaderId: string;
    /**
     * @generated from protobuf field: string zip_code = 2;
     */
    zipCode: string;
}
/**
 * CustomerSalesAnalytics represents sales analytics data for a customer.
 *
 * @generated from protobuf message rd.txn.CustomerSalesAnalytics
 */
export interface CustomerSalesAnalytics {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // customer id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_ytd = 3;
     */
    salesYtd?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_last_ytd = 4;
     */
    salesLastYtd?: Money;
    /**
     * @generated from protobuf field: float yoy_growth = 5;
     */
    yoyGrowth: number;
    /**
     * @generated from protobuf field: float margin_ytd = 6;
     */
    marginYtd: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_sale = 7;
     */
    lastSale?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_last_12mo = 8 [json_name = "salesLast12mo"];
     */
    salesLast12Mo?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_last_3mo = 9 [json_name = "salesLast3mo"];
     */
    salesLast3Mo?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_last_1mo = 10 [json_name = "salesLast1mo"];
     */
    salesLast1Mo?: Money;
}
/**
 * CustomerSalesRollup is a summary of a customer's sales data over a period
 * of time.
 *
 * @generated from protobuf message rd.txn.CustomerSalesRollup
 */
export interface CustomerSalesRollup {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp series_start = 4;
     */
    seriesStart?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp series_end = 5;
     */
    seriesEnd?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 7;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: string ledger_entries_hash = 8;
     */
    ledgerEntriesHash: string;
    /**
     * @generated from protobuf field: rd.txn.CustomerSalesRollup.SalesData sales_data = 9;
     */
    salesData?: CustomerSalesRollup_SalesData;
}
/**
 * @generated from protobuf message rd.txn.CustomerSalesRollup.SalesData
 */
export interface CustomerSalesRollup_SalesData {
    /**
     * @generated from protobuf field: rd.proto.money.Money price_of_goods_sold = 1;
     */
    priceOfGoodsSold?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_sale = 3;
     */
    lastSale?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_of_goods_sold_v2 = 4;
     */
    costOfGoodsSoldV2?: FractionalMoney;
}
/**
 * ProductSalesAnalytics represents sales analytics data for a product at a
 * location.
 *
 * @generated from protobuf message rd.txn.ProductSalesAnalytics
 */
export interface ProductSalesAnalytics {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // xor product id and location id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 2;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 3;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: int32 units_ytd = 5;
     */
    unitsYtd: number;
    /**
     * @generated from protobuf field: int32 units_last_ytd = 6;
     */
    unitsLastYtd: number;
    /**
     * @generated from protobuf field: float yoy_growth = 7;
     */
    yoyGrowth: number;
    /**
     * @generated from protobuf field: float margin_ytd = 8;
     */
    marginYtd: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_sale = 9;
     */
    lastSale?: Timestamp;
    /**
     * @generated from protobuf field: int32 units_last_12mo = 10 [json_name = "unitsLast12mo"];
     */
    unitsLast12Mo: number;
    /**
     * @generated from protobuf field: int32 units_last_3mo = 11 [json_name = "unitsLast3mo"];
     */
    unitsLast3Mo: number;
    /**
     * @generated from protobuf field: int32 units_last_1mo = 12 [json_name = "unitsLast1mo"];
     */
    unitsLast1Mo: number;
    /**
     * @generated from protobuf field: float inventory_turns = 13;
     */
    inventoryTurns: number;
}
/**
 * ProductSalesRollup is a summary of a product's sales data over a period
 * of time at each of its company's locations.
 *
 * @generated from protobuf message rd.txn.ProductSalesRollup
 */
export interface ProductSalesRollup {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp series_start = 4;
     */
    seriesStart?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp series_end = 5;
     */
    seriesEnd?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 7;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: string sales_hash = 8;
     */
    salesHash: string;
    /**
     * @generated from protobuf field: map<string, rd.txn.ProductSalesRollup.SalesData> sales_data_by_location_id = 9;
     */
    salesDataByLocationId: {
        [key: string]: ProductSalesRollup_SalesData;
    };
}
/**
 * @generated from protobuf message rd.txn.ProductSalesRollup.SalesData
 */
export interface ProductSalesRollup_SalesData {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: int32 units_sold = 2;
     */
    unitsSold: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money price_of_goods_sold = 3;
     */
    priceOfGoodsSold?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_sale = 5;
     */
    lastSale?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_of_goods_sold_v2 = 6;
     */
    costOfGoodsSoldV2?: FractionalMoney;
}
/**
 * CondensedSalesPDFData represents the data required to fill the html template for condensed sales
 *
 * @generated from protobuf message rd.txn.CondensedSalePDFData
 */
export interface CondensedSalePDFData {
    /**
     * @generated from protobuf field: repeated rd.txn.CondensedSalePDFData.Sale condensed_sales = 1;
     */
    condensedSales: CondensedSalePDFData_Sale[];
}
/**
 * @generated from protobuf message rd.txn.CondensedSalePDFData.Sale
 */
export interface CondensedSalePDFData_Sale {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp sold_at = 2;
     */
    soldAt?: Timestamp;
    /**
     * @generated from protobuf field: string purchase_order_name = 3;
     */
    purchaseOrderName: string;
    /**
     * @generated from protobuf field: string sale_notes = 4;
     */
    saleNotes: string;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> additional_fee_totals_by_name = 6;
     */
    additionalFeeTotalsByName: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax = 7;
     */
    salesTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money refunded_total = 8;
     */
    refundedTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total = 9;
     */
    adjustedTotal?: Money;
    /**
     * @generated from protobuf field: string signature_url = 10;
     */
    signatureUrl: string;
    /**
     * @generated from protobuf field: string customer_name = 11;
     */
    customerName: string;
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 12;
     */
    taxRate?: TaxRate;
    /**
     * @generated from protobuf field: repeated rd.txn.CondensedSalePDFData.Sale.Product products = 13;
     */
    products: CondensedSalePDFData_Sale_Product[];
    /**
     * @generated from protobuf field: bool show_discount = 14;
     */
    showDiscount: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 16;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: string card_surcharge_rate_string = 17;
     */
    cardSurchargeRateString: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money early_payment_discount = 18;
     */
    earlyPaymentDiscount?: Money;
    /**
     * @generated from protobuf field: bool show_early_payment_discount = 19;
     */
    showEarlyPaymentDiscount: boolean;
}
/**
 * @generated from protobuf message rd.txn.CondensedSalePDFData.Sale.Product
 */
export interface CondensedSalePDFData_Sale_Product {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.tintcolor.TintColor tint_color = 3;
     */
    tintColor?: TintColor;
    /**
     * @generated from protobuf field: rd.proto.money.Money sub_total = 4;
     */
    subTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money returned_sub_total = 5;
     */
    returnedSubTotal?: Money;
    /**
     * @generated from protobuf field: int32 quantity = 6;
     */
    quantity: number;
    /**
     * @generated from protobuf field: int32 quantity_returned = 7;
     */
    quantityReturned: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money price = 8;
     */
    price?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money tier_1_price = 9;
     */
    tier1Price?: Money;
    /**
     * @generated from protobuf field: string notes = 10;
     */
    notes: string;
    /**
     * @generated from protobuf field: string custom_tint_color = 11;
     */
    customTintColor: string;
}
/**
 * OrderShipmentPDFData represents the data required to fill the html
 * template for an order shipment pdf
 *
 * @generated from protobuf message rd.txn.OrderShipmentPDFData
 */
export interface OrderShipmentPDFData {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.txn.Order order = 2;
     */
    order?: Order;
    /**
     * @generated from protobuf field: rd.company.Company company = 3;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 4;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 5;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.vendors.Contacts.Contact vendor_contact = 6;
     */
    vendorContact?: Contacts_Contact$;
    /**
     * @generated from protobuf field: repeated rd.txn.OrderShipmentPDFData.Product products = 7;
     */
    products: OrderShipmentPDFData_Product[];
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 8;
     */
    total?: Money;
    /**
     * @generated from protobuf field: string account_number = 9;
     */
    accountNumber: string;
    /**
     * @generated from protobuf field: string status_tag = 10;
     */
    statusTag: string;
    /**
     * @generated from protobuf field: string invoice_date = 11;
     */
    invoiceDate: string;
    /**
     * @generated from protobuf field: string due_date = 12;
     */
    dueDate: string;
    /**
     * @generated from protobuf field: string invoice_number = 13;
     */
    invoiceNumber: string;
    /**
     * @generated from protobuf field: bool show_invoice_section = 14;
     */
    showInvoiceSection: boolean;
}
/**
 * @generated from protobuf message rd.txn.OrderShipmentPDFData.Product
 */
export interface OrderShipmentPDFData_Product {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
    /**
     * @generated from protobuf field: int32 quantity_ordered = 4;
     */
    quantityOrdered: number;
    /**
     * @generated from protobuf field: int32 quantity_received = 5;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee_cost = 6;
     */
    feeCost?: Money;
    /**
     * @generated from protobuf field: string notes = 7;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 8;
     */
    costV2?: FractionalMoney;
}
// @generated message type with reflection information, may provide speed optimized methods
class Sale$Type extends MessageType<Sale> {
    constructor() {
        super("rd.txn.Sale", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "customer_job_site_id", kind: "message", T: () => UUID },
            { no: 7, name: "customer_purchase_order_id", kind: "message", T: () => UUID },
            { no: 8, name: "location_id", kind: "message", T: () => UUID },
            { no: 9, name: "created_by", kind: "message", T: () => User },
            { no: 10, name: "quoted_by", kind: "message", T: () => User },
            { no: 11, name: "sold_by", kind: "message", T: () => User },
            { no: 12, name: "updated_by", kind: "message", T: () => User },
            { no: 13, name: "voided_by", kind: "message", T: () => User },
            { no: 14, name: "will_called_by", kind: "message", T: () => User },
            { no: 15, name: "quoted_at", kind: "message", T: () => Timestamp },
            { no: 16, name: "quote_expires_at", kind: "message", T: () => Timestamp },
            { no: 17, name: "will_called_at", kind: "message", T: () => Timestamp },
            { no: 18, name: "voided_at", kind: "message", T: () => Timestamp },
            { no: 19, name: "sold_at", kind: "message", T: () => Timestamp },
            { no: 20, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "status", kind: "enum", T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"] },
            { no: 22, name: "sale_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "returns", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sale_Return },
            { no: 26, name: "customer_contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "signature_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "tax_rate", kind: "message", T: () => Decimal },
            { no: 29, name: "tax_rate_id", kind: "message", T: () => UUID },
            { no: 30, name: "subtotal", kind: "message", T: () => Money },
            { no: 31, name: "total", kind: "message", T: () => Money },
            { no: 32, name: "adjusted_total", kind: "message", T: () => Money },
            { no: 33, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 34, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 35, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 36, name: "total_early_payment_discount_adjustment", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<Sale>): Sale {
        const message = { identifier: "", status: 0, saleNotes: "", returns: [], customerContactIdString: "", signatureUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Sale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sale): Sale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID customer_job_site_id */ 6:
                    message.customerJobSiteId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSiteId);
                    break;
                case /* rd.proto.uuid.UUID customer_purchase_order_id */ 7:
                    message.customerPurchaseOrderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerPurchaseOrderId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 8:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.user.User created_by */ 9:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User quoted_by */ 10:
                    message.quotedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.quotedBy);
                    break;
                case /* rd.user.User sold_by */ 11:
                    message.soldBy = User.internalBinaryRead(reader, reader.uint32(), options, message.soldBy);
                    break;
                case /* rd.user.User updated_by */ 12:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.user.User voided_by */ 13:
                    message.voidedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.voidedBy);
                    break;
                case /* rd.user.User will_called_by */ 14:
                    message.willCalledBy = User.internalBinaryRead(reader, reader.uint32(), options, message.willCalledBy);
                    break;
                case /* google.protobuf.Timestamp quoted_at */ 15:
                    message.quotedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.quotedAt);
                    break;
                case /* google.protobuf.Timestamp quote_expires_at */ 16:
                    message.quoteExpiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.quoteExpiresAt);
                    break;
                case /* google.protobuf.Timestamp will_called_at */ 17:
                    message.willCalledAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.willCalledAt);
                    break;
                case /* google.protobuf.Timestamp voided_at */ 18:
                    message.voidedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.voidedAt);
                    break;
                case /* google.protobuf.Timestamp sold_at */ 19:
                    message.soldAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.soldAt);
                    break;
                case /* string identifier */ 20:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.Sale.Status status */ 21:
                    message.status = reader.int32();
                    break;
                case /* string sale_notes */ 22:
                    message.saleNotes = reader.string();
                    break;
                case /* repeated rd.txn.Sale.Return returns */ 25:
                    message.returns.push(Sale_Return.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customer_contact_id_string */ 26:
                    message.customerContactIdString = reader.string();
                    break;
                case /* string signature_url */ 27:
                    message.signatureUrl = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal tax_rate */ 28:
                    message.taxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.uuid.UUID tax_rate_id */ 29:
                    message.taxRateId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.taxRateId);
                    break;
                case /* rd.proto.money.Money subtotal */ 30:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                case /* rd.proto.money.Money total */ 31:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.proto.money.Money adjusted_total */ 32:
                    message.adjustedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotal);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 33:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 34:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 35:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* rd.proto.money.Money total_early_payment_discount_adjustment */ 36:
                    message.totalEarlyPaymentDiscountAdjustment = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalEarlyPaymentDiscountAdjustment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_job_site_id = 6; */
        if (message.customerJobSiteId)
            UUID.internalBinaryWrite(message.customerJobSiteId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_purchase_order_id = 7; */
        if (message.customerPurchaseOrderId)
            UUID.internalBinaryWrite(message.customerPurchaseOrderId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 8; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 9; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User quoted_by = 10; */
        if (message.quotedBy)
            User.internalBinaryWrite(message.quotedBy, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User sold_by = 11; */
        if (message.soldBy)
            User.internalBinaryWrite(message.soldBy, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 12; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User voided_by = 13; */
        if (message.voidedBy)
            User.internalBinaryWrite(message.voidedBy, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User will_called_by = 14; */
        if (message.willCalledBy)
            User.internalBinaryWrite(message.willCalledBy, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp quoted_at = 15; */
        if (message.quotedAt)
            Timestamp.internalBinaryWrite(message.quotedAt, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp quote_expires_at = 16; */
        if (message.quoteExpiresAt)
            Timestamp.internalBinaryWrite(message.quoteExpiresAt, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp will_called_at = 17; */
        if (message.willCalledAt)
            Timestamp.internalBinaryWrite(message.willCalledAt, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp voided_at = 18; */
        if (message.voidedAt)
            Timestamp.internalBinaryWrite(message.voidedAt, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp sold_at = 19; */
        if (message.soldAt)
            Timestamp.internalBinaryWrite(message.soldAt, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 20; */
        if (message.identifier !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.Sale.Status status = 21; */
        if (message.status !== 0)
            writer.tag(21, WireType.Varint).int32(message.status);
        /* string sale_notes = 22; */
        if (message.saleNotes !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.saleNotes);
        /* repeated rd.txn.Sale.Return returns = 25; */
        for (let i = 0; i < message.returns.length; i++)
            Sale_Return.internalBinaryWrite(message.returns[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* string customer_contact_id_string = 26; */
        if (message.customerContactIdString !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.customerContactIdString);
        /* string signature_url = 27; */
        if (message.signatureUrl !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.signatureUrl);
        /* rd.proto.decimal.Decimal tax_rate = 28; */
        if (message.taxRate)
            Decimal.internalBinaryWrite(message.taxRate, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID tax_rate_id = 29; */
        if (message.taxRateId)
            UUID.internalBinaryWrite(message.taxRateId, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money subtotal = 30; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 31; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total = 32; */
        if (message.adjustedTotal)
            Money.internalBinaryWrite(message.adjustedTotal, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 33; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 34; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 35; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_early_payment_discount_adjustment = 36; */
        if (message.totalEarlyPaymentDiscountAdjustment)
            Money.internalBinaryWrite(message.totalEarlyPaymentDiscountAdjustment, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Sale
 */
export const Sale = new Sale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sale_Return$Type extends MessageType<Sale_Return> {
    constructor() {
        super("rd.txn.Sale.Return", [
            { no: 2, name: "returned_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "returned_by", kind: "message", T: () => User },
            { no: 4, name: "sale_products_returned", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaleProduct },
            { no: 5, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_by", kind: "message", T: () => User },
            { no: 7, name: "location_id", kind: "message", T: () => UUID },
            { no: 8, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "customer_id", kind: "message", T: () => UUID },
            { no: 10, name: "customer_job_site_id", kind: "message", T: () => UUID },
            { no: 11, name: "customer_purchase_order_id", kind: "message", T: () => UUID },
            { no: 13, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "subtotal", kind: "message", T: () => Money },
            { no: 15, name: "total", kind: "message", T: () => Money },
            { no: 16, name: "additional_fees_by_name", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 17, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 18, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 19, name: "sales_tax", kind: "message", T: () => Money },
            { no: 20, name: "contact_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "rounding_adjustment", kind: "message", T: () => Money },
            { no: 22, name: "early_payment_discount_adjustment", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<Sale_Return>): Sale_Return {
        const message = { saleProductsReturned: [], identifier: "", notes: "", additionalFeesByName: {}, contactIdString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Sale_Return>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sale_Return): Sale_Return {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp returned_at */ 2:
                    message.returnedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.returnedAt);
                    break;
                case /* rd.user.User returned_by */ 3:
                    message.returnedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.returnedBy);
                    break;
                case /* repeated rd.txn.SaleProduct sale_products_returned */ 4:
                    message.saleProductsReturned.push(SaleProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp updated_at */ 5:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User updated_by */ 6:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 7:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string identifier */ 8:
                    message.identifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 9:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID customer_job_site_id */ 10:
                    message.customerJobSiteId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerJobSiteId);
                    break;
                case /* rd.proto.uuid.UUID customer_purchase_order_id */ 11:
                    message.customerPurchaseOrderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerPurchaseOrderId);
                    break;
                case /* string notes */ 13:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.money.Money subtotal */ 14:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                case /* rd.proto.money.Money total */ 15:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* map<string, rd.proto.money.Money> additional_fees_by_name */ 16:
                    this.binaryReadMap16(message.additionalFeesByName, reader, options);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 17:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 18:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* rd.proto.money.Money sales_tax */ 19:
                    message.salesTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTax);
                    break;
                case /* string contact_id_string */ 20:
                    message.contactIdString = reader.string();
                    break;
                case /* rd.proto.money.Money rounding_adjustment */ 21:
                    message.roundingAdjustment = Money.internalBinaryRead(reader, reader.uint32(), options, message.roundingAdjustment);
                    break;
                case /* rd.proto.money.Money early_payment_discount_adjustment */ 22:
                    message.earlyPaymentDiscountAdjustment = Money.internalBinaryRead(reader, reader.uint32(), options, message.earlyPaymentDiscountAdjustment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap16(map: Sale_Return["additionalFeesByName"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Sale_Return["additionalFeesByName"] | undefined, val: Sale_Return["additionalFeesByName"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.Sale.Return.additional_fees_by_name");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: Sale_Return, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp returned_at = 2; */
        if (message.returnedAt)
            Timestamp.internalBinaryWrite(message.returnedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User returned_by = 3; */
        if (message.returnedBy)
            User.internalBinaryWrite(message.returnedBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.SaleProduct sale_products_returned = 4; */
        for (let i = 0; i < message.saleProductsReturned.length; i++)
            SaleProduct.internalBinaryWrite(message.saleProductsReturned[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 5; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 6; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 7; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 8; */
        if (message.identifier !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.identifier);
        /* rd.proto.uuid.UUID customer_id = 9; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_job_site_id = 10; */
        if (message.customerJobSiteId)
            UUID.internalBinaryWrite(message.customerJobSiteId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_purchase_order_id = 11; */
        if (message.customerPurchaseOrderId)
            UUID.internalBinaryWrite(message.customerPurchaseOrderId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 13; */
        if (message.notes !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.money.Money subtotal = 14; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 15; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> additional_fees_by_name = 16; */
        for (let k of Object.keys(message.additionalFeesByName)) {
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.additionalFeesByName[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money card_surcharge = 17; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 18; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_tax = 19; */
        if (message.salesTax)
            Money.internalBinaryWrite(message.salesTax, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string contact_id_string = 20; */
        if (message.contactIdString !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.contactIdString);
        /* rd.proto.money.Money rounding_adjustment = 21; */
        if (message.roundingAdjustment)
            Money.internalBinaryWrite(message.roundingAdjustment, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money early_payment_discount_adjustment = 22; */
        if (message.earlyPaymentDiscountAdjustment)
            Money.internalBinaryWrite(message.earlyPaymentDiscountAdjustment, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Sale.Return
 */
export const Sale_Return = new Sale_Return$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReturnIdentifierMapping$Type extends MessageType<ReturnIdentifierMapping> {
    constructor() {
        super("rd.txn.ReturnIdentifierMapping", [
            { no: 2, name: "sale_id", kind: "message", T: () => UUID },
            { no: 3, name: "company_id", kind: "message", T: () => UUID },
            { no: 4, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReturnIdentifierMapping>): ReturnIdentifierMapping {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReturnIdentifierMapping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReturnIdentifierMapping): ReturnIdentifierMapping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 2:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 3:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string identifier */ 4:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReturnIdentifierMapping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 2; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 3; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 4; */
        if (message.identifier !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.ReturnIdentifierMapping
 */
export const ReturnIdentifierMapping = new ReturnIdentifierMapping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaleLog$Type extends MessageType<SaleLog> {
    constructor() {
        super("rd.txn.SaleLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "sale", kind: "message", T: () => Sale }
        ]);
    }
    create(value?: PartialMessage<SaleLog>): SaleLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaleLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaleLog): SaleLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.txn.Sale sale */ 4:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaleLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 4; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SaleLog
 */
export const SaleLog = new SaleLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaleProduct$Type extends MessageType<SaleProduct> {
    constructor() {
        super("rd.txn.SaleProduct", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "product_id", kind: "message", T: () => UUID },
            { no: 3, name: "sale_id", kind: "message", T: () => UUID },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "tint_color_id", kind: "message", T: () => UUID },
            { no: 7, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "quantity_returned", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "price", kind: "message", T: () => Money },
            { no: 10, name: "additional_fee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "custom_tint_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "is_price_edited", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "tier_1_price", kind: "message", T: () => Money },
            { no: 17, name: "is_taxed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "is_fee_taxed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "colorx_tinted_product_id", kind: "message", T: () => UUID },
            { no: 20, name: "cost_v2", kind: "message", T: () => FractionalMoney },
            { no: 21, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 23, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 24, name: "sold_at", kind: "message", T: () => Timestamp },
            { no: 25, name: "location_id", kind: "message", T: () => UUID },
            { no: 26, name: "is_cost_edited", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 27, name: "additional_fee_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<SaleProduct>): SaleProduct {
        const message = { quantity: 0, quantityReturned: 0, additionalFeeName: "", notes: "", customTintColor: "", position: 0, isPriceEdited: false, isTaxed: false, isFeeTaxed: false, isActive: false, isCostEdited: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaleProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaleProduct): SaleProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 2:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 3:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID tint_color_id */ 6:
                    message.tintColorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.tintColorId);
                    break;
                case /* int32 quantity */ 7:
                    message.quantity = reader.int32();
                    break;
                case /* int32 quantity_returned */ 8:
                    message.quantityReturned = reader.int32();
                    break;
                case /* rd.proto.money.Money price */ 9:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* string additional_fee_name */ 10:
                    message.additionalFeeName = reader.string();
                    break;
                case /* string notes */ 12:
                    message.notes = reader.string();
                    break;
                case /* string custom_tint_color */ 13:
                    message.customTintColor = reader.string();
                    break;
                case /* int32 position */ 14:
                    message.position = reader.int32();
                    break;
                case /* bool is_price_edited */ 15:
                    message.isPriceEdited = reader.bool();
                    break;
                case /* rd.proto.money.Money tier_1_price */ 16:
                    message.tier1Price = Money.internalBinaryRead(reader, reader.uint32(), options, message.tier1Price);
                    break;
                case /* bool is_taxed */ 17:
                    message.isTaxed = reader.bool();
                    break;
                case /* bool is_fee_taxed */ 18:
                    message.isFeeTaxed = reader.bool();
                    break;
                case /* rd.proto.uuid.UUID colorx_tinted_product_id */ 19:
                    message.colorxTintedProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.colorxTintedProductId);
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 20:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                case /* bool is_active */ 21:
                    message.isActive = reader.bool();
                    break;
                case /* google.protobuf.Timestamp created_at */ 22:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 23:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* google.protobuf.Timestamp sold_at */ 24:
                    message.soldAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.soldAt);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 25:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* bool is_cost_edited */ 26:
                    message.isCostEdited = reader.bool();
                    break;
                case /* rd.proto.money.FractionalMoney additional_fee_v2 */ 27:
                    message.additionalFeeV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.additionalFeeV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaleProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 2; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 3; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID tint_color_id = 6; */
        if (message.tintColorId)
            UUID.internalBinaryWrite(message.tintColorId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 7; */
        if (message.quantity !== 0)
            writer.tag(7, WireType.Varint).int32(message.quantity);
        /* int32 quantity_returned = 8; */
        if (message.quantityReturned !== 0)
            writer.tag(8, WireType.Varint).int32(message.quantityReturned);
        /* rd.proto.money.Money price = 9; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string additional_fee_name = 10; */
        if (message.additionalFeeName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.additionalFeeName);
        /* string notes = 12; */
        if (message.notes !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.notes);
        /* string custom_tint_color = 13; */
        if (message.customTintColor !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.customTintColor);
        /* int32 position = 14; */
        if (message.position !== 0)
            writer.tag(14, WireType.Varint).int32(message.position);
        /* bool is_price_edited = 15; */
        if (message.isPriceEdited !== false)
            writer.tag(15, WireType.Varint).bool(message.isPriceEdited);
        /* rd.proto.money.Money tier_1_price = 16; */
        if (message.tier1Price)
            Money.internalBinaryWrite(message.tier1Price, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bool is_taxed = 17; */
        if (message.isTaxed !== false)
            writer.tag(17, WireType.Varint).bool(message.isTaxed);
        /* bool is_fee_taxed = 18; */
        if (message.isFeeTaxed !== false)
            writer.tag(18, WireType.Varint).bool(message.isFeeTaxed);
        /* rd.proto.uuid.UUID colorx_tinted_product_id = 19; */
        if (message.colorxTintedProductId)
            UUID.internalBinaryWrite(message.colorxTintedProductId, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost_v2 = 20; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 21; */
        if (message.isActive !== false)
            writer.tag(21, WireType.Varint).bool(message.isActive);
        /* google.protobuf.Timestamp created_at = 22; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 23; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp sold_at = 24; */
        if (message.soldAt)
            Timestamp.internalBinaryWrite(message.soldAt, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 25; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* bool is_cost_edited = 26; */
        if (message.isCostEdited !== false)
            writer.tag(26, WireType.Varint).bool(message.isCostEdited);
        /* rd.proto.money.FractionalMoney additional_fee_v2 = 27; */
        if (message.additionalFeeV2)
            FractionalMoney.internalBinaryWrite(message.additionalFeeV2, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SaleProduct
 */
export const SaleProduct = new SaleProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaleProductLog$Type extends MessageType<SaleProductLog> {
    constructor() {
        super("rd.txn.SaleProductLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "sale_product", kind: "message", T: () => SaleProduct }
        ]);
    }
    create(value?: PartialMessage<SaleProductLog>): SaleProductLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaleProductLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaleProductLog): SaleProductLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.txn.SaleProduct sale_product */ 4:
                    message.saleProduct = SaleProduct.internalBinaryRead(reader, reader.uint32(), options, message.saleProduct);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaleProductLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.SaleProduct sale_product = 4; */
        if (message.saleProduct)
            SaleProduct.internalBinaryWrite(message.saleProduct, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SaleProductLog
 */
export const SaleProductLog = new SaleProductLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalePDFData$Type extends MessageType<SalePDFData> {
    constructor() {
        super("rd.txn.SalePDFData", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "primary_contact", kind: "message", T: () => Contacts_Contact },
            { no: 6, name: "last_action_staff", kind: "message", T: () => User },
            { no: 7, name: "last_action_time", kind: "message", T: () => Timestamp },
            { no: 8, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 9, name: "job_site", kind: "message", T: () => JobSite },
            { no: 10, name: "company", kind: "message", T: () => Company },
            { no: 11, name: "location", kind: "message", T: () => Location },
            { no: 12, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SalePDFData_Product },
            { no: 14, name: "sub_total", kind: "message", T: () => Money },
            { no: 15, name: "fee_totals_by_name", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 16, name: "sales_tax_total", kind: "message", T: () => Money },
            { no: 17, name: "total", kind: "message", T: () => Money },
            { no: 18, name: "due_at", kind: "message", T: () => Timestamp },
            { no: 19, name: "remaining_balance", kind: "message", T: () => Money },
            { no: 20, name: "expires_at", kind: "message", T: () => Timestamp },
            { no: 21, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 23, name: "cash_received", kind: "message", T: () => Money },
            { no: 24, name: "cash_change", kind: "message", T: () => Money },
            { no: 25, name: "refunded_total", kind: "message", T: () => Money },
            { no: 26, name: "adjusted_total", kind: "message", T: () => Money },
            { no: 27, name: "show_signature_line", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "purchase_methods_without_cash", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SalePDFData_PurchaseMethod },
            { no: 29, name: "show_cash_refunded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 30, name: "bill_payments_total", kind: "message", T: () => Money },
            { no: 31, name: "tax_rate", kind: "message", T: () => TaxRate },
            { no: 33, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 34, name: "card_surcharge_rate_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "receipt_info", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardPaymentReceiptInfo },
            { no: 36, name: "barcode", kind: "message", T: () => Barcode },
            { no: 37, name: "show_prices", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SalePDFData>): SalePDFData {
        const message = { identifier: "", title: "", products: [], feeTotalsByName: {}, showSignatureLine: false, purchaseMethodsWithoutCash: [], showCashRefunded: false, cardSurchargeRateString: "", receiptInfo: [], showPrices: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalePDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalePDFData): SalePDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts.Contact primary_contact */ 5:
                    message.primaryContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.primaryContact);
                    break;
                case /* rd.user.User last_action_staff */ 6:
                    message.lastActionStaff = User.internalBinaryRead(reader, reader.uint32(), options, message.lastActionStaff);
                    break;
                case /* google.protobuf.Timestamp last_action_time */ 7:
                    message.lastActionTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastActionTime);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 8:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 9:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* rd.company.Company company */ 10:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 11:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* repeated rd.txn.SalePDFData.Product products */ 12:
                    message.products.push(SalePDFData_Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money sub_total */ 14:
                    message.subTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subTotal);
                    break;
                case /* map<string, rd.proto.money.Money> fee_totals_by_name */ 15:
                    this.binaryReadMap15(message.feeTotalsByName, reader, options);
                    break;
                case /* rd.proto.money.Money sales_tax_total */ 16:
                    message.salesTaxTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTaxTotal);
                    break;
                case /* rd.proto.money.Money total */ 17:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* google.protobuf.Timestamp due_at */ 18:
                    message.dueAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueAt);
                    break;
                case /* rd.proto.money.Money remaining_balance */ 19:
                    message.remainingBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.remainingBalance);
                    break;
                case /* google.protobuf.Timestamp expires_at */ 20:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 21:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* rd.proto.money.Money cash_received */ 23:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                case /* rd.proto.money.Money cash_change */ 24:
                    message.cashChange = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashChange);
                    break;
                case /* rd.proto.money.Money refunded_total */ 25:
                    message.refundedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.refundedTotal);
                    break;
                case /* rd.proto.money.Money adjusted_total */ 26:
                    message.adjustedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotal);
                    break;
                case /* bool show_signature_line */ 27:
                    message.showSignatureLine = reader.bool();
                    break;
                case /* repeated rd.txn.SalePDFData.PurchaseMethod purchase_methods_without_cash */ 28:
                    message.purchaseMethodsWithoutCash.push(SalePDFData_PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool show_cash_refunded */ 29:
                    message.showCashRefunded = reader.bool();
                    break;
                case /* rd.proto.money.Money bill_payments_total */ 30:
                    message.billPaymentsTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentsTotal);
                    break;
                case /* rd.company.TaxRate tax_rate */ 31:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 33:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* string card_surcharge_rate_string */ 34:
                    message.cardSurchargeRateString = reader.string();
                    break;
                case /* repeated rd.payments.CardPaymentReceiptInfo receipt_info */ 35:
                    message.receiptInfo.push(CardPaymentReceiptInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.pdf.Barcode barcode */ 36:
                    message.barcode = Barcode.internalBinaryRead(reader, reader.uint32(), options, message.barcode);
                    break;
                case /* bool show_prices */ 37:
                    message.showPrices = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap15(map: SalePDFData["feeTotalsByName"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SalePDFData["feeTotalsByName"] | undefined, val: SalePDFData["feeTotalsByName"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.SalePDFData.fee_totals_by_name");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: SalePDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact primary_contact = 5; */
        if (message.primaryContact)
            Contacts_Contact.internalBinaryWrite(message.primaryContact, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User last_action_staff = 6; */
        if (message.lastActionStaff)
            User.internalBinaryWrite(message.lastActionStaff, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_action_time = 7; */
        if (message.lastActionTime)
            Timestamp.internalBinaryWrite(message.lastActionTime, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 8; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 9; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 10; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 11; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.SalePDFData.Product products = 12; */
        for (let i = 0; i < message.products.length; i++)
            SalePDFData_Product.internalBinaryWrite(message.products[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sub_total = 14; */
        if (message.subTotal)
            Money.internalBinaryWrite(message.subTotal, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> fee_totals_by_name = 15; */
        for (let k of Object.keys(message.feeTotalsByName)) {
            writer.tag(15, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.feeTotalsByName[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money sales_tax_total = 16; */
        if (message.salesTaxTotal)
            Money.internalBinaryWrite(message.salesTaxTotal, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 17; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_at = 18; */
        if (message.dueAt)
            Timestamp.internalBinaryWrite(message.dueAt, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money remaining_balance = 19; */
        if (message.remainingBalance)
            Money.internalBinaryWrite(message.remainingBalance, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expires_at = 20; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 21; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_received = 23; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_change = 24; */
        if (message.cashChange)
            Money.internalBinaryWrite(message.cashChange, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money refunded_total = 25; */
        if (message.refundedTotal)
            Money.internalBinaryWrite(message.refundedTotal, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total = 26; */
        if (message.adjustedTotal)
            Money.internalBinaryWrite(message.adjustedTotal, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* bool show_signature_line = 27; */
        if (message.showSignatureLine !== false)
            writer.tag(27, WireType.Varint).bool(message.showSignatureLine);
        /* repeated rd.txn.SalePDFData.PurchaseMethod purchase_methods_without_cash = 28; */
        for (let i = 0; i < message.purchaseMethodsWithoutCash.length; i++)
            SalePDFData_PurchaseMethod.internalBinaryWrite(message.purchaseMethodsWithoutCash[i], writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* bool show_cash_refunded = 29; */
        if (message.showCashRefunded !== false)
            writer.tag(29, WireType.Varint).bool(message.showCashRefunded);
        /* rd.proto.money.Money bill_payments_total = 30; */
        if (message.billPaymentsTotal)
            Money.internalBinaryWrite(message.billPaymentsTotal, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.TaxRate tax_rate = 31; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 33; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* string card_surcharge_rate_string = 34; */
        if (message.cardSurchargeRateString !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.cardSurchargeRateString);
        /* repeated rd.payments.CardPaymentReceiptInfo receipt_info = 35; */
        for (let i = 0; i < message.receiptInfo.length; i++)
            CardPaymentReceiptInfo.internalBinaryWrite(message.receiptInfo[i], writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* rd.pdf.Barcode barcode = 36; */
        if (message.barcode)
            Barcode.internalBinaryWrite(message.barcode, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* bool show_prices = 37; */
        if (message.showPrices !== false)
            writer.tag(37, WireType.Varint).bool(message.showPrices);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SalePDFData
 */
export const SalePDFData = new SalePDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalePDFData_Product$Type extends MessageType<SalePDFData_Product> {
    constructor() {
        super("rd.txn.SalePDFData.Product", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tint_color", kind: "message", T: () => TintColor },
            { no: 5, name: "sub_total", kind: "message", T: () => Money },
            { no: 6, name: "returned_sub_total", kind: "message", T: () => Money },
            { no: 9, name: "custom_tint_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "price", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "show_tier_1_price", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "tier_1_price", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "show_quantity_returned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "quantity_returned", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SalePDFData_Product>): SalePDFData_Product {
        const message = { identifier: "", name: "", customTintColor: "", notes: "", quantity: 0, price: "", showTier1Price: false, tier1Price: "", showQuantityReturned: false, quantityReturned: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalePDFData_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalePDFData_Product): SalePDFData_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.tintcolor.TintColor tint_color */ 4:
                    message.tintColor = TintColor.internalBinaryRead(reader, reader.uint32(), options, message.tintColor);
                    break;
                case /* rd.proto.money.Money sub_total */ 5:
                    message.subTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subTotal);
                    break;
                case /* rd.proto.money.Money returned_sub_total */ 6:
                    message.returnedSubTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.returnedSubTotal);
                    break;
                case /* string custom_tint_color */ 9:
                    message.customTintColor = reader.string();
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* int32 quantity */ 11:
                    message.quantity = reader.int32();
                    break;
                case /* string price */ 12:
                    message.price = reader.string();
                    break;
                case /* bool show_tier_1_price */ 13:
                    message.showTier1Price = reader.bool();
                    break;
                case /* string tier_1_price */ 14:
                    message.tier1Price = reader.string();
                    break;
                case /* bool show_quantity_returned */ 15:
                    message.showQuantityReturned = reader.bool();
                    break;
                case /* int32 quantity_returned */ 16:
                    message.quantityReturned = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SalePDFData_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.tintcolor.TintColor tint_color = 4; */
        if (message.tintColor)
            TintColor.internalBinaryWrite(message.tintColor, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sub_total = 5; */
        if (message.subTotal)
            Money.internalBinaryWrite(message.subTotal, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money returned_sub_total = 6; */
        if (message.returnedSubTotal)
            Money.internalBinaryWrite(message.returnedSubTotal, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string custom_tint_color = 9; */
        if (message.customTintColor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.customTintColor);
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* int32 quantity = 11; */
        if (message.quantity !== 0)
            writer.tag(11, WireType.Varint).int32(message.quantity);
        /* string price = 12; */
        if (message.price !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.price);
        /* bool show_tier_1_price = 13; */
        if (message.showTier1Price !== false)
            writer.tag(13, WireType.Varint).bool(message.showTier1Price);
        /* string tier_1_price = 14; */
        if (message.tier1Price !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.tier1Price);
        /* bool show_quantity_returned = 15; */
        if (message.showQuantityReturned !== false)
            writer.tag(15, WireType.Varint).bool(message.showQuantityReturned);
        /* int32 quantity_returned = 16; */
        if (message.quantityReturned !== 0)
            writer.tag(16, WireType.Varint).int32(message.quantityReturned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SalePDFData.Product
 */
export const SalePDFData_Product = new SalePDFData_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalePDFData_PurchaseMethod$Type extends MessageType<SalePDFData_PurchaseMethod> {
    constructor() {
        super("rd.txn.SalePDFData.PurchaseMethod", [
            { no: 1, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SalePDFData_PurchaseMethod>): SalePDFData_PurchaseMethod {
        const message = { method: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalePDFData_PurchaseMethod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalePDFData_PurchaseMethod): SalePDFData_PurchaseMethod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string method */ 1:
                    message.method = reader.string();
                    break;
                case /* rd.proto.money.Money amount */ 2:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SalePDFData_PurchaseMethod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string method = 1; */
        if (message.method !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.method);
        /* rd.proto.money.Money amount = 2; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.SalePDFData.PurchaseMethod
 */
export const SalePDFData_PurchaseMethod = new SalePDFData_PurchaseMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry$Type extends MessageType<LedgerEntry> {
    constructor() {
        super("rd.txn.LedgerEntry", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "created_by", kind: "message", T: () => User },
            { no: 7, name: "amount", kind: "message", T: () => Money },
            { no: 8, name: "type", kind: "enum", T: () => ["rd.txn.LedgerEntry.Type", LedgerEntry_Type, "TYPE_"] },
            { no: 9, name: "purchase", kind: "message", T: () => LedgerEntry_Purchase },
            { no: 10, name: "return", kind: "message", T: () => LedgerEntry_Return },
            { no: 11, name: "payment", kind: "message", T: () => LedgerEntry_Payment },
            { no: 12, name: "write_off", kind: "message", T: () => LedgerEntry_WriteOff },
            { no: 13, name: "refund", kind: "message", T: () => LedgerEntry_Refund },
            { no: 14, name: "location_id", kind: "message", T: () => UUID },
            { no: 15, name: "petty_cash", kind: "message", T: () => LedgerEntry_PettyCash },
            { no: 16, name: "close_out", kind: "message", T: () => LedgerEntry_CloseOut },
            { no: 17, name: "allocation", kind: "message", T: () => LedgerEntry_Allocation },
            { no: 18, name: "discount", kind: "message", T: () => LedgerEntry_Discount },
            { no: 19, name: "reverse_discount", kind: "message", T: () => LedgerEntry_ReverseDiscount },
            { no: 37, name: "job_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry>): LedgerEntry {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry): LedgerEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.user.User created_by */ 6:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.money.Money amount */ 7:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.txn.LedgerEntry.Type type */ 8:
                    message.type = reader.int32();
                    break;
                case /* rd.txn.LedgerEntry.Purchase purchase */ 9:
                    message.purchase = LedgerEntry_Purchase.internalBinaryRead(reader, reader.uint32(), options, message.purchase);
                    break;
                case /* rd.txn.LedgerEntry.Return return */ 10:
                    message.return = LedgerEntry_Return.internalBinaryRead(reader, reader.uint32(), options, message.return);
                    break;
                case /* rd.txn.LedgerEntry.Payment payment */ 11:
                    message.payment = LedgerEntry_Payment.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                case /* rd.txn.LedgerEntry.WriteOff write_off */ 12:
                    message.writeOff = LedgerEntry_WriteOff.internalBinaryRead(reader, reader.uint32(), options, message.writeOff);
                    break;
                case /* rd.txn.LedgerEntry.Refund refund */ 13:
                    message.refund = LedgerEntry_Refund.internalBinaryRead(reader, reader.uint32(), options, message.refund);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 14:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.txn.LedgerEntry.PettyCash petty_cash */ 15:
                    message.pettyCash = LedgerEntry_PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.txn.LedgerEntry.CloseOut close_out */ 16:
                    message.closeOut = LedgerEntry_CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeOut);
                    break;
                case /* rd.txn.LedgerEntry.Allocation allocation */ 17:
                    message.allocation = LedgerEntry_Allocation.internalBinaryRead(reader, reader.uint32(), options, message.allocation);
                    break;
                case /* rd.txn.LedgerEntry.Discount discount */ 18:
                    message.discount = LedgerEntry_Discount.internalBinaryRead(reader, reader.uint32(), options, message.discount);
                    break;
                case /* rd.txn.LedgerEntry.ReverseDiscount reverse_discount */ 19:
                    message.reverseDiscount = LedgerEntry_ReverseDiscount.internalBinaryRead(reader, reader.uint32(), options, message.reverseDiscount);
                    break;
                case /* rd.proto.uuid.UUID job_id */ 37:
                    message.jobId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.jobId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 6; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 7; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Type type = 8; */
        if (message.type !== 0)
            writer.tag(8, WireType.Varint).int32(message.type);
        /* rd.txn.LedgerEntry.Purchase purchase = 9; */
        if (message.purchase)
            LedgerEntry_Purchase.internalBinaryWrite(message.purchase, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Return return = 10; */
        if (message.return)
            LedgerEntry_Return.internalBinaryWrite(message.return, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Payment payment = 11; */
        if (message.payment)
            LedgerEntry_Payment.internalBinaryWrite(message.payment, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.WriteOff write_off = 12; */
        if (message.writeOff)
            LedgerEntry_WriteOff.internalBinaryWrite(message.writeOff, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Refund refund = 13; */
        if (message.refund)
            LedgerEntry_Refund.internalBinaryWrite(message.refund, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 14; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.PettyCash petty_cash = 15; */
        if (message.pettyCash)
            LedgerEntry_PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.CloseOut close_out = 16; */
        if (message.closeOut)
            LedgerEntry_CloseOut.internalBinaryWrite(message.closeOut, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Allocation allocation = 17; */
        if (message.allocation)
            LedgerEntry_Allocation.internalBinaryWrite(message.allocation, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.Discount discount = 18; */
        if (message.discount)
            LedgerEntry_Discount.internalBinaryWrite(message.discount, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry.ReverseDiscount reverse_discount = 19; */
        if (message.reverseDiscount)
            LedgerEntry_ReverseDiscount.internalBinaryWrite(message.reverseDiscount, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID job_id = 37; */
        if (message.jobId)
            UUID.internalBinaryWrite(message.jobId, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry
 */
export const LedgerEntry = new LedgerEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_PotentialDiscount$Type extends MessageType<LedgerEntry_PotentialDiscount> {
    constructor() {
        super("rd.txn.LedgerEntry.PotentialDiscount", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.txn.LedgerEntry.PotentialDiscount.Type", LedgerEntry_PotentialDiscount_Type] },
            { no: 3, name: "rate", kind: "message", T: () => Decimal },
            { no: 4, name: "term_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_PotentialDiscount>): LedgerEntry_PotentialDiscount {
        const message = { type: 0, termName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_PotentialDiscount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_PotentialDiscount): LedgerEntry_PotentialDiscount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.LedgerEntry.PotentialDiscount.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal rate */ 3:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                case /* string term_name */ 4:
                    message.termName = reader.string();
                    break;
                case /* google.protobuf.Timestamp expires_at */ 5:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_PotentialDiscount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.LedgerEntry.PotentialDiscount.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.proto.decimal.Decimal rate = 3; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string term_name = 4; */
        if (message.termName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.termName);
        /* google.protobuf.Timestamp expires_at = 5; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.PotentialDiscount
 */
export const LedgerEntry_PotentialDiscount = new LedgerEntry_PotentialDiscount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Purchase$Type extends MessageType<LedgerEntry_Purchase> {
    constructor() {
        super("rd.txn.LedgerEntry.Purchase", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID },
            { no: 2, name: "due_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "potential_discounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LedgerEntry_PotentialDiscount }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Purchase>): LedgerEntry_Purchase {
        const message = { potentialDiscounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Purchase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Purchase): LedgerEntry_Purchase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* google.protobuf.Timestamp due_at */ 2:
                    message.dueAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueAt);
                    break;
                case /* repeated rd.txn.LedgerEntry.PotentialDiscount potential_discounts */ 3:
                    message.potentialDiscounts.push(LedgerEntry_PotentialDiscount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Purchase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_at = 2; */
        if (message.dueAt)
            Timestamp.internalBinaryWrite(message.dueAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.LedgerEntry.PotentialDiscount potential_discounts = 3; */
        for (let i = 0; i < message.potentialDiscounts.length; i++)
            LedgerEntry_PotentialDiscount.internalBinaryWrite(message.potentialDiscounts[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Purchase
 */
export const LedgerEntry_Purchase = new LedgerEntry_Purchase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Return$Type extends MessageType<LedgerEntry_Return> {
    constructor() {
        super("rd.txn.LedgerEntry.Return", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "sale_id", kind: "message", T: () => UUID },
            { no: 3, name: "return_index", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Return>): LedgerEntry_Return {
        const message = { returnIndex: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Return>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Return): LedgerEntry_Return {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 2:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* int64 return_index */ 3:
                    message.returnIndex = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Return, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 2; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 return_index = 3; */
        if (message.returnIndex !== 0n)
            writer.tag(3, WireType.Varint).int64(message.returnIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Return
 */
export const LedgerEntry_Return = new LedgerEntry_Return$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Payment$Type extends MessageType<LedgerEntry_Payment> {
    constructor() {
        super("rd.txn.LedgerEntry.Payment", [
            { no: 1, name: "method", kind: "enum", T: () => ["rd.txn.LedgerEntry.Payment.Method", LedgerEntry_Payment_Method, "METHOD_"] },
            { no: 2, name: "purchase_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 3, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 4, name: "cash_received", kind: "message", T: () => Money },
            { no: 5, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "check_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "bill_payment_id", kind: "message", T: () => UUID },
            { no: 8, name: "card_fee", kind: "message", T: () => Money },
            { no: 9, name: "cash_register", kind: "message", T: () => CashRegister },
            { no: 10, name: "card", kind: "message", T: () => Card },
            { no: 11, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 12, name: "card_surcharge_tax", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Payment>): LedgerEntry_Payment {
        const message = { method: 0, purchaseAllocationsById: {}, stripePaymentIntentId: "", checkNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Payment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Payment): LedgerEntry_Payment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.LedgerEntry.Payment.Method method */ 1:
                    message.method = reader.int32();
                    break;
                case /* map<string, rd.proto.money.Money> purchase_allocations_by_id */ 2:
                    this.binaryReadMap2(message.purchaseAllocationsById, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 3:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.money.Money cash_received */ 4:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                case /* string stripe_payment_intent_id */ 5:
                    message.stripePaymentIntentId = reader.string();
                    break;
                case /* string check_number */ 6:
                    message.checkNumber = reader.string();
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 7:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                case /* rd.proto.money.Money card_fee */ 8:
                    message.cardFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardFee);
                    break;
                case /* rd.company.CashRegister cash_register */ 9:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                case /* rd.payments.Card card */ 10:
                    message.card = Card.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 11:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 12:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: LedgerEntry_Payment["purchaseAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_Payment["purchaseAllocationsById"] | undefined, val: LedgerEntry_Payment["purchaseAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.LedgerEntry.Payment.purchase_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: LedgerEntry_Payment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.LedgerEntry.Payment.Method method = 1; */
        if (message.method !== 0)
            writer.tag(1, WireType.Varint).int32(message.method);
        /* map<string, rd.proto.money.Money> purchase_allocations_by_id = 2; */
        for (let k of Object.keys(message.purchaseAllocationsById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.purchaseAllocationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 3; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_received = 4; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_payment_intent_id = 5; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        /* string check_number = 6; */
        if (message.checkNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.checkNumber);
        /* rd.proto.uuid.UUID bill_payment_id = 7; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_fee = 8; */
        if (message.cardFee)
            Money.internalBinaryWrite(message.cardFee, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 9; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card card = 10; */
        if (message.card)
            Card.internalBinaryWrite(message.card, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 11; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 12; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Payment
 */
export const LedgerEntry_Payment = new LedgerEntry_Payment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_WriteOff$Type extends MessageType<LedgerEntry_WriteOff> {
    constructor() {
        super("rd.txn.LedgerEntry.WriteOff", [
            { no: 1, name: "purchase_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 2, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 3, name: "bill_payment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_WriteOff>): LedgerEntry_WriteOff {
        const message = { purchaseAllocationsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_WriteOff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_WriteOff): LedgerEntry_WriteOff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, rd.proto.money.Money> purchase_allocations_by_id */ 1:
                    this.binaryReadMap1(message.purchaseAllocationsById, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 2:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 3:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LedgerEntry_WriteOff["purchaseAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_WriteOff["purchaseAllocationsById"] | undefined, val: LedgerEntry_WriteOff["purchaseAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.LedgerEntry.WriteOff.purchase_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: LedgerEntry_WriteOff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, rd.proto.money.Money> purchase_allocations_by_id = 1; */
        for (let k of Object.keys(message.purchaseAllocationsById)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.purchaseAllocationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 2; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 3; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.WriteOff
 */
export const LedgerEntry_WriteOff = new LedgerEntry_WriteOff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Refund$Type extends MessageType<LedgerEntry_Refund> {
    constructor() {
        super("rd.txn.LedgerEntry.Refund", [
            { no: 1, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "return_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "stripe_refund_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "method", kind: "enum", T: () => ["rd.txn.LedgerEntry.Refund.Method", LedgerEntry_Refund_Method, "METHOD_"] },
            { no: 5, name: "cash_register", kind: "message", T: () => CashRegister },
            { no: 6, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 7, name: "card_surcharge_tax", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Refund>): LedgerEntry_Refund {
        const message = { stripeRefundId: "", method: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Refund>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Refund): LedgerEntry_Refund {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 1:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID return_ledger_entry_id */ 2:
                    message.returnLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntryId);
                    break;
                case /* string stripe_refund_id */ 3:
                    message.stripeRefundId = reader.string();
                    break;
                case /* rd.txn.LedgerEntry.Refund.Method method */ 4:
                    message.method = reader.int32();
                    break;
                case /* rd.company.CashRegister cash_register */ 5:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 6:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 7:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Refund, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 1; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID return_ledger_entry_id = 2; */
        if (message.returnLedgerEntryId)
            UUID.internalBinaryWrite(message.returnLedgerEntryId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_refund_id = 3; */
        if (message.stripeRefundId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stripeRefundId);
        /* rd.txn.LedgerEntry.Refund.Method method = 4; */
        if (message.method !== 0)
            writer.tag(4, WireType.Varint).int32(message.method);
        /* rd.company.CashRegister cash_register = 5; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 6; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 7; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Refund
 */
export const LedgerEntry_Refund = new LedgerEntry_Refund$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_PettyCash$Type extends MessageType<LedgerEntry_PettyCash> {
    constructor() {
        super("rd.txn.LedgerEntry.PettyCash", [
            { no: 1, name: "petty_cash_id", kind: "message", T: () => UUID },
            { no: 2, name: "cash_register", kind: "message", T: () => CashRegister }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_PettyCash>): LedgerEntry_PettyCash {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_PettyCash>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_PettyCash): LedgerEntry_PettyCash {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID petty_cash_id */ 1:
                    message.pettyCashId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.pettyCashId);
                    break;
                case /* rd.company.CashRegister cash_register */ 2:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_PettyCash, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID petty_cash_id = 1; */
        if (message.pettyCashId)
            UUID.internalBinaryWrite(message.pettyCashId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 2; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.PettyCash
 */
export const LedgerEntry_PettyCash = new LedgerEntry_PettyCash$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_CloseOut$Type extends MessageType<LedgerEntry_CloseOut> {
    constructor() {
        super("rd.txn.LedgerEntry.CloseOut", [
            { no: 1, name: "close_out_id", kind: "message", T: () => UUID },
            { no: 2, name: "cash_register", kind: "message", T: () => CashRegister }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_CloseOut>): LedgerEntry_CloseOut {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_CloseOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_CloseOut): LedgerEntry_CloseOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID close_out_id */ 1:
                    message.closeOutId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.closeOutId);
                    break;
                case /* rd.company.CashRegister cash_register */ 2:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_CloseOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID close_out_id = 1; */
        if (message.closeOutId)
            UUID.internalBinaryWrite(message.closeOutId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 2; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.CloseOut
 */
export const LedgerEntry_CloseOut = new LedgerEntry_CloseOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Allocation$Type extends MessageType<LedgerEntry_Allocation> {
    constructor() {
        super("rd.txn.LedgerEntry.Allocation", [
            { no: 1, name: "allocation_amount", kind: "message", T: () => Money },
            { no: 2, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 4, name: "write_off_ledger_entry_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Allocation>): LedgerEntry_Allocation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Allocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Allocation): LedgerEntry_Allocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money allocation_amount */ 1:
                    message.allocationAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.allocationAmount);
                    break;
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 2:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 3:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID write_off_ledger_entry_id */ 4:
                    message.writeOffLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntryId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Allocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money allocation_amount = 1; */
        if (message.allocationAmount)
            Money.internalBinaryWrite(message.allocationAmount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 2; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 3; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID write_off_ledger_entry_id = 4; */
        if (message.writeOffLedgerEntryId)
            UUID.internalBinaryWrite(message.writeOffLedgerEntryId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Allocation
 */
export const LedgerEntry_Allocation = new LedgerEntry_Allocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Discount$Type extends MessageType<LedgerEntry_Discount> {
    constructor() {
        super("rd.txn.LedgerEntry.Discount", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "purchase_location_id", kind: "message", T: () => UUID },
            { no: 4, name: "bill_payment_id", kind: "message", T: () => UUID },
            { no: 5, name: "sale_id", kind: "message", T: () => UUID },
            { no: 6, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Discount>): LedgerEntry_Discount {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Discount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Discount): LedgerEntry_Discount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_location_id */ 3:
                    message.purchaseLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLocationId);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 4:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 5:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* string return_identifier */ 6:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Discount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_location_id = 3; */
        if (message.purchaseLocationId)
            UUID.internalBinaryWrite(message.purchaseLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 4; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 5; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 6; */
        if (message.returnIdentifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.Discount
 */
export const LedgerEntry_Discount = new LedgerEntry_Discount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_ReverseDiscount$Type extends MessageType<LedgerEntry_ReverseDiscount> {
    constructor() {
        super("rd.txn.LedgerEntry.ReverseDiscount", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "discount_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 5, name: "purchase_location_id", kind: "message", T: () => UUID },
            { no: 6, name: "bill_payment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_ReverseDiscount>): LedgerEntry_ReverseDiscount {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_ReverseDiscount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_ReverseDiscount): LedgerEntry_ReverseDiscount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID discount_ledger_entry_id */ 3:
                    message.discountLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.discountLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_location_id */ 5:
                    message.purchaseLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLocationId);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 6:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_ReverseDiscount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID discount_ledger_entry_id = 3; */
        if (message.discountLedgerEntryId)
            UUID.internalBinaryWrite(message.discountLedgerEntryId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_location_id = 5; */
        if (message.purchaseLocationId)
            UUID.internalBinaryWrite(message.purchaseLocationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 6; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.LedgerEntry.ReverseDiscount
 */
export const LedgerEntry_ReverseDiscount = new LedgerEntry_ReverseDiscount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Order$Type extends MessageType<Order> {
    constructor() {
        super("rd.txn.Order", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "message", T: () => User },
            { no: 5, name: "ordered_by", kind: "message", T: () => User },
            { no: 6, name: "received_by", kind: "message", T: () => User },
            { no: 7, name: "updated_by", kind: "message", T: () => User },
            { no: 8, name: "voided_by", kind: "message", T: () => User },
            { no: 9, name: "company_id", kind: "message", T: () => UUID },
            { no: 10, name: "location_id", kind: "message", T: () => UUID },
            { no: 11, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 12, name: "requested_delivery_at", kind: "message", T: () => Timestamp },
            { no: 13, name: "ordered_at", kind: "message", T: () => Timestamp },
            { no: 14, name: "received_at", kind: "message", T: () => Timestamp },
            { no: 15, name: "voided_at", kind: "message", T: () => Timestamp },
            { no: 16, name: "status", kind: "enum", T: () => ["rd.txn.Order.Status", Order_Status, "STATUS_"] },
            { no: 17, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "internal_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Order_Product } },
            { no: 21, name: "type", kind: "enum", T: () => ["rd.txn.Order.Type", Order_Type, "TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Order>): Order {
        const message = { status: 0, identifier: "", orderNotes: "", internalNotes: "", productsById: {}, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Order>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Order): Order {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User created_by */ 4:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User ordered_by */ 5:
                    message.orderedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.orderedBy);
                    break;
                case /* rd.user.User received_by */ 6:
                    message.receivedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.receivedBy);
                    break;
                case /* rd.user.User updated_by */ 7:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.user.User voided_by */ 8:
                    message.voidedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.voidedBy);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 9:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 10:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 11:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* google.protobuf.Timestamp requested_delivery_at */ 12:
                    message.requestedDeliveryAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.requestedDeliveryAt);
                    break;
                case /* google.protobuf.Timestamp ordered_at */ 13:
                    message.orderedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.orderedAt);
                    break;
                case /* google.protobuf.Timestamp received_at */ 14:
                    message.receivedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.receivedAt);
                    break;
                case /* google.protobuf.Timestamp voided_at */ 15:
                    message.voidedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.voidedAt);
                    break;
                case /* rd.txn.Order.Status status */ 16:
                    message.status = reader.int32();
                    break;
                case /* string identifier */ 17:
                    message.identifier = reader.string();
                    break;
                case /* string order_notes */ 18:
                    message.orderNotes = reader.string();
                    break;
                case /* string internal_notes */ 19:
                    message.internalNotes = reader.string();
                    break;
                case /* map<string, rd.txn.Order.Product> products_by_id */ 20:
                    this.binaryReadMap20(message.productsById, reader, options);
                    break;
                case /* rd.txn.Order.Type type */ 21:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap20(map: Order["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Order["productsById"] | undefined, val: Order["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Order_Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.Order.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Order_Product.create();
    }
    internalBinaryWrite(message: Order, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 4; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User ordered_by = 5; */
        if (message.orderedBy)
            User.internalBinaryWrite(message.orderedBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User received_by = 6; */
        if (message.receivedBy)
            User.internalBinaryWrite(message.receivedBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 7; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User voided_by = 8; */
        if (message.voidedBy)
            User.internalBinaryWrite(message.voidedBy, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 9; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 10; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID vendor_id = 11; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp requested_delivery_at = 12; */
        if (message.requestedDeliveryAt)
            Timestamp.internalBinaryWrite(message.requestedDeliveryAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ordered_at = 13; */
        if (message.orderedAt)
            Timestamp.internalBinaryWrite(message.orderedAt, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp received_at = 14; */
        if (message.receivedAt)
            Timestamp.internalBinaryWrite(message.receivedAt, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp voided_at = 15; */
        if (message.voidedAt)
            Timestamp.internalBinaryWrite(message.voidedAt, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Order.Status status = 16; */
        if (message.status !== 0)
            writer.tag(16, WireType.Varint).int32(message.status);
        /* string identifier = 17; */
        if (message.identifier !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.identifier);
        /* string order_notes = 18; */
        if (message.orderNotes !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderNotes);
        /* string internal_notes = 19; */
        if (message.internalNotes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.internalNotes);
        /* map<string, rd.txn.Order.Product> products_by_id = 20; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(20, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Order_Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* rd.txn.Order.Type type = 21; */
        if (message.type !== 0)
            writer.tag(21, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Order
 */
export const Order = new Order$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Order_Product$Type extends MessageType<Order_Product> {
    constructor() {
        super("rd.txn.Order.Product", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity_ordered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "additional_fee_when_received", kind: "message", T: () => Money },
            { no: 6, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "additional_fee_name_when_received", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<Order_Product>): Order_Product {
        const message = { quantityOrdered: 0, quantityReceived: 0, position: 0, notes: "", additionalFeeNameWhenReceived: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Order_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Order_Product): Order_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity_ordered */ 2:
                    message.quantityOrdered = reader.int32();
                    break;
                case /* int32 quantity_received */ 4:
                    message.quantityReceived = reader.int32();
                    break;
                case /* rd.proto.money.Money additional_fee_when_received */ 5:
                    message.additionalFeeWhenReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.additionalFeeWhenReceived);
                    break;
                case /* int32 position */ 6:
                    message.position = reader.int32();
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* string additional_fee_name_when_received */ 8:
                    message.additionalFeeNameWhenReceived = reader.string();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 9:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Order_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_ordered = 2; */
        if (message.quantityOrdered !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityOrdered);
        /* int32 quantity_received = 4; */
        if (message.quantityReceived !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantityReceived);
        /* rd.proto.money.Money additional_fee_when_received = 5; */
        if (message.additionalFeeWhenReceived)
            Money.internalBinaryWrite(message.additionalFeeWhenReceived, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 6; */
        if (message.position !== 0)
            writer.tag(6, WireType.Varint).int32(message.position);
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* string additional_fee_name_when_received = 8; */
        if (message.additionalFeeNameWhenReceived !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.additionalFeeNameWhenReceived);
        /* rd.proto.money.FractionalMoney cost_v2 = 9; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Order.Product
 */
export const Order_Product = new Order_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderLog$Type extends MessageType<OrderLog> {
    constructor() {
        super("rd.txn.OrderLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "order", kind: "message", T: () => Order }
        ]);
    }
    create(value?: PartialMessage<OrderLog>): OrderLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderLog): OrderLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.txn.Order order */ 4:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Order order = 4; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderLog
 */
export const OrderLog = new OrderLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipment$Type extends MessageType<OrderShipment> {
    constructor() {
        super("rd.txn.OrderShipment", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "order_id", kind: "message", T: () => UUID },
            { no: 3, name: "company_id", kind: "message", T: () => UUID },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "created_by", kind: "message", T: () => User },
            { no: 6, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => OrderShipment_Product } },
            { no: 7, name: "total_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "received_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "received_by", kind: "message", T: () => User },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "location_id", kind: "message", T: () => UUID },
            { no: 12, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 14, name: "cost_adjustments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderShipment_CostAdjustment },
            { no: 15, name: "financial_transaction_id", kind: "message", T: () => UUID },
            { no: 16, name: "external_transaction_id", kind: "message", T: () => UUID },
            { no: 17, name: "marked_paid_at", kind: "message", T: () => Timestamp },
            { no: 18, name: "marked_paid_by", kind: "message", T: () => User },
            { no: 19, name: "status", kind: "enum", T: () => ["rd.txn.OrderShipment.Status", OrderShipment_Status, "STATUS_"] },
            { no: 20, name: "pay_to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "invoice_details", kind: "message", T: () => InvoiceDetails },
            { no: 22, name: "vouchered_at", kind: "message", T: () => Timestamp },
            { no: 23, name: "vouchered_by", kind: "message", T: () => User },
            { no: 24, name: "updated_by", kind: "message", T: () => User },
            { no: 25, name: "updated_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<OrderShipment>): OrderShipment {
        const message = { productsById: {}, totalReceived: 0, notes: "", identifier: "", costAdjustments: [], status: 0, payTo: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipment): OrderShipment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID order_id */ 2:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 3:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* map<string, rd.txn.OrderShipment.Product> products_by_id */ 6:
                    this.binaryReadMap6(message.productsById, reader, options);
                    break;
                case /* int32 total_received */ 7:
                    message.totalReceived = reader.int32();
                    break;
                case /* google.protobuf.Timestamp received_at */ 8:
                    message.receivedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.receivedAt);
                    break;
                case /* rd.user.User received_by */ 9:
                    message.receivedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.receivedBy);
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.uuid.UUID location_id */ 11:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string identifier */ 12:
                    message.identifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID vendor_id */ 13:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* repeated rd.txn.OrderShipment.CostAdjustment cost_adjustments */ 14:
                    message.costAdjustments.push(OrderShipment_CostAdjustment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID financial_transaction_id */ 15:
                    message.financialTransactionId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.financialTransactionId);
                    break;
                case /* rd.proto.uuid.UUID external_transaction_id */ 16:
                    message.externalTransactionId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.externalTransactionId);
                    break;
                case /* google.protobuf.Timestamp marked_paid_at */ 17:
                    message.markedPaidAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.markedPaidAt);
                    break;
                case /* rd.user.User marked_paid_by */ 18:
                    message.markedPaidBy = User.internalBinaryRead(reader, reader.uint32(), options, message.markedPaidBy);
                    break;
                case /* rd.txn.OrderShipment.Status status */ 19:
                    message.status = reader.int32();
                    break;
                case /* string pay_to */ 20:
                    message.payTo = reader.string();
                    break;
                case /* rd.txn.InvoiceDetails invoice_details */ 21:
                    message.invoiceDetails = InvoiceDetails.internalBinaryRead(reader, reader.uint32(), options, message.invoiceDetails);
                    break;
                case /* google.protobuf.Timestamp vouchered_at */ 22:
                    message.voucheredAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.voucheredAt);
                    break;
                case /* rd.user.User vouchered_by */ 23:
                    message.voucheredBy = User.internalBinaryRead(reader, reader.uint32(), options, message.voucheredBy);
                    break;
                case /* rd.user.User updated_by */ 24:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 25:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: OrderShipment["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof OrderShipment["productsById"] | undefined, val: OrderShipment["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = OrderShipment_Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.OrderShipment.products_by_id");
            }
        }
        map[key ?? ""] = val ?? OrderShipment_Product.create();
    }
    internalBinaryWrite(message: OrderShipment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_id = 2; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 3; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.OrderShipment.Product> products_by_id = 6; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            OrderShipment_Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* int32 total_received = 7; */
        if (message.totalReceived !== 0)
            writer.tag(7, WireType.Varint).int32(message.totalReceived);
        /* google.protobuf.Timestamp received_at = 8; */
        if (message.receivedAt)
            Timestamp.internalBinaryWrite(message.receivedAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User received_by = 9; */
        if (message.receivedBy)
            User.internalBinaryWrite(message.receivedBy, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.uuid.UUID location_id = 11; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 12; */
        if (message.identifier !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.identifier);
        /* rd.proto.uuid.UUID vendor_id = 13; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.OrderShipment.CostAdjustment cost_adjustments = 14; */
        for (let i = 0; i < message.costAdjustments.length; i++)
            OrderShipment_CostAdjustment.internalBinaryWrite(message.costAdjustments[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID financial_transaction_id = 15; */
        if (message.financialTransactionId)
            UUID.internalBinaryWrite(message.financialTransactionId, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID external_transaction_id = 16; */
        if (message.externalTransactionId)
            UUID.internalBinaryWrite(message.externalTransactionId, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp marked_paid_at = 17; */
        if (message.markedPaidAt)
            Timestamp.internalBinaryWrite(message.markedPaidAt, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User marked_paid_by = 18; */
        if (message.markedPaidBy)
            User.internalBinaryWrite(message.markedPaidBy, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.OrderShipment.Status status = 19; */
        if (message.status !== 0)
            writer.tag(19, WireType.Varint).int32(message.status);
        /* string pay_to = 20; */
        if (message.payTo !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.payTo);
        /* rd.txn.InvoiceDetails invoice_details = 21; */
        if (message.invoiceDetails)
            InvoiceDetails.internalBinaryWrite(message.invoiceDetails, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp vouchered_at = 22; */
        if (message.voucheredAt)
            Timestamp.internalBinaryWrite(message.voucheredAt, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User vouchered_by = 23; */
        if (message.voucheredBy)
            User.internalBinaryWrite(message.voucheredBy, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 24; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 25; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipment
 */
export const OrderShipment = new OrderShipment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipment_Product$Type extends MessageType<OrderShipment_Product> {
    constructor() {
        super("rd.txn.OrderShipment.Product", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "additional_fee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "additional_fee_amount", kind: "message", T: () => Money },
            { no: 6, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<OrderShipment_Product>): OrderShipment_Product {
        const message = { quantityReceived: 0, additionalFeeName: "", position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipment_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipment_Product): OrderShipment_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity_received */ 2:
                    message.quantityReceived = reader.int32();
                    break;
                case /* string additional_fee_name */ 4:
                    message.additionalFeeName = reader.string();
                    break;
                case /* rd.proto.money.Money additional_fee_amount */ 5:
                    message.additionalFeeAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.additionalFeeAmount);
                    break;
                case /* int32 position */ 6:
                    message.position = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 7:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderShipment_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_received = 2; */
        if (message.quantityReceived !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityReceived);
        /* string additional_fee_name = 4; */
        if (message.additionalFeeName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.additionalFeeName);
        /* rd.proto.money.Money additional_fee_amount = 5; */
        if (message.additionalFeeAmount)
            Money.internalBinaryWrite(message.additionalFeeAmount, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 6; */
        if (message.position !== 0)
            writer.tag(6, WireType.Varint).int32(message.position);
        /* rd.proto.money.FractionalMoney cost_v2 = 7; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipment.Product
 */
export const OrderShipment_Product = new OrderShipment_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipment_CostAdjustment$Type extends MessageType<OrderShipment_CostAdjustment> {
    constructor() {
        super("rd.txn.OrderShipment.CostAdjustment", [
            { no: 1, name: "chart_of_account_id", kind: "message", T: () => UUID },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<OrderShipment_CostAdjustment>): OrderShipment_CostAdjustment {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipment_CostAdjustment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipment_CostAdjustment): OrderShipment_CostAdjustment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID chart_of_account_id */ 1:
                    message.chartOfAccountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.chartOfAccountId);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money amount */ 3:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderShipment_CostAdjustment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID chart_of_account_id = 1; */
        if (message.chartOfAccountId)
            UUID.internalBinaryWrite(message.chartOfAccountId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money amount = 3; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipment.CostAdjustment
 */
export const OrderShipment_CostAdjustment = new OrderShipment_CostAdjustment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceDetails$Type extends MessageType<InvoiceDetails> {
    constructor() {
        super("rd.txn.InvoiceDetails", [
            { no: 1, name: "invoice_date", kind: "message", T: () => Timestamp },
            { no: 2, name: "due_date", kind: "message", T: () => Timestamp },
            { no: 3, name: "invoice_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InvoiceDetails>): InvoiceDetails {
        const message = { invoiceNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InvoiceDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvoiceDetails): InvoiceDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp invoice_date */ 1:
                    message.invoiceDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.invoiceDate);
                    break;
                case /* google.protobuf.Timestamp due_date */ 2:
                    message.dueDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueDate);
                    break;
                case /* string invoice_number */ 3:
                    message.invoiceNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvoiceDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp invoice_date = 1; */
        if (message.invoiceDate)
            Timestamp.internalBinaryWrite(message.invoiceDate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_date = 2; */
        if (message.dueDate)
            Timestamp.internalBinaryWrite(message.dueDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string invoice_number = 3; */
        if (message.invoiceNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.invoiceNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.InvoiceDetails
 */
export const InvoiceDetails = new InvoiceDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipmentLog$Type extends MessageType<OrderShipmentLog> {
    constructor() {
        super("rd.txn.OrderShipmentLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "order_shipment", kind: "message", T: () => OrderShipment }
        ]);
    }
    create(value?: PartialMessage<OrderShipmentLog>): OrderShipmentLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipmentLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipmentLog): OrderShipmentLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.txn.OrderShipment order_shipment */ 4:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderShipmentLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.OrderShipment order_shipment = 4; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipmentLog
 */
export const OrderShipmentLog = new OrderShipmentLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderPDFData$Type extends MessageType<OrderPDFData> {
    constructor() {
        super("rd.txn.OrderPDFData", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "order", kind: "message", T: () => Order },
            { no: 3, name: "company", kind: "message", T: () => Company },
            { no: 4, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "vendor", kind: "message", T: () => Vendor },
            { no: 6, name: "vendor_contact", kind: "message", T: () => Contacts_Contact$ },
            { no: 7, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderPDFData_VendorProduct },
            { no: 9, name: "total", kind: "message", T: () => Money },
            { no: 10, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderPDFData>): OrderPDFData {
        const message = { identifier: "", products: [], accountNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderPDFData): OrderPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.Order order */ 2:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* rd.company.Company company */ 3:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 4:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.vendors.Vendor vendor */ 5:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.vendors.Contacts.Contact vendor_contact */ 6:
                    message.vendorContact = Contacts_Contact$.internalBinaryRead(reader, reader.uint32(), options, message.vendorContact);
                    break;
                case /* repeated rd.txn.OrderPDFData.VendorProduct products */ 7:
                    message.products.push(OrderPDFData_VendorProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money total */ 9:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* string account_number */ 10:
                    message.accountNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.Order order = 2; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 3; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 4; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 5; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Contacts.Contact vendor_contact = 6; */
        if (message.vendorContact)
            Contacts_Contact$.internalBinaryWrite(message.vendorContact, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.OrderPDFData.VendorProduct products = 7; */
        for (let i = 0; i < message.products.length; i++)
            OrderPDFData_VendorProduct.internalBinaryWrite(message.products[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 9; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string account_number = 10; */
        if (message.accountNumber !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.accountNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderPDFData
 */
export const OrderPDFData = new OrderPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderPDFData_VendorProduct$Type extends MessageType<OrderPDFData_VendorProduct> {
    constructor() {
        super("rd.txn.OrderPDFData.VendorProduct", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "total", kind: "message", T: () => Money },
            { no: 5, name: "quantity_ordered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fee_cost", kind: "message", T: () => Money },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<OrderPDFData_VendorProduct>): OrderPDFData_VendorProduct {
        const message = { identifier: "", name: "", quantityOrdered: 0, notes: "", quantityReceived: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderPDFData_VendorProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderPDFData_VendorProduct): OrderPDFData_VendorProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money total */ 4:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* int32 quantity_ordered */ 5:
                    message.quantityOrdered = reader.int32();
                    break;
                case /* rd.proto.money.Money fee_cost */ 6:
                    message.feeCost = Money.internalBinaryRead(reader, reader.uint32(), options, message.feeCost);
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* int32 quantity_received */ 8:
                    message.quantityReceived = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 9:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderPDFData_VendorProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money total = 4; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_ordered = 5; */
        if (message.quantityOrdered !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantityOrdered);
        /* rd.proto.money.Money fee_cost = 6; */
        if (message.feeCost)
            Money.internalBinaryWrite(message.feeCost, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* int32 quantity_received = 8; */
        if (message.quantityReceived !== 0)
            writer.tag(8, WireType.Varint).int32(message.quantityReceived);
        /* rd.proto.money.FractionalMoney cost_v2 = 9; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderPDFData.VendorProduct
 */
export const OrderPDFData_VendorProduct = new OrderPDFData_VendorProduct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertOrderError$Type extends MessageType<UpsertOrderError> {
    constructor() {
        super("rd.txn.UpsertOrderError", [
            { no: 1, name: "invalid_upc_products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product }
        ]);
    }
    create(value?: PartialMessage<UpsertOrderError>): UpsertOrderError {
        const message = { invalidUpcProducts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertOrderError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertOrderError): UpsertOrderError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.product.Product invalid_upc_products */ 1:
                    message.invalidUpcProducts.push(Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertOrderError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.product.Product invalid_upc_products = 1; */
        for (let i = 0; i < message.invalidUpcProducts.length; i++)
            Product.internalBinaryWrite(message.invalidUpcProducts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.UpsertOrderError
 */
export const UpsertOrderError = new UpsertOrderError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transfer$Type extends MessageType<Transfer> {
    constructor() {
        super("rd.txn.Transfer", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "created_by", kind: "message", T: () => User },
            { no: 6, name: "sent_by", kind: "message", T: () => User },
            { no: 7, name: "received_by", kind: "message", T: () => User },
            { no: 8, name: "voided_by", kind: "message", T: () => User },
            { no: 9, name: "from_location_id", kind: "message", T: () => UUID },
            { no: 10, name: "to_location_id", kind: "message", T: () => UUID },
            { no: 11, name: "sent_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "received_at", kind: "message", T: () => Timestamp },
            { no: 13, name: "voided_at", kind: "message", T: () => Timestamp },
            { no: 14, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "status", kind: "enum", T: () => ["rd.txn.Transfer.Status", Transfer_Status, "STATUS_"] },
            { no: 16, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Transfer_Product } },
            { no: 17, name: "total_cost_cents", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 18, name: "send_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "receive_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Transfer>): Transfer {
        const message = { identifier: "", status: 0, productsById: {}, totalCostCents: 0n, sendNotes: "", receiveNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Transfer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transfer): Transfer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User sent_by */ 6:
                    message.sentBy = User.internalBinaryRead(reader, reader.uint32(), options, message.sentBy);
                    break;
                case /* rd.user.User received_by */ 7:
                    message.receivedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.receivedBy);
                    break;
                case /* rd.user.User voided_by */ 8:
                    message.voidedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.voidedBy);
                    break;
                case /* rd.proto.uuid.UUID from_location_id */ 9:
                    message.fromLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.fromLocationId);
                    break;
                case /* rd.proto.uuid.UUID to_location_id */ 10:
                    message.toLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.toLocationId);
                    break;
                case /* google.protobuf.Timestamp sent_at */ 11:
                    message.sentAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.sentAt);
                    break;
                case /* google.protobuf.Timestamp received_at */ 12:
                    message.receivedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.receivedAt);
                    break;
                case /* google.protobuf.Timestamp voided_at */ 13:
                    message.voidedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.voidedAt);
                    break;
                case /* string identifier */ 14:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.Transfer.Status status */ 15:
                    message.status = reader.int32();
                    break;
                case /* map<string, rd.txn.Transfer.Product> products_by_id */ 16:
                    this.binaryReadMap16(message.productsById, reader, options);
                    break;
                case /* int64 total_cost_cents */ 17:
                    message.totalCostCents = reader.int64().toBigInt();
                    break;
                case /* string send_notes */ 18:
                    message.sendNotes = reader.string();
                    break;
                case /* string receive_notes */ 19:
                    message.receiveNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap16(map: Transfer["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Transfer["productsById"] | undefined, val: Transfer["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Transfer_Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.Transfer.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Transfer_Product.create();
    }
    internalBinaryWrite(message: Transfer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User sent_by = 6; */
        if (message.sentBy)
            User.internalBinaryWrite(message.sentBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User received_by = 7; */
        if (message.receivedBy)
            User.internalBinaryWrite(message.receivedBy, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User voided_by = 8; */
        if (message.voidedBy)
            User.internalBinaryWrite(message.voidedBy, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID from_location_id = 9; */
        if (message.fromLocationId)
            UUID.internalBinaryWrite(message.fromLocationId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID to_location_id = 10; */
        if (message.toLocationId)
            UUID.internalBinaryWrite(message.toLocationId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp sent_at = 11; */
        if (message.sentAt)
            Timestamp.internalBinaryWrite(message.sentAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp received_at = 12; */
        if (message.receivedAt)
            Timestamp.internalBinaryWrite(message.receivedAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp voided_at = 13; */
        if (message.voidedAt)
            Timestamp.internalBinaryWrite(message.voidedAt, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 14; */
        if (message.identifier !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.Transfer.Status status = 15; */
        if (message.status !== 0)
            writer.tag(15, WireType.Varint).int32(message.status);
        /* map<string, rd.txn.Transfer.Product> products_by_id = 16; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Transfer_Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* int64 total_cost_cents = 17; */
        if (message.totalCostCents !== 0n)
            writer.tag(17, WireType.Varint).int64(message.totalCostCents);
        /* string send_notes = 18; */
        if (message.sendNotes !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.sendNotes);
        /* string receive_notes = 19; */
        if (message.receiveNotes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.receiveNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Transfer
 */
export const Transfer = new Transfer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transfer_Product$Type extends MessageType<Transfer_Product> {
    constructor() {
        super("rd.txn.Transfer.Product", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID },
            { no: 2, name: "quantity_sent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "additional_fee_when_received", kind: "message", T: () => Money },
            { no: 6, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<Transfer_Product>): Transfer_Product {
        const message = { quantitySent: 0, quantityReceived: 0, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Transfer_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transfer_Product): Transfer_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 quantity_sent */ 2:
                    message.quantitySent = reader.int32();
                    break;
                case /* int32 quantity_received */ 3:
                    message.quantityReceived = reader.int32();
                    break;
                case /* rd.proto.money.Money additional_fee_when_received */ 5:
                    message.additionalFeeWhenReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.additionalFeeWhenReceived);
                    break;
                case /* int32 position */ 6:
                    message.position = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 7:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Transfer_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_sent = 2; */
        if (message.quantitySent !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantitySent);
        /* int32 quantity_received = 3; */
        if (message.quantityReceived !== 0)
            writer.tag(3, WireType.Varint).int32(message.quantityReceived);
        /* rd.proto.money.Money additional_fee_when_received = 5; */
        if (message.additionalFeeWhenReceived)
            Money.internalBinaryWrite(message.additionalFeeWhenReceived, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 6; */
        if (message.position !== 0)
            writer.tag(6, WireType.Varint).int32(message.position);
        /* rd.proto.money.FractionalMoney cost_v2 = 7; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.Transfer.Product
 */
export const Transfer_Product = new Transfer_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferLog$Type extends MessageType<TransferLog> {
    constructor() {
        super("rd.txn.TransferLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "transfer", kind: "message", T: () => Transfer }
        ]);
    }
    create(value?: PartialMessage<TransferLog>): TransferLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransferLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferLog): TransferLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.txn.Transfer transfer */ 4:
                    message.transfer = Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Transfer transfer = 4; */
        if (message.transfer)
            Transfer.internalBinaryWrite(message.transfer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.TransferLog
 */
export const TransferLog = new TransferLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferPDFData$Type extends MessageType<TransferPDFData> {
    constructor() {
        super("rd.txn.TransferPDFData", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transfer", kind: "message", T: () => Transfer },
            { no: 3, name: "company", kind: "message", T: () => Company },
            { no: 4, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "to_location", kind: "message", T: () => Location },
            { no: 6, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransferPDFData_Product },
            { no: 7, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<TransferPDFData>): TransferPDFData {
        const message = { identifier: "", products: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransferPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferPDFData): TransferPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.Transfer transfer */ 2:
                    message.transfer = Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                case /* rd.company.Company company */ 3:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 4:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.company.Location to_location */ 5:
                    message.toLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.toLocation);
                    break;
                case /* repeated rd.txn.TransferPDFData.Product products */ 6:
                    message.products.push(TransferPDFData_Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money total */ 7:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.Transfer transfer = 2; */
        if (message.transfer)
            Transfer.internalBinaryWrite(message.transfer, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 3; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 4; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location to_location = 5; */
        if (message.toLocation)
            Location.internalBinaryWrite(message.toLocation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.TransferPDFData.Product products = 6; */
        for (let i = 0; i < message.products.length; i++)
            TransferPDFData_Product.internalBinaryWrite(message.products[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 7; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.TransferPDFData
 */
export const TransferPDFData = new TransferPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferPDFData_Product$Type extends MessageType<TransferPDFData_Product> {
    constructor() {
        super("rd.txn.TransferPDFData.Product", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "total", kind: "message", T: () => Money },
            { no: 5, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "fee_cost", kind: "message", T: () => Money },
            { no: 8, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<TransferPDFData_Product>): TransferPDFData_Product {
        const message = { identifier: "", name: "", quantity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransferPDFData_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferPDFData_Product): TransferPDFData_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money total */ 4:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* int32 quantity */ 5:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.money.Money fee_cost */ 7:
                    message.feeCost = Money.internalBinaryRead(reader, reader.uint32(), options, message.feeCost);
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 8:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferPDFData_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money total = 4; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 5; */
        if (message.quantity !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantity);
        /* rd.proto.money.Money fee_cost = 7; */
        if (message.feeCost)
            Money.internalBinaryWrite(message.feeCost, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost_v2 = 8; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.TransferPDFData.Product
 */
export const TransferPDFData_Product = new TransferPDFData_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillPayment$Type extends MessageType<BillPayment> {
    constructor() {
        super("rd.txn.BillPayment", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "message", T: () => User },
            { no: 5, name: "company_id", kind: "message", T: () => UUID },
            { no: 6, name: "customer_id", kind: "message", T: () => UUID },
            { no: 7, name: "location_id", kind: "message", T: () => UUID },
            { no: 8, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "refunded_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "refunded_by", kind: "message", T: () => User },
            { no: 12, name: "refund_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 14, name: "tax_rate", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<BillPayment>): BillPayment {
        const message = { identifier: "", notes: "", refundNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillPayment): BillPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User created_by */ 4:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 5:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 6:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 7:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string identifier */ 8:
                    message.identifier = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* google.protobuf.Timestamp refunded_at */ 10:
                    message.refundedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.refundedAt);
                    break;
                case /* rd.user.User refunded_by */ 11:
                    message.refundedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.refundedBy);
                    break;
                case /* string refund_notes */ 12:
                    message.refundNotes = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 13:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.decimal.Decimal tax_rate */ 14:
                    message.taxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 4; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 5; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 6; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 7; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 8; */
        if (message.identifier !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.identifier);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* google.protobuf.Timestamp refunded_at = 10; */
        if (message.refundedAt)
            Timestamp.internalBinaryWrite(message.refundedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User refunded_by = 11; */
        if (message.refundedBy)
            User.internalBinaryWrite(message.refundedBy, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string refund_notes = 12; */
        if (message.refundNotes !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.refundNotes);
        /* rd.proto.decimal.Decimal card_surcharge_rate = 13; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal tax_rate = 14; */
        if (message.taxRate)
            Decimal.internalBinaryWrite(message.taxRate, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.BillPayment
 */
export const BillPayment = new BillPayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillPaymentPDFData$Type extends MessageType<BillPaymentPDFData> {
    constructor() {
        super("rd.txn.BillPaymentPDFData", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 3, name: "company", kind: "message", T: () => Company },
            { no: 4, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "customer", kind: "message", T: () => Customer },
            { no: 6, name: "primary_contact", kind: "message", T: () => Contacts_Contact },
            { no: 7, name: "customer_balance", kind: "message", T: () => CustomerBalance },
            { no: 8, name: "sales", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BillPaymentPDFData_Sale },
            { no: 10, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 11, name: "sales_allocation", kind: "message", T: () => Money },
            { no: 12, name: "credit_allocation", kind: "message", T: () => Money },
            { no: 13, name: "payment_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "payment_amount", kind: "message", T: () => Money },
            { no: 15, name: "cash_received", kind: "message", T: () => Money },
            { no: 16, name: "cash_change", kind: "message", T: () => Money },
            { no: 17, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 18, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 19, name: "receipt_info", kind: "message", T: () => CardPaymentReceiptInfo }
        ]);
    }
    create(value?: PartialMessage<BillPaymentPDFData>): BillPaymentPDFData {
        const message = { identifier: "", sales: [], paymentMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillPaymentPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillPaymentPDFData): BillPaymentPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* rd.txn.BillPayment bill_payment */ 2:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.company.Company company */ 3:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 4:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 5:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts.Contact primary_contact */ 6:
                    message.primaryContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.primaryContact);
                    break;
                case /* rd.txn.CustomerBalance customer_balance */ 7:
                    message.customerBalance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.customerBalance);
                    break;
                case /* repeated rd.txn.BillPaymentPDFData.Sale sales */ 8:
                    message.sales.push(BillPaymentPDFData_Sale.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 10:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.money.Money sales_allocation */ 11:
                    message.salesAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesAllocation);
                    break;
                case /* rd.proto.money.Money credit_allocation */ 12:
                    message.creditAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.creditAllocation);
                    break;
                case /* string payment_method */ 13:
                    message.paymentMethod = reader.string();
                    break;
                case /* rd.proto.money.Money payment_amount */ 14:
                    message.paymentAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.paymentAmount);
                    break;
                case /* rd.proto.money.Money cash_received */ 15:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                case /* rd.proto.money.Money cash_change */ 16:
                    message.cashChange = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashChange);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 17:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 18:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* rd.payments.CardPaymentReceiptInfo receipt_info */ 19:
                    message.receiptInfo = CardPaymentReceiptInfo.internalBinaryRead(reader, reader.uint32(), options, message.receiptInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillPaymentPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* rd.txn.BillPayment bill_payment = 2; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 3; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 4; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 5; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact primary_contact = 6; */
        if (message.primaryContact)
            Contacts_Contact.internalBinaryWrite(message.primaryContact, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance customer_balance = 7; */
        if (message.customerBalance)
            CustomerBalance.internalBinaryWrite(message.customerBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.BillPaymentPDFData.Sale sales = 8; */
        for (let i = 0; i < message.sales.length; i++)
            BillPaymentPDFData_Sale.internalBinaryWrite(message.sales[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money financing_charge_allocation = 10; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_allocation = 11; */
        if (message.salesAllocation)
            Money.internalBinaryWrite(message.salesAllocation, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money credit_allocation = 12; */
        if (message.creditAllocation)
            Money.internalBinaryWrite(message.creditAllocation, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string payment_method = 13; */
        if (message.paymentMethod !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.paymentMethod);
        /* rd.proto.money.Money payment_amount = 14; */
        if (message.paymentAmount)
            Money.internalBinaryWrite(message.paymentAmount, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_received = 15; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_change = 16; */
        if (message.cashChange)
            Money.internalBinaryWrite(message.cashChange, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 17; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 18; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.CardPaymentReceiptInfo receipt_info = 19; */
        if (message.receiptInfo)
            CardPaymentReceiptInfo.internalBinaryWrite(message.receiptInfo, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.BillPaymentPDFData
 */
export const BillPaymentPDFData = new BillPaymentPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillPaymentPDFData_Sale$Type extends MessageType<BillPaymentPDFData_Sale> {
    constructor() {
        super("rd.txn.BillPaymentPDFData.Sale", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "purchase_order", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sold_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "amount_allocated", kind: "message", T: () => Money },
            { no: 7, name: "sale_id", kind: "message", T: () => UUID },
            { no: 8, name: "subtotal", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<BillPaymentPDFData_Sale>): BillPaymentPDFData_Sale {
        const message = { identifier: "", locationName: "", customerName: "", purchaseOrder: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillPaymentPDFData_Sale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillPaymentPDFData_Sale): BillPaymentPDFData_Sale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string location_name */ 2:
                    message.locationName = reader.string();
                    break;
                case /* string customer_name */ 3:
                    message.customerName = reader.string();
                    break;
                case /* string purchase_order */ 4:
                    message.purchaseOrder = reader.string();
                    break;
                case /* google.protobuf.Timestamp sold_at */ 5:
                    message.soldAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.soldAt);
                    break;
                case /* rd.proto.money.Money amount_allocated */ 6:
                    message.amountAllocated = Money.internalBinaryRead(reader, reader.uint32(), options, message.amountAllocated);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 7:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.money.Money subtotal */ 8:
                    message.subtotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subtotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillPaymentPDFData_Sale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string location_name = 2; */
        if (message.locationName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationName);
        /* string customer_name = 3; */
        if (message.customerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerName);
        /* string purchase_order = 4; */
        if (message.purchaseOrder !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.purchaseOrder);
        /* google.protobuf.Timestamp sold_at = 5; */
        if (message.soldAt)
            Timestamp.internalBinaryWrite(message.soldAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount_allocated = 6; */
        if (message.amountAllocated)
            Money.internalBinaryWrite(message.amountAllocated, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 7; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money subtotal = 8; */
        if (message.subtotal)
            Money.internalBinaryWrite(message.subtotal, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.BillPaymentPDFData.Sale
 */
export const BillPaymentPDFData_Sale = new BillPaymentPDFData_Sale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerBalance$Type extends MessageType<CustomerBalance> {
    constructor() {
        super("rd.txn.CustomerBalance", [
            { no: 1, name: "credits", kind: "message", T: () => Money },
            { no: 2, name: "current", kind: "message", T: () => Money },
            { no: 3, name: "late_1_to_30", kind: "message", T: () => Money },
            { no: 4, name: "late_31_to_60", kind: "message", T: () => Money },
            { no: 5, name: "late_61_plus", kind: "message", T: () => Money },
            { no: 6, name: "financing_charges", kind: "message", T: () => Money },
            { no: 7, name: "outstanding_purchases", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 8, name: "id", kind: "message", T: () => UUID },
            { no: 9, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CustomerBalance>): CustomerBalance {
        const message = { outstandingPurchases: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerBalance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerBalance): CustomerBalance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money credits */ 1:
                    message.credits = Money.internalBinaryRead(reader, reader.uint32(), options, message.credits);
                    break;
                case /* rd.proto.money.Money current */ 2:
                    message.current = Money.internalBinaryRead(reader, reader.uint32(), options, message.current);
                    break;
                case /* rd.proto.money.Money late_1_to_30 */ 3:
                    message.late1To30 = Money.internalBinaryRead(reader, reader.uint32(), options, message.late1To30);
                    break;
                case /* rd.proto.money.Money late_31_to_60 */ 4:
                    message.late31To60 = Money.internalBinaryRead(reader, reader.uint32(), options, message.late31To60);
                    break;
                case /* rd.proto.money.Money late_61_plus */ 5:
                    message.late61Plus = Money.internalBinaryRead(reader, reader.uint32(), options, message.late61Plus);
                    break;
                case /* rd.proto.money.Money financing_charges */ 6:
                    message.financingCharges = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingCharges);
                    break;
                case /* map<string, rd.proto.money.Money> outstanding_purchases */ 7:
                    this.binaryReadMap7(message.outstandingPurchases, reader, options);
                    break;
                case /* rd.proto.uuid.UUID id */ 8:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 9:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 10:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: CustomerBalance["outstandingPurchases"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerBalance["outstandingPurchases"] | undefined, val: CustomerBalance["outstandingPurchases"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerBalance.outstanding_purchases");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CustomerBalance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money credits = 1; */
        if (message.credits)
            Money.internalBinaryWrite(message.credits, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money current = 2; */
        if (message.current)
            Money.internalBinaryWrite(message.current, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_1_to_30 = 3; */
        if (message.late1To30)
            Money.internalBinaryWrite(message.late1To30, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_31_to_60 = 4; */
        if (message.late31To60)
            Money.internalBinaryWrite(message.late31To60, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_61_plus = 5; */
        if (message.late61Plus)
            Money.internalBinaryWrite(message.late61Plus, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money financing_charges = 6; */
        if (message.financingCharges)
            Money.internalBinaryWrite(message.financingCharges, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> outstanding_purchases = 7; */
        for (let k of Object.keys(message.outstandingPurchases)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.outstandingPurchases[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.uuid.UUID id = 8; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 9; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 10; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerBalance
 */
export const CustomerBalance = new CustomerBalance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAgingLedger$Type extends MessageType<CustomerAgingLedger> {
    constructor() {
        super("rd.txn.CustomerAgingLedger", [
            { no: 1, name: "credits", kind: "message", T: () => Money },
            { no: 2, name: "current", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 3, name: "late_1_to_30", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "late_31_to_60", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 5, name: "late_61_plus", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 6, name: "financing_charges", kind: "message", T: () => Money },
            { no: 7, name: "outstanding_purchases", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 8, name: "id", kind: "message", T: () => UUID },
            { no: 9, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CustomerAgingLedger>): CustomerAgingLedger {
        const message = { current: {}, late1To30: {}, late31To60: {}, late61Plus: {}, outstandingPurchases: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerAgingLedger>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerAgingLedger): CustomerAgingLedger {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money credits */ 1:
                    message.credits = Money.internalBinaryRead(reader, reader.uint32(), options, message.credits);
                    break;
                case /* map<string, rd.proto.money.Money> current */ 2:
                    this.binaryReadMap2(message.current, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> late_1_to_30 */ 3:
                    this.binaryReadMap3(message.late1To30, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> late_31_to_60 */ 4:
                    this.binaryReadMap4(message.late31To60, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> late_61_plus */ 5:
                    this.binaryReadMap5(message.late61Plus, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charges */ 6:
                    message.financingCharges = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingCharges);
                    break;
                case /* map<string, rd.proto.money.Money> outstanding_purchases */ 7:
                    this.binaryReadMap7(message.outstandingPurchases, reader, options);
                    break;
                case /* rd.proto.uuid.UUID id */ 8:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 9:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 10:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: CustomerAgingLedger["current"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerAgingLedger["current"] | undefined, val: CustomerAgingLedger["current"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerAgingLedger.current");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap3(map: CustomerAgingLedger["late1To30"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerAgingLedger["late1To30"] | undefined, val: CustomerAgingLedger["late1To30"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerAgingLedger.late_1_to_30");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap4(map: CustomerAgingLedger["late31To60"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerAgingLedger["late31To60"] | undefined, val: CustomerAgingLedger["late31To60"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerAgingLedger.late_31_to_60");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap5(map: CustomerAgingLedger["late61Plus"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerAgingLedger["late61Plus"] | undefined, val: CustomerAgingLedger["late61Plus"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerAgingLedger.late_61_plus");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: CustomerAgingLedger["outstandingPurchases"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CustomerAgingLedger["outstandingPurchases"] | undefined, val: CustomerAgingLedger["outstandingPurchases"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CustomerAgingLedger.outstanding_purchases");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CustomerAgingLedger, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money credits = 1; */
        if (message.credits)
            Money.internalBinaryWrite(message.credits, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> current = 2; */
        for (let k of Object.keys(message.current)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.current[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> late_1_to_30 = 3; */
        for (let k of Object.keys(message.late1To30)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.late1To30[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> late_31_to_60 = 4; */
        for (let k of Object.keys(message.late31To60)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.late31To60[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> late_61_plus = 5; */
        for (let k of Object.keys(message.late61Plus)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.late61Plus[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charges = 6; */
        if (message.financingCharges)
            Money.internalBinaryWrite(message.financingCharges, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> outstanding_purchases = 7; */
        for (let k of Object.keys(message.outstandingPurchases)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.outstandingPurchases[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.uuid.UUID id = 8; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 9; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 10; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerAgingLedger
 */
export const CustomerAgingLedger = new CustomerAgingLedger$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSaleHistoryPDFData$Type extends MessageType<CustomerSaleHistoryPDFData> {
    constructor() {
        super("rd.txn.CustomerSaleHistoryPDFData", [
            { no: 1, name: "company", kind: "message", T: () => Company },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer", kind: "message", T: () => Customer },
            { no: 4, name: "primary_contact", kind: "message", T: () => Contacts_Contact },
            { no: 5, name: "timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "sales", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomerSaleHistoryPDFData_Sale },
            { no: 7, name: "sales_total", kind: "message", T: () => Money },
            { no: 8, name: "purchase_orders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseOrder },
            { no: 9, name: "remaining_balance_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CustomerSaleHistoryPDFData>): CustomerSaleHistoryPDFData {
        const message = { sales: [], purchaseOrders: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSaleHistoryPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSaleHistoryPDFData): CustomerSaleHistoryPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Company company */ 1:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 3:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts.Contact primary_contact */ 4:
                    message.primaryContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.primaryContact);
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 5:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* repeated rd.txn.CustomerSaleHistoryPDFData.Sale sales */ 6:
                    message.sales.push(CustomerSaleHistoryPDFData_Sale.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money sales_total */ 7:
                    message.salesTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTotal);
                    break;
                case /* repeated rd.customer.PurchaseOrder purchase_orders */ 8:
                    message.purchaseOrders.push(PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money remaining_balance_total */ 9:
                    message.remainingBalanceTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.remainingBalanceTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSaleHistoryPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Company company = 1; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 3; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact primary_contact = 4; */
        if (message.primaryContact)
            Contacts_Contact.internalBinaryWrite(message.primaryContact, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 5; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.CustomerSaleHistoryPDFData.Sale sales = 6; */
        for (let i = 0; i < message.sales.length; i++)
            CustomerSaleHistoryPDFData_Sale.internalBinaryWrite(message.sales[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_total = 7; */
        if (message.salesTotal)
            Money.internalBinaryWrite(message.salesTotal, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.customer.PurchaseOrder purchase_orders = 8; */
        for (let i = 0; i < message.purchaseOrders.length; i++)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrders[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money remaining_balance_total = 9; */
        if (message.remainingBalanceTotal)
            Money.internalBinaryWrite(message.remainingBalanceTotal, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerSaleHistoryPDFData
 */
export const CustomerSaleHistoryPDFData = new CustomerSaleHistoryPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSaleHistoryPDFData_Sale$Type extends MessageType<CustomerSaleHistoryPDFData_Sale> {
    constructor() {
        super("rd.txn.CustomerSaleHistoryPDFData.Sale", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "purchase_order", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sold_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "due_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "total", kind: "message", T: () => Money },
            { no: 7, name: "remaining_balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CustomerSaleHistoryPDFData_Sale>): CustomerSaleHistoryPDFData_Sale {
        const message = { identifier: "", locationName: "", purchaseOrder: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSaleHistoryPDFData_Sale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSaleHistoryPDFData_Sale): CustomerSaleHistoryPDFData_Sale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string location_name */ 2:
                    message.locationName = reader.string();
                    break;
                case /* string purchase_order */ 3:
                    message.purchaseOrder = reader.string();
                    break;
                case /* google.protobuf.Timestamp sold_at */ 4:
                    message.soldAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.soldAt);
                    break;
                case /* google.protobuf.Timestamp due_at */ 5:
                    message.dueAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueAt);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.proto.money.Money remaining_balance */ 7:
                    message.remainingBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.remainingBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSaleHistoryPDFData_Sale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string location_name = 2; */
        if (message.locationName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationName);
        /* string purchase_order = 3; */
        if (message.purchaseOrder !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.purchaseOrder);
        /* google.protobuf.Timestamp sold_at = 4; */
        if (message.soldAt)
            Timestamp.internalBinaryWrite(message.soldAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_at = 5; */
        if (message.dueAt)
            Timestamp.internalBinaryWrite(message.dueAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money remaining_balance = 7; */
        if (message.remainingBalance)
            Money.internalBinaryWrite(message.remainingBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerSaleHistoryPDFData.Sale
 */
export const CustomerSaleHistoryPDFData_Sale = new CustomerSaleHistoryPDFData_Sale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PettyCash$Type extends MessageType<PettyCash> {
    constructor() {
        super("rd.txn.PettyCash", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "location_id", kind: "message", T: () => UUID },
            { no: 7, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<PettyCash>): PettyCash {
        const message = { identifier: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PettyCash>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PettyCash): PettyCash {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* string identifier */ 4:
                    message.identifier = reader.string();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.uuid.UUID location_id */ 6:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 7:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PettyCash, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 4; */
        if (message.identifier !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.identifier);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.uuid.UUID location_id = 6; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 7; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PettyCash
 */
export const PettyCash = new PettyCash$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseOut$Type extends MessageType<CloseOut> {
    constructor() {
        super("rd.txn.CloseOut", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "location_id", kind: "message", T: () => UUID },
            { no: 6, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "counted_100_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "counted_50_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "counted_20_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "counted_10_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "counted_5_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "counted_1_dollar_bills", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "counted_25_cent_coins", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "counted_10_cent_coins", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "counted_5_cent_coins", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "counted_1_cent_coins", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "float", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CloseOut>): CloseOut {
        const message = { identifier: "", notes: "", counted100DollarBills: 0, counted50DollarBills: 0, counted20DollarBills: 0, counted10DollarBills: 0, counted5DollarBills: 0, counted1DollarBills: 0, counted25CentCoins: 0, counted10CentCoins: 0, counted5CentCoins: 0, counted1CentCoins: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloseOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseOut): CloseOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 5:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string identifier */ 6:
                    message.identifier = reader.string();
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* int32 counted_100_dollar_bills */ 8:
                    message.counted100DollarBills = reader.int32();
                    break;
                case /* int32 counted_50_dollar_bills */ 9:
                    message.counted50DollarBills = reader.int32();
                    break;
                case /* int32 counted_20_dollar_bills */ 10:
                    message.counted20DollarBills = reader.int32();
                    break;
                case /* int32 counted_10_dollar_bills */ 11:
                    message.counted10DollarBills = reader.int32();
                    break;
                case /* int32 counted_5_dollar_bills */ 12:
                    message.counted5DollarBills = reader.int32();
                    break;
                case /* int32 counted_1_dollar_bills */ 13:
                    message.counted1DollarBills = reader.int32();
                    break;
                case /* int32 counted_25_cent_coins */ 14:
                    message.counted25CentCoins = reader.int32();
                    break;
                case /* int32 counted_10_cent_coins */ 15:
                    message.counted10CentCoins = reader.int32();
                    break;
                case /* int32 counted_5_cent_coins */ 16:
                    message.counted5CentCoins = reader.int32();
                    break;
                case /* int32 counted_1_cent_coins */ 17:
                    message.counted1CentCoins = reader.int32();
                    break;
                case /* rd.proto.money.Money float */ 18:
                    message.float = Money.internalBinaryRead(reader, reader.uint32(), options, message.float);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 5; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 6; */
        if (message.identifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.identifier);
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* int32 counted_100_dollar_bills = 8; */
        if (message.counted100DollarBills !== 0)
            writer.tag(8, WireType.Varint).int32(message.counted100DollarBills);
        /* int32 counted_50_dollar_bills = 9; */
        if (message.counted50DollarBills !== 0)
            writer.tag(9, WireType.Varint).int32(message.counted50DollarBills);
        /* int32 counted_20_dollar_bills = 10; */
        if (message.counted20DollarBills !== 0)
            writer.tag(10, WireType.Varint).int32(message.counted20DollarBills);
        /* int32 counted_10_dollar_bills = 11; */
        if (message.counted10DollarBills !== 0)
            writer.tag(11, WireType.Varint).int32(message.counted10DollarBills);
        /* int32 counted_5_dollar_bills = 12; */
        if (message.counted5DollarBills !== 0)
            writer.tag(12, WireType.Varint).int32(message.counted5DollarBills);
        /* int32 counted_1_dollar_bills = 13; */
        if (message.counted1DollarBills !== 0)
            writer.tag(13, WireType.Varint).int32(message.counted1DollarBills);
        /* int32 counted_25_cent_coins = 14; */
        if (message.counted25CentCoins !== 0)
            writer.tag(14, WireType.Varint).int32(message.counted25CentCoins);
        /* int32 counted_10_cent_coins = 15; */
        if (message.counted10CentCoins !== 0)
            writer.tag(15, WireType.Varint).int32(message.counted10CentCoins);
        /* int32 counted_5_cent_coins = 16; */
        if (message.counted5CentCoins !== 0)
            writer.tag(16, WireType.Varint).int32(message.counted5CentCoins);
        /* int32 counted_1_cent_coins = 17; */
        if (message.counted1CentCoins !== 0)
            writer.tag(17, WireType.Varint).int32(message.counted1CentCoins);
        /* rd.proto.money.Money float = 18; */
        if (message.float)
            Money.internalBinaryWrite(message.float, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CloseOut
 */
export const CloseOut = new CloseOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PurchaseMethod$Type extends MessageType<PurchaseMethod> {
    constructor() {
        super("rd.txn.PurchaseMethod", [
            { no: 1, name: "method", kind: "enum", T: () => ["rd.txn.PurchaseMethod.Method", PurchaseMethod_Method, "METHOD_"] },
            { no: 2, name: "card", kind: "message", T: () => Card },
            { no: 3, name: "check_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount", kind: "message", T: () => Money },
            { no: 5, name: "cash_received", kind: "message", T: () => Money },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PurchaseMethod>): PurchaseMethod {
        const message = { method: 0, checkNumber: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PurchaseMethod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PurchaseMethod): PurchaseMethod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.PurchaseMethod.Method method */ 1:
                    message.method = reader.int32();
                    break;
                case /* rd.payments.Card card */ 2:
                    message.card = Card.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                case /* string check_number */ 3:
                    message.checkNumber = reader.string();
                    break;
                case /* rd.proto.money.Money amount */ 4:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.proto.money.Money cash_received */ 5:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PurchaseMethod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.PurchaseMethod.Method method = 1; */
        if (message.method !== 0)
            writer.tag(1, WireType.Varint).int32(message.method);
        /* rd.payments.Card card = 2; */
        if (message.card)
            Card.internalBinaryWrite(message.card, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string check_number = 3; */
        if (message.checkNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.checkNumber);
        /* rd.proto.money.Money amount = 4; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_received = 5; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PurchaseMethod
 */
export const PurchaseMethod = new PurchaseMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMetadata$Type extends MessageType<PaymentMetadata> {
    constructor() {
        super("rd.txn.PaymentMetadata", [
            { no: 1, name: "payment_method", kind: "enum", T: () => ["rd.txn.LedgerEntry.Payment.Method", LedgerEntry_Payment_Method, "METHOD_"] },
            { no: 2, name: "amount", kind: "message", T: () => Money },
            { no: 3, name: "should_save_card", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "cash", kind: "message", T: () => PaymentMetadata_Cash },
            { no: 6, name: "check", kind: "message", T: () => PaymentMetadata_Check },
            { no: 7, name: "manually_entered_card", kind: "message", T: () => ManuallyEnteredCard },
            { no: 8, name: "saved_card", kind: "message", T: () => PaymentMetadata_SavedCard },
            { no: 9, name: "present_card", kind: "message", T: () => PaymentMetadata_PresentCard },
            { no: 10, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 11, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 12, name: "card_nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentMetadata>): PaymentMetadata {
        const message = { paymentMethod: 0, shouldSaveCard: false, stripePaymentIntentId: "", cardNickname: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMetadata): PaymentMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.LedgerEntry.Payment.Method payment_method */ 1:
                    message.paymentMethod = reader.int32();
                    break;
                case /* rd.proto.money.Money amount */ 2:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* bool should_save_card */ 3:
                    message.shouldSaveCard = reader.bool();
                    break;
                case /* string stripe_payment_intent_id */ 4:
                    message.stripePaymentIntentId = reader.string();
                    break;
                case /* rd.txn.PaymentMetadata.Cash cash */ 5:
                    message.cash = PaymentMetadata_Cash.internalBinaryRead(reader, reader.uint32(), options, message.cash);
                    break;
                case /* rd.txn.PaymentMetadata.Check check */ 6:
                    message.check = PaymentMetadata_Check.internalBinaryRead(reader, reader.uint32(), options, message.check);
                    break;
                case /* rd.payments.ManuallyEnteredCard manually_entered_card */ 7:
                    message.manuallyEnteredCard = ManuallyEnteredCard.internalBinaryRead(reader, reader.uint32(), options, message.manuallyEnteredCard);
                    break;
                case /* rd.txn.PaymentMetadata.SavedCard saved_card */ 8:
                    message.savedCard = PaymentMetadata_SavedCard.internalBinaryRead(reader, reader.uint32(), options, message.savedCard);
                    break;
                case /* rd.txn.PaymentMetadata.PresentCard present_card */ 9:
                    message.presentCard = PaymentMetadata_PresentCard.internalBinaryRead(reader, reader.uint32(), options, message.presentCard);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 10:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 11:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* string card_nickname */ 12:
                    message.cardNickname = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.LedgerEntry.Payment.Method payment_method = 1; */
        if (message.paymentMethod !== 0)
            writer.tag(1, WireType.Varint).int32(message.paymentMethod);
        /* rd.proto.money.Money amount = 2; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool should_save_card = 3; */
        if (message.shouldSaveCard !== false)
            writer.tag(3, WireType.Varint).bool(message.shouldSaveCard);
        /* string stripe_payment_intent_id = 4; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        /* rd.txn.PaymentMetadata.Cash cash = 5; */
        if (message.cash)
            PaymentMetadata_Cash.internalBinaryWrite(message.cash, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PaymentMetadata.Check check = 6; */
        if (message.check)
            PaymentMetadata_Check.internalBinaryWrite(message.check, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.ManuallyEnteredCard manually_entered_card = 7; */
        if (message.manuallyEnteredCard)
            ManuallyEnteredCard.internalBinaryWrite(message.manuallyEnteredCard, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PaymentMetadata.SavedCard saved_card = 8; */
        if (message.savedCard)
            PaymentMetadata_SavedCard.internalBinaryWrite(message.savedCard, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PaymentMetadata.PresentCard present_card = 9; */
        if (message.presentCard)
            PaymentMetadata_PresentCard.internalBinaryWrite(message.presentCard, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 10; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 11; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string card_nickname = 12; */
        if (message.cardNickname !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.cardNickname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PaymentMetadata
 */
export const PaymentMetadata = new PaymentMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMetadata_Cash$Type extends MessageType<PaymentMetadata_Cash> {
    constructor() {
        super("rd.txn.PaymentMetadata.Cash", [
            { no: 1, name: "cash_received", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<PaymentMetadata_Cash>): PaymentMetadata_Cash {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMetadata_Cash>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMetadata_Cash): PaymentMetadata_Cash {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money cash_received */ 1:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMetadata_Cash, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money cash_received = 1; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PaymentMetadata.Cash
 */
export const PaymentMetadata_Cash = new PaymentMetadata_Cash$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMetadata_Check$Type extends MessageType<PaymentMetadata_Check> {
    constructor() {
        super("rd.txn.PaymentMetadata.Check", [
            { no: 1, name: "check_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentMetadata_Check>): PaymentMetadata_Check {
        const message = { checkNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMetadata_Check>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMetadata_Check): PaymentMetadata_Check {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string check_number */ 1:
                    message.checkNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMetadata_Check, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string check_number = 1; */
        if (message.checkNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.checkNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PaymentMetadata.Check
 */
export const PaymentMetadata_Check = new PaymentMetadata_Check$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMetadata_SavedCard$Type extends MessageType<PaymentMetadata_SavedCard> {
    constructor() {
        super("rd.txn.PaymentMetadata.SavedCard", [
            { no: 1, name: "stripe_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentMetadata_SavedCard>): PaymentMetadata_SavedCard {
        const message = { stripePaymentMethodId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMetadata_SavedCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMetadata_SavedCard): PaymentMetadata_SavedCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_payment_method_id */ 1:
                    message.stripePaymentMethodId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMetadata_SavedCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_payment_method_id = 1; */
        if (message.stripePaymentMethodId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripePaymentMethodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PaymentMetadata.SavedCard
 */
export const PaymentMetadata_SavedCard = new PaymentMetadata_SavedCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMetadata_PresentCard$Type extends MessageType<PaymentMetadata_PresentCard> {
    constructor() {
        super("rd.txn.PaymentMetadata.PresentCard", [
            { no: 1, name: "stripe_reader_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } }
        ]);
    }
    create(value?: PartialMessage<PaymentMetadata_PresentCard>): PaymentMetadata_PresentCard {
        const message = { stripeReaderId: "", zipCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMetadata_PresentCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMetadata_PresentCard): PaymentMetadata_PresentCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_reader_id */ 1:
                    message.stripeReaderId = reader.string();
                    break;
                case /* string zip_code */ 2:
                    message.zipCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMetadata_PresentCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_reader_id = 1; */
        if (message.stripeReaderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeReaderId);
        /* string zip_code = 2; */
        if (message.zipCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.zipCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.PaymentMetadata.PresentCard
 */
export const PaymentMetadata_PresentCard = new PaymentMetadata_PresentCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSalesAnalytics$Type extends MessageType<CustomerSalesAnalytics> {
    constructor() {
        super("rd.txn.CustomerSalesAnalytics", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "sales_ytd", kind: "message", T: () => Money },
            { no: 4, name: "sales_last_ytd", kind: "message", T: () => Money },
            { no: 5, name: "yoy_growth", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "margin_ytd", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "last_sale", kind: "message", T: () => Timestamp },
            { no: 8, name: "sales_last_12mo", kind: "message", jsonName: "salesLast12mo", T: () => Money },
            { no: 9, name: "sales_last_3mo", kind: "message", jsonName: "salesLast3mo", T: () => Money },
            { no: 10, name: "sales_last_1mo", kind: "message", jsonName: "salesLast1mo", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CustomerSalesAnalytics>): CustomerSalesAnalytics {
        const message = { yoyGrowth: 0, marginYtd: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSalesAnalytics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSalesAnalytics): CustomerSalesAnalytics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.money.Money sales_ytd */ 3:
                    message.salesYtd = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesYtd);
                    break;
                case /* rd.proto.money.Money sales_last_ytd */ 4:
                    message.salesLastYtd = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesLastYtd);
                    break;
                case /* float yoy_growth */ 5:
                    message.yoyGrowth = reader.float();
                    break;
                case /* float margin_ytd */ 6:
                    message.marginYtd = reader.float();
                    break;
                case /* google.protobuf.Timestamp last_sale */ 7:
                    message.lastSale = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastSale);
                    break;
                case /* rd.proto.money.Money sales_last_12mo = 8 [json_name = "salesLast12mo"];*/ 8:
                    message.salesLast12Mo = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesLast12Mo);
                    break;
                case /* rd.proto.money.Money sales_last_3mo = 9 [json_name = "salesLast3mo"];*/ 9:
                    message.salesLast3Mo = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesLast3Mo);
                    break;
                case /* rd.proto.money.Money sales_last_1mo = 10 [json_name = "salesLast1mo"];*/ 10:
                    message.salesLast1Mo = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesLast1Mo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSalesAnalytics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_ytd = 3; */
        if (message.salesYtd)
            Money.internalBinaryWrite(message.salesYtd, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_last_ytd = 4; */
        if (message.salesLastYtd)
            Money.internalBinaryWrite(message.salesLastYtd, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* float yoy_growth = 5; */
        if (message.yoyGrowth !== 0)
            writer.tag(5, WireType.Bit32).float(message.yoyGrowth);
        /* float margin_ytd = 6; */
        if (message.marginYtd !== 0)
            writer.tag(6, WireType.Bit32).float(message.marginYtd);
        /* google.protobuf.Timestamp last_sale = 7; */
        if (message.lastSale)
            Timestamp.internalBinaryWrite(message.lastSale, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_last_12mo = 8 [json_name = "salesLast12mo"]; */
        if (message.salesLast12Mo)
            Money.internalBinaryWrite(message.salesLast12Mo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_last_3mo = 9 [json_name = "salesLast3mo"]; */
        if (message.salesLast3Mo)
            Money.internalBinaryWrite(message.salesLast3Mo, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_last_1mo = 10 [json_name = "salesLast1mo"]; */
        if (message.salesLast1Mo)
            Money.internalBinaryWrite(message.salesLast1Mo, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerSalesAnalytics
 */
export const CustomerSalesAnalytics = new CustomerSalesAnalytics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSalesRollup$Type extends MessageType<CustomerSalesRollup> {
    constructor() {
        super("rd.txn.CustomerSalesRollup", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "series_start", kind: "message", T: () => Timestamp },
            { no: 5, name: "series_end", kind: "message", T: () => Timestamp },
            { no: 6, name: "company_id", kind: "message", T: () => UUID },
            { no: 7, name: "customer_id", kind: "message", T: () => UUID },
            { no: 8, name: "ledger_entries_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "sales_data", kind: "message", T: () => CustomerSalesRollup_SalesData }
        ]);
    }
    create(value?: PartialMessage<CustomerSalesRollup>): CustomerSalesRollup {
        const message = { ledgerEntriesHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSalesRollup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSalesRollup): CustomerSalesRollup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* google.protobuf.Timestamp series_start */ 4:
                    message.seriesStart = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seriesStart);
                    break;
                case /* google.protobuf.Timestamp series_end */ 5:
                    message.seriesEnd = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seriesEnd);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 7:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* string ledger_entries_hash */ 8:
                    message.ledgerEntriesHash = reader.string();
                    break;
                case /* rd.txn.CustomerSalesRollup.SalesData sales_data */ 9:
                    message.salesData = CustomerSalesRollup_SalesData.internalBinaryRead(reader, reader.uint32(), options, message.salesData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSalesRollup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp series_start = 4; */
        if (message.seriesStart)
            Timestamp.internalBinaryWrite(message.seriesStart, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp series_end = 5; */
        if (message.seriesEnd)
            Timestamp.internalBinaryWrite(message.seriesEnd, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 7; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string ledger_entries_hash = 8; */
        if (message.ledgerEntriesHash !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.ledgerEntriesHash);
        /* rd.txn.CustomerSalesRollup.SalesData sales_data = 9; */
        if (message.salesData)
            CustomerSalesRollup_SalesData.internalBinaryWrite(message.salesData, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerSalesRollup
 */
export const CustomerSalesRollup = new CustomerSalesRollup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerSalesRollup_SalesData$Type extends MessageType<CustomerSalesRollup_SalesData> {
    constructor() {
        super("rd.txn.CustomerSalesRollup.SalesData", [
            { no: 1, name: "price_of_goods_sold", kind: "message", T: () => Money },
            { no: 3, name: "last_sale", kind: "message", T: () => Timestamp },
            { no: 4, name: "cost_of_goods_sold_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<CustomerSalesRollup_SalesData>): CustomerSalesRollup_SalesData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerSalesRollup_SalesData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerSalesRollup_SalesData): CustomerSalesRollup_SalesData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money price_of_goods_sold */ 1:
                    message.priceOfGoodsSold = Money.internalBinaryRead(reader, reader.uint32(), options, message.priceOfGoodsSold);
                    break;
                case /* google.protobuf.Timestamp last_sale */ 3:
                    message.lastSale = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastSale);
                    break;
                case /* rd.proto.money.FractionalMoney cost_of_goods_sold_v2 */ 4:
                    message.costOfGoodsSoldV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costOfGoodsSoldV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerSalesRollup_SalesData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money price_of_goods_sold = 1; */
        if (message.priceOfGoodsSold)
            Money.internalBinaryWrite(message.priceOfGoodsSold, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_sale = 3; */
        if (message.lastSale)
            Timestamp.internalBinaryWrite(message.lastSale, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost_of_goods_sold_v2 = 4; */
        if (message.costOfGoodsSoldV2)
            FractionalMoney.internalBinaryWrite(message.costOfGoodsSoldV2, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CustomerSalesRollup.SalesData
 */
export const CustomerSalesRollup_SalesData = new CustomerSalesRollup_SalesData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSalesAnalytics$Type extends MessageType<ProductSalesAnalytics> {
    constructor() {
        super("rd.txn.ProductSalesAnalytics", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "product_id", kind: "message", T: () => UUID },
            { no: 3, name: "location_id", kind: "message", T: () => UUID },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "units_ytd", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "units_last_ytd", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "yoy_growth", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "margin_ytd", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "last_sale", kind: "message", T: () => Timestamp },
            { no: 10, name: "units_last_12mo", kind: "scalar", jsonName: "unitsLast12mo", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "units_last_3mo", kind: "scalar", jsonName: "unitsLast3mo", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "units_last_1mo", kind: "scalar", jsonName: "unitsLast1mo", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "inventory_turns", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ProductSalesAnalytics>): ProductSalesAnalytics {
        const message = { unitsYtd: 0, unitsLastYtd: 0, yoyGrowth: 0, marginYtd: 0, unitsLast12Mo: 0, unitsLast3Mo: 0, unitsLast1Mo: 0, inventoryTurns: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductSalesAnalytics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductSalesAnalytics): ProductSalesAnalytics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 2:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 3:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* int32 units_ytd */ 5:
                    message.unitsYtd = reader.int32();
                    break;
                case /* int32 units_last_ytd */ 6:
                    message.unitsLastYtd = reader.int32();
                    break;
                case /* float yoy_growth */ 7:
                    message.yoyGrowth = reader.float();
                    break;
                case /* float margin_ytd */ 8:
                    message.marginYtd = reader.float();
                    break;
                case /* google.protobuf.Timestamp last_sale */ 9:
                    message.lastSale = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastSale);
                    break;
                case /* int32 units_last_12mo = 10 [json_name = "unitsLast12mo"];*/ 10:
                    message.unitsLast12Mo = reader.int32();
                    break;
                case /* int32 units_last_3mo = 11 [json_name = "unitsLast3mo"];*/ 11:
                    message.unitsLast3Mo = reader.int32();
                    break;
                case /* int32 units_last_1mo = 12 [json_name = "unitsLast1mo"];*/ 12:
                    message.unitsLast1Mo = reader.int32();
                    break;
                case /* float inventory_turns */ 13:
                    message.inventoryTurns = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductSalesAnalytics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 2; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 3; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 units_ytd = 5; */
        if (message.unitsYtd !== 0)
            writer.tag(5, WireType.Varint).int32(message.unitsYtd);
        /* int32 units_last_ytd = 6; */
        if (message.unitsLastYtd !== 0)
            writer.tag(6, WireType.Varint).int32(message.unitsLastYtd);
        /* float yoy_growth = 7; */
        if (message.yoyGrowth !== 0)
            writer.tag(7, WireType.Bit32).float(message.yoyGrowth);
        /* float margin_ytd = 8; */
        if (message.marginYtd !== 0)
            writer.tag(8, WireType.Bit32).float(message.marginYtd);
        /* google.protobuf.Timestamp last_sale = 9; */
        if (message.lastSale)
            Timestamp.internalBinaryWrite(message.lastSale, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 units_last_12mo = 10 [json_name = "unitsLast12mo"]; */
        if (message.unitsLast12Mo !== 0)
            writer.tag(10, WireType.Varint).int32(message.unitsLast12Mo);
        /* int32 units_last_3mo = 11 [json_name = "unitsLast3mo"]; */
        if (message.unitsLast3Mo !== 0)
            writer.tag(11, WireType.Varint).int32(message.unitsLast3Mo);
        /* int32 units_last_1mo = 12 [json_name = "unitsLast1mo"]; */
        if (message.unitsLast1Mo !== 0)
            writer.tag(12, WireType.Varint).int32(message.unitsLast1Mo);
        /* float inventory_turns = 13; */
        if (message.inventoryTurns !== 0)
            writer.tag(13, WireType.Bit32).float(message.inventoryTurns);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.ProductSalesAnalytics
 */
export const ProductSalesAnalytics = new ProductSalesAnalytics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSalesRollup$Type extends MessageType<ProductSalesRollup> {
    constructor() {
        super("rd.txn.ProductSalesRollup", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "series_start", kind: "message", T: () => Timestamp },
            { no: 5, name: "series_end", kind: "message", T: () => Timestamp },
            { no: 6, name: "company_id", kind: "message", T: () => UUID },
            { no: 7, name: "product_id", kind: "message", T: () => UUID },
            { no: 8, name: "sales_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "sales_data_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ProductSalesRollup_SalesData } }
        ]);
    }
    create(value?: PartialMessage<ProductSalesRollup>): ProductSalesRollup {
        const message = { salesHash: "", salesDataByLocationId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductSalesRollup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductSalesRollup): ProductSalesRollup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* google.protobuf.Timestamp series_start */ 4:
                    message.seriesStart = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seriesStart);
                    break;
                case /* google.protobuf.Timestamp series_end */ 5:
                    message.seriesEnd = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seriesEnd);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 7:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* string sales_hash */ 8:
                    message.salesHash = reader.string();
                    break;
                case /* map<string, rd.txn.ProductSalesRollup.SalesData> sales_data_by_location_id */ 9:
                    this.binaryReadMap9(message.salesDataByLocationId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap9(map: ProductSalesRollup["salesDataByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ProductSalesRollup["salesDataByLocationId"] | undefined, val: ProductSalesRollup["salesDataByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ProductSalesRollup_SalesData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.ProductSalesRollup.sales_data_by_location_id");
            }
        }
        map[key ?? ""] = val ?? ProductSalesRollup_SalesData.create();
    }
    internalBinaryWrite(message: ProductSalesRollup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp series_start = 4; */
        if (message.seriesStart)
            Timestamp.internalBinaryWrite(message.seriesStart, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp series_end = 5; */
        if (message.seriesEnd)
            Timestamp.internalBinaryWrite(message.seriesEnd, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 7; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string sales_hash = 8; */
        if (message.salesHash !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.salesHash);
        /* map<string, rd.txn.ProductSalesRollup.SalesData> sales_data_by_location_id = 9; */
        for (let k of Object.keys(message.salesDataByLocationId)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ProductSalesRollup_SalesData.internalBinaryWrite(message.salesDataByLocationId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.ProductSalesRollup
 */
export const ProductSalesRollup = new ProductSalesRollup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductSalesRollup_SalesData$Type extends MessageType<ProductSalesRollup_SalesData> {
    constructor() {
        super("rd.txn.ProductSalesRollup.SalesData", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID },
            { no: 2, name: "units_sold", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "price_of_goods_sold", kind: "message", T: () => Money },
            { no: 5, name: "last_sale", kind: "message", T: () => Timestamp },
            { no: 6, name: "cost_of_goods_sold_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<ProductSalesRollup_SalesData>): ProductSalesRollup_SalesData {
        const message = { unitsSold: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductSalesRollup_SalesData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductSalesRollup_SalesData): ProductSalesRollup_SalesData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* int32 units_sold */ 2:
                    message.unitsSold = reader.int32();
                    break;
                case /* rd.proto.money.Money price_of_goods_sold */ 3:
                    message.priceOfGoodsSold = Money.internalBinaryRead(reader, reader.uint32(), options, message.priceOfGoodsSold);
                    break;
                case /* google.protobuf.Timestamp last_sale */ 5:
                    message.lastSale = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastSale);
                    break;
                case /* rd.proto.money.FractionalMoney cost_of_goods_sold_v2 */ 6:
                    message.costOfGoodsSoldV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costOfGoodsSoldV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductSalesRollup_SalesData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 units_sold = 2; */
        if (message.unitsSold !== 0)
            writer.tag(2, WireType.Varint).int32(message.unitsSold);
        /* rd.proto.money.Money price_of_goods_sold = 3; */
        if (message.priceOfGoodsSold)
            Money.internalBinaryWrite(message.priceOfGoodsSold, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_sale = 5; */
        if (message.lastSale)
            Timestamp.internalBinaryWrite(message.lastSale, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney cost_of_goods_sold_v2 = 6; */
        if (message.costOfGoodsSoldV2)
            FractionalMoney.internalBinaryWrite(message.costOfGoodsSoldV2, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.ProductSalesRollup.SalesData
 */
export const ProductSalesRollup_SalesData = new ProductSalesRollup_SalesData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CondensedSalePDFData$Type extends MessageType<CondensedSalePDFData> {
    constructor() {
        super("rd.txn.CondensedSalePDFData", [
            { no: 1, name: "condensed_sales", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CondensedSalePDFData_Sale }
        ]);
    }
    create(value?: PartialMessage<CondensedSalePDFData>): CondensedSalePDFData {
        const message = { condensedSales: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CondensedSalePDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CondensedSalePDFData): CondensedSalePDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.txn.CondensedSalePDFData.Sale condensed_sales */ 1:
                    message.condensedSales.push(CondensedSalePDFData_Sale.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CondensedSalePDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.txn.CondensedSalePDFData.Sale condensed_sales = 1; */
        for (let i = 0; i < message.condensedSales.length; i++)
            CondensedSalePDFData_Sale.internalBinaryWrite(message.condensedSales[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CondensedSalePDFData
 */
export const CondensedSalePDFData = new CondensedSalePDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CondensedSalePDFData_Sale$Type extends MessageType<CondensedSalePDFData_Sale> {
    constructor() {
        super("rd.txn.CondensedSalePDFData.Sale", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sold_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "purchase_order_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sale_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "additional_fee_totals_by_name", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "sales_tax", kind: "message", T: () => Money },
            { no: 8, name: "refunded_total", kind: "message", T: () => Money },
            { no: 9, name: "adjusted_total", kind: "message", T: () => Money },
            { no: 10, name: "signature_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "tax_rate", kind: "message", T: () => TaxRate },
            { no: 13, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CondensedSalePDFData_Sale_Product },
            { no: 14, name: "show_discount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 17, name: "card_surcharge_rate_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "early_payment_discount", kind: "message", T: () => Money },
            { no: 19, name: "show_early_payment_discount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CondensedSalePDFData_Sale>): CondensedSalePDFData_Sale {
        const message = { identifier: "", purchaseOrderName: "", saleNotes: "", additionalFeeTotalsByName: {}, signatureUrl: "", customerName: "", products: [], showDiscount: false, cardSurchargeRateString: "", showEarlyPaymentDiscount: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CondensedSalePDFData_Sale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CondensedSalePDFData_Sale): CondensedSalePDFData_Sale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* google.protobuf.Timestamp sold_at */ 2:
                    message.soldAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.soldAt);
                    break;
                case /* string purchase_order_name */ 3:
                    message.purchaseOrderName = reader.string();
                    break;
                case /* string sale_notes */ 4:
                    message.saleNotes = reader.string();
                    break;
                case /* map<string, rd.proto.money.Money> additional_fee_totals_by_name */ 6:
                    this.binaryReadMap6(message.additionalFeeTotalsByName, reader, options);
                    break;
                case /* rd.proto.money.Money sales_tax */ 7:
                    message.salesTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTax);
                    break;
                case /* rd.proto.money.Money refunded_total */ 8:
                    message.refundedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.refundedTotal);
                    break;
                case /* rd.proto.money.Money adjusted_total */ 9:
                    message.adjustedTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotal);
                    break;
                case /* string signature_url */ 10:
                    message.signatureUrl = reader.string();
                    break;
                case /* string customer_name */ 11:
                    message.customerName = reader.string();
                    break;
                case /* rd.company.TaxRate tax_rate */ 12:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* repeated rd.txn.CondensedSalePDFData.Sale.Product products */ 13:
                    message.products.push(CondensedSalePDFData_Sale_Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool show_discount */ 14:
                    message.showDiscount = reader.bool();
                    break;
                case /* rd.proto.money.Money card_surcharge */ 16:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* string card_surcharge_rate_string */ 17:
                    message.cardSurchargeRateString = reader.string();
                    break;
                case /* rd.proto.money.Money early_payment_discount */ 18:
                    message.earlyPaymentDiscount = Money.internalBinaryRead(reader, reader.uint32(), options, message.earlyPaymentDiscount);
                    break;
                case /* bool show_early_payment_discount */ 19:
                    message.showEarlyPaymentDiscount = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: CondensedSalePDFData_Sale["additionalFeeTotalsByName"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CondensedSalePDFData_Sale["additionalFeeTotalsByName"] | undefined, val: CondensedSalePDFData_Sale["additionalFeeTotalsByName"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.txn.CondensedSalePDFData.Sale.additional_fee_totals_by_name");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CondensedSalePDFData_Sale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* google.protobuf.Timestamp sold_at = 2; */
        if (message.soldAt)
            Timestamp.internalBinaryWrite(message.soldAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string purchase_order_name = 3; */
        if (message.purchaseOrderName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.purchaseOrderName);
        /* string sale_notes = 4; */
        if (message.saleNotes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.saleNotes);
        /* map<string, rd.proto.money.Money> additional_fee_totals_by_name = 6; */
        for (let k of Object.keys(message.additionalFeeTotalsByName)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.additionalFeeTotalsByName[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money sales_tax = 7; */
        if (message.salesTax)
            Money.internalBinaryWrite(message.salesTax, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money refunded_total = 8; */
        if (message.refundedTotal)
            Money.internalBinaryWrite(message.refundedTotal, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total = 9; */
        if (message.adjustedTotal)
            Money.internalBinaryWrite(message.adjustedTotal, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string signature_url = 10; */
        if (message.signatureUrl !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.signatureUrl);
        /* string customer_name = 11; */
        if (message.customerName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.customerName);
        /* rd.company.TaxRate tax_rate = 12; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.CondensedSalePDFData.Sale.Product products = 13; */
        for (let i = 0; i < message.products.length; i++)
            CondensedSalePDFData_Sale_Product.internalBinaryWrite(message.products[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bool show_discount = 14; */
        if (message.showDiscount !== false)
            writer.tag(14, WireType.Varint).bool(message.showDiscount);
        /* rd.proto.money.Money card_surcharge = 16; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* string card_surcharge_rate_string = 17; */
        if (message.cardSurchargeRateString !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.cardSurchargeRateString);
        /* rd.proto.money.Money early_payment_discount = 18; */
        if (message.earlyPaymentDiscount)
            Money.internalBinaryWrite(message.earlyPaymentDiscount, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* bool show_early_payment_discount = 19; */
        if (message.showEarlyPaymentDiscount !== false)
            writer.tag(19, WireType.Varint).bool(message.showEarlyPaymentDiscount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CondensedSalePDFData.Sale
 */
export const CondensedSalePDFData_Sale = new CondensedSalePDFData_Sale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CondensedSalePDFData_Sale_Product$Type extends MessageType<CondensedSalePDFData_Sale_Product> {
    constructor() {
        super("rd.txn.CondensedSalePDFData.Sale.Product", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tint_color", kind: "message", T: () => TintColor },
            { no: 4, name: "sub_total", kind: "message", T: () => Money },
            { no: 5, name: "returned_sub_total", kind: "message", T: () => Money },
            { no: 6, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "quantity_returned", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "price", kind: "message", T: () => Money },
            { no: 9, name: "tier_1_price", kind: "message", T: () => Money },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "custom_tint_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CondensedSalePDFData_Sale_Product>): CondensedSalePDFData_Sale_Product {
        const message = { identifier: "", name: "", quantity: 0, quantityReturned: 0, notes: "", customTintColor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CondensedSalePDFData_Sale_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CondensedSalePDFData_Sale_Product): CondensedSalePDFData_Sale_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.tintcolor.TintColor tint_color */ 3:
                    message.tintColor = TintColor.internalBinaryRead(reader, reader.uint32(), options, message.tintColor);
                    break;
                case /* rd.proto.money.Money sub_total */ 4:
                    message.subTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.subTotal);
                    break;
                case /* rd.proto.money.Money returned_sub_total */ 5:
                    message.returnedSubTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.returnedSubTotal);
                    break;
                case /* int32 quantity */ 6:
                    message.quantity = reader.int32();
                    break;
                case /* int32 quantity_returned */ 7:
                    message.quantityReturned = reader.int32();
                    break;
                case /* rd.proto.money.Money price */ 8:
                    message.price = Money.internalBinaryRead(reader, reader.uint32(), options, message.price);
                    break;
                case /* rd.proto.money.Money tier_1_price */ 9:
                    message.tier1Price = Money.internalBinaryRead(reader, reader.uint32(), options, message.tier1Price);
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* string custom_tint_color */ 11:
                    message.customTintColor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CondensedSalePDFData_Sale_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.tintcolor.TintColor tint_color = 3; */
        if (message.tintColor)
            TintColor.internalBinaryWrite(message.tintColor, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sub_total = 4; */
        if (message.subTotal)
            Money.internalBinaryWrite(message.subTotal, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money returned_sub_total = 5; */
        if (message.returnedSubTotal)
            Money.internalBinaryWrite(message.returnedSubTotal, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 6; */
        if (message.quantity !== 0)
            writer.tag(6, WireType.Varint).int32(message.quantity);
        /* int32 quantity_returned = 7; */
        if (message.quantityReturned !== 0)
            writer.tag(7, WireType.Varint).int32(message.quantityReturned);
        /* rd.proto.money.Money price = 8; */
        if (message.price)
            Money.internalBinaryWrite(message.price, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money tier_1_price = 9; */
        if (message.tier1Price)
            Money.internalBinaryWrite(message.tier1Price, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* string custom_tint_color = 11; */
        if (message.customTintColor !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.customTintColor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.CondensedSalePDFData.Sale.Product
 */
export const CondensedSalePDFData_Sale_Product = new CondensedSalePDFData_Sale_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipmentPDFData$Type extends MessageType<OrderShipmentPDFData> {
    constructor() {
        super("rd.txn.OrderShipmentPDFData", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "order", kind: "message", T: () => Order },
            { no: 3, name: "company", kind: "message", T: () => Company },
            { no: 4, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "vendor", kind: "message", T: () => Vendor },
            { no: 6, name: "vendor_contact", kind: "message", T: () => Contacts_Contact$ },
            { no: 7, name: "products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderShipmentPDFData_Product },
            { no: 8, name: "total", kind: "message", T: () => Money },
            { no: 9, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "status_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "invoice_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "due_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "invoice_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "show_invoice_section", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<OrderShipmentPDFData>): OrderShipmentPDFData {
        const message = { products: [], accountNumber: "", statusTag: "", invoiceDate: "", dueDate: "", invoiceNumber: "", showInvoiceSection: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipmentPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipmentPDFData): OrderShipmentPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.txn.Order order */ 2:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* rd.company.Company company */ 3:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 4:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.vendors.Vendor vendor */ 5:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.vendors.Contacts.Contact vendor_contact */ 6:
                    message.vendorContact = Contacts_Contact$.internalBinaryRead(reader, reader.uint32(), options, message.vendorContact);
                    break;
                case /* repeated rd.txn.OrderShipmentPDFData.Product products */ 7:
                    message.products.push(OrderShipmentPDFData_Product.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.Money total */ 8:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* string account_number */ 9:
                    message.accountNumber = reader.string();
                    break;
                case /* string status_tag */ 10:
                    message.statusTag = reader.string();
                    break;
                case /* string invoice_date */ 11:
                    message.invoiceDate = reader.string();
                    break;
                case /* string due_date */ 12:
                    message.dueDate = reader.string();
                    break;
                case /* string invoice_number */ 13:
                    message.invoiceNumber = reader.string();
                    break;
                case /* bool show_invoice_section */ 14:
                    message.showInvoiceSection = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderShipmentPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Order order = 2; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company company = 3; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 4; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 5; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Contacts.Contact vendor_contact = 6; */
        if (message.vendorContact)
            Contacts_Contact$.internalBinaryWrite(message.vendorContact, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.OrderShipmentPDFData.Product products = 7; */
        for (let i = 0; i < message.products.length; i++)
            OrderShipmentPDFData_Product.internalBinaryWrite(message.products[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 8; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string account_number = 9; */
        if (message.accountNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.accountNumber);
        /* string status_tag = 10; */
        if (message.statusTag !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.statusTag);
        /* string invoice_date = 11; */
        if (message.invoiceDate !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.invoiceDate);
        /* string due_date = 12; */
        if (message.dueDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.dueDate);
        /* string invoice_number = 13; */
        if (message.invoiceNumber !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.invoiceNumber);
        /* bool show_invoice_section = 14; */
        if (message.showInvoiceSection !== false)
            writer.tag(14, WireType.Varint).bool(message.showInvoiceSection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipmentPDFData
 */
export const OrderShipmentPDFData = new OrderShipmentPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderShipmentPDFData_Product$Type extends MessageType<OrderShipmentPDFData_Product> {
    constructor() {
        super("rd.txn.OrderShipmentPDFData.Product", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "total", kind: "message", T: () => Money },
            { no: 4, name: "quantity_ordered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fee_cost", kind: "message", T: () => Money },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<OrderShipmentPDFData_Product>): OrderShipmentPDFData_Product {
        const message = { identifier: "", name: "", quantityOrdered: 0, quantityReceived: 0, notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderShipmentPDFData_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderShipmentPDFData_Product): OrderShipmentPDFData_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* int32 quantity_ordered */ 4:
                    message.quantityOrdered = reader.int32();
                    break;
                case /* int32 quantity_received */ 5:
                    message.quantityReceived = reader.int32();
                    break;
                case /* rd.proto.money.Money fee_cost */ 6:
                    message.feeCost = Money.internalBinaryRead(reader, reader.uint32(), options, message.feeCost);
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 8:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderShipmentPDFData_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity_ordered = 4; */
        if (message.quantityOrdered !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantityOrdered);
        /* int32 quantity_received = 5; */
        if (message.quantityReceived !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantityReceived);
        /* rd.proto.money.Money fee_cost = 6; */
        if (message.feeCost)
            Money.internalBinaryWrite(message.feeCost, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.money.FractionalMoney cost_v2 = 8; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.txn.OrderShipmentPDFData.Product
 */
export const OrderShipmentPDFData_Product = new OrderShipmentPDFData_Product$Type();
