import {
  Customer,
  Contacts_Contact as CustomerContact,
  JobSite,
  PurchaseOrder,
} from "gen/customer/models_pb"
import { FractionalMoney, Money } from "money"
import { GetSaleRes, SyncCartPricesRes } from "gen/api/sale/service_pb"
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import {
  PaymentMetadata,
  LedgerEntry_Payment_Method as PaymentMethod,
  SaleProduct as SaleProductV2,
} from "gen/txn/models_pb"
import { Product, Product_TaxStatus } from "gen/product/models_pb"
import {
  calculateSurchargeFromPayments,
  getProductAndIdxFromSaleProductsByIdForUniqueTint,
  sumPayments,
} from "./helpers/helpers"
import {
  checkProductsEquality,
  fixSaleProductsOrders,
} from "../transactions/helpers"
import {
  createPaymentCardDefaults,
  getProductAndIdxFromSaleProductsById,
  getSortedProductListFromMap,
} from "./helpers"

import { AdditionalFees_Fee as AdditionalFee } from "gen/company/models_pb"
import { AppState } from "app/store"
import { Card } from "gen/payments/models_pb"
import { ListColorxTintedProductsRes_Result as ColorxTintedProductsResult } from "gen/api/colorx/service_pb"
import { CustomerState } from "../transactions/CustomerSidebar"
import { Decimal } from "decimal"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import SaleForm from "./types/SaleForm"
import { Timestamp } from "gen/google/protobuf/timestamp_pb"
import { TintColor } from "gen/tintcolor/models_pb"
import { TransactionProduct } from "../transactions/transactionProduct"
import { UUID } from "uuid-rd"
import { salesApiSlice } from "features/api/sale"

export interface productForReturn extends uniqueProductIdentifier {
  quantityToReturn: number
}

export type uniqueProductIdentifier = {
  id?: PBUUID // sale product id
  productId?: PBUUID
}

export type uniqueTintIdentifier = {
  productId?: PBUUID
  tintColorId?: PBUUID
  customTintColor: string
}

type ProductPayloadAction<T> = PayloadAction<uniqueProductIdentifier & T>

export interface SaleFormState extends SaleForm {
  initialState?: SaleForm
  isEdited?: boolean
  drawerEdited?: boolean
  isInitializing?: boolean
  customerCards?: Card[]
  cartTotals?: SyncCartPricesRes
  // wee bit jank but this sale products array tracks only the products we are returning *right now*
  // meaning `quantityReturned` reflects the quantity a user is actively toggling return quantities on
  scrollToBottom?: boolean
}

const initialState: SaleFormState = {}

const checkIsEdited = (state: SaleFormState) => {
  if (state.initialState?.saleNotes !== state.saleNotes) {
    return true
  }
  if (
    state.initialState?.quoteExpiration?.getTime() !==
    state.quoteExpiration?.getTime()
  ) {
    return true
  }
  if (!UUID.eqFromPB(state.initialState?.location?.id, state.location?.id)) {
    return true
  }
  if (
    !UUID.eqFromPB(
      state.initialState?.purchaseOrder?.id,
      state.purchaseOrder?.id
    )
  ) {
    return true
  }
  if (!UUID.eqFromPB(state.initialState?.jobSite?.id, state.jobSite?.id)) {
    return true
  }

  if (
    state.initialState?.customerContact?.idString !==
    state.customerContact?.idString
  ) {
    return true
  }

  let initSaleProductsV2: SaleProductV2[] = []
  let saleProductsV2: SaleProductV2[] = []

  for (const [, products] of Object.entries(
    state.initialState?.saleProductsByIdV2 || {}
  )) {
    initSaleProductsV2 = [...initSaleProductsV2, ...products]
  }

  for (const [, products] of Object.entries(state?.saleProductsByIdV2 || {})) {
    saleProductsV2 = [...saleProductsV2, ...products]
  }

  if (!checkProductsEquality(initSaleProductsV2, saleProductsV2)) {
    return true
  }

  if (!UUID.eqFromPB(state.initialState?.customerId, state.customerId)) {
    return true
  }

  // TODO: Replace with initial state check to prevent unexpected behavior
  if (state.searchProduct !== undefined) {
    return true
  }

  // TODO(rkofman): consider making this not hardcoded. See also SaleForm.test.tsx extra setup
  // necessary because of this.
  if (state.addProductQuantity !== 1) {
    return true
  }

  if (!UUID.eqFromPB(state.initialState?.taxRateId, state.taxRateId)) {
    return true
  }

  return false
}

function makeSaleProductsByIdMap(
  products?: SaleProductV2[]
): Record<string, SaleProductV2[]> {
  if (!products?.length) {
    return {}
  }

  const saleProductsById: Record<string, SaleProductV2[]> = {}
  for (const product of products) {
    if (!product.productId) {
      continue
    }

    const productWithDefaults: SaleProductV2 = {
      ...product,
      customTintColor: product.customTintColor ?? "",
    }

    const productId = UUID.fromPB(product.productId).toString()
    const saleProducts = saleProductsById[productId]
    if (saleProducts) {
      saleProductsById[productId] = [...saleProducts, productWithDefaults]
    } else {
      saleProductsById[productId] = [productWithDefaults]
    }
  }

  return saleProductsById
}

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setIsInitializing: (state) => {
      state.isInitializing = true
    },
    setSaleInitialState: (state, action: PayloadAction<GetSaleRes>) => {
      state.initialState = {
        location: action.payload.location,
        purchaseOrder: action.payload.customerPurchaseOrder,
        jobSite: action.payload.customerJobSite,
        productsById: action.payload.productsById,
        tintColorsById: action.payload.tintColorsById,
        catalogProductsById: action.payload.catalogProductsById,
        saleProductsByIdV2: makeSaleProductsByIdMap(
          action.payload.saleProducts
        ),
        saleNotes: action.payload.sale?.saleNotes,
        quoteExpiration: action.payload.sale?.quoteExpiresAt
          ? Timestamp.toDate(action.payload.sale?.quoteExpiresAt)
          : undefined,
        customerId: action.payload.sale?.customerId,
        inventoriesByProductId: action.payload.inventoriesByProductId,
        splitPayments: [],
        isSplitPayment: false,
        payment: undefined,
        paymentAmount: undefined,
        paymentMethod: undefined,
        taxRateId: action.payload.sale?.taxRateId,
      }
      state.location = action.payload.location
      state.purchaseOrder = action.payload.customerPurchaseOrder
      state.jobSite = action.payload.customerJobSite
      state.customerContact = action.payload.customerContact
      state.productsById = action.payload.productsById
      state.tintColorsById = action.payload.tintColorsById
      state.catalogProductsById = action.payload.catalogProductsById
      state.amountRefunded = 0

      state.saleProductsByIdV2 = makeSaleProductsByIdMap(
        action.payload.saleProducts
      )
      state.saleNotes = action.payload.sale?.saleNotes
      state.quoteExpiration = action.payload.sale?.quoteExpiresAt
        ? Timestamp.toDate(action.payload.sale?.quoteExpiresAt)
        : undefined
      state.customerId = action.payload.sale?.customerId
      state.cartTotals = undefined

      state.isEdited = false
      state.isInitializing = false
      state.addProductQuantity = 1
      state.inventoriesByProductId = action.payload.inventoriesByProductId
      state.splitPayments = []
      state.isSplitPayment = false
      state.payment = createPaymentCardDefaults()
      state.paymentAmount = undefined
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.drawerEdited = false
      state.customerCards = []
      state.taxRateId = action.payload.sale?.taxRateId
      state.idempotencyKey = UUID.newRandom().toString()
    },
    resetSaleForm: (state) => {
      for (const key in state) {
        delete state[key as keyof SaleFormState]
      }
      state.addProductQuantity = 1
      state.initialState = {
        addProductQuantity: 1,
      }
      state.payment = createPaymentCardDefaults()
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.isEdited = false
      state.drawerEdited = false
      state.idempotencyKey = UUID.newRandom().toString()
    },
    incrementReturnQuantities: (
      state,
      action: PayloadAction<Record<string, number>>
    ) => {
      if (!state.saleProductsByIdV2) {
        return
      }

      for (const fullProductID in state.saleProductsByIdV2) {
        for (const sp of state.saleProductsByIdV2[fullProductID]) {
          if (!sp.id) {
            continue
          }
          const spID = UUID.fromPB(sp.id).toString()
          if (spID in action.payload) {
            sp.quantityReturned += action.payload[spID]
          }
        }
      }
    },
    setInitialQuoteExpiration: (state, action: PayloadAction<Date>) => {
      state.initialState = {
        ...state.initialState,
        quoteExpiration: action.payload,
      }
      state.quoteExpiration = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setQuoteExpiration: (state, action: PayloadAction<Date>) => {
      state.quoteExpiration = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerSelected: (state, action: PayloadAction<CustomerState>) => {
      state.customerId = action.payload?.customerId
      if (!state.drawerEdited) {
        if (
          Money.fromPB(action.payload?.customerCreditLimit).gt(Money.zero())
        ) {
          state.payment = {
            drawer: "charge_account",
          }
          state.paymentMethod = PaymentMethod.UNDEFINED
        } else {
          state.payment = createPaymentCardDefaults()
        }
      }
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerCardsSelected: (state, action: PayloadAction<Card[]>) => {
      state.customerCards = action.payload
      if (
        !state.drawerEdited &&
        state.customerCards?.length > 0 &&
        state.payment?.drawer === "card"
      ) {
        state.payment.card = state.customerCards[0]
        state.paymentMethod = PaymentMethod.CARD_SAVED
      }
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    resetCustomer: (state) => {
      delete state.customerId
      delete state.customerContact
      state.customerCards = []
      state.payment = createPaymentCardDefaults()
      state.paymentMethod = PaymentMethod.CARD_PRESENT

      state.isEdited = checkIsEdited(state as SaleFormState)
      state.drawerEdited = false
    },
    removeProductV2: (state, action: PayloadAction<SaleProductV2>) => {
      if (!state.saleProductsByIdV2) {
        return
      }
      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        action.payload
      )

      if (!productAndIdx) {
        return
      }
      const { idx, product } = productAndIdx
      const productId = product.productId
        ? UUID.fromPB(product.productId).toString()
        : ""

      state.saleProductsByIdV2[productId].splice(idx, 1)

      if (state.saleProductsByIdV2[productId].length === 0) {
        delete state.saleProductsByIdV2[productId]
      }

      state.saleProductsByIdV2 = fixSaleProductsOrders(state.saleProductsByIdV2)
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setAddProductQuantity: (state, action: PayloadAction<number>) => {
      state.addProductQuantity = action.payload
      state.isEdited
    },
    setProductQuantityV2: (
      state,
      action: ProductPayloadAction<{ quantity?: number }>
    ) => {
      if (!action.payload.productId || !state.saleProductsByIdV2) {
        return
      }

      const payloadId = UUID.fromPB(action.payload.productId!).toString()
      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        action.payload
      )

      if (!productAndIdx) {
        return
      }

      const { product: saleProduct, idx } = productAndIdx

      if (action.payload.quantity !== undefined) {
        state.saleProductsByIdV2![payloadId][idx] = {
          ...saleProduct,
          quantity: action.payload.quantity,
        }
      }
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductPriceV2: (
      state,
      action: ProductPayloadAction<{
        price: Money
      }>
    ) => {
      if (!action.payload.productId || !state.saleProductsByIdV2) {
        return
      }

      const payloadId = UUID.fromPB(action.payload.productId!).toString()
      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        action.payload
      )
      if (!productAndIdx) {
        return
      }

      const { product: saleProduct, idx } = productAndIdx
      state.saleProductsByIdV2![payloadId][idx] = {
        ...saleProduct,
        price: action.payload.price.toPB(),
        isPriceEdited: true,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductCostV2: (
      state,
      action: ProductPayloadAction<{
        cost: FractionalMoney
      }>
    ) => {
      if (!action.payload.productId || !state.saleProductsByIdV2) {
        return
      }

      const productID = UUID.fromPB(action.payload.productId).toString()
      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        action.payload
      )
      if (!productAndIdx) {
        return
      }

      const { product: saleProduct, idx } = productAndIdx
      state.saleProductsByIdV2[productID][idx] = {
        ...saleProduct,
        costV2: action.payload.cost.toPB(),
        isCostEdited: true,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setJobSite: (state, action: PayloadAction<JobSite | undefined>) => {
      state.jobSite = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setPurchaseOrder: (
      state,
      action: PayloadAction<PurchaseOrder | undefined>
    ) => {
      state.purchaseOrder = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setCustomerContact: (
      state,
      action: PayloadAction<CustomerContact | undefined>
    ) => {
      state.customerContact = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setSaleNotes: (state, action: PayloadAction<string>) => {
      state.saleNotes = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductNotesV2: (
      state,
      action: ProductPayloadAction<{
        notes: string
      }>
    ) => {
      if (!action.payload.productId || !state.saleProductsByIdV2) {
        return
      }

      const payloadId = UUID.fromPB(action.payload.productId!).toString()
      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        action.payload
      )

      if (!productAndIdx) {
        return
      }

      const { product: saleProduct, idx } = productAndIdx

      state.saleProductsByIdV2[payloadId][idx] = {
        ...saleProduct,
        notes: action.payload.notes,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    searchProductSelected: (
      state,
      action: PayloadAction<{
        productResult: TransactionProduct
      }>
    ) => {
      state.searchProduct = {
        ...action.payload.productResult,
      }

      if (
        action.payload.productResult.isScan &&
        action.payload.productResult.quantity > 0
      ) {
        state.addProductQuantity = action.payload.productResult.quantity
      }

      if (
        action.payload.productResult?.isScan &&
        !action.payload.productResult.product?.isTintable
      ) {
        salesSlice.caseReducers.searchProductSubmitted(state, {
          type: "searchProductSubmitted",
          payload: {},
        })
      }
    },
    searchProductSubmitted: (
      state,
      action: PayloadAction<{
        notes?: string
        tintColor?: TintColor
        customTintColor?: string
      }>
    ) => {
      const searchProduct = state.searchProduct?.product
      const { notes = "", tintColor, customTintColor = "" } = action.payload

      if (!searchProduct?.id || (customTintColor && tintColor)) {
        return
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      const alwaysAddNew = searchProduct.isSpecialOrderable
      const searchProductIDString = UUID.fromPB(searchProduct.id).toString()

      if (!state.saleProductsByIdV2) {
        state.saleProductsByIdV2 = {}
      }
      const productAndIdxV2 = getProductAndIdxFromSaleProductsByIdForUniqueTint(
        state.saleProductsByIdV2,
        {
          productId: searchProduct.id,
          tintColorId: tintColor?.id,
          customTintColor,
        }
      )
      const lastPositionV2 = getSortedProductListFromMap(
        state.saleProductsByIdV2
      ).length
      if (productAndIdxV2 && !alwaysAddNew) {
        const { product: existingSaleProductV2, idx: idxV2 } = productAndIdxV2
        state.saleProductsByIdV2[searchProductIDString.toString()][idxV2] = {
          ...existingSaleProductV2,
          quantity:
            existingSaleProductV2.quantity + (state.addProductQuantity ?? 0),
          position: lastPositionV2 + 1,
          tintColorId: tintColor?.id,
          customTintColor,
          notes: notes || existingSaleProductV2.notes,
          isActive: true,
        }
      } else {
        state.saleProductsByIdV2[searchProductIDString.toString()] = [
          ...(state.saleProductsByIdV2[searchProductIDString.toString()] ?? []),
          newSaleProductV2FromProduct(
            state.addProductQuantity ?? 0,
            customTintColor,
            notes,
            lastPositionV2 + 1,
            state.searchProduct?.product,
            state.searchProduct?.additionalFee,
            tintColor
          ),
        ]
      }

      state.saleProductsByIdV2 = fixSaleProductsOrders(state.saleProductsByIdV2)

      state.productsById = {
        ...state.productsById,
        [searchProductIDString]: searchProduct,
      }

      if (state.searchProduct?.inventories) {
        state.inventoriesByProductId = {
          ...state.inventoriesByProductId,
          [searchProductIDString]: state.searchProduct.inventories,
        }
      }

      if (state.searchProduct?.catalogProduct) {
        state.catalogProductsById = {
          ...state.catalogProductsById,
          [searchProductIDString]: state.searchProduct.catalogProduct,
        }
      }

      if (tintColor?.id) {
        state.tintColorsById = {
          ...state.tintColorsById,
          [UUID.fromPB(tintColor.id).toString()]: tintColor,
        }
      }
      state.addProductQuantity = 1
      delete state.searchProduct

      state.scrollToBottom = true
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    addColorxTintedProductsToCartV2: (
      state,
      action: PayloadAction<{
        colorxProducts: ColorxTintedProductsResult[]
      }>
    ) => {
      if (!action?.payload?.colorxProducts?.length) {
        return
      }

      if (!state.saleProductsByIdV2) {
        state.saleProductsByIdV2 = {}
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      for (const colorxProduct of action.payload.colorxProducts) {
        const {
          colorxIdentifier,
          product,
          catalogProduct,
          tintColor,
          customTintColor,
          notes: formula,
          additionalFee,
        } = colorxProduct
        if (!product || !product.id) {
          continue
        }
        let notes = colorxIdentifier
        if (formula) {
          notes += `: ${formula}`
        }

        const payloadId = UUID.fromPB(product.id).toString()
        const productAndIdx = getProductAndIdxFromSaleProductsByIdForUniqueTint(
          state.saleProductsByIdV2,
          {
            productId: product.id,
            tintColorId: tintColor?.id,
            customTintColor,
          }
        )

        const alwaysAddNew = product.isSpecialOrderable
        const lastPosition = getSortedProductListFromMap(
          state.saleProductsByIdV2
        ).length
        if (productAndIdx && !alwaysAddNew) {
          const { product: existingSaleProduct, idx } = productAndIdx
          state.saleProductsByIdV2[payloadId][idx] = {
            ...existingSaleProduct,
            quantity:
              existingSaleProduct.quantity + Number(colorxProduct.quantity),
            position: lastPosition + 1,
            tintColorId: tintColor?.id,
            customTintColor,
            notes: notes || existingSaleProduct.notes,
            colorxTintedProductId: colorxProduct.colorxTintedProductId,
            isActive: true,
          }
        } else {
          state.saleProductsByIdV2[payloadId] = [
            ...(state.saleProductsByIdV2[payloadId] ?? []),
            newSaleProductV2FromProduct(
              Number(colorxProduct.quantity),
              customTintColor,
              notes,
              lastPosition + 1,
              product,
              additionalFee,
              tintColor,
              colorxProduct.colorxTintedProductId
            ),
          ]
        }

        state.saleProductsByIdV2 = fixSaleProductsOrders(
          state.saleProductsByIdV2
        )

        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(product.id).toString()]: product,
        }

        if (colorxProduct?.inventories && colorxProduct?.product?.id) {
          state.inventoriesByProductId = {
            ...state.inventoriesByProductId,
            [UUID.fromPB(colorxProduct.product.id).toString()]:
              colorxProduct.inventories,
          }
        }

        if (tintColor?.id) {
          state.tintColorsById = {
            ...state.tintColorsById,
            [UUID.fromPB(tintColor.id).toString()]: tintColor,
          }
        }

        if (catalogProduct) {
          state.catalogProductsById = {
            ...state.catalogProductsById,
            [UUID.fromPB(product.id).toString()]: catalogProduct,
          }
        }
      }

      state.scrollToBottom = true
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductTintAndNotesV2: (
      state,
      action: PayloadAction<{
        productToEdit: SaleProductV2
        tintColor?: TintColor
        customTintColor?: string
        notes?: string
      }>
    ) => {
      const {
        productToEdit,
        tintColor,
        customTintColor = "",
        notes = "",
      } = action.payload
      if (
        !productToEdit.productId ||
        !state.saleProductsByIdV2 ||
        (tintColor && customTintColor)
      ) {
        return
      }

      const productId = UUID.fromPB(productToEdit.productId).toString()

      const productAndIdx = getProductAndIdxFromSaleProductsById(
        state.saleProductsByIdV2,
        {
          id: productToEdit.id,
          productId: productToEdit.productId,
        }
      )

      if (!productAndIdx) {
        return
      }
      const { product: existingSaleProduct, idx } = productAndIdx

      state.saleProductsByIdV2[productId][idx] = {
        ...existingSaleProduct,
        notes,
        tintColorId: tintColor?.id,
        customTintColor,
      }

      if (tintColor?.id) {
        state.tintColorsById = {
          ...state.tintColorsById,
          [UUID.fromPB(tintColor.id).toString()]: tintColor,
        }
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    paymentSelected(
      state,
      action: PayloadAction<"charge_account" | "check" | "card" | "cash">
    ) {
      switch (action.payload) {
        case "charge_account": {
          state.payment = {
            drawer: "charge_account",
          }
          state.paymentMethod = PaymentMethod.UNDEFINED
          break
        }
        case "cash": {
          state.payment = {
            drawer: "cash",
            cashReceived: Money.zero(),
          }
          state.paymentMethod = PaymentMethod.CASH
          break
        }
        case "check": {
          state.payment = {
            drawer: "check",
            checkNumber: "",
          }
          state.paymentMethod = PaymentMethod.CHECK
          break
        }
        case "card": {
          state.payment = createPaymentCardDefaults()
          if (state.customerCards && state.customerCards.length > 0) {
            state.payment.card = state.customerCards[0]
            state.paymentMethod = PaymentMethod.CARD_SAVED
          } else {
            state.paymentMethod = PaymentMethod.CARD_PRESENT
          }
          break
        }
        default: {
          throw new Error("unexpected payment method")
        }
      }

      state.drawerEdited = true
    },
    setCheckNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "check") {
        return
      }
      state.paymentMethod = PaymentMethod.CHECK
      state.payment.checkNumber = action.payload
    },
    savedCardSelected(state, action: PayloadAction<Card>) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_SAVED
      state.payment = {
        ...state.payment,
        card: action.payload,
      }
      state.drawerEdited = true
    },
    cardPresentSelected(state) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment = {
        ...state.payment,
      }
      state.drawerEdited = true
    },
    toggleShouldSaveReadCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.shouldSaveCardPresent = !state.payment.shouldSaveCardPresent
    },
    setCardPresentCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.cardPresentCardNickname = action.payload
    },
    manuallyEnterCardSelected(state) {
      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.drawerEdited = true
    },
    setManuallyEnteredCardNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.number = action.payload
    },
    setManuallyEnteredCardExp(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.exp = action.payload
    },
    setManuallyEnteredCardCVC(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.cvc = action.payload
    },
    setManuallyEnteredCardZIP(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.zip = action.payload
    },
    toggleShouldSaveManuallyEnteredCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.shouldSaveCardManuallyEntered =
        !state.payment.shouldSaveCardManuallyEntered
    },
    setManuallyEnteredCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.manuallyEnteredCardNickname = action.payload
    },
    setCashReceived(state, action: PayloadAction<Money>) {
      if (state.payment?.drawer !== "cash") {
        return
      }

      state.payment.cashReceived = action.payload
    },
    resetCartTotals: (state) => {
      delete state.cartTotals

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload
    },
    resetSearchProductSelected: (state) => {
      delete state.searchProduct

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setPaymentAmount(state, action: PayloadAction<Money>) {
      if (action.payload.lte(Money.zero())) {
        return
      }

      const surchargePaid = calculateSurchargeFromPayments(
        state.splitPayments ?? []
      )
      const saleTotal = Money.fromPB(state.cartTotals?.total).add(surchargePaid)
      const amountReadyToPay = sumPayments(state.splitPayments)
      const leftToPay = saleTotal.sub(amountReadyToPay)

      if (action.payload.gt(leftToPay)) {
        state.paymentAmount = leftToPay
      } else {
        state.paymentAmount = action.payload
      }
    },
    addSplitPaymentAndClearCurrent: (
      state,
      action: PayloadAction<PaymentMetadata>
    ) => {
      if (action.payload.paymentMethod === PaymentMethod.UNDEFINED) {
        return
      }

      if (
        !action.payload.amount ||
        Money.fromPB(action.payload.amount).lte(Money.zero())
      ) {
        return
      }

      const updatedPayments = [...(state.splitPayments ?? []), action.payload]

      const surchargePaid = calculateSurchargeFromPayments(updatedPayments)
      const saleTotal = Money.fromPB(state.cartTotals?.total).add(surchargePaid)
      const amountReadyToPay = sumPayments(updatedPayments)

      const isOverPaying = saleTotal.lt(amountReadyToPay)
      if (isOverPaying) {
        return
      }

      state.splitPayments = updatedPayments
      state.payment = undefined
      state.paymentMethod = undefined
      state.paymentAmount = undefined
      state.drawerEdited = false
    },
    toggleIsSplitPayment(state) {
      if (state.paymentMethod == PaymentMethod.UNDEFINED) {
        state.payment = createPaymentCardDefaults()
        if (state.customerCards && state.customerCards.length > 0) {
          state.payment.card = state.customerCards[0]
          state.paymentMethod = PaymentMethod.CARD_SAVED
        } else {
          state.paymentMethod = PaymentMethod.CARD_PRESENT
        }
      }

      state.isSplitPayment = !state.isSplitPayment

      state.paymentAmount = undefined
      state.splitPayments = []
    },
    resetPayments(state) {
      state.isSplitPayment = false
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment = createPaymentCardDefaults()
      state.paymentAmount = undefined
      state.splitPayments = []
      state.drawerEdited = false
    },
    setTaxRateId(state, action: PayloadAction<PBUUID>) {
      state.taxRateId = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerLoaded(state, action: PayloadAction<Customer | undefined>) {
      state.defaultTaxRateId = action.payload?.taxRateId
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      salesApiSlice.endpoints.syncCartPrices.matchFulfilled,
      (state, { payload }) => {
        state.cartTotals = payload
      }
    )
  },
})

export const {
  setIsInitializing,
  setSaleInitialState,
  resetSaleForm,
  setQuoteExpiration,
  setInitialQuoteExpiration,
  customerSelected,
  customerCardsSelected,
  resetCustomer,
  removeProductV2,
  setAddProductQuantity,
  setProductQuantityV2,
  setJobSite,
  setPurchaseOrder,
  setCustomerContact,
  setSaleNotes,
  setProductNotesV2,
  searchProductSubmitted,
  searchProductSelected,
  resetSearchProductSelected,
  setProductTintAndNotesV2,
  setProductPriceV2,
  setProductCostV2,
  paymentSelected,
  setCheckNumber,
  savedCardSelected,
  cardPresentSelected,
  toggleShouldSaveReadCard,
  setCardPresentCardNickname,
  manuallyEnterCardSelected,
  setManuallyEnteredCardNumber,
  setManuallyEnteredCardExp,
  setManuallyEnteredCardCVC,
  setManuallyEnteredCardZIP,
  toggleShouldSaveManuallyEnteredCard,
  setManuallyEnteredCardNickname,
  setCashReceived,
  resetCartTotals,
  incrementReturnQuantities,

  setScrollToBottom,
  addSplitPaymentAndClearCurrent,
  setPaymentAmount,
  toggleIsSplitPayment,
  resetPayments,
  addColorxTintedProductsToCartV2,
  setTaxRateId,
  customerLoaded,
} = salesSlice.actions

export const selectIsEdited = (state: AppState) => !!state.sales.isEdited
export const selectIsInitializing = (state: AppState) =>
  state.sales.isInitializing

export const selectCustomerId = (state: AppState) => state.sales.customerId
export const selectPurchaseOrder = (state: AppState) =>
  state.sales.purchaseOrder
export const selectJobSite = (state: AppState) => state.sales.jobSite
export const selectCustomerContact = (state: AppState) =>
  state.sales.customerContact
export const selectSaleNotes = (state: AppState) => state.sales.saleNotes
export const selectSaleProductsV2 = (state: AppState) =>
  state.sales.saleProductsByIdV2
export const selectQuoteExpiration = (state: AppState) =>
  state.sales.quoteExpiration
export const selectLocation = (state: AppState) => state.sales.location
export const selectProductsById = (state: AppState) => state.sales.productsById
export const selectCatalogProductsById = (state: AppState) =>
  state.sales.catalogProductsById
export const selectTintColorsById = (state: AppState) =>
  state.sales.tintColorsById
export const selectAmountRefunded = (state: AppState) =>
  state.sales.amountRefunded
export const selectPaymentMethod = (state: AppState) =>
  state.sales.paymentMethod
export const selectCheckNumber = (state: AppState) =>
  state.sales.payment?.drawer === "check" ? state.sales.payment.checkNumber : ""
export const selectCashReceived = createSelector(
  (state: AppState) => state.sales.payment,
  (payment) =>
    payment?.drawer === "cash" ? payment.cashReceived : Money.zero()
)
export const selectSavedCard = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.card : null
export const selectCards = (state: AppState) => state.sales.customerCards ?? []
export const selectManuallyEnteredCardNumber = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.number : ""
export const selectManuallyEnteredCardExp = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.exp : ""
export const selectManuallyEnteredCardCVC = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.cvc : ""
export const selectManuallyEnteredCardZIP = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.zip : ""
export const selectShouldSaveManuallyEnteredCard = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.shouldSaveCardManuallyEntered
    : false
export const selectManuallyEnteredCardNickname = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.manuallyEnteredCardNickname
    : ""
export const selectShouldSaveCardPresent = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.shouldSaveCardPresent
    : false
export const selectCardPresentCardNickname = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.cardPresentCardNickname
    : ""
export const selectCartTotals = (state: AppState) => state.sales.cartTotals
export const selectPayment = (state: AppState) => state.sales.payment
export const selectSplitPayments = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => splitPayments ?? []
)
export const selectSplitTotal = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => sumPayments(splitPayments)
)
export const selectIsSplitPayment = (state: AppState) =>
  state.sales.isSplitPayment
export const selectPaymentDrawer = (state: AppState) =>
  state.sales.payment?.drawer
export const selectPaymentAmount = createSelector(
  (state: AppState) => state.sales.paymentAmount,
  (paymentAmount) => (paymentAmount as Money) ?? Money.zero()
)
export const selectScrollToBottom = (state: AppState) =>
  state.sales.scrollToBottom
export const selectSearchProduct = (state: AppState) =>
  state.sales.searchProduct
export const selectAddProductQuantity = (state: AppState) =>
  state.sales.addProductQuantity
export const selectSaleInventoriesByProductId = (state: AppState) =>
  state.sales.inventoriesByProductId
export const selectTaxRateId = (state: AppState) =>
  state.sales.taxRateId || state.sales.defaultTaxRateId
export const selectCartTaxRate = createSelector(
  (state: AppState) => state.sales.cartTotals,
  (cartTotals) =>
    cartTotals?.taxRate?.rate
      ? Decimal.fromPB(cartTotals.taxRate.rate)
      : Decimal.zero()
)
export const selectCardSurchargeRate = createSelector(
  (state: AppState) => state.sales.cartTotals?.cardSurchargeRate,
  (cardSurchargeRate) =>
    cardSurchargeRate ? Decimal.fromPB(cardSurchargeRate) : Decimal.zero()
)
export const selectIsCardPayment = (state: AppState) =>
  state.sales.paymentMethod !== undefined &&
  [
    PaymentMethod.CARD_MANUALLY_ENTERED,
    PaymentMethod.CARD_PRESENT,
    PaymentMethod.CARD_SAVED,
  ].includes(state.sales.paymentMethod)
export const selectSplitTotalSurcharge = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => calculateSurchargeFromPayments(splitPayments ?? [])
)
export const selectCurrentAmount = (state: AppState) =>
  state.sales.isSplitPayment
    ? state.sales.paymentAmount?.toPB()
    : state.sales.cartTotals?.total
export const selectIdempotencyKey = (state: AppState) =>
  state.sales.idempotencyKey

function newSaleProductV2FromProduct(
  addProductQuantity: number,
  customTintColor: string,
  notes: string,
  position: number,
  product?: Product,
  additionalFee?: AdditionalFee,
  tintColor?: TintColor,
  colorxTintedProductId?: PBUUID
): SaleProductV2 {
  const sp: SaleProductV2 = {
    id: UUID.newRandom().toPB(),
    isActive: true,
    isCostEdited: false,

    productId: product?.id,
    quantity: addProductQuantity,
    quantityReturned: 0,
    additionalFeeName: additionalFee?.name ?? "",
    additionalFeeV2: additionalFee?.fee
      ? FractionalMoney.fromMoney(Money.fromPB(additionalFee.fee)).toPB()
      : undefined,
    customTintColor,
    tintColorId: tintColor?.id,
    notes,
    isPriceEdited: false,
    position,
    isFeeTaxed: !!additionalFee?.isTaxable,
    isTaxed: product?.taxStatus !== Product_TaxStatus.NON_TAXABLE,
    colorxTintedProductId,
  }
  return sp
}
