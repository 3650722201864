/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/flags/service.proto" (package "rd.api.flags", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { FlagsService } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetFlagsRes } from "./service_pb";
import type { GetFlagsReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.api.flags.FlagsService
 */
export interface IFlagsServiceClient {
    /**
     * @generated from protobuf rpc: GetFlags(rd.api.flags.GetFlagsReq) returns (rd.api.flags.GetFlagsRes);
     */
    getFlags(input: GetFlagsReq, options?: RpcOptions): UnaryCall<GetFlagsReq, GetFlagsRes>;
}
/**
 * @generated from protobuf service rd.api.flags.FlagsService
 */
export class FlagsServiceClient implements IFlagsServiceClient, ServiceInfo {
    typeName = FlagsService.typeName;
    methods = FlagsService.methods;
    options = FlagsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetFlags(rd.api.flags.GetFlagsReq) returns (rd.api.flags.GetFlagsRes);
     */
    getFlags(input: GetFlagsReq, options?: RpcOptions): UnaryCall<GetFlagsReq, GetFlagsRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFlagsReq, GetFlagsRes>("unary", this._transport, method, opt, input);
    }
}
