/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "proto/money/models.proto" (package "rd.proto.money", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Money represents a monetary value.
 *
 * @generated from protobuf message rd.proto.money.Money
 */
export interface Money {
    /**
     * cents should not be modified directly.
     *
     * @generated from protobuf field: int64 cents = 1;
     */
    cents: bigint;
}
/**
 * FractionalMoney represents monetary value with a precision of a billionth of a cent.
 * It canonically represents money where cents and nano_cents are of the
 * same sign and abs(nano_cents) < 1e9.
 *
 * @generated from protobuf message rd.proto.money.FractionalMoney
 */
export interface FractionalMoney {
    /**
     * @generated from protobuf field: int64 cents = 1;
     */
    cents: bigint;
    /**
     * @generated from protobuf field: int64 nano_cents = 2;
     */
    nanoCents: bigint;
}
// @generated message type with reflection information, may provide speed optimized methods
class Money$Type extends MessageType<Money> {
    constructor() {
        super("rd.proto.money.Money", [
            { no: 1, name: "cents", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Money>): Money {
        const message = { cents: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Money>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Money): Money {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 cents */ 1:
                    message.cents = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Money, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 cents = 1; */
        if (message.cents !== 0n)
            writer.tag(1, WireType.Varint).int64(message.cents);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.proto.money.Money
 */
export const Money = new Money$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FractionalMoney$Type extends MessageType<FractionalMoney> {
    constructor() {
        super("rd.proto.money.FractionalMoney", [
            { no: 1, name: "cents", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "nano_cents", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<FractionalMoney>): FractionalMoney {
        const message = { cents: 0n, nanoCents: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FractionalMoney>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FractionalMoney): FractionalMoney {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 cents */ 1:
                    message.cents = reader.int64().toBigInt();
                    break;
                case /* int64 nano_cents */ 2:
                    message.nanoCents = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FractionalMoney, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 cents = 1; */
        if (message.cents !== 0n)
            writer.tag(1, WireType.Varint).int64(message.cents);
        /* int64 nano_cents = 2; */
        if (message.nanoCents !== 0n)
            writer.tag(2, WireType.Varint).int64(message.nanoCents);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.proto.money.FractionalMoney
 */
export const FractionalMoney = new FractionalMoney$Type();
