import { useGetCompanyForSubdomainQuery } from "features/api/company"

function getCurrentSubdomain(): string {
  if (typeof window === "undefined") return ""
  let subdomain = window.location.hostname.split(".")[0]
  if (subdomain === "localhost") {
    subdomain = "rdo"
  }
  return subdomain
}

export function useGetCompanyForCurrentSubdomain() {
  const subdomain = getCurrentSubdomain()
  return useGetCompanyForSubdomainQuery(subdomain, {
    skip: !subdomain,
  })
}
