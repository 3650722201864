export class Text {
  private str: string

  private constructor(s: string) {
    this.str = s
  }

  static fromString(str: string) {
    return new Text(str)
  }

  // capitalizes a string or set of words
  capitalize(): string {
    if (this.str === "") return this.str

    return this.str[0].toUpperCase() + this.str.slice(1).toLowerCase()
  }

  // truncates a set of words to a max length
  truncate(length: number): string {
    if (this.str === "" || this.str.length <= length) {
      return this.str
    }

    return `${this.str.slice(0, length)}...`
  }

  // trims out all the extra whitespaces
  trim(): string {
    if (this.str === "") return this.str

    return this.str.trim()
  }
}
