/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "config/models.proto" (package "rd.config", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../google/protobuf/duration_pb";
/**
 * @generated from protobuf message rd.config.Config
 */
export interface Config {
    /**
     * @generated from protobuf field: rd.config.Config.Env env = 1;
     */
    env: Config_Env;
    /**
     * @generated from protobuf field: uint32 port = 2;
     */
    port: number;
    /**
     * In prod, port & search_port are the same since they
     * are deployed separately. Locally, we need to expose
     * search on a different port so that we can run both
     * services at the same time.
     *
     * @generated from protobuf field: uint32 search_port = 3;
     */
    searchPort: number;
    /**
     * @generated from protobuf field: string base_url = 4;
     */
    baseUrl: string;
    /**
     * @generated from protobuf field: string search_url = 5;
     */
    searchUrl: string;
    /**
     * @generated from protobuf field: string pdf_url = 6;
     */
    pdfUrl: string;
    /**
     * @generated from protobuf field: string database_url = 7;
     */
    databaseUrl: string;
    /**
     * @generated from protobuf field: int32 database_conns = 8;
     */
    databaseConns: number;
    /**
     * @generated from protobuf field: string diskman_data_dir = 9;
     */
    diskmanDataDir: string;
    /**
     * @generated from protobuf field: string diskman_lock_dir = 10;
     */
    diskmanLockDir: string;
    /**
     * @generated from protobuf field: string domain = 11;
     */
    domain: string;
    /**
     * @generated from protobuf field: uint32 admin_port = 12;
     */
    adminPort: number;
    /**
     * @generated from protobuf field: string admin_url = 13;
     */
    adminUrl: string;
    /**
     * @generated from protobuf field: string bin_name = 14;
     */
    binName: string;
    /**
     * @generated from protobuf field: rd.config.Config.App app = 15;
     */
    app: Config_App;
    /**
     * @generated from protobuf field: string hostname = 16;
     */
    hostname: string;
    /**
     * Google
     *
     * @generated from protobuf field: string google_project_id = 17;
     */
    googleProjectId: string;
    /**
     * @generated from protobuf field: string google_cloud_credentials_json = 18;
     */
    googleCloudCredentialsJson: string;
    /**
     * @generated from protobuf field: string google_maps_api_key = 19;
     */
    googleMapsApiKey: string;
    /**
     * @generated from protobuf field: string google_cloud_sql_instance_conn_name = 20;
     */
    googleCloudSqlInstanceConnName: string;
    /**
     * Stripe
     *
     * @generated from protobuf field: string stripe_secret_key = 21;
     */
    stripeSecretKey: string;
    /**
     * Stytch
     *
     * @generated from protobuf field: string stytch_project_id = 22;
     */
    stytchProjectId: string;
    /**
     * @generated from protobuf field: string stytch_secret = 23;
     */
    stytchSecret: string;
    /**
     * Stytch customer mobile app project
     *
     * @generated from protobuf field: string stytch_cxa_project_id = 24;
     */
    stytchCxaProjectId: string;
    /**
     * @generated from protobuf field: string stytch_cxa_secret = 25;
     */
    stytchCxaSecret: string;
    /**
     * @generated from protobuf field: string stytch_webauthn_domain = 26;
     */
    stytchWebauthnDomain: string;
    /**
     * Misc.
     *
     * @generated from protobuf field: string sendgrid_api_key = 27;
     */
    sendgridApiKey: string;
    /**
     * @generated from protobuf field: string docraptor_api_key = 28;
     */
    docraptorApiKey: string;
    /**
     * @generated from protobuf field: string taxjar_api_key = 29;
     */
    taxjarApiKey: string;
    /**
     * @generated from protobuf field: string nolt_sso_key = 30;
     */
    noltSsoKey: string;
    /**
     * @generated from protobuf field: string pager_duty_token = 31;
     */
    pagerDutyToken: string;
    /**
     * Cloudinary
     *
     * @generated from protobuf field: string cloudinary_cloud_name = 32;
     */
    cloudinaryCloudName: string;
    /**
     * @generated from protobuf field: string cloudinary_api_key = 33;
     */
    cloudinaryApiKey: string;
    /**
     * @generated from protobuf field: string cloudinary_api_secret = 34;
     */
    cloudinaryApiSecret: string;
    /**
     * @generated from protobuf field: rd.config.Config.LogOptions log_options = 35;
     */
    logOptions?: Config_LogOptions;
    /**
     * @generated from protobuf field: string rundoo_service_secret = 36;
     */
    rundooServiceSecret: string;
    /**
     * @generated from protobuf field: string bm_contractor_rewards_sftp_pass = 37;
     */
    bmContractorRewardsSftpPass: string;
    /**
     * @generated from protobuf field: bool use_pubsub = 38;
     */
    usePubsub: boolean;
    /**
     * @generated from protobuf field: string gitsha = 39;
     */
    gitsha: string;
    /**
     * @generated from protobuf field: string stripe_publishable_key = 40;
     */
    stripePublishableKey: string;
    /**
     * @generated from protobuf field: rd.config.Config.Trace trace_backend = 41;
     */
    traceBackend: Config_Trace;
    /**
     * @generated from protobuf field: rd.config.Config.TasksConfig tasks_config = 42;
     */
    tasksConfig?: Config_TasksConfig;
    /**
     * @generated from protobuf field: uint32 tasks_port = 43;
     */
    tasksPort: number;
    /**
     * @generated from protobuf field: string openai_api_key = 44;
     */
    openaiApiKey: string;
    /**
     * @generated from protobuf field: string openai_assistant_id = 45;
     */
    openaiAssistantId: string;
}
/**
 * @generated from protobuf message rd.config.Config.LogOptions
 */
export interface Config_LogOptions {
    /**
     * @generated from protobuf field: rd.config.Config.LogOptions.Level level = 1;
     */
    level: Config_LogOptions_Level;
    /**
     * @generated from protobuf field: rd.config.Config.LogOptions.Src src = 2;
     */
    src: Config_LogOptions_Src;
    /**
     * @generated from protobuf field: bool use_json = 3;
     */
    useJson: boolean;
    /**
     * @generated from protobuf field: rd.config.Config.LogOptions.Level stacktrace_level = 4;
     */
    stacktraceLevel: Config_LogOptions_Level; // minimum log level to include stacktrace in message
    /**
     * @generated from protobuf field: rd.config.Config.LogOptions.Level rndo_stack_level = 5;
     */
    rndoStackLevel: Config_LogOptions_Level; // minimum log level to include stacktrace as metadata
}
/**
 * must match log.Level values
 *
 * @generated from protobuf enum rd.config.Config.LogOptions.Level
 */
export enum Config_LogOptions_Level {
    /**
     * @generated from protobuf enum value: Level_ALL = 0;
     */
    Level_ALL = 0,
    /**
     * @generated from protobuf enum value: Level_TRACE = 1;
     */
    Level_TRACE = 1,
    /**
     * @generated from protobuf enum value: Level_DEBUG = 2;
     */
    Level_DEBUG = 2,
    /**
     * @generated from protobuf enum value: Level_INFO = 3;
     */
    Level_INFO = 3,
    /**
     * @generated from protobuf enum value: Level_WARN = 4;
     */
    Level_WARN = 4,
    /**
     * @generated from protobuf enum value: Level_ERROR = 5;
     */
    Level_ERROR = 5,
    /**
     * @generated from protobuf enum value: Level_ALERT = 6;
     */
    Level_ALERT = 6,
    /**
     * @generated from protobuf enum value: Level_NONE = 7;
     */
    Level_NONE = 7
}
/**
 * @generated from protobuf enum rd.config.Config.LogOptions.Src
 */
export enum Config_LogOptions_Src {
    /**
     * @generated from protobuf enum value: Src_NONE = 0;
     */
    Src_NONE = 0,
    /**
     * @generated from protobuf enum value: Src_SHORT = 1;
     */
    Src_SHORT = 1,
    /**
     * @generated from protobuf enum value: Src_FULL = 2;
     */
    Src_FULL = 2
}
/**
 * TasksConfig defines the tasks service configuration.
 *
 * @generated from protobuf message rd.config.Config.TasksConfig
 */
export interface Config_TasksConfig {
    /**
     * The target service prefix for tasks (our Cloud Run tasks service). The
     * task's endpoint is joined with the base url to determine the endpoint
     * for each task.
     *
     * @generated from protobuf field: string service_base_url = 1;
     */
    serviceBaseUrl: string;
    /**
     * The service account for authenticating task requests.
     *
     * @generated from protobuf field: string service_account = 2;
     */
    serviceAccount: string;
    /**
     * @generated from protobuf field: int32 api_conns = 3;
     */
    apiConns: number;
    /**
     * @generated from protobuf field: map<int32, rd.config.Config.TasksConfig.QueueConfig> queue_configs = 4;
     */
    queueConfigs: {
        [key: number]: Config_TasksConfig_QueueConfig;
    };
}
/**
 * @generated from protobuf message rd.config.Config.TasksConfig.QueueConfig
 */
export interface Config_TasksConfig_QueueConfig {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * if num_queues > 1, the constructed path will look like
     * `Sprintf("%s-%d", path, n)`, with 0 < n < num_queues.
     *
     * @generated from protobuf field: int64 num_queues = 2;
     */
    numQueues: bigint;
    /**
     * timeout will, if present, set the timeout field on the Task.
     *
     * @generated from protobuf field: google.protobuf.Duration timeout = 3;
     */
    timeout?: Duration;
}
/**
 * @generated from protobuf enum rd.config.Config.TasksConfig.Queue
 */
export enum Config_TasksConfig_Queue {
    /**
     * @generated from protobuf enum value: QUEUE_DEFAULT = 0;
     */
    DEFAULT = 0,
    /**
     * @generated from protobuf enum value: QUEUE_ADMIN_MIGRATIONS = 1;
     */
    ADMIN_MIGRATIONS = 1,
    /**
     * @generated from protobuf enum value: QUEUE_ADMIN_PIPELINE = 2;
     */
    ADMIN_PIPELINE = 2
}
/**
 * @generated from protobuf enum rd.config.Config.Env
 */
export enum Config_Env {
    /**
     * @generated from protobuf enum value: ENV_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ENV_DEVELOPMENT = 1;
     */
    DEVELOPMENT = 1,
    /**
     * @generated from protobuf enum value: ENV_PRODUCTION = 2;
     */
    PRODUCTION = 2
}
/**
 * @generated from protobuf enum rd.config.Config.App
 */
export enum Config_App {
    /**
     * @generated from protobuf enum value: APP_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: APP_API = 1;
     */
    API = 1,
    /**
     * @generated from protobuf enum value: APP_ADMIN = 2;
     */
    ADMIN = 2,
    /**
     * @generated from protobuf enum value: APP_SEARCH = 3;
     */
    SEARCH = 3,
    /**
     * @generated from protobuf enum value: APP_SEARCH_PROXY = 4;
     */
    SEARCH_PROXY = 4,
    /**
     * @generated from protobuf enum value: APP_TASKS = 5;
     */
    TASKS = 5
}
/**
 * @generated from protobuf enum rd.config.Config.Trace
 */
export enum Config_Trace {
    /**
     * @generated from protobuf enum value: TRACE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TRACE_DATADOG = 1;
     */
    DATADOG = 1
}
/**
 * SearchSize is a heuristic for determining a company's size. It is used to
 * determine how much memory a company's search instance will require.
 *
 * @generated from protobuf enum rd.config.SearchSize
 */
export enum SearchSize {
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_NONE = 0;
     */
    SEARCH_SIZE_NONE = 0,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_DEFAULT = -1;
     */
    SEARCH_SIZE_DEFAULT = -1,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_XS = 1;
     */
    SEARCH_SIZE_XS = 1,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_S = 2;
     */
    SEARCH_SIZE_S = 2,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_M = 3;
     */
    SEARCH_SIZE_M = 3,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_L = 4;
     */
    SEARCH_SIZE_L = 4,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_XL = 5;
     */
    SEARCH_SIZE_XL = 5,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_XXL = 6;
     */
    SEARCH_SIZE_XXL = 6,
    /**
     * @generated from protobuf enum value: SEARCH_SIZE_MEGA = 11;
     */
    SEARCH_SIZE_MEGA = 11
}
// @generated message type with reflection information, may provide speed optimized methods
class Config$Type extends MessageType<Config> {
    constructor() {
        super("rd.config.Config", [
            { no: 1, name: "env", kind: "enum", T: () => ["rd.config.Config.Env", Config_Env, "ENV_"] },
            { no: 2, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "search_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "base_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "search_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pdf_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "database_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "database_conns", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "diskman_data_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "diskman_lock_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "admin_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "admin_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "bin_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "app", kind: "enum", T: () => ["rd.config.Config.App", Config_App, "APP_"] },
            { no: 16, name: "hostname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "google_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "google_cloud_credentials_json", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "google_maps_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "google_cloud_sql_instance_conn_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "stripe_secret_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "stytch_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "stytch_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "stytch_cxa_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "stytch_cxa_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "stytch_webauthn_domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "sendgrid_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "docraptor_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "taxjar_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "nolt_sso_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "pager_duty_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "cloudinary_cloud_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "cloudinary_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "cloudinary_api_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "log_options", kind: "message", T: () => Config_LogOptions },
            { no: 36, name: "rundoo_service_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "bm_contractor_rewards_sftp_pass", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "use_pubsub", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 39, name: "gitsha", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "stripe_publishable_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "trace_backend", kind: "enum", T: () => ["rd.config.Config.Trace", Config_Trace, "TRACE_"] },
            { no: 42, name: "tasks_config", kind: "message", T: () => Config_TasksConfig },
            { no: 43, name: "tasks_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 44, name: "openai_api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "openai_assistant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Config>): Config {
        const message = { env: 0, port: 0, searchPort: 0, baseUrl: "", searchUrl: "", pdfUrl: "", databaseUrl: "", databaseConns: 0, diskmanDataDir: "", diskmanLockDir: "", domain: "", adminPort: 0, adminUrl: "", binName: "", app: 0, hostname: "", googleProjectId: "", googleCloudCredentialsJson: "", googleMapsApiKey: "", googleCloudSqlInstanceConnName: "", stripeSecretKey: "", stytchProjectId: "", stytchSecret: "", stytchCxaProjectId: "", stytchCxaSecret: "", stytchWebauthnDomain: "", sendgridApiKey: "", docraptorApiKey: "", taxjarApiKey: "", noltSsoKey: "", pagerDutyToken: "", cloudinaryCloudName: "", cloudinaryApiKey: "", cloudinaryApiSecret: "", rundooServiceSecret: "", bmContractorRewardsSftpPass: "", usePubsub: false, gitsha: "", stripePublishableKey: "", traceBackend: 0, tasksPort: 0, openaiApiKey: "", openaiAssistantId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Config>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Config): Config {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.config.Config.Env env */ 1:
                    message.env = reader.int32();
                    break;
                case /* uint32 port */ 2:
                    message.port = reader.uint32();
                    break;
                case /* uint32 search_port */ 3:
                    message.searchPort = reader.uint32();
                    break;
                case /* string base_url */ 4:
                    message.baseUrl = reader.string();
                    break;
                case /* string search_url */ 5:
                    message.searchUrl = reader.string();
                    break;
                case /* string pdf_url */ 6:
                    message.pdfUrl = reader.string();
                    break;
                case /* string database_url */ 7:
                    message.databaseUrl = reader.string();
                    break;
                case /* int32 database_conns */ 8:
                    message.databaseConns = reader.int32();
                    break;
                case /* string diskman_data_dir */ 9:
                    message.diskmanDataDir = reader.string();
                    break;
                case /* string diskman_lock_dir */ 10:
                    message.diskmanLockDir = reader.string();
                    break;
                case /* string domain */ 11:
                    message.domain = reader.string();
                    break;
                case /* uint32 admin_port */ 12:
                    message.adminPort = reader.uint32();
                    break;
                case /* string admin_url */ 13:
                    message.adminUrl = reader.string();
                    break;
                case /* string bin_name */ 14:
                    message.binName = reader.string();
                    break;
                case /* rd.config.Config.App app */ 15:
                    message.app = reader.int32();
                    break;
                case /* string hostname */ 16:
                    message.hostname = reader.string();
                    break;
                case /* string google_project_id */ 17:
                    message.googleProjectId = reader.string();
                    break;
                case /* string google_cloud_credentials_json */ 18:
                    message.googleCloudCredentialsJson = reader.string();
                    break;
                case /* string google_maps_api_key */ 19:
                    message.googleMapsApiKey = reader.string();
                    break;
                case /* string google_cloud_sql_instance_conn_name */ 20:
                    message.googleCloudSqlInstanceConnName = reader.string();
                    break;
                case /* string stripe_secret_key */ 21:
                    message.stripeSecretKey = reader.string();
                    break;
                case /* string stytch_project_id */ 22:
                    message.stytchProjectId = reader.string();
                    break;
                case /* string stytch_secret */ 23:
                    message.stytchSecret = reader.string();
                    break;
                case /* string stytch_cxa_project_id */ 24:
                    message.stytchCxaProjectId = reader.string();
                    break;
                case /* string stytch_cxa_secret */ 25:
                    message.stytchCxaSecret = reader.string();
                    break;
                case /* string stytch_webauthn_domain */ 26:
                    message.stytchWebauthnDomain = reader.string();
                    break;
                case /* string sendgrid_api_key */ 27:
                    message.sendgridApiKey = reader.string();
                    break;
                case /* string docraptor_api_key */ 28:
                    message.docraptorApiKey = reader.string();
                    break;
                case /* string taxjar_api_key */ 29:
                    message.taxjarApiKey = reader.string();
                    break;
                case /* string nolt_sso_key */ 30:
                    message.noltSsoKey = reader.string();
                    break;
                case /* string pager_duty_token */ 31:
                    message.pagerDutyToken = reader.string();
                    break;
                case /* string cloudinary_cloud_name */ 32:
                    message.cloudinaryCloudName = reader.string();
                    break;
                case /* string cloudinary_api_key */ 33:
                    message.cloudinaryApiKey = reader.string();
                    break;
                case /* string cloudinary_api_secret */ 34:
                    message.cloudinaryApiSecret = reader.string();
                    break;
                case /* rd.config.Config.LogOptions log_options */ 35:
                    message.logOptions = Config_LogOptions.internalBinaryRead(reader, reader.uint32(), options, message.logOptions);
                    break;
                case /* string rundoo_service_secret */ 36:
                    message.rundooServiceSecret = reader.string();
                    break;
                case /* string bm_contractor_rewards_sftp_pass */ 37:
                    message.bmContractorRewardsSftpPass = reader.string();
                    break;
                case /* bool use_pubsub */ 38:
                    message.usePubsub = reader.bool();
                    break;
                case /* string gitsha */ 39:
                    message.gitsha = reader.string();
                    break;
                case /* string stripe_publishable_key */ 40:
                    message.stripePublishableKey = reader.string();
                    break;
                case /* rd.config.Config.Trace trace_backend */ 41:
                    message.traceBackend = reader.int32();
                    break;
                case /* rd.config.Config.TasksConfig tasks_config */ 42:
                    message.tasksConfig = Config_TasksConfig.internalBinaryRead(reader, reader.uint32(), options, message.tasksConfig);
                    break;
                case /* uint32 tasks_port */ 43:
                    message.tasksPort = reader.uint32();
                    break;
                case /* string openai_api_key */ 44:
                    message.openaiApiKey = reader.string();
                    break;
                case /* string openai_assistant_id */ 45:
                    message.openaiAssistantId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Config, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.config.Config.Env env = 1; */
        if (message.env !== 0)
            writer.tag(1, WireType.Varint).int32(message.env);
        /* uint32 port = 2; */
        if (message.port !== 0)
            writer.tag(2, WireType.Varint).uint32(message.port);
        /* uint32 search_port = 3; */
        if (message.searchPort !== 0)
            writer.tag(3, WireType.Varint).uint32(message.searchPort);
        /* string base_url = 4; */
        if (message.baseUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.baseUrl);
        /* string search_url = 5; */
        if (message.searchUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.searchUrl);
        /* string pdf_url = 6; */
        if (message.pdfUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pdfUrl);
        /* string database_url = 7; */
        if (message.databaseUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.databaseUrl);
        /* int32 database_conns = 8; */
        if (message.databaseConns !== 0)
            writer.tag(8, WireType.Varint).int32(message.databaseConns);
        /* string diskman_data_dir = 9; */
        if (message.diskmanDataDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.diskmanDataDir);
        /* string diskman_lock_dir = 10; */
        if (message.diskmanLockDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.diskmanLockDir);
        /* string domain = 11; */
        if (message.domain !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.domain);
        /* uint32 admin_port = 12; */
        if (message.adminPort !== 0)
            writer.tag(12, WireType.Varint).uint32(message.adminPort);
        /* string admin_url = 13; */
        if (message.adminUrl !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.adminUrl);
        /* string bin_name = 14; */
        if (message.binName !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.binName);
        /* rd.config.Config.App app = 15; */
        if (message.app !== 0)
            writer.tag(15, WireType.Varint).int32(message.app);
        /* string hostname = 16; */
        if (message.hostname !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.hostname);
        /* string google_project_id = 17; */
        if (message.googleProjectId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.googleProjectId);
        /* string google_cloud_credentials_json = 18; */
        if (message.googleCloudCredentialsJson !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.googleCloudCredentialsJson);
        /* string google_maps_api_key = 19; */
        if (message.googleMapsApiKey !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.googleMapsApiKey);
        /* string google_cloud_sql_instance_conn_name = 20; */
        if (message.googleCloudSqlInstanceConnName !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.googleCloudSqlInstanceConnName);
        /* string stripe_secret_key = 21; */
        if (message.stripeSecretKey !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.stripeSecretKey);
        /* string stytch_project_id = 22; */
        if (message.stytchProjectId !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.stytchProjectId);
        /* string stytch_secret = 23; */
        if (message.stytchSecret !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.stytchSecret);
        /* string stytch_cxa_project_id = 24; */
        if (message.stytchCxaProjectId !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.stytchCxaProjectId);
        /* string stytch_cxa_secret = 25; */
        if (message.stytchCxaSecret !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.stytchCxaSecret);
        /* string stytch_webauthn_domain = 26; */
        if (message.stytchWebauthnDomain !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.stytchWebauthnDomain);
        /* string sendgrid_api_key = 27; */
        if (message.sendgridApiKey !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.sendgridApiKey);
        /* string docraptor_api_key = 28; */
        if (message.docraptorApiKey !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.docraptorApiKey);
        /* string taxjar_api_key = 29; */
        if (message.taxjarApiKey !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.taxjarApiKey);
        /* string nolt_sso_key = 30; */
        if (message.noltSsoKey !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.noltSsoKey);
        /* string pager_duty_token = 31; */
        if (message.pagerDutyToken !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.pagerDutyToken);
        /* string cloudinary_cloud_name = 32; */
        if (message.cloudinaryCloudName !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.cloudinaryCloudName);
        /* string cloudinary_api_key = 33; */
        if (message.cloudinaryApiKey !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.cloudinaryApiKey);
        /* string cloudinary_api_secret = 34; */
        if (message.cloudinaryApiSecret !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.cloudinaryApiSecret);
        /* rd.config.Config.LogOptions log_options = 35; */
        if (message.logOptions)
            Config_LogOptions.internalBinaryWrite(message.logOptions, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* string rundoo_service_secret = 36; */
        if (message.rundooServiceSecret !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.rundooServiceSecret);
        /* string bm_contractor_rewards_sftp_pass = 37; */
        if (message.bmContractorRewardsSftpPass !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.bmContractorRewardsSftpPass);
        /* bool use_pubsub = 38; */
        if (message.usePubsub !== false)
            writer.tag(38, WireType.Varint).bool(message.usePubsub);
        /* string gitsha = 39; */
        if (message.gitsha !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.gitsha);
        /* string stripe_publishable_key = 40; */
        if (message.stripePublishableKey !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.stripePublishableKey);
        /* rd.config.Config.Trace trace_backend = 41; */
        if (message.traceBackend !== 0)
            writer.tag(41, WireType.Varint).int32(message.traceBackend);
        /* rd.config.Config.TasksConfig tasks_config = 42; */
        if (message.tasksConfig)
            Config_TasksConfig.internalBinaryWrite(message.tasksConfig, writer.tag(42, WireType.LengthDelimited).fork(), options).join();
        /* uint32 tasks_port = 43; */
        if (message.tasksPort !== 0)
            writer.tag(43, WireType.Varint).uint32(message.tasksPort);
        /* string openai_api_key = 44; */
        if (message.openaiApiKey !== "")
            writer.tag(44, WireType.LengthDelimited).string(message.openaiApiKey);
        /* string openai_assistant_id = 45; */
        if (message.openaiAssistantId !== "")
            writer.tag(45, WireType.LengthDelimited).string(message.openaiAssistantId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.config.Config
 */
export const Config = new Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Config_LogOptions$Type extends MessageType<Config_LogOptions> {
    constructor() {
        super("rd.config.Config.LogOptions", [
            { no: 1, name: "level", kind: "enum", T: () => ["rd.config.Config.LogOptions.Level", Config_LogOptions_Level] },
            { no: 2, name: "src", kind: "enum", T: () => ["rd.config.Config.LogOptions.Src", Config_LogOptions_Src] },
            { no: 3, name: "use_json", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "stacktrace_level", kind: "enum", T: () => ["rd.config.Config.LogOptions.Level", Config_LogOptions_Level] },
            { no: 5, name: "rndo_stack_level", kind: "enum", T: () => ["rd.config.Config.LogOptions.Level", Config_LogOptions_Level] }
        ]);
    }
    create(value?: PartialMessage<Config_LogOptions>): Config_LogOptions {
        const message = { level: 0, src: 0, useJson: false, stacktraceLevel: 0, rndoStackLevel: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Config_LogOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Config_LogOptions): Config_LogOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.config.Config.LogOptions.Level level */ 1:
                    message.level = reader.int32();
                    break;
                case /* rd.config.Config.LogOptions.Src src */ 2:
                    message.src = reader.int32();
                    break;
                case /* bool use_json */ 3:
                    message.useJson = reader.bool();
                    break;
                case /* rd.config.Config.LogOptions.Level stacktrace_level */ 4:
                    message.stacktraceLevel = reader.int32();
                    break;
                case /* rd.config.Config.LogOptions.Level rndo_stack_level */ 5:
                    message.rndoStackLevel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Config_LogOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.config.Config.LogOptions.Level level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* rd.config.Config.LogOptions.Src src = 2; */
        if (message.src !== 0)
            writer.tag(2, WireType.Varint).int32(message.src);
        /* bool use_json = 3; */
        if (message.useJson !== false)
            writer.tag(3, WireType.Varint).bool(message.useJson);
        /* rd.config.Config.LogOptions.Level stacktrace_level = 4; */
        if (message.stacktraceLevel !== 0)
            writer.tag(4, WireType.Varint).int32(message.stacktraceLevel);
        /* rd.config.Config.LogOptions.Level rndo_stack_level = 5; */
        if (message.rndoStackLevel !== 0)
            writer.tag(5, WireType.Varint).int32(message.rndoStackLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.config.Config.LogOptions
 */
export const Config_LogOptions = new Config_LogOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Config_TasksConfig$Type extends MessageType<Config_TasksConfig> {
    constructor() {
        super("rd.config.Config.TasksConfig", [
            { no: 1, name: "service_base_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "service_account", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "api_conns", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "queue_configs", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "message", T: () => Config_TasksConfig_QueueConfig } }
        ]);
    }
    create(value?: PartialMessage<Config_TasksConfig>): Config_TasksConfig {
        const message = { serviceBaseUrl: "", serviceAccount: "", apiConns: 0, queueConfigs: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Config_TasksConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Config_TasksConfig): Config_TasksConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string service_base_url */ 1:
                    message.serviceBaseUrl = reader.string();
                    break;
                case /* string service_account */ 2:
                    message.serviceAccount = reader.string();
                    break;
                case /* int32 api_conns */ 3:
                    message.apiConns = reader.int32();
                    break;
                case /* map<int32, rd.config.Config.TasksConfig.QueueConfig> queue_configs */ 4:
                    this.binaryReadMap4(message.queueConfigs, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: Config_TasksConfig["queueConfigs"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Config_TasksConfig["queueConfigs"] | undefined, val: Config_TasksConfig["queueConfigs"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = Config_TasksConfig_QueueConfig.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.config.Config.TasksConfig.queue_configs");
            }
        }
        map[key ?? 0] = val ?? Config_TasksConfig_QueueConfig.create();
    }
    internalBinaryWrite(message: Config_TasksConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string service_base_url = 1; */
        if (message.serviceBaseUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.serviceBaseUrl);
        /* string service_account = 2; */
        if (message.serviceAccount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.serviceAccount);
        /* int32 api_conns = 3; */
        if (message.apiConns !== 0)
            writer.tag(3, WireType.Varint).int32(message.apiConns);
        /* map<int32, rd.config.Config.TasksConfig.QueueConfig> queue_configs = 4; */
        for (let k of Object.keys(message.queueConfigs)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k));
            writer.tag(2, WireType.LengthDelimited).fork();
            Config_TasksConfig_QueueConfig.internalBinaryWrite(message.queueConfigs[k as any], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.config.Config.TasksConfig
 */
export const Config_TasksConfig = new Config_TasksConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Config_TasksConfig_QueueConfig$Type extends MessageType<Config_TasksConfig_QueueConfig> {
    constructor() {
        super("rd.config.Config.TasksConfig.QueueConfig", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "num_queues", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "timeout", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<Config_TasksConfig_QueueConfig>): Config_TasksConfig_QueueConfig {
        const message = { path: "", numQueues: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Config_TasksConfig_QueueConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Config_TasksConfig_QueueConfig): Config_TasksConfig_QueueConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* int64 num_queues */ 2:
                    message.numQueues = reader.int64().toBigInt();
                    break;
                case /* google.protobuf.Duration timeout */ 3:
                    message.timeout = Duration.internalBinaryRead(reader, reader.uint32(), options, message.timeout);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Config_TasksConfig_QueueConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* int64 num_queues = 2; */
        if (message.numQueues !== 0n)
            writer.tag(2, WireType.Varint).int64(message.numQueues);
        /* google.protobuf.Duration timeout = 3; */
        if (message.timeout)
            Duration.internalBinaryWrite(message.timeout, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.config.Config.TasksConfig.QueueConfig
 */
export const Config_TasksConfig_QueueConfig = new Config_TasksConfig_QueueConfig$Type();
