import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { Location } from "gen/company/models_pb"
import { TimestampRange } from "gen/proto/timestamprange/models_pb"

export interface TransfersSearchFiltersState {
  filters: {
    dateRange: TimestampRange | null
    fromLocations: Location[]
    toLocations: Location[]
  }
  searchQuery: string
  isReady: boolean
}

const initialState: TransfersSearchFiltersState = {
  filters: {
    dateRange: null,
    fromLocations: [],
    toLocations: [],
  },
  searchQuery: "",
  isReady: false,
}

export const transfersSearchSlice = createSlice({
  name: "transfersSearch",
  initialState,
  reducers: {
    resetTransfersSearchFilters: (state) => {
      const newState = { ...initialState }
      newState.filters = { ...initialState.filters }
      newState.isReady = state.isReady
      return newState
    },
    setTransfersSearchDateRangeFilter: (
      state,
      action: PayloadAction<TimestampRange | null>
    ) => {
      state.filters.dateRange = action.payload
    },
    setTransfersSearchFromLocationsFilter: (
      state,
      action: PayloadAction<Location[]>
    ) => {
      state.filters.fromLocations = action.payload
    },
    setTransfersSearchToLocationsFilter: (
      state,
      action: PayloadAction<Location[]>
    ) => {
      state.filters.toLocations = action.payload
    },
    setTransfersSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    setTransfersLocationsDataLoaded: (state, _: PayloadAction<undefined>) => {
      state.isReady = true
    },
  },
})

export const {
  setTransfersLocationsDataLoaded,
  resetTransfersSearchFilters,
  setTransfersSearchDateRangeFilter,
  setTransfersSearchFromLocationsFilter,
  setTransfersSearchToLocationsFilter,
  setTransfersSearchQuery,
} = transfersSearchSlice.actions

export const selectTransfersSearchDateRangeFilter = (state: AppState) =>
  state.transfersSearch.filters.dateRange
export const selectTransfersSearchFromLocationsFilter = (state: AppState) =>
  state.transfersSearch.filters.fromLocations
export const selectTransfersSearchToLocationsFilter = (state: AppState) =>
  state.transfersSearch.filters.toLocations
export const selectTransfersSearchQuery = (state: AppState) =>
  state.transfersSearch.searchQuery
