import {
  VisualStateT,
  getVisualState,
} from "components/TimestampRangePicker/TimestampRangePickerCalendarDay"

import { Button } from "../Button"
import { DisabledModeT } from "components/TextDateInput/TextDateInput"
import { PartialInterval } from "lib/dates/interval"
import { classNames } from "lib/classNames"

interface CalendarCellProps {
  date: Date
  range: PartialInterval | null
  displayMonth: Date
  currentlySelecting: "from" | "to"
  onDateClicked: (date: Date) => void
  disabledMode: DisabledModeT
}

export function CalendarCell({
  date,
  range,
  displayMonth,
  currentlySelecting,
  onDateClicked,
  disabledMode,
}: CalendarCellProps) {
  const visualState = getVisualState(
    date,
    range,
    currentlySelecting,
    displayMonth,
    disabledMode
  )
  return (
    <Button
      mode="secondary"
      className={classNames(
        "h-8 w-8 items-center justify-center rounded-none border-none text-xs",
        "overflow-visible hover:rounded-lg focus:z-10 focus:rounded-lg",
        getClassName(visualState)
      )}
      disabled={visualState === "disabled"}
      onMouseDown={() => onDateClicked(date)}
      tabIndex={-1}
    >
      {date.getDate()}
    </Button>
  )
}

function getClassName(state: VisualStateT): string {
  const boundaryClassName =
    "bg-accent-button-medium-light dark:bg-accent-button-medium text-white"
  const classNameByState: Record<VisualStateT, string> = {
    disabled: "disabled:text-disable-light dark:disabled:text-disable-dark",
    muted: "text-gray-600 dark:text-gray-200",
    bolded: classNames(boundaryClassName, "rounded-lg"),
    "left-bolded": classNames(boundaryClassName, "rounded-l-lg"),
    "right-bolded": classNames(boundaryClassName, "rounded-r-lg"),
    highlighted:
      "bg-accent-negative-secondary-light dark:bg-accent-negative-secondary-dark",
    plain: "",
  }

  return classNameByState[state]
}
