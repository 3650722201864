import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react"

import { useRouter } from "next/router"

// PreviousRouteContext should ideally not be exported. The public interface is meant to include
// only PreviousRouteContextProvider and usePreviousRoute. This is exported solely for use in
// our renderWithProviders function used to ensure our components are unit tested with the
// appropriate context provided.
export const PreviousRouteContext = createContext<string>("")

// PreviousRouteContextProvider wraps the PreviousRouteContext provider and implements the logic
// for tracking the previous route. While the implementation here is concise, it is complex, relying
// on a deep understanding of refs and useEffect and how they interact with re-renders in React.
// In short, changes to asPath cause this component to re-render and at the time of rendering, we
// provide the current value of the ref via the context. Because 1) the ref's value is updated as a
// side effect of rendering (i.e. after rendering) and 2) a ref's value changing does not cause
// re-renders, the value provided via the context is always a step behind.
export const PreviousRouteContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { asPath, isReady } = useRouter()

  const ref = useRef<string>("")

  useEffect(() => {
    if (!isReady) return

    ref.current = asPath
  }, [isReady, asPath])

  return (
    <PreviousRouteContext.Provider value={ref.current}>
      {children}
    </PreviousRouteContext.Provider>
  )
}

// usePreviousRoute is a convenience hook that wraps the useContext call to make it easier and more
// readable to grab the previous route from the context.
export function usePreviousRoute() {
  return useContext(PreviousRouteContext)
}
