import { Modal, ModalProps } from "components/Modal"
import { useRef, useState } from "react"

import { Banner } from "components/Banner"
import { Button } from "components/Button"
import { Cookies } from "lib/cookies/cookies"
import { differenceInSeconds } from "date-fns"
import { useInterval } from "app/hooks"
import useKeyboardShortcut from "hooks/useKeyboardShortcut"

interface SessionTimeoutModalProps extends ModalProps {
  onStayLoggedIn: () => void
  isExtendingSession: boolean
  onSignOut: () => void
  isSigningOut: boolean
  showErrorBanner: boolean
}

export default function SessionTimeoutModal({
  open,
  onSignOut,
  isSigningOut,
  onStayLoggedIn,
  isExtendingSession,
  onClose,
  showErrorBanner,
}: SessionTimeoutModalProps) {
  const calculateSecondsUntilExpiration = () => {
    const { sessionExpiresDate } = Cookies.fromDocument(document)

    if (!sessionExpiresDate) {
      return 0
    }

    const now = new Date()
    return Math.max(differenceInSeconds(sessionExpiresDate, now), 0)
  }

  const [secondsUntilExpiration, setSecondsUntilExpiration] = useState(
    calculateSecondsUntilExpiration()
  )

  useInterval(
    () => {
      setSecondsUntilExpiration(calculateSecondsUntilExpiration())
    },
    open ? 1000 : null
  )

  const stayLoggedInButtonRef = useRef<HTMLButtonElement>(null)

  useKeyboardShortcut(
    "enter",
    () => {
      stayLoggedInButtonRef.current?.click()
    },
    { disableOnModalOpen: false }
  )

  return (
    <Modal onClose={onClose} open={open} initialFocus={stayLoggedInButtonRef}>
      <h2 className="font-bold">Session timeout</h2>
      {showErrorBanner && (
        <Banner
          mode="error"
          className="my-4"
          message="Something went wrong. Please try again."
        />
      )}
      {secondsUntilExpiration > 0 && (
        <p className="mb-4 mt-4 text-gray-600">
          You will be signed out in {secondsUntilExpiration}{" "}
          {secondsUntilExpiration === 1 ? "second" : "seconds"}.
        </p>
      )}
      <div className="flex flex-row justify-end sm:col-span-6">
        <Button
          id="timeout-logout-button"
          mode="secondary"
          loading={isSigningOut}
          onClick={onSignOut}
        >
          Sign out
        </Button>
        <Button
          id="timeout-stay-logged-in-button"
          mode="primary"
          className="ml-4"
          type="submit"
          loading={isExtendingSession}
          onClick={onStayLoggedIn}
          ref={stayLoggedInButtonRef}
        >
          Stay logged in
        </Button>
      </div>
    </Modal>
  )
}
