/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "user/models.proto" (package "rd.user", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../google/protobuf/any_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * User represents the currently logged-in user for a HTTP request. Users can
 * be cron jobs (no email), Rundoo employees (@getrundoo.com emails), or
 * [client] company staff members (company-specific emails).
 *
 * @generated from protobuf message rd.user.User
 */
export interface User {
    /**
     * @generated from protobuf field: rd.user.User.Type type = 1;
     */
    type: User_Type;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 3;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID staff_id = 4;
     */
    staffId?: UUID;
    /**
     * @generated from protobuf field: string stytch_id = 5;
     */
    stytchId: string;
    /**
     * @generated from protobuf field: string phone = 6;
     */
    phone: string;
    /**
     * Name is set only for staff users. Use ReadableString for all backend logs.
     * This is used primarily to cache the user's name when storing e.g. the user
     * who created a record. We do this so we can avoid querying Staff e.g. when
     * enabling searching over the users who created or updated a record.
     *
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID dooer_staff_id = 8;
     */
    dooerStaffId?: UUID;
    /**
     * @generated from protobuf field: repeated string role_ids = 9;
     */
    roleIds: string[]; // for intercom
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 11;
     */
    customerId?: UUID; // for CxaUser
}
/**
 * @generated from protobuf enum rd.user.User.Type
 */
export enum User_Type {
    /**
     * @generated from protobuf enum value: USER_TYPE_UNKNOWN = 0;
     */
    USER_TYPE_UNKNOWN = 0,
    /**
     * GCP cron job
     *
     * @generated from protobuf enum value: USER_TYPE_CRON = 1;
     */
    USER_TYPE_CRON = 1,
    /**
     * Rundoo employee via gcloud token (admin service)
     *
     * @generated from protobuf enum value: USER_TYPE_DOOER = 2;
     */
    USER_TYPE_DOOER = 2,
    /**
     * Staff member at client company
     *
     * @generated from protobuf enum value: USER_TYPE_STAFF = 3;
     */
    USER_TYPE_STAFF = 3,
    /**
     * Rundoo web service
     *
     * @generated from protobuf enum value: USER_TYPE_RUNDOO_SERVICE = 4;
     */
    USER_TYPE_RUNDOO_SERVICE = 4,
    /**
     * Dooer acting as a company Staff member
     *
     * @generated from protobuf enum value: USER_TYPE_DOOER_STAFF = 5;
     */
    USER_TYPE_DOOER_STAFF = 5,
    /**
     * Rundoo service account in CI or internal tool
     *
     * @generated from protobuf enum value: USER_TYPE_RUNDOO_SERVICE_ACCOUNT = 6;
     */
    USER_TYPE_RUNDOO_SERVICE_ACCOUNT = 6,
    /**
     * Customer app user
     *
     * @generated from protobuf enum value: USER_TYPE_CXA_USER = 7;
     */
    USER_TYPE_CXA_USER = 7
}
/**
 * RequestLog stores data about an HTTP request.
 *
 * @generated from protobuf message rd.user.RequestLog
 */
export interface RequestLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string service_name = 3;
     */
    serviceName: string;
    /**
     * @generated from protobuf field: string method_name = 4;
     */
    methodName: string;
    /**
     * @generated from protobuf field: string rndo_trace = 5;
     */
    rndoTrace: string;
    /**
     * @generated from protobuf field: rd.user.User user = 6;
     */
    user?: User;
    /**
     * @generated from protobuf field: google.protobuf.Any request = 7;
     */
    request?: Any;
    /**
     * @generated from protobuf field: string error = 8;
     */
    error: string;
    /**
     * @generated from protobuf field: double latency_ms = 9;
     */
    latencyMs: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("rd.user.User", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.user.User.Type", User_Type] },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "company_id", kind: "message", T: () => UUID },
            { no: 4, name: "staff_id", kind: "message", T: () => UUID },
            { no: 5, name: "stytch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "dooer_staff_id", kind: "message", T: () => UUID },
            { no: 9, name: "role_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "customer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { type: 0, email: "", stytchId: "", phone: "", name: "", roleIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.user.User.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 3:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID staff_id */ 4:
                    message.staffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.staffId);
                    break;
                case /* string stytch_id */ 5:
                    message.stytchId = reader.string();
                    break;
                case /* string phone */ 6:
                    message.phone = reader.string();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* rd.proto.uuid.UUID dooer_staff_id */ 8:
                    message.dooerStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.dooerStaffId);
                    break;
                case /* repeated string role_ids */ 9:
                    message.roleIds.push(reader.string());
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 11:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.user.User.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* rd.proto.uuid.UUID company_id = 3; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID staff_id = 4; */
        if (message.staffId)
            UUID.internalBinaryWrite(message.staffId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stytch_id = 5; */
        if (message.stytchId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stytchId);
        /* string phone = 6; */
        if (message.phone !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.phone);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* rd.proto.uuid.UUID dooer_staff_id = 8; */
        if (message.dooerStaffId)
            UUID.internalBinaryWrite(message.dooerStaffId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated string role_ids = 9; */
        for (let i = 0; i < message.roleIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.roleIds[i]);
        /* rd.proto.uuid.UUID customer_id = 11; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.user.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestLog$Type extends MessageType<RequestLog> {
    constructor() {
        super("rd.user.RequestLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "service_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "method_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "rndo_trace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "user", kind: "message", T: () => User },
            { no: 7, name: "request", kind: "message", T: () => Any },
            { no: 8, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "latency_ms", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<RequestLog>): RequestLog {
        const message = { serviceName: "", methodName: "", rndoTrace: "", error: "", latencyMs: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestLog): RequestLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string service_name */ 3:
                    message.serviceName = reader.string();
                    break;
                case /* string method_name */ 4:
                    message.methodName = reader.string();
                    break;
                case /* string rndo_trace */ 5:
                    message.rndoTrace = reader.string();
                    break;
                case /* rd.user.User user */ 6:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* google.protobuf.Any request */ 7:
                    message.request = Any.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* string error */ 8:
                    message.error = reader.string();
                    break;
                case /* double latency_ms */ 9:
                    message.latencyMs = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RequestLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string service_name = 3; */
        if (message.serviceName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.serviceName);
        /* string method_name = 4; */
        if (message.methodName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.methodName);
        /* string rndo_trace = 5; */
        if (message.rndoTrace !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.rndoTrace);
        /* rd.user.User user = 6; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Any request = 7; */
        if (message.request)
            Any.internalBinaryWrite(message.request, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string error = 8; */
        if (message.error !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.error);
        /* double latency_ms = 9; */
        if (message.latencyMs !== 0)
            writer.tag(9, WireType.Bit64).double(message.latencyMs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.user.RequestLog
 */
export const RequestLog = new RequestLog$Type();
