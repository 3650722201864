/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "proto/decimal/models.proto" (package "rd.proto.decimal", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Decimal is a wrapper for storing decimals in protobuf.
 *
 * @generated from protobuf message rd.proto.decimal.Decimal
 */
export interface Decimal {
    /**
     * str should not be modified directly.
     *
     * @generated from protobuf field: string str = 1;
     */
    str: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Decimal$Type extends MessageType<Decimal> {
    constructor() {
        super("rd.proto.decimal.Decimal", [
            { no: 1, name: "str", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Decimal>): Decimal {
        const message = { str: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Decimal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Decimal): Decimal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string str */ 1:
                    message.str = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Decimal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string str = 1; */
        if (message.str !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.str);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.proto.decimal.Decimal
 */
export const Decimal = new Decimal$Type();
