import { classNames } from "lib/classNames"

interface CheckboxClassNamesProps {
  checked: boolean
  hover?: boolean
  disabled?: boolean
}

export function checkboxClassNames({
  checked,
  hover,
  disabled,
}: CheckboxClassNamesProps) {
  return classNames(
    "inline-flex items-center justify-center align-middle",
    "h-4 w-4",
    "rounded-sm",
    "cursor-pointer",
    "bg-transparent",
    "border",
    "border-disable-light dark:border-disable-dark border",
    hover && "border-text-secondary-light dark:border-text-secondary-dark",
    checked &&
      classNames(
        "border-accent-button-medium-light dark:border-accent-button-medium-dark",
        "bg-accent-button-medium-light dark:bg-accent-button-medium-dark",
        hover && "border-accent-button-light dark:border-accent-button-dark",
        hover && "bg-accent-button-light dark:bg-accent-button-dark"
      ),
    disabled &&
      classNames(
        "cursor-not-allowed",
        "border-border-accent-light dark:border-border-accent-dark",
        checked && "bg-border-accent-light dark:bg-border-accent-dark"
      )
  )
}
