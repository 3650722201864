import { ArrowRightIcon, CalendarIcon } from "@heroicons/react/solid"
import {
  DisabledMode,
  DisabledModeT,
} from "components/TextDateInput/TextDateInput"
import {
  FullInterval,
  PartialInterval,
  isFullInterval,
  newFullIntervalFromTimestampRange,
} from "lib/dates/interval"
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { Preset, listDateRangePresets, matchingPreset } from "lib/dates/presets"
import {
  addMonths,
  endOfDay,
  endOfToday,
  startOfDay,
  startOfToday,
  subMonths,
} from "date-fns"
import { useRef, useState } from "react"

import { Card } from "../Card"
import { TextDateInput } from "components/TextDateInput"
import { TimestampRange } from "lib/timestamp_range"
import { TimestampRange as TimestampRangePB } from "gen/proto/timestamprange/models_pb"
import { TimestampRangePickerCalendar } from "./TimestampRangePickerCalendar"
import { TimestampRangePickerPresetSidebar } from "./TimestampRangePickerPresetSidebar"
import { classNames } from "lib/classNames"

interface TimestampRangePickerProps {
  timestampRange: TimestampRangePB | null
  onChangeTimestampRange: (range: TimestampRangePB | null) => void
  showSelectedValueInButton?: boolean
  buttonClassName?: string
  panelClassName?: string
  showAllTimePreset?: boolean
  popoverButtonTestId?: string
  disabledMode?: DisabledModeT
}

export interface UseIntervalPickerProps {
  publishedInterval: FullInterval | null
  onChangePublishedInterval: (range: FullInterval | null) => void
  disabledMode?: DisabledModeT
}

export function useIntervalPicker(props: UseIntervalPickerProps) {
  const {
    publishedInterval: publishedRange,
    onChangePublishedInterval: onChangeTimestampRange,
    disabledMode = DisabledMode.Future,
  } = props

  const [draftRange, setDraftRange] = useState<PartialInterval | null>(null)
  const displayRange = draftRange || publishedRange

  const today = startOfToday()
  const [monthDisplayed, setMonthDisplayed] = useState<Date>(today)

  const fromInputRef = useRef<HTMLInputElement>(null)
  const toInputRef = useRef<HTMLInputElement>(null)

  const [currentDateField, setCurrentDateField] = useState<"from" | "to">(
    "from"
  )

  function setSelection(
    range: PartialInterval | null,
    publishIfValid: boolean
  ) {
    setDraftRange(range)
    if (!publishIfValid) {
      return
    }

    if (range === null || range.isEmpty()) {
      onChangeTimestampRange(null)
    } else if (isFullInterval(range)) {
      onChangeTimestampRange(range)
    }
  }

  function onDelete() {
    setSelection(null, true)
  }

  function onSelectPreset(range: Preset) {
    setSelection(range.rangeFunc(), true)
  }

  function onChangeFromDate(day: Date | null) {
    const newDraft = new PartialInterval({
      start: day ? startOfDay(day) : undefined,
      end: displayRange?.end,
    })
    setSelection(newDraft, false)
    toInputRef.current?.focus()
  }

  function onChangeToDate(day: Date | null) {
    const newDraft = new PartialInterval({
      start: displayRange?.start,
      end: day ? endOfDay(day) : undefined,
    })
    setSelection(newDraft, true)
  }

  function onDateClicked(day: Date) {
    if (currentDateField === "from") {
      onChangeFromDate(day)
    } else {
      onChangeToDate(day)
    }
  }

  const startInputProps = {
    ref: fromInputRef,
    date: displayRange?.start ?? null,
    onFocus: () => {
      setCurrentDateField("from")
      setMonthDisplayed(displayRange?.start ?? today)
    },
    onBlur: () => toInputRef.current?.focus(),
    defaultRefDate: startOfToday(),
    disabledMode: disabledMode,
    placeholder: "From",
    autoFocus: true,
    onChangeDate: onChangeFromDate,
  }

  const endInputProps = {
    ref: toInputRef,
    date: displayRange?.end ?? null,
    onFocus: () => {
      setCurrentDateField("to")
      setMonthDisplayed(monthDisplayed ?? displayRange?.end ?? today)
    },
    onBlur: () => fromInputRef.current?.focus(),
    defaultRefDate: monthDisplayed ?? endOfToday(),
    disabledMode: disabledMode,
    placeholder: "To",
    onChangeDate: onChangeToDate,
  }

  return {
    monthDisplayed,
    onClickPreviousMonth: () => setMonthDisplayed(subMonths(monthDisplayed, 1)),
    onClickNextMonth: () => setMonthDisplayed(addMonths(monthDisplayed, 1)),
    currentDateField,
    displayRange,
    onDateClicked,
    onSelectPreset,
    startInputProps,
    endInputProps,
    onDelete,
    disabledMode,
  }
}

export function TimestampRangePicker(props: TimestampRangePickerProps) {
  const {
    timestampRange,
    onChangeTimestampRange,
    showSelectedValueInButton,
    buttonClassName,
    panelClassName,
    showAllTimePreset,
    disabledMode,
  } = props

  const publishedRange = newFullIntervalFromTimestampRange(timestampRange)

  function handleChangePublishedInterval(range: PartialInterval | null) {
    onChangeTimestampRange(range?.toTimestampRangePB() ?? null)
  }

  const presetsToDisplay = listDateRangePresets(!!showAllTimePreset)

  const selectedPreset = matchingPreset(presetsToDisplay, publishedRange)

  function buttonText(): string {
    if (!showSelectedValueInButton) return "Date"
    if (!publishedRange?.isValid()) {
      return showAllTimePreset ? "All time" : "Date"
    }
    if (selectedPreset) {
      return selectedPreset.displayText
    }

    return TimestampRange.humanize(publishedRange.toTimestampRangePB())
  }

  return (
    <Popover className="relative">
      <PopoverButton
        data-testid="timestamp-range-picker-button"
        className={classNames(
          "relative z-10 flex w-full items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-2 text-gray-600 focus:z-20 focus:border-gray-300 focus:text-black dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:focus:border-gray-400 dark:focus:text-gray-300",
          buttonClassName
        )}
      >
        <CalendarIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
        {buttonText()}
      </PopoverButton>

      <PopoverPanel
        className={classNames("absolute right-0 z-40 mt-1", panelClassName)}
      >
        {({ close }) => (
          <TimestampRangePickerPanel
            publishedInterval={publishedRange}
            onChangePublishedInterval={(range) => {
              handleChangePublishedInterval(range)
              close()
            }}
            selectedPreset={selectedPreset}
            presetsToDisplay={presetsToDisplay}
            disabledMode={disabledMode}
          />
        )}
      </PopoverPanel>
    </Popover>
  )
}

interface TimestampRangePickerPanelProps extends UseIntervalPickerProps {
  selectedPreset: Preset | null
  presetsToDisplay: Preset[]
}

function TimestampRangePickerPanel({
  selectedPreset,
  presetsToDisplay,
  ...useIntervalPickerProps
}: TimestampRangePickerPanelProps) {
  const {
    monthDisplayed,
    onClickPreviousMonth,
    onClickNextMonth,
    currentDateField,
    displayRange,
    onDateClicked,
    onSelectPreset,
    startInputProps,
    endInputProps,
    disabledMode,
  } = useIntervalPicker(useIntervalPickerProps)

  return (
    <Card className="dark:ring-opacity-8 flex flex-row shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="flex min-w-[21rem] flex-col gap-y-4 border border-none border-r-gray-200 pr-4 dark:border-r-gray-600">
        <div className="flex flex-row items-center gap-x-2 border-b border-b-gray-200 pb-4 dark:border-b-gray-600">
          <TextDateInput
            data-testid="timestamp-range-picker-from-input"
            {...startInputProps}
          />
          <ArrowRightIcon className="h-7 w-7 text-gray-600 dark:text-gray-200" />
          <TextDateInput
            data-testid="timestamp-range-picker-to-input"
            {...endInputProps}
          />
        </div>

        <TimestampRangePickerCalendar
          selection={displayRange}
          monthDisplayed={monthDisplayed}
          onClickPreviousMonth={onClickPreviousMonth}
          onClickNextMonth={onClickNextMonth}
          currentlySelecting={currentDateField}
          onDateClicked={onDateClicked}
          disabledMode={disabledMode}
        />
      </div>
      <TimestampRangePickerPresetSidebar
        selectedPreset={selectedPreset}
        onSelectPreset={onSelectPreset}
        presetsToDisplay={presetsToDisplay}
      />
    </Card>
  )
}
