import Big from "big.js"
import { Decimal as PBDecimal } from "gen/proto/decimal/models_pb"

// Decimal is a simple wrapper for our Decimal protobuf message.
export class Decimal {
  private d: PBDecimal

  private constructor(decimal: PBDecimal) {
    this.d = decimal
  }

  private big(): Big {
    return new Big(this.d.str)
  }

  // str constructs a string from a Big.
  static str(big: Big): string {
    return big.toString()
  }

  // zero returns a zero-valued Decimal.
  static zero(): Decimal {
    return new Decimal({ str: Decimal.str(new Big(0)) })
  }

  // fromPB constructs a Decimal from our protobuf UUID message defined
  // in rd/proto/decimal/models.proto.
  static fromPB(pbDecimal?: PBDecimal): Decimal {
    if (!pbDecimal) {
      return Decimal.zero()
    }
    return new Decimal({ str: pbDecimal.str })
  }

  // fromString constructs a Decimal from a string. It throws an error
  // if the string is not parseable as a number.
  static fromString(str: string): Decimal {
    if (Number.isNaN(Number(str))) {
      throw new Error("string not parseable as number")
    }

    return new Decimal({ str: Decimal.str(new Big(str)) })
  }

  // fromNumber constructs a Decimal from a number.
  static fromNumber(num: number): Decimal {
    return new Decimal({ str: Decimal.str(new Big(num)) })
  }

  // add returns a new Decimal representing the sum of this and other.
  // The original values are unmodified.
  add(other?: Decimal): Decimal {
    const b = this.big().add(other?.big() ?? new Big(0))
    return new Decimal({ str: Decimal.str(b) })
  }

  // sub returns a new Decimal representing the difference of this and
  // other. The original values are unmodified.
  sub(other?: Decimal): Decimal {
    const b = this.big().sub(other?.big() ?? new Big(0))
    return new Decimal({ str: Decimal.str(b) })
  }

  // mult returns a new Decimal representing the product of this and
  // other. The original values are unmodified.
  mult(other: Decimal): Decimal {
    const b = this.big().mul(other.big())
    return new Decimal({ str: Decimal.str(b) })
  }

  // div returns a new Decimal representing the division of this by the
  // other. The original values are unmodified.
  div(other: Decimal): Decimal {
    const b = this.big().div(other.big())
    return new Decimal({ str: Decimal.str(b) })
  }

  // cmp compares the two Decimals. The return value is:
  //  1 if this > other
  //  0 if this == other
  // -1 if this < other
  // This function is intended for use in sorting; to compare
  // two currencies, use lt, le, ge, or gt.
  cmp(other: Decimal): number {
    return this.big().cmp(other.big())
  }

  // lt returns whether a Decimal is strictly less than another Decimal.
  lt(other: Decimal): boolean {
    return this.big().lt(other.big())
  }

  // lte returns whether a Decimal is less than or equal to another Decimal.
  lte(other: Decimal): boolean {
    return this.big().lte(other.big())
  }

  // gt returns whether a Decimal is strictly greater than another Decimal.
  gt(other: Decimal): boolean {
    return this.big().gt(other.big())
  }

  // gte returns whether a Decimal is greater than or equal to another Decimal.
  gte(other: Decimal): boolean {
    return this.big().gte(other.big())
  }

  // eq returns whether a Decimal is equal to another Decimal.
  eq(other: Decimal): boolean {
    return this.big().eq(other.big())
  }

  // round returns a new Decimal representing this rounded to the supplied number of
  // decimal places.
  round(decimalPlaces: number): Decimal {
    const b = this.big().round(decimalPlaces)
    return new Decimal({ str: Decimal.str(b) })
  }

  // toPB returns the protobuf UUID message representation defined in
  // rd/proto/decimal/models.proto.
  toPB(): PBDecimal {
    return this.d
  }

  // toFixed returns a string representation of a Decimal rounded to a fixed number of decimalPlaces.
  // If decimalPlaces is omitted or is undefined, the return value is simply the value in normal notation.
  toFixed(decimalPlaces?: number): string {
    const b = this.big()
    return b.toFixed(decimalPlaces)
  }

  // toString returns a string representation of a Decimal.
  toString(): string {
    const b = this.big()
    return b.toFixed()
  }

  // places returns the number of decimal places for a Decimal.
  places(): number {
    if (!this.d.str.includes(".")) {
      return 0
    }

    return this.d.str.split(".")[1].length
  }

  // int returns the integer component of d as a bigint.
  // For non-integer values, this always rounds down.
  int(): bigint {
    if (!this.d.str.includes(".")) {
      return BigInt(this.d.str)
    }
    return BigInt(this.d.str.split(".")[0])
  }

  // toPercentString returns a string representation of a decimal as a percentage.
  toPercentString(decimalPlaces?: number): string {
    return `${this.mult(Decimal.fromNumber(100)).toFixed(decimalPlaces)}%`
  }
}
