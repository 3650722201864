import {
  Field as HeadlessField,
  FieldProps as HeadlessFieldProps,
  Label as HeadlessLabel,
} from "@headlessui/react"

import { Checkbox } from "components/Checkbox"
import { CheckboxLabel } from "./CheckboxLabel"
import { CheckboxLabelledRow } from "./CheckboxLabelledRow"
import { PropsWithChildren } from "react"

export interface CheckboxLabelledProps
  extends PropsWithChildren<
    Omit<HeadlessFieldProps, "className" | "onChange">
  > {
  className?: string
  disabled?: boolean
  checked: boolean
  onChange?: (checked: boolean) => void
  name?: string
}

export function CheckboxLabelled({
  children,
  className,
  checked,
  onChange,
  disabled,
  name,
  ...otherProps
}: CheckboxLabelledProps) {
  return (
    <HeadlessField
      as={CheckboxLabelledRow}
      className={className}
      disabled={disabled}
      {...otherProps}
    >
      <Checkbox name={name} checked={checked} onChange={onChange} />
      <HeadlessLabel
        as={CheckboxLabel}
        className="data-[disabled]:cursor-not-allowed"
      >
        {children}
      </HeadlessLabel>
    </HeadlessField>
  )
}
