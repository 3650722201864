import { UseBoolFilterProps, UseBoolFilterRes } from "./types"

import { BoolFilter } from "gen/search/service/service_pb"
import { useFilter } from "./useFilter"
import { useMemo } from "react"

export function useBoolFilter(props: UseBoolFilterProps): UseBoolFilterRes {
  const { selected, ...res } = useFilter({
    ...props,
    values: [BoolFilter.TRUE, BoolFilter.FALSE],
    keyExtractor: (option) => option.toString(),
    autoFilterOptions: true,
  })
  const selectedValue = useMemo(
    () => (selected.length === 1 ? selected[0] : BoolFilter.NONE),
    [selected]
  )

  return {
    ...res,
    selectedValue,
  }
}
