import { ComponentPropsWithRef, forwardRef } from "react"

import { LoadingSpinner } from "components/LoadingSpinner"
import { classNames } from "lib/classNames"

export interface SingleCharacterInputProps
  extends Omit<ComponentPropsWithRef<"input">, "size"> {
  size?: "sm" | "lg"
  isLoading?: boolean
}

export const SingleCharacterInput = forwardRef<
  HTMLInputElement,
  SingleCharacterInputProps
>((props, ref) => {
  const { className, size = "lg", isLoading, disabled, ...otherProps } = props

  const cn = classNames(
    "bg-cobalt-50 dark:bg-cobalt-600 text-primary rounded-lg border-none",
    size === "sm" && "h-10 w-10",
    size === "lg" && "h-20 w-20",
    className
  )

  if (isLoading) {
    return (
      <div
        className={classNames(
          "flex items-center justify-center",
          cn,
          disabled && "bg-surface-accent-light dark:bg-surface-accent-dark"
        )}
      >
        <LoadingSpinner size={size === "sm" ? "small" : "large"} />
      </div>
    )
  }

  return (
    <input
      className={classNames(
        "focus:ring-accent-primary-light dark:focus:ring-accent-button-dark disabled:bg-surface-accent-light dark:disabled:bg-surface-accent-dark text-center font-semibold",
        size === "sm" && "text-base",
        size === "lg" && "text-lg",
        cn
      )}
      disabled={disabled}
      {...otherProps}
      ref={ref}
    />
  )
})
