import {
  UseComboboxReturnValue as UseDownshiftComboboxReturnValue,
  useCombobox as useDownshiftCombobox,
} from "downshift"

import { filterItems } from "./filterItems"
import { useState } from "react"

export interface UseComboboxProps<T> {
  items: T[]
  keyExtractor: (item: T) => string
  valueExtractor: (item: T) => string
  onSelect: (item: T) => void
  autoFilter?: boolean
}

export interface UseComboboxResult<T>
  extends UseDownshiftComboboxReturnValue<T> {
  filteredItems: T[]
}

export function useCombobox<T>({
  items,
  keyExtractor,
  valueExtractor,
  onSelect,
  autoFilter,
}: UseComboboxProps<T>): UseComboboxResult<T> {
  const [filterInputValue, setFilterInputValue] = useState("")

  const filteredItems = filterItems({
    items,
    filterInputValue,
    valueExtractor,
    autoFilter,
  })

  function handleKeyExtractor(item: T | null): string {
    return item ? keyExtractor(item) : ""
  }

  function handleItemToString(item: T | null): string {
    return item ? valueExtractor(item) : ""
  }

  return {
    ...useDownshiftCombobox({
      items: filteredItems,
      onSelectedItemChange({ selectedItem }) {
        onSelect(selectedItem)
      },
      itemToString: handleItemToString,
      itemToKey: handleKeyExtractor,
      defaultHighlightedIndex: 0,
      onInputValueChange(changes) {
        setFilterInputValue(changes.inputValue)
      },
      initialIsOpen: true,
    }),
    filteredItems,
  }
}

type UseMultiComboboxProps<T> = Omit<UseComboboxProps<T>, "isOpenInitially">
export function useMultiCombobox<T>({
  items,
  keyExtractor,
  valueExtractor,
  onSelect,
  autoFilter,
}: UseMultiComboboxProps<T>): UseComboboxResult<T> {
  const [filterInputValue, setFilterInputValue] = useState("")

  const filteredItems = filterItems({
    items,
    filterInputValue,
    valueExtractor,
    autoFilter,
  })

  function handleKeyExtractor(item: T | null): string {
    return item ? keyExtractor(item) : ""
  }

  function handleItemToString(item: T | null): string {
    return item ? valueExtractor(item) : ""
  }

  return {
    ...useDownshiftCombobox({
      inputValue: filterInputValue,
      items: filteredItems,
      selectedItem: null,
      onSelectedItemChange({ selectedItem }) {
        onSelect(selectedItem)
      },
      itemToString: handleItemToString,
      itemToKey: handleKeyExtractor,
      defaultHighlightedIndex: 0,
      onInputValueChange(changes) {
        switch (changes.type) {
          case useDownshiftCombobox.stateChangeTypes.ItemClick:
          case useDownshiftCombobox.stateChangeTypes.InputKeyDownEnter:
          case useDownshiftCombobox.stateChangeTypes
            .ControlledPropUpdatedSelectedItem:
            return
        }

        setFilterInputValue(changes.inputValue)
      },
      initialIsOpen: true,
      isOpen: true,
    }),
    filteredItems,
  }
}
