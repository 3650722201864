// @ts-strict-ignore
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  Transfer_Product as TransferProduct,
  Transfer_Status as TransferStatus,
} from "gen/txn/models_pb"
import {
  checkProductsEquality,
  fixTxnProductsOrders,
} from "../transactions/helpers"

import { AppState } from "app/store"
import { GetTransferRes } from "gen/api/transfer/service_pb"
import { Location } from "gen/company/models_pb"
import { TransactionProduct } from "../transactions/transactionProduct"
import TransferForm from "./types/TransferForm"
import { UUID } from "uuid-rd"
import { stringify } from "lib/stringify"

export interface TransferFormState extends TransferForm {
  initialState?: TransferForm
  isEdited?: boolean
  scrollToBottom?: boolean
}

const initialState: TransferFormState = {}

const checkTransferIsEdited = (state: TransferFormState) => {
  const initState = { ...state.initialState }
  const currState = { ...state, isEdited: undefined, initialState: undefined }
  return !checkStateEquality(initState ?? {}, currState)
}

const checkStateEquality = (
  stateA: TransferFormState,
  stateB: TransferFormState
) =>
  UUID.eqFromPB(
    stateA.transferFromLocation?.id,
    stateB.transferFromLocation?.id
  ) &&
  UUID.eqFromPB(stateA.transferToLocation?.id, stateB.transferToLocation?.id) &&
  checkProductsEquality(
    Object.values(stateA.transferProductsById || {}),
    Object.values(stateB.transferProductsById || {})
  ) &&
  stateA.sendNotes === stateB.sendNotes &&
  stateA.receiveNotes === stateB.receiveNotes &&
  stringify(stateA.searchProduct) === stringify(stateB.searchProduct) &&
  stateA.addProductQuantity === stateB.addProductQuantity

function seedProductsById(productsById?: Record<string, TransferProduct>) {
  if (!productsById) {
    return {}
  }

  const seededProductsById: Record<string, TransferProduct> = {}
  for (const [id, product] of Object.entries(productsById)) {
    seededProductsById[id] = {
      ...product,
      quantityReceived: product.quantitySent,
    }
  }

  return seededProductsById
}

export const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setTransferInitialState: (state, action: PayloadAction<GetTransferRes>) => {
      state.initialState = {
        inventoriesByProductId: action.payload.inventoriesByProductId,
        productsById: action.payload.productsById,
        catalogProductsById: action.payload.catalogProductsById,
        receiveNotes: action.payload.transfer?.receiveNotes,
        sendNotes: action.payload.transfer?.sendNotes,
        transferFromLocation: action.payload.fromLocation,
        transferToLocation: action.payload.toLocation,
        transferProductsById:
          action.payload.transfer?.status === TransferStatus.SENT
            ? seedProductsById(action.payload.transfer?.productsById)
            : action.payload.transfer?.productsById,
      }
      state.inventoriesByProductId = action.payload.inventoriesByProductId
      state.isEdited = false
      state.productsById = action.payload.productsById
      state.catalogProductsById = action.payload.catalogProductsById
      state.receiveNotes = action.payload.transfer?.receiveNotes
      state.sendNotes = action.payload.transfer?.sendNotes
      state.transferFromLocation = action.payload.fromLocation
      state.transferProductsById =
        action.payload.transfer?.status === TransferStatus.SENT
          ? seedProductsById(action.payload.transfer?.productsById)
          : action.payload.transfer?.productsById
      state.transferToLocation = action.payload.toLocation
      state.searchProduct = undefined
      state.addProductQuantity = 1
    },
    resetTransferState: (state) => {
      state.receiveNotes = ""
      state.sendNotes = ""
      state.transferToLocation = undefined
      state.transferFromLocation = undefined
      state.transferProductsById = {}
      state.isEdited = false
      state.searchProduct = undefined
      state.addProductQuantity = 1
    },
    setTransferFromLocation: (
      state,
      action: PayloadAction<Location | undefined>
    ) => {
      if (
        !!action.payload?.id &&
        UUID.eqFromPB(action.payload?.id, state.transferToLocation?.id)
      ) {
        return
      }
      state.transferFromLocation = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferToLocation: (
      state,
      action: PayloadAction<Location | undefined>
    ) => {
      if (
        !!action.payload?.id &&
        UUID.eqFromPB(action.payload?.id, state.transferFromLocation?.id)
      ) {
        return
      }
      state.transferToLocation = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferSendNotes: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.sendNotes = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferReceiveNotes: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.receiveNotes = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProducts: (
      state,
      action: PayloadAction<Record<string, TransferProduct>>
    ) => {
      state.transferProductsById = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProductQuantity: (
      state,
      action: PayloadAction<TransferProduct>
    ) => {
      if (!action.payload.productId) {
        return
      }

      const payloadId = UUID.fromPB(action.payload.productId).toString()
      if (!state.transferProductsById?.[payloadId]) {
        return
      }

      state.transferProductsById![payloadId] = {
        ...state.transferProductsById![payloadId],
        quantityReceived: action.payload.quantityReceived,
        quantitySent: action.payload.quantitySent,
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    setNewTransferProduct: (state, action: PayloadAction<TransferProduct>) => {
      if (!action.payload.productId) {
        return
      }

      if (!state.transferProductsById) {
        state.transferProductsById = {}
      }

      const payloadId = UUID.fromPB(action.payload.productId).toString()

      state.transferProductsById[payloadId] = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    removeTransferProduct: (state, action: PayloadAction<string>) => {
      if (state.transferProductsById?.[action.payload]) {
        delete state.transferProductsById[action.payload]
        state.transferProductsById = fixTxnProductsOrders(
          state.transferProductsById
        )
      }

      if (state.productsById?.[action.payload]) {
        delete state.productsById[action.payload]
      }

      if (state.catalogProductsById?.[action.payload]) {
        delete state.catalogProductsById[action.payload]
      }

      if (state.inventoriesByProductId?.[action.payload]) {
        delete state.inventoriesByProductId[action.payload]
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    searchProductSelected: (
      state,
      action: PayloadAction<{
        result: TransactionProduct
      }>
    ) => {
      const { result } = action.payload
      if (!result || !result?.product?.id) {
        return
      }
      state.searchProduct = result

      if (result.isScan && result.quantity > 0) {
        state.addProductQuantity = result.quantity
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    searchProductSubmitted: (
      state,
      action: PayloadAction<{
        status?: TransferStatus
      }>
    ) => {
      const { status } = action.payload

      if (!state.addProductQuantity || !state.searchProduct?.product?.id) {
        return
      }

      if (!state.transferProductsById) {
        state.transferProductsById = {}
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      const payloadId = UUID.fromPB(state.searchProduct.product.id).toString()

      const existingTransferProduct = state.transferProductsById?.[payloadId]

      if (existingTransferProduct) {
        state.transferProductsById[payloadId] = {
          ...existingTransferProduct,
          quantitySent:
            status === TransferStatus.SENT
              ? existingTransferProduct.quantitySent
              : existingTransferProduct.quantitySent + state.addProductQuantity,
          quantityReceived:
            status === TransferStatus.SENT
              ? existingTransferProduct.quantityReceived +
                state.addProductQuantity
              : 0,
          position: Object.values(state.transferProductsById).length + 1,
        }
      } else {
        state.transferProductsById[payloadId] = {
          productId: state.searchProduct.product.id,
          quantitySent:
            status === TransferStatus.SENT ? 0 : state.addProductQuantity,
          quantityReceived:
            status === TransferStatus.SENT ? state.addProductQuantity : 0,
          position: Object.values(state.transferProductsById).length + 1,
        }
      }

      state.transferProductsById = fixTxnProductsOrders(
        state.transferProductsById
      )

      state.productsById = {
        ...state.productsById,
        [UUID.fromPB(state.searchProduct.product.id).toString()]:
          state.searchProduct.product,
      }

      if (state.searchProduct.matchedCostProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedCostProduct.id).toString()]:
            state.searchProduct.matchedCostProduct,
        }
      }

      if (state.searchProduct.matchedPriceProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedPriceProduct.id).toString()]:
            state.searchProduct.matchedPriceProduct,
        }
      }

      if (state.searchProduct.catalogProduct) {
        state.catalogProductsById = {
          ...state.catalogProductsById,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.catalogProduct,
        }
      }

      if (state.searchProduct.inventories) {
        state.inventoriesByProductId = {
          ...state.inventoriesByProductId,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.inventories,
        }
      }

      state.scrollToBottom = true
      state.addProductQuantity = 1
      delete state.searchProduct
      state.isEdited = checkTransferIsEdited(state)
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload
    },
    resetSearchProductSelected: (state) => {
      delete state.searchProduct
      state.isEdited = checkTransferIsEdited(state)
    },
    setAddProductQuantity: (state, action: PayloadAction<number>) => {
      state.addProductQuantity = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
  },
})

export const {
  removeTransferProduct,
  resetTransferState,
  setNewTransferProduct,
  setTransferFromLocation,
  setTransferInitialState,
  setTransferProducts,
  setTransferReceiveNotes,
  setTransferSendNotes,
  setTransferToLocation,
  searchProductSelected,
  setTransferProductQuantity,
  setScrollToBottom,
  searchProductSubmitted,
  resetSearchProductSelected,
  setAddProductQuantity,
} = transfersSlice.actions

export const selectTransferFromLocation = (state: AppState) =>
  state.transfers.transferFromLocation
export const selectTransferInitialState = (state: AppState) =>
  state.transfers.initialState
export const selectTransferInventoriesByProductId = (state: AppState) =>
  state.transfers.inventoriesByProductId
export const selectTransferIsEdited = (state: AppState) =>
  state.transfers.isEdited
export const selectTransferProducts = (state: AppState) =>
  state.transfers.transferProductsById
export const selectTransferProductsById = (state: AppState) =>
  state.transfers.productsById
export const selectTransferCatalogProductsById = (state: AppState) =>
  state.transfers.catalogProductsById
export const selectTransferReceiveNotes = (state: AppState) =>
  state.transfers.receiveNotes
export const selectTransferSendNotes = (state: AppState) =>
  state.transfers.sendNotes
export const selectTransferToLocation = (state: AppState) =>
  state.transfers.transferToLocation
export const selectScrollToBottom = (state: AppState) =>
  state.transfers.scrollToBottom
export const selectSearchProduct = (state: AppState) =>
  state.transfers.searchProduct
export const selectAddProductQuantity = (state: AppState) =>
  state.transfers.addProductQuantity
