import { Combobox, ComboboxProps } from "components/Combobox"

import { BoolFilter } from "gen/search/service/service_pb"
import { Customer } from "gen/customer/models_pb"
import { UUID } from "uuid-rd"
import { useMemo } from "react"
import { useSearchCustomersV2 } from "features/api/search"

type CustomerComboboxProps = Omit<
  ComboboxProps<Customer>,
  "items" | "keyExtractor" | "valueExtractor" | "onChangeQuery"
>

export function CustomerCombobox(props: CustomerComboboxProps) {
  const { setQuery: setCustomerQuery, results: customerResults } =
    useSearchCustomersV2({
      isActiveFilter: BoolFilter.TRUE,
    })

  const customersForFilter = useMemo(() => {
    const customers: Customer[] = []
    for (const customerResult of customerResults) {
      if (customerResult.customer) {
        customers.push(customerResult.customer)
      }
    }
    return customers
  }, [customerResults])

  return (
    <Combobox<Customer>
      items={customersForFilter}
      onChangeQuery={setCustomerQuery}
      keyExtractor={(customer) =>
        customer?.id ? UUID.fromPB(customer.id).toString() : ""
      }
      valueExtractor={(customer) => customer?.name ?? ""}
      {...props}
    />
  )
}
