import { selectLocationWithoutDefault, setLocation } from "./authSlice"
import { useAppDispatch, useAppSelector } from "app/hooks"

import { Combobox } from "components/Combobox"
import { Location } from "gen/company/models_pb"
import { UUID } from "uuid-rd"
import { useGetCompanyForCurrentSubdomain } from "./useGetCompanyForCurrentSubdomain"

export function LocationsCombobox() {
  const { data: company } = useGetCompanyForCurrentSubdomain()
  const locations = company?.locations.filter((loc) => loc.isActive) ?? []

  const selectedLocation = useAppSelector(selectLocationWithoutDefault)

  const dispatch = useAppDispatch()

  function onSelectItem(item: Location | null) {
    dispatch(setLocation(item))
  }

  function keyExtractor(item: Location): string {
    if (!item.id) {
      return ""
    }
    return UUID.fromPB(item.id).toString()
  }

  function valueExtractor(item: Location): string {
    return item.name
  }

  return (
    <Combobox<Location>
      label="Location"
      items={locations}
      selectedItem={selectedLocation}
      onSelectItem={onSelectItem}
      keyExtractor={keyExtractor}
      valueExtractor={valueExtractor}
      className="w-full"
      isOpenOnFocus
    />
  )
}
