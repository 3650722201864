import { useEffect, useRef } from "react"

import { SCAN_PREFIX_SUFFIX } from "lib/constants"

const keysToIgnore = [
  "Shift",
  "Alt",
  "Control",
  "Backspace",
  "ArrowRight",
  "ArrowLeft",
  "ArrowUp",
  "ArrowDown",
]

// useBarcodeScanner is a hook that captures barcode scans and calls the
// provided callback with the text representation of the barcode.
//
// With most barcode scanners, their input is indistinguishable from keyboard input.
// As such, we detect barcode scans by listening for keydown events. We look for keyboard
// entry prefixed & suffixed with the pipe character ("|").
export function useBarcodeScanner({
  onScan,
  preventScanIntoInputs,
}: {
  onScan: (barcodeText: string) => void
  preventScanIntoInputs?: boolean
}) {
  const buffer = useRef<Array<string>>([])

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (keysToIgnore.includes(event.key)) {
        return
      }

      const isPrefix =
        event.key === SCAN_PREFIX_SUFFIX && buffer.current.length === 0
      const isMiddle = buffer.current.length > 0
      const isSuffix =
        event.key === SCAN_PREFIX_SUFFIX && buffer.current.length > 0

      // always prevent the prefix & suffix from populating inputs;
      // conditionally prevent the barcode from populating inputs
      if (isPrefix || isSuffix || (isMiddle && preventScanIntoInputs)) {
        event.preventDefault()
      }

      if (isSuffix) {
        const code = buffer.current
          .filter((x) => x !== SCAN_PREFIX_SUFFIX)
          .join("")
        onScan(code)
        buffer.current = []
        return
      }

      if (isPrefix || isMiddle) {
        buffer.current.push(event.key)
      }
    }

    document.addEventListener("keydown", onKeyDown)

    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [onScan])
}
