/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "tintcolor/models.proto" (package "rd.tintcolor", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * TintColor represents a tint that can be placed on a
 * tintable product, like a paint base. These are shared
 * between Companies.
 *
 * @generated from protobuf message rd.tintcolor.TintColor
 */
export interface TintColor {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string code = 5;
     */
    code: string;
    /**
     * @generated from protobuf field: string company = 6;
     */
    company: string;
    /**
     * @generated from protobuf field: string color_hex = 7;
     */
    colorHex: string;
    /**
     * @generated from protobuf field: bool is_active = 8;
     */
    isActive: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class TintColor$Type extends MessageType<TintColor> {
    constructor() {
        super("rd.tintcolor.TintColor", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "company", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "color_hex", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TintColor>): TintColor {
        const message = { name: "", code: "", company: "", colorHex: "", isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TintColor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TintColor): TintColor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string code */ 5:
                    message.code = reader.string();
                    break;
                case /* string company */ 6:
                    message.company = reader.string();
                    break;
                case /* string color_hex */ 7:
                    message.colorHex = reader.string();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TintColor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string code = 5; */
        if (message.code !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.code);
        /* string company = 6; */
        if (message.company !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.company);
        /* string color_hex = 7; */
        if (message.colorHex !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.colorHex);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.tintcolor.TintColor
 */
export const TintColor = new TintColor$Type();
