import {
  ChangeEventHandler,
  ClipboardEventHandler,
  KeyboardEventHandler,
  LegacyRef,
  useCallback,
  useRef,
  useState,
} from "react"

import { Button } from "components/Button"
import { ErrorMessage } from "./ErrorMessage"
import { SignInViaKeyButton } from "./SignInViaKeyButton"
import { SingleCharacterInput } from "components/SingleCharacterInput"
import useKeyboardShortcut from "hooks/useKeyboardShortcut"
import { useSignInContext } from "./SignInContext"

interface StepTextMessageCodeProps {
  staffExternalID: string
  phoneLast4: string
  onSubmit: (code: string) => void
  onClickSendAnotherCode: () => void
  isSendingAnotherCode: boolean
  errorMessage: string | null
  isSubmitting: boolean
}

export function StepTextMessageCode(props: StepTextMessageCodeProps) {
  const {
    staffExternalID,
    phoneLast4,
    onSubmit,
    onClickSendAnotherCode,
    isSendingAnotherCode,
    errorMessage,
    isSubmitting,
  } = props

  const [codeValues, setCodeValues] = useState(["", "", "", "", "", ""])

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debouncedSubmit = useCallback(
    (code: string) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        onSubmit(code)
      }, 500)
    },
    [onSubmit]
  )

  const onChangeCodeValues = useCallback(
    (codeValues: string[]) => {
      setCodeValues(codeValues)
      const code = codeValues.join("")
      if (code.length === codeValues.length) {
        debouncedSubmit(code)
      }
    },
    [debouncedSubmit]
  )

  const handleSubmit = useCallback(() => {
    const code = codeValues.join("")
    if (code.length !== codeValues.length) {
      return
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    onSubmit(code)
  }, [onSubmit, codeValues])

  const { goBack } = useSignInContext()

  useKeyboardShortcut("enter", handleSubmit)

  return (
    <>
      <SingleCharacterInput
        className="self-center"
        value={staffExternalID}
        isLoading={isSubmitting}
        disabled
      />
      <span className="text-secondary text-center">
        A 6-digit code was sent to the phone
        <br />
        number ending in {phoneLast4}
      </span>
      <TextMessageCodeInputs
        codeValues={codeValues}
        onChangeCodeValues={onChangeCodeValues}
        errorMessage={errorMessage}
      />
      <div className="flex flex-col gap-y-2">
        <Button
          mode="primary"
          onClick={onClickSendAnotherCode}
          loading={isSendingAnotherCode}
        >
          Send another code
        </Button>
        <Button mode="secondary" onClick={goBack}>
          Go back
        </Button>
      </div>
      <SignInViaKeyButton />
    </>
  )
}

interface TextMessageCodeInputsProps {
  codeValues: string[]
  onChangeCodeValues: (codeValues: string[]) => void
  errorMessage: string | null
}

function TextMessageCodeInputs(props: TextMessageCodeInputsProps) {
  const { codeValues, onChangeCodeValues, errorMessage } = props

  const codeInputRefs = useRef<(HTMLInputElement | null)[]>([])

  function onKeyDown(idx: number): KeyboardEventHandler<HTMLInputElement> {
    return function (e) {
      if (e.key === "Backspace" && !codeValues[idx] && idx > 0) {
        codeInputRefs.current[idx - 1]?.focus()
      }
    }
  }

  function onChange(idx: number): ChangeEventHandler<HTMLInputElement> {
    return function (e) {
      if (e.target.value && !Number.isInteger(Number(e.target.value))) return

      const newValues = [...codeValues]
      newValues[idx] = e.target.value
      onChangeCodeValues(newValues)

      if (e.target.value && idx < codeValues.length - 1) {
        codeInputRefs.current[idx + 1]?.focus()
      }

      if (e.target.value && idx === codeValues.length - 1) {
        e.target.blur()
      }
    }
  }

  function onPaste(idx: number): ClipboardEventHandler<HTMLInputElement> {
    return function (e) {
      if (idx !== 0) {
        return
      }
      e.preventDefault()
      const pasted = e.clipboardData.getData("text").trim()
      if (pasted.length !== codeValues.length) {
        return
      }
      onChangeCodeValues(pasted.split(""))
      e.currentTarget.blur()
    }
  }

  function ref(idx: number): LegacyRef<HTMLInputElement> {
    return function (el) {
      if (!el) return
      codeInputRefs.current[idx] = el
    }
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex justify-between">
        {codeValues.map((value, idx) => (
          <SingleCharacterInput
            key={`code-value-${idx}`}
            data-testid="code-input"
            size="sm"
            maxLength={1}
            value={value}
            onKeyDown={onKeyDown(idx)}
            onChange={onChange(idx)}
            onPaste={onPaste(idx)}
            ref={ref(idx)}
            autoFocus={idx === 0}
          />
        ))}
      </div>
      <ErrorMessage message={errorMessage} />
    </div>
  )
}
