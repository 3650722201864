import { ApiError, isApiError } from "features/api/apiSlice"
import {
  selectLastUsedSignInMethod,
  selectLastUsedStaffExternalID,
  setLoggedInStaffCode,
} from "./authSlice"
import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  useAuthenticateWebAuthnMultiUserStartMutation,
  useAuthenticateWebAuthnMutation,
  useAuthenticateWebAuthnStartMutation,
} from "features/api/auth"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Flag } from "gen/flags/models_pb"
import { PubkeyHistory } from "./PubkeyHistory"
import Router from "next/router"
import { SerializedError } from "@reduxjs/toolkit"
import { stytchErrorMessage } from "./stytchErrorMessage"
import { useGetCompanyForCurrentSubdomain } from "./useGetCompanyForCurrentSubdomain"
import { useLazyGetFlagsQuery } from "features/api/flags"
import { useSignInContext } from "./SignInContext"
import { useWebauthn } from "./useWebauthn"

export function useKeySignIn() {
  const dispatch = useAppDispatch()

  const { step, setStep, redirectPath } = useSignInContext()

  const { data: company } = useGetCompanyForCurrentSubdomain()
  const companyId = company?.id

  const [getFlags, { error: getFlagsError }] = useLazyGetFlagsQuery()

  const [authenticateWebAuthnStart, { error: authenticateWebAuthnStartError }] =
    useAuthenticateWebAuthnStartMutation()
  const webauthn = useWebauthn()
  const [
    authenticateWebAuthnMultiUserStart,
    {
      error: authenticateWebAuthnMultiUserStartError,
      reset: resetAuthenticateWebAuthnMultiUserStart,
    },
  ] = useAuthenticateWebAuthnMultiUserStartMutation()
  const [authenticateWebAuthn, { error: authenticateWebAuthnError }] =
    useAuthenticateWebAuthnMutation()

  const onSuccess = useCallback(
    (key: string, staffExternalID: string) => {
      PubkeyHistory.addKey(key)
      dispatch(setLoggedInStaffCode(staffExternalID))
      if (redirectPath) {
        Router.push(redirectPath)
      }
    },
    [dispatch, redirectPath]
  )

  const [isSigningInWithKey, setIsSigningInWithKey] = useState(false)
  const signInWithKey = useCallback(async () => {
    if (!companyId) return

    try {
      setIsSigningInWithKey(true)

      const { flags } = await getFlags({}).unwrap()
      const AUTH_KEY_MAX = flags[Flag.AUTH_KEY_MAX] ?? { intVal: 0n }
      console.debug("AUTH_KEY_MAX", AUTH_KEY_MAX)

      const { publicKeyCredentialRequestOptions, pubkeyIds: serverPubkeyIds } =
        await authenticateWebAuthnMultiUserStart({
          companyId,
        }).unwrap()
      const credopts = JSON.parse(publicKeyCredentialRequestOptions)
      console.debug("server pubkey ids:", serverPubkeyIds)
      console.debug("pubkey history:", PubkeyHistory.listKeys())
      const pubkeyIds = PubkeyHistory.sortByRecent(
        serverPubkeyIds,
        Number(AUTH_KEY_MAX.intVal)
      )
      console.debug("amended pubkey ids:", pubkeyIds)
      if (pubkeyIds.length > 0) {
        credopts.allowCredentials = pubkeyIds.map((v) => ({
          id: v,
          type: "public-key",
        }))
      }
      console.debug("credopts:", credopts)
      const publicKeyCredentialWithAssertion =
        await webauthn.getPubKeyCredWithAssertion({
          publicKey: credopts,
        })

      const authRes = await authenticateWebAuthn({
        companyId,
        publicKeyCredential: JSON.stringify(publicKeyCredentialWithAssertion),
      }).unwrap()

      const successfulId = publicKeyCredentialWithAssertion.id
      console.debug("assertion:", publicKeyCredentialWithAssertion)
      console.debug("successfulId:", typeof successfulId, successfulId)

      onSuccess(successfulId, authRes?.staff?.code ?? "")
    } catch (error) {
      setStep("key-id")
    } finally {
      setIsSigningInWithKey(false)
    }
  }, [
    authenticateWebAuthn,
    authenticateWebAuthnMultiUserStart,
    companyId,
    getFlags,
    onSuccess,
    setStep,
    webauthn,
  ])

  const [isSigningInWithKeyID, setIsSigningInWithKeyID] = useState(false)
  const signInWithKeyID = useCallback(
    async (staffExternalID: string) => {
      if (!staffExternalID) return

      try {
        setIsSigningInWithKeyID(true)

        resetAuthenticateWebAuthnMultiUserStart()
        webauthn.clearError()

        const { publicKeyCredentialRequestOptions } =
          await authenticateWebAuthnStart({
            companyId,
            staffCode: staffExternalID,
          }).unwrap()

        const publicKeyCredentialWithAssertion =
          await webauthn.getPubKeyCredWithAssertion({
            publicKey: JSON.parse(publicKeyCredentialRequestOptions),
          })

        await authenticateWebAuthn({
          companyId,
          publicKeyCredential: JSON.stringify(publicKeyCredentialWithAssertion),
        }).unwrap()

        const successfulId = publicKeyCredentialWithAssertion.id
        console.debug("assertion:", publicKeyCredentialWithAssertion)
        console.debug("successfulId:", typeof successfulId, successfulId)

        onSuccess(successfulId, staffExternalID)
      } catch {
      } finally {
        setIsSigningInWithKeyID(false)
      }
    },
    [
      authenticateWebAuthn,
      authenticateWebAuthnStart,
      companyId,
      onSuccess,
      resetAuthenticateWebAuthnMultiUserStart,
      webauthn,
    ]
  )

  const errorMessage = useMemo(
    () =>
      errorToMessage(
        authenticateWebAuthnError ??
          webauthn.error ??
          authenticateWebAuthnStartError ??
          authenticateWebAuthnMultiUserStartError ??
          getFlagsError
      ),
    [
      authenticateWebAuthnError,
      webauthn.error,
      authenticateWebAuthnMultiUserStartError,
      authenticateWebAuthnStartError,
      getFlagsError,
    ]
  )

  const lastUsedSignInMethod = useAppSelector(selectLastUsedSignInMethod)
  const lastUsedStaffExternalID = useAppSelector(selectLastUsedStaffExternalID)
  const hasAttemptedAutoSignIn = useRef(false)
  useEffect(() => {
    if (!companyId) return
    if (hasAttemptedAutoSignIn.current) return
    hasAttemptedAutoSignIn.current = true
    if (lastUsedSignInMethod === "key" && step === "key") {
      signInWithKey()
    } else if (
      lastUsedSignInMethod === "key" &&
      step === "session-timeout" &&
      lastUsedStaffExternalID
    ) {
      signInWithKeyID(lastUsedStaffExternalID)
    }
  }, [
    companyId,
    lastUsedSignInMethod,
    lastUsedStaffExternalID,
    step,
    signInWithKey,
    signInWithKeyID,
  ])

  return {
    signInWithKey,
    isSigningInWithKey,

    signInWithKeyID,
    isSigningInWithKeyID,

    errorMessage,

    // companyId is included for use in unit tests
    companyId,
  }
}

export function errorToMessage(
  err?: ApiError | SerializedError | Error
): string | null {
  if (!err) return null
  if (isApiError(err) && err.meta && "stytch_error_type" in err.meta) {
    return stytchErrorMessage(err.meta.stytch_error_type as string)
  }
  if (isApiError(err) && err.code === "not_found") {
    return "ID not found. Please try again."
  }
  return "Something went wrong. Please try again."
}
