// @ts-strict-ignore
import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit"
import { authSlice, clearState } from "features/auth/authSlice"
import { cloneDeep, isString } from "lodash"
import { createTransform, persistReducer, persistStore } from "redux-persist"

import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore"
import { UUID } from "uuid-rd"
import { apiSlice } from "features/api/apiSlice"
import { billsSlice } from "features/pos/bills/billsSlice"
import { cardReadersSlice } from "features/pos/cardReadersSlice"
import { countSlice } from "features/pos/counts/countSlice"
import { orderShipmentsSlice } from "features/pos/purchase-orders/orderShipmentsSlice"
import { ordersSearchSlice } from "features/pos/purchase-orders/ordersSearchSlice"
import { ordersSlice } from "features/pos/purchase-orders/ordersSlice"
import { printersSlice } from "features/pos/printersSlice"
import { returnsSlice } from "features/pos/sales/returnsSlice"
import { salesSearchSlice } from "features/pos/sales/salesSearchSlice"
import { salesSlice } from "features/pos/sales/salesSlice"
import { searchSlice } from "features/api/searchSlice"
import { setupListeners } from "@reduxjs/toolkit/query"
import storage from "redux-persist/lib/storage"
import { themeSlice } from "features/theme/themeSlice"
import { transfersSearchSlice } from "features/pos/transfers/transfersSearchSlice"
import { transfersSlice } from "features/pos/transfers/transfersSlice"

// transformInbound recursively searches the object to be persisted
// and provides workarounds for being able to serialize types that
// aren't natively serializable (e.g. bigint, Uint8Array). This
// currently assumes that the only Uint8Array fields in our state
// are UUIDs.
export function transformInbound(obj: unknown) {
  if (typeof obj !== "object") {
    return obj
  }

  for (const key in obj) {
    switch (typeof obj[key]) {
      case "bigint":
        obj[key] = `rundoo_bigint:${(obj[key] as bigint).toString()}`
        break
      case "object":
        if (obj[key] instanceof Uint8Array) {
          obj[key] = `rundoo_uuid:${UUID.fromBytes(obj[key]).toString()}`
          break
        }

        transformInbound(obj[key])
        break
      default:
    }
  }

  return obj
}

// transformOutbound recursively searches the persisted object
// and parses the non-natively-serializable types based on the
// workarounds implemented in `transformInbound` above.
export function transformOutbound(obj: unknown) {
  if (typeof obj !== "object") {
    return obj
  }

  for (const key in obj) {
    if (typeof obj[key] === "object") {
      transformOutbound(obj[key])
      continue
    }

    if (
      isString(obj[key]) &&
      (obj[key] as string).startsWith("rundoo_bigint:")
    ) {
      obj[key] = BigInt((obj[key] as string).split("rundoo_bigint:")[1])
      continue
    }

    if (isString(obj[key]) && (obj[key] as string).startsWith("rundoo_uuid:")) {
      obj[key] = UUID.fromString(
        (obj[key] as string).split("rundoo_uuid:")[1]
      ).toBytes()
      continue
    }
  }

  return obj
}

const Transform = createTransform(
  (inbound) => {
    const clone = cloneDeep(inbound)
    transformInbound(clone)
    return clone
  },
  (outbound) => {
    const clone = cloneDeep(outbound)
    transformOutbound(clone)
    return clone
  }
)

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    apiSlice.reducerPath,
    ordersSlice.name,
    orderShipmentsSlice.name,
    transfersSlice.name,
    salesSlice.name,
    returnsSlice.name,
    billsSlice.name,
    countSlice.name,
    transfersSearchSlice.name,
    ordersSearchSlice.name,
    salesSearchSlice.name,
  ],
  transforms: [Transform],
}

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [themeSlice.name]: themeSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [billsSlice.name]: billsSlice.reducer,
  [countSlice.name]: countSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [orderShipmentsSlice.name]: orderShipmentsSlice.reducer,
  [transfersSlice.name]: transfersSlice.reducer,
  [salesSlice.name]: salesSlice.reducer,
  [returnsSlice.name]: returnsSlice.reducer,
  [cardReadersSlice.name]: cardReadersSlice.reducer,
  [printersSlice.name]: printersSlice.reducer,
  [transfersSearchSlice.name]: transfersSearchSlice.reducer,
  [ordersSearchSlice.name]: ordersSearchSlice.reducer,
  [salesSearchSlice.name]: salesSearchSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
})

const rootReducer = (state, action) => {
  if (clearState.match(action)) {
    state = {
      auth: state.auth,
      theme: state.theme,
      cardReaders: state.cardReaders,
      printers: state.printers,
    }
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: {
    serialize: {
      replacer: (key: string, value: unknown) => {
        return typeof value === "bigint"
          ? { data: value.toString(), __serializedType__: "BigInt" }
          : value
      },
      reviver: (
        key: string,
        value: { __serializedType__?: string; data: string | unknown }
      ) => {
        if (
          typeof value === "object" &&
          value !== null &&
          "__serializedType__" in value
        ) {
          const data = value.data
          switch (value.__serializedType__) {
            case "BigInt":
              return BigInt(data as string)
            default:
              return data
          }
        }
      },
    },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})
declare global {
  interface Window {
    store: ToolkitStore
  }
}
if (typeof window !== "undefined") {
  window.store = store
}

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export default store
