/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/auth/service.proto" (package "rd.api.auth", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AuthService } from "./service_pb";
import type { AuthenticateNoltRes } from "./service_pb";
import type { AuthenticateNoltReq } from "./service_pb";
import type { RevokeSessionRes } from "./service_pb";
import type { RevokeSessionReq } from "./service_pb";
import type { ExtendSessionRes } from "./service_pb";
import type { ExtendSessionReq } from "./service_pb";
import type { AuthenticateOneTimePasswordRes } from "./service_pb";
import type { AuthenticateOneTimePasswordReq } from "./service_pb";
import type { SendOneTimePasswordSMSRes } from "./service_pb";
import type { SendOneTimePasswordSMSReq } from "./service_pb";
import type { AuthenticateWebAuthnRes } from "./service_pb";
import type { AuthenticateWebAuthnReq } from "./service_pb";
import type { AuthenticateWebAuthnMultiUserStartRes } from "./service_pb";
import type { AuthenticateWebAuthnMultiUserStartReq } from "./service_pb";
import type { AuthenticateWebAuthnStartRes } from "./service_pb";
import type { AuthenticateWebAuthnStartReq } from "./service_pb";
import type { RegisterWebAuthnRes } from "./service_pb";
import type { RegisterWebAuthnReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { RegisterWebAuthnStartRes } from "./service_pb";
import type { RegisterWebAuthnStartReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.api.auth.AuthService
 */
export interface IAuthServiceClient {
    /**
     * Registration
     *
     * @generated from protobuf rpc: RegisterWebAuthnStart(rd.api.auth.RegisterWebAuthnStartReq) returns (rd.api.auth.RegisterWebAuthnStartRes);
     */
    registerWebAuthnStart(input: RegisterWebAuthnStartReq, options?: RpcOptions): UnaryCall<RegisterWebAuthnStartReq, RegisterWebAuthnStartRes>;
    /**
     * @generated from protobuf rpc: RegisterWebAuthn(rd.api.auth.RegisterWebAuthnReq) returns (rd.api.auth.RegisterWebAuthnRes);
     */
    registerWebAuthn(input: RegisterWebAuthnReq, options?: RpcOptions): UnaryCall<RegisterWebAuthnReq, RegisterWebAuthnRes>;
    /**
     * Authentication
     *
     * @generated from protobuf rpc: AuthenticateWebAuthnStart(rd.api.auth.AuthenticateWebAuthnStartReq) returns (rd.api.auth.AuthenticateWebAuthnStartRes);
     */
    authenticateWebAuthnStart(input: AuthenticateWebAuthnStartReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnStartReq, AuthenticateWebAuthnStartRes>;
    /**
     * @generated from protobuf rpc: AuthenticateWebAuthnMultiUserStart(rd.api.auth.AuthenticateWebAuthnMultiUserStartReq) returns (rd.api.auth.AuthenticateWebAuthnMultiUserStartRes);
     */
    authenticateWebAuthnMultiUserStart(input: AuthenticateWebAuthnMultiUserStartReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnMultiUserStartReq, AuthenticateWebAuthnMultiUserStartRes>;
    /**
     * @generated from protobuf rpc: AuthenticateWebAuthn(rd.api.auth.AuthenticateWebAuthnReq) returns (rd.api.auth.AuthenticateWebAuthnRes);
     */
    authenticateWebAuthn(input: AuthenticateWebAuthnReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnReq, AuthenticateWebAuthnRes>;
    /**
     * @generated from protobuf rpc: SendOneTimePasswordSMS(rd.api.auth.SendOneTimePasswordSMSReq) returns (rd.api.auth.SendOneTimePasswordSMSRes);
     */
    sendOneTimePasswordSMS(input: SendOneTimePasswordSMSReq, options?: RpcOptions): UnaryCall<SendOneTimePasswordSMSReq, SendOneTimePasswordSMSRes>;
    /**
     * @generated from protobuf rpc: AuthenticateOneTimePassword(rd.api.auth.AuthenticateOneTimePasswordReq) returns (rd.api.auth.AuthenticateOneTimePasswordRes);
     */
    authenticateOneTimePassword(input: AuthenticateOneTimePasswordReq, options?: RpcOptions): UnaryCall<AuthenticateOneTimePasswordReq, AuthenticateOneTimePasswordRes>;
    /**
     * @generated from protobuf rpc: ExtendSession(rd.api.auth.ExtendSessionReq) returns (rd.api.auth.ExtendSessionRes);
     */
    extendSession(input: ExtendSessionReq, options?: RpcOptions): UnaryCall<ExtendSessionReq, ExtendSessionRes>;
    /**
     * @generated from protobuf rpc: RevokeSession(rd.api.auth.RevokeSessionReq) returns (rd.api.auth.RevokeSessionRes);
     */
    revokeSession(input: RevokeSessionReq, options?: RpcOptions): UnaryCall<RevokeSessionReq, RevokeSessionRes>;
    /**
     * @generated from protobuf rpc: AuthenticateNolt(rd.api.auth.AuthenticateNoltReq) returns (rd.api.auth.AuthenticateNoltRes);
     */
    authenticateNolt(input: AuthenticateNoltReq, options?: RpcOptions): UnaryCall<AuthenticateNoltReq, AuthenticateNoltRes>;
}
/**
 * @generated from protobuf service rd.api.auth.AuthService
 */
export class AuthServiceClient implements IAuthServiceClient, ServiceInfo {
    typeName = AuthService.typeName;
    methods = AuthService.methods;
    options = AuthService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Registration
     *
     * @generated from protobuf rpc: RegisterWebAuthnStart(rd.api.auth.RegisterWebAuthnStartReq) returns (rd.api.auth.RegisterWebAuthnStartRes);
     */
    registerWebAuthnStart(input: RegisterWebAuthnStartReq, options?: RpcOptions): UnaryCall<RegisterWebAuthnStartReq, RegisterWebAuthnStartRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<RegisterWebAuthnStartReq, RegisterWebAuthnStartRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RegisterWebAuthn(rd.api.auth.RegisterWebAuthnReq) returns (rd.api.auth.RegisterWebAuthnRes);
     */
    registerWebAuthn(input: RegisterWebAuthnReq, options?: RpcOptions): UnaryCall<RegisterWebAuthnReq, RegisterWebAuthnRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<RegisterWebAuthnReq, RegisterWebAuthnRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Authentication
     *
     * @generated from protobuf rpc: AuthenticateWebAuthnStart(rd.api.auth.AuthenticateWebAuthnStartReq) returns (rd.api.auth.AuthenticateWebAuthnStartRes);
     */
    authenticateWebAuthnStart(input: AuthenticateWebAuthnStartReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnStartReq, AuthenticateWebAuthnStartRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateWebAuthnStartReq, AuthenticateWebAuthnStartRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthenticateWebAuthnMultiUserStart(rd.api.auth.AuthenticateWebAuthnMultiUserStartReq) returns (rd.api.auth.AuthenticateWebAuthnMultiUserStartRes);
     */
    authenticateWebAuthnMultiUserStart(input: AuthenticateWebAuthnMultiUserStartReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnMultiUserStartReq, AuthenticateWebAuthnMultiUserStartRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateWebAuthnMultiUserStartReq, AuthenticateWebAuthnMultiUserStartRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthenticateWebAuthn(rd.api.auth.AuthenticateWebAuthnReq) returns (rd.api.auth.AuthenticateWebAuthnRes);
     */
    authenticateWebAuthn(input: AuthenticateWebAuthnReq, options?: RpcOptions): UnaryCall<AuthenticateWebAuthnReq, AuthenticateWebAuthnRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateWebAuthnReq, AuthenticateWebAuthnRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendOneTimePasswordSMS(rd.api.auth.SendOneTimePasswordSMSReq) returns (rd.api.auth.SendOneTimePasswordSMSRes);
     */
    sendOneTimePasswordSMS(input: SendOneTimePasswordSMSReq, options?: RpcOptions): UnaryCall<SendOneTimePasswordSMSReq, SendOneTimePasswordSMSRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendOneTimePasswordSMSReq, SendOneTimePasswordSMSRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthenticateOneTimePassword(rd.api.auth.AuthenticateOneTimePasswordReq) returns (rd.api.auth.AuthenticateOneTimePasswordRes);
     */
    authenticateOneTimePassword(input: AuthenticateOneTimePasswordReq, options?: RpcOptions): UnaryCall<AuthenticateOneTimePasswordReq, AuthenticateOneTimePasswordRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateOneTimePasswordReq, AuthenticateOneTimePasswordRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExtendSession(rd.api.auth.ExtendSessionReq) returns (rd.api.auth.ExtendSessionRes);
     */
    extendSession(input: ExtendSessionReq, options?: RpcOptions): UnaryCall<ExtendSessionReq, ExtendSessionRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExtendSessionReq, ExtendSessionRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RevokeSession(rd.api.auth.RevokeSessionReq) returns (rd.api.auth.RevokeSessionRes);
     */
    revokeSession(input: RevokeSessionReq, options?: RpcOptions): UnaryCall<RevokeSessionReq, RevokeSessionRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<RevokeSessionReq, RevokeSessionRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthenticateNolt(rd.api.auth.AuthenticateNoltReq) returns (rd.api.auth.AuthenticateNoltRes);
     */
    authenticateNolt(input: AuthenticateNoltReq, options?: RpcOptions): UnaryCall<AuthenticateNoltReq, AuthenticateNoltRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateNoltReq, AuthenticateNoltRes>("unary", this._transport, method, opt, input);
    }
}
