import { ApiError, isApiError } from "features/api/apiSlice"
import {
  useAuthenticateOneTimePasswordMutation,
  useSendOneTimePasswordSMSMutation,
} from "features/api/auth"
import { useCallback, useMemo, useState } from "react"

import Router from "next/router"
import { SerializedError } from "@reduxjs/toolkit"
import { setLoggedInStaffCode } from "./authSlice"
import { stytchErrorMessage } from "./stytchErrorMessage"
import { useAppDispatch } from "app/hooks"
import { useGetCompanyForCurrentSubdomain } from "./useGetCompanyForCurrentSubdomain"
import { useSignInContext } from "./SignInContext"

export function useTextMessageSignIn() {
  const dispatch = useAppDispatch()

  const { data: company } = useGetCompanyForCurrentSubdomain()
  const companyId = company?.id

  const { redirectPath, setStep } = useSignInContext()

  const [
    sendOTP,
    {
      isLoading: isSendingTextMessage,
      data: sendOneTimePasswordSMSData,
      originalArgs: sendOneTimePasswordSMSOriginalArgs,
      error: sendOneTimePasswordSMSError,
    },
  ] = useSendOneTimePasswordSMSMutation()
  const [
    authenticateOneTimePassword,
    {
      isLoading: isVerifyingTextMessage,
      error: authenticateOneTimePasswordError,
      reset: resetAuthenticateOneTimePassword,
    },
  ] = useAuthenticateOneTimePasswordMutation()

  const sendTextMessage = useCallback(
    async (staffCode: string) => {
      try {
        resetAuthenticateOneTimePassword()
        await sendOTP({ companyId, staffCode }).unwrap()
        setStep("text-message-code")
      } catch {}
    },
    [resetAuthenticateOneTimePassword, companyId, sendOTP, setStep]
  )

  const sendTextMessageError = useMemo(
    () => sendOneTimePasswordSMSErrorToMessage(sendOneTimePasswordSMSError),
    [sendOneTimePasswordSMSError]
  )

  const staffExternalID = sendOneTimePasswordSMSOriginalArgs?.staffCode ?? ""
  const phoneId = sendOneTimePasswordSMSData?.phoneId ?? ""
  const phoneLast4 = sendOneTimePasswordSMSData?.phoneLast4 ?? ""

  const [prevPhoneLast4, setPrevPhoneLast4] = useState(phoneLast4)
  if (phoneLast4 && prevPhoneLast4 !== phoneLast4) {
    setPrevPhoneLast4(phoneLast4)
  }

  const verifyTextMessage = useCallback(
    async (code: string) => {
      try {
        await authenticateOneTimePassword({
          phoneId,
          companyId,
          code,
        }).unwrap()

        dispatch(setLoggedInStaffCode(staffExternalID))

        if (redirectPath) {
          Router.push(redirectPath)
        }
      } catch {}
    },
    [
      phoneId,
      authenticateOneTimePassword,
      staffExternalID,
      companyId,
      dispatch,
      redirectPath,
    ]
  )

  const verifyTextMessageError = useMemo(
    () =>
      authenticateOneTimePasswordErrorToMessage(
        authenticateOneTimePasswordError
      ),
    [authenticateOneTimePasswordError]
  )

  const resendTextMessage = useCallback(() => {
    sendTextMessage(staffExternalID)
  }, [sendTextMessage, staffExternalID])

  return {
    sendTextMessage,
    isSendingTextMessage,
    sendTextMessageError,

    verifyTextMessage,
    isVerifyingTextMessage,
    verifyTextMessageError,

    staffExternalID,
    phoneLast4: phoneLast4 || prevPhoneLast4,
    resendTextMessage,
  }
}

export function sendOneTimePasswordSMSErrorToMessage(
  err?: ApiError | SerializedError
): string | null {
  if (!err) return null
  if (isApiError(err) && err.meta && "stytch_error_type" in err.meta) {
    return stytchErrorMessage(err.meta.stytch_error_type as string)
  }
  if (isApiError(err) && err.code === "not_found") {
    return "ID not found. Please try again."
  }
  return "Something went wrong. Please try again."
}

export function authenticateOneTimePasswordErrorToMessage(
  err?: ApiError | SerializedError
): string | null {
  if (!err) return null
  if (isApiError(err) && err.meta && "stytch_error_type" in err.meta) {
    return stytchErrorMessage(err.meta.stytch_error_type as string)
  }
  return "Something went wrong. Please try again."
}
