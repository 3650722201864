/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "coa/models.proto" (package "rd.coa", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Money } from "../proto/money/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.coa.Account
 */
export interface Account {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: int32 number = 5;
     */
    number: number;
    /**
     * @generated from protobuf field: rd.coa.Account.Type type = 6;
     */
    type: Account_Type;
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.user.User created_by = 8;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 9;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: bool isDefault = 10;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: rd.coa.Account.StartingBalance starting_balance = 11;
     */
    startingBalance?: Account_StartingBalance;
}
/**
 * @generated from protobuf message rd.coa.Account.StartingBalance
 */
export interface Account_StartingBalance {
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 1;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date = 2;
     */
    date?: Timestamp;
}
/**
 * @generated from protobuf enum rd.coa.Account.Type
 */
export enum Account_Type {
    /**
     * @generated from protobuf enum value: Undefined = 0;
     */
    Undefined = 0,
    /**
     * @generated from protobuf enum value: Asset = 1;
     */
    Asset = 1,
    /**
     * @generated from protobuf enum value: Liability = 2;
     */
    Liability = 2,
    /**
     * @generated from protobuf enum value: Income = 3;
     */
    Income = 3,
    /**
     * @generated from protobuf enum value: Expense = 4;
     */
    Expense = 4,
    /**
     * @generated from protobuf enum value: Equity = 5;
     */
    Equity = 5,
    /**
     * @generated from protobuf enum value: COGS = 6;
     */
    COGS = 6
}
/**
 * @generated from protobuf message rd.coa.AccountLog
 */
export interface AccountLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.coa.Account account = 4;
     */
    account?: Account;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
}
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType<Account> {
    constructor() {
        super("rd.coa.Account", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "type", kind: "enum", T: () => ["rd.coa.Account.Type", Account_Type] },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_by", kind: "message", T: () => User },
            { no: 9, name: "updated_by", kind: "message", T: () => User },
            { no: 10, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "starting_balance", kind: "message", T: () => Account_StartingBalance }
        ]);
    }
    create(value?: PartialMessage<Account>): Account {
        const message = { number: 0, type: 0, name: "", isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account): Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* int32 number */ 5:
                    message.number = reader.int32();
                    break;
                case /* rd.coa.Account.Type type */ 6:
                    message.type = reader.int32();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* rd.user.User created_by */ 8:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 9:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* bool isDefault */ 10:
                    message.isDefault = reader.bool();
                    break;
                case /* rd.coa.Account.StartingBalance starting_balance */ 11:
                    message.startingBalance = Account_StartingBalance.internalBinaryRead(reader, reader.uint32(), options, message.startingBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 number = 5; */
        if (message.number !== 0)
            writer.tag(5, WireType.Varint).int32(message.number);
        /* rd.coa.Account.Type type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* rd.user.User created_by = 8; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 9; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bool isDefault = 10; */
        if (message.isDefault !== false)
            writer.tag(10, WireType.Varint).bool(message.isDefault);
        /* rd.coa.Account.StartingBalance starting_balance = 11; */
        if (message.startingBalance)
            Account_StartingBalance.internalBinaryWrite(message.startingBalance, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.coa.Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Account_StartingBalance$Type extends MessageType<Account_StartingBalance> {
    constructor() {
        super("rd.coa.Account.StartingBalance", [
            { no: 1, name: "amount", kind: "message", T: () => Money },
            { no: 2, name: "date", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Account_StartingBalance>): Account_StartingBalance {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Account_StartingBalance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account_StartingBalance): Account_StartingBalance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money amount */ 1:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* google.protobuf.Timestamp date */ 2:
                    message.date = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.date);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Account_StartingBalance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money amount = 1; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date = 2; */
        if (message.date)
            Timestamp.internalBinaryWrite(message.date, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.coa.Account.StartingBalance
 */
export const Account_StartingBalance = new Account_StartingBalance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountLog$Type extends MessageType<AccountLog> {
    constructor() {
        super("rd.coa.AccountLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "account", kind: "message", T: () => Account },
            { no: 5, name: "created_by", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<AccountLog>): AccountLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountLog): AccountLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.coa.Account account */ 4:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.coa.Account account = 4; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.coa.AccountLog
 */
export const AccountLog = new AccountLog$Type();
