// @ts-strict-ignore
import { FractionalMoney, Money } from "money"
import {
  Order_Product as OrderProduct,
  Order_Status as OrderStatus,
} from "gen/txn/models_pb"
import {
  FractionalMoney as PBFractionalMoney,
  Money as PBMoney,
} from "gen/proto/money/models_pb"
import {
  Product as PBProduct,
  Product_Cost as PBProductCost,
} from "gen/product/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { GetOrderRes } from "gen/api/order/service_pb"
import { Location } from "gen/company/models_pb"
import OrderForm from "./types/OrderForm"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Tier } from "gen/price/models_pb"
import { Timestamp } from "gen/google/protobuf/timestamp_pb"
import { TransactionProduct } from "../transactions/transactionProduct"
import { UUID } from "uuid-rd"
import { Vendor } from "gen/vendors/models_pb"
import { fixTxnProductsListOrder } from "../transactions/helpers"
import { stringify } from "lib/stringify"

export default interface OrderFormState extends OrderForm {
  initialState?: OrderForm
  isEdited?: boolean
  scrollToBottom?: boolean
}

interface EditProductCostAndPricePayload {
  productId: PBUUID
  tiers: Tier[]
  isMarginPriceRoundedUp: boolean
  defaultPrice: PBMoney
  defaultCostV2: PBFractionalMoney
  pricingTiersDefaultIdString: string
  costs: PBProductCost[]
}

const initialState: OrderFormState = {}

const checkOrderIsEdited = (state: OrderFormState) => {
  const initState = { ...state.initialState }
  const currState = { ...state, isEdited: undefined, initialState: undefined }
  return !checkStateEquality(initState ?? {}, currState)
}

const checkStateEquality = (stateA: OrderFormState, stateB: OrderFormState) => {
  const checkProductsEquality = (
    productsA: OrderProduct[],
    productsB: OrderProduct[]
  ) => {
    if (productsA.length !== productsB.length) {
      return false
    }
    productsA.sort((pa, pb) =>
      (pa.productId?.bytes || 0) < (pb.productId?.bytes || 0) ? 1 : -1
    )
    productsB.sort((pa, pb) =>
      (pa.productId?.bytes || 0) < (pb.productId?.bytes || 0) ? 1 : -1
    )

    for (let i = 0; i < productsA.length; i += 1) {
      const a = productsA[i]
      const b = productsB[i]

      if (
        !UUID.eqFromPB(a.productId, b.productId) ||
        !Money.eqFromPB(
          a.additionalFeeWhenReceived,
          b.additionalFeeWhenReceived
        ) ||
        a.quantityOrdered !== b.quantityOrdered ||
        a.quantityReceived !== b.quantityReceived ||
        a.position !== b.position ||
        !FractionalMoney.eqFromPB(a.costV2, b.costV2)
      ) {
        return false
      }
    }
    return true
  }

  if (stateA.internalNotes !== stateB.internalNotes) {
    return false
  }

  if (stateA.orderNotes !== stateB.orderNotes) {
    return false
  }

  if (!UUID.eqFromPB(stateA.location?.id, stateB.location?.id)) {
    return false
  }

  if (!checkProductsEquality(stateA.products ?? [], stateB.products ?? [])) {
    return false
  }

  if (
    !Timestamp.equals(
      stateA.requestedDeliveryDate,
      stateB.requestedDeliveryDate
    )
  ) {
    return false
  }

  if (!UUID.eqFromPB(stateA.vendor?.id, stateB.vendor?.id)) {
    return false
  }

  if (stringify(stateA.searchProduct) !== stringify(stateB.searchProduct)) {
    return false
  }

  if (stateA.addProductQuantity !== stateB.addProductQuantity) {
    return false
  }

  return true
}

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrderInitialState: (state, action: PayloadAction<GetOrderRes>) => {
      const initialProducts = action.payload.order?.productsById
        ? Object.values(action.payload.order.productsById)
            .sort((a: OrderProduct, b: OrderProduct) => a.position - b.position)
            .map((prod: OrderProduct) =>
              action.payload.order?.status === OrderStatus.ORDERED
                ? {
                    ...prod,
                    quantityReceived:
                      prod.quantityOrdered < 0
                        ? prod.quantityOrdered - prod.quantityReceived
                        : Math.max(
                            prod.quantityOrdered - prod.quantityReceived,
                            0
                          ),
                  }
                : prod
            )
        : undefined

      const tierPricesById = {}
      Object.keys(action.payload.apiProductsById ?? {}).forEach(
        (id: string) => {
          tierPricesById[id] = action.payload.apiProductsById[id].tierPrices
        }
      )

      state.initialState = {
        internalNotes: action.payload.order?.internalNotes,
        orderNotes: action.payload.order?.orderNotes,
        location: action.payload.location,
        products: initialProducts,
        requestedDeliveryDate: action.payload.order?.requestedDeliveryAt,
        vendor: action.payload.vendor,

        productsById: action.payload.productsById,
        catalogProductsById: action.payload.catalogProductsById,
        inventoriesByProductId: action.payload.inventoriesByProductId,
        additionalFeeByProductId: action.payload.additionalFeeByProductId,
        tierPricesByProductId: tierPricesById,
      }
      state.isEdited = false
      state.internalNotes = action.payload.order?.internalNotes
      state.orderNotes = action.payload.order?.orderNotes
      state.location = action.payload.location
      state.products = initialProducts
      state.requestedDeliveryDate = action.payload.order?.requestedDeliveryAt
      state.vendor = action.payload.vendor

      state.productsById = action.payload.productsById
      state.catalogProductsById = action.payload.catalogProductsById
      state.inventoriesByProductId = action.payload.inventoriesByProductId
      state.additionalFeeByProductId = action.payload.additionalFeeByProductId
      state.tierPricesByProductId = tierPricesById
      state.addProductQuantity = 1
      return state
    },
    setOrderInternalNotes: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.internalNotes = action.payload
      state.isEdited = checkOrderIsEdited(state)
    },
    setOrderNotes: (state, action: PayloadAction<string | undefined>) => {
      state.orderNotes = action.payload
      state.isEdited = checkOrderIsEdited(state)
    },
    setOrderLocation: (state, action: PayloadAction<Location | undefined>) => {
      state.location = action.payload
      state.isEdited = checkOrderIsEdited(state)
      return state
    },
    setOrderProducts: (
      state,
      action: PayloadAction<OrderProduct[] | undefined>
    ) => {
      state.products = action.payload
      state.isEdited = checkOrderIsEdited(state)
      return state
    },
    setOrderRequestedDeliveryDate: (
      state,
      action: PayloadAction<Timestamp | undefined>
    ) => {
      state.requestedDeliveryDate = action.payload
      state.isEdited = checkOrderIsEdited(state)
      return state
    },
    setOrderVendor: (state, action: PayloadAction<Vendor | undefined>) => {
      state.vendor = action.payload
      state.isEdited = checkOrderIsEdited(state)
      return state
    },
    resetOrder: (state, action: PayloadAction<Location | undefined>) => {
      state.location = action.payload
      state.orderNotes = ""
      state.internalNotes = ""
      state.productsById = {}
      state.catalogProductsById = {}
      state.tierPricesByProductId = {}
      state.additionalFeeByProductId = {}
      state.inventoriesByProductId = {}
      state.vendor = undefined
      state.requestedDeliveryDate = undefined
      state.products = []
      state.isEdited = false
      state.searchProduct = undefined
      state.addProductQuantity = 1
    },
    searchProductSelected: (
      state,
      action: PayloadAction<{
        result: TransactionProduct
      }>
    ) => {
      const { result } = action.payload
      if (!result || !result?.product?.id) {
        return
      }
      state.searchProduct = result

      if (result.isScan && result.quantity > 0) {
        state.addProductQuantity = state.searchProduct.quantity
      }

      state.isEdited = checkOrderIsEdited(state)
    },
    searchProductSubmitted: (
      state,
      action: PayloadAction<{
        status?: OrderStatus
      }>
    ) => {
      const { status } = action.payload

      if (!state.addProductQuantity || !state.searchProduct?.product?.id) {
        return
      }

      if (!state.products) {
        state.products = []
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      if (!state.tierPricesByProductId) {
        state.tierPricesByProductId = {}
      }

      const payloadId = UUID.fromPB(state.searchProduct.product.id).toString()

      const existingProductIndex = state.products.findIndex(
        (orderProduct) =>
          UUID.fromPB(orderProduct.productId!).toString() === payloadId
      )

      if (
        typeof existingProductIndex === "number" &&
        existingProductIndex >= 0
      ) {
        state.products[existingProductIndex] = {
          ...state.products[existingProductIndex],
          quantityOrdered:
            status !== OrderStatus.ORDERED
              ? state.products[existingProductIndex].quantityOrdered +
                state.addProductQuantity
              : state.products[existingProductIndex].quantityOrdered,
          quantityReceived:
            status === OrderStatus.ORDERED
              ? state.products[existingProductIndex].quantityReceived +
                state.addProductQuantity
              : state.products[existingProductIndex].quantityReceived,
          position: state.products.length + 1,
        }
      } else {
        state.products = [
          ...state.products,
          {
            productId: state.searchProduct.product.id,
            quantityOrdered:
              status !== OrderStatus.ORDERED ? state.addProductQuantity : 0,
            quantityReceived:
              status === OrderStatus.ORDERED ? state.addProductQuantity : 0,
            position: state.products.length + 1,
            notes: "",
            additionalFeeNameWhenReceived: "",
          },
        ]
      }

      state.products = fixTxnProductsListOrder(state.products) as OrderProduct[]

      state.productsById = {
        ...state.productsById,
        [UUID.fromPB(state.searchProduct.product.id).toString()]:
          state.searchProduct.product,
      }

      if (state.searchProduct.matchedCostProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedCostProduct.id).toString()]:
            state.searchProduct.matchedCostProduct,
        }
      }

      if (state.searchProduct.matchedPriceProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedPriceProduct.id).toString()]:
            state.searchProduct.matchedPriceProduct,
        }
      }

      if (state.searchProduct.catalogProduct) {
        state.catalogProductsById = {
          ...state.catalogProductsById,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.catalogProduct,
        }
      }

      if (state.searchProduct.inventories) {
        state.inventoriesByProductId = {
          ...state.inventoriesByProductId,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.inventories,
        }
      }

      if (state.searchProduct.additionalFee) {
        state.additionalFeeByProductId = {
          ...state.additionalFeeByProductId,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.additionalFee,
        }
      }

      if (state.searchProduct.tierPrices) {
        state.tierPricesByProductId = {
          ...state.tierPricesByProductId,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.tierPrices,
        }
      }

      delete state.searchProduct
      state.addProductQuantity = 1
      state.scrollToBottom = true
      state.isEdited = checkOrderIsEdited(state)
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload
    },
    removeOrderProduct: (state, action: PayloadAction<string>) => {
      const existingProductIndex = state.products?.findIndex(
        (product) =>
          UUID.fromPB(product.productId!).toString() === action.payload
      )

      if (
        typeof existingProductIndex === "number" &&
        existingProductIndex >= 0
      ) {
        state.products?.splice(existingProductIndex, 1)
      }

      if (state.productsById?.[action.payload]) {
        delete state.productsById[action.payload]
      }

      if (state.catalogProductsById?.[action.payload]) {
        delete state.catalogProductsById[action.payload]
      }

      if (state.inventoriesByProductId?.[action.payload]) {
        delete state.inventoriesByProductId[action.payload]
      }

      if (state.additionalFeeByProductId?.[action.payload]) {
        delete state.additionalFeeByProductId[action.payload]
      }

      if (state.tierPricesByProductId?.[action.payload]) {
        delete state.tierPricesByProductId[action.payload]
      }

      if (state.products) {
        state.products = fixTxnProductsListOrder(
          state.products
        ) as OrderProduct[]
      }

      state.isEdited = checkOrderIsEdited(state)
    },
    setOrderProductQuantity: (state, action: PayloadAction<OrderProduct>) => {
      if (!action?.payload?.productId) {
        return
      }

      if (!state.products) {
        state.products = []
      }

      const existingProductIndex = state.products?.findIndex((product) =>
        UUID.fromPB(product.productId!).eq(
          UUID.fromPB(action.payload.productId!)
        )
      )

      if (
        typeof existingProductIndex === "number" &&
        existingProductIndex >= 0
      ) {
        state.products[existingProductIndex] = {
          ...state.products[existingProductIndex],
          quantityOrdered: action.payload.quantityOrdered,
          quantityReceived: action.payload.quantityReceived,
        }
      }
      state.isEdited = checkOrderIsEdited(state)
    },
    setOrderProductNotes: (
      state,
      action: PayloadAction<{ productId: PBUUID; notes: string }>
    ) => {
      if (!state.products) {
        state.products = []
      }

      const existingProductIndex = state.products?.findIndex((product) =>
        UUID.fromPB(product.productId!).eq(
          UUID.fromPB(action.payload.productId!)
        )
      )

      if (existingProductIndex >= 0) {
        state.products[existingProductIndex] = {
          ...state.products[existingProductIndex],
          notes: action.payload.notes,
        }
      }
      state.isEdited = checkOrderIsEdited(state)
    },
    setProductCostAndPrice: (
      state,
      action: PayloadAction<EditProductCostAndPricePayload>
    ) => {
      if (!action.payload.productId || !state.productsById) {
        return
      }

      const existingProduct: PBProduct =
        state.productsById[UUID.fromPB(action.payload.productId).toString()]

      existingProduct.costs = action.payload.costs

      existingProduct.costs.filter(
        (cost: PBProductCost) => cost.isDefault
      )[0].costV2 = action.payload.defaultCostV2

      state.productsById = {
        ...state.productsById,
        [UUID.fromPB(action.payload.productId).toString()]: existingProduct,
      }

      state.tierPricesByProductId = {
        ...state.tierPricesByProductId,
        [UUID.fromPB(action.payload.productId).toString()]:
          action.payload.tiers,
      }
    },
    setOrderProductCostV2: (
      state,
      action: PayloadAction<{
        productId: PBUUID
        oneTimeCostV2: PBFractionalMoney
      }>
    ) => {
      if (!action?.payload?.productId) {
        return
      }

      if (!state.products) {
        state.products = []
      }

      const existingProductIndex = state.products?.findIndex((product) =>
        UUID.fromPB(product.productId).eq(UUID.fromPB(action.payload.productId))
      )

      if (existingProductIndex >= 0) {
        state.products[existingProductIndex] = {
          ...state.products[existingProductIndex],
          costV2: action.payload.oneTimeCostV2,
        }
      }

      state.isEdited = checkOrderIsEdited(state)
    },
    setProductReceivedQuantitiesToZero: (state) => {
      for (const product of state.products) {
        product.quantityReceived = 0
      }
      state.isEdited = checkOrderIsEdited(state)
    },
    resetSearchProductSelected: (state) => {
      delete state.searchProduct
      state.isEdited = checkOrderIsEdited(state)
    },
    setAddProductQuantity: (state, action: PayloadAction<number>) => {
      state.addProductQuantity = action.payload
      state.isEdited = checkOrderIsEdited(state)
    },
  },
})

export const {
  setOrderInitialState,
  setOrderInternalNotes,
  setOrderNotes,
  setOrderLocation,
  setOrderProducts,
  setOrderRequestedDeliveryDate,
  setOrderVendor,
  resetOrder,
  searchProductSelected,
  searchProductSubmitted,
  removeOrderProduct,
  setOrderProductQuantity,
  setProductCostAndPrice,
  setOrderProductNotes,
  setScrollToBottom,
  setProductReceivedQuantitiesToZero,
  resetSearchProductSelected,
  setAddProductQuantity,
  setOrderProductCostV2,
} = ordersSlice.actions

export const selectOrderInitalState = (state: AppState) =>
  state.orders.initialState
export const selectOrderIsEdited = (state: AppState) => !!state.orders.isEdited
export const selectOrderInternalNotes = (state: AppState) =>
  state.orders.internalNotes
export const selectOrderNotes = (state: AppState) => state.orders.orderNotes
export const selectOrderLocation = (state: AppState) => state.orders.location
export const selectOrderProducts = (state: AppState) => state.orders.products
export const selectOrderRequestedDeliveryDate = (state: AppState) =>
  state.orders.requestedDeliveryDate
export const selectOrderVendor = (state: AppState) => state.orders.vendor
export const selectOrderAdditionalFeeByProductId = (state: AppState) =>
  state.orders.additionalFeeByProductId
export const selectOrderProductsById = (state: AppState) =>
  state.orders.productsById
export const selectOrderCatalogProductsById = (state: AppState) =>
  state.orders.catalogProductsById
export const selectOrderInventoriesByProductId = (state: AppState) =>
  state.orders.inventoriesByProductId
export const selectOrderIsSubmittable = ({
  orders: { products, vendor, location },
}: AppState) => (products ?? []).length >= 1 && vendor && location
export const selectScrollToBottom = (state: AppState) =>
  state.orders.scrollToBottom
export const selectSearchProduct = (state: AppState) =>
  state.orders.searchProduct
export const selectAddProductQuantity = (state: AppState) =>
  state.orders.addProductQuantity
export const selectOrderTierPricesById = (state: AppState) =>
  state.orders.tierPricesByProductId
