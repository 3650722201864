import { Button } from "../Button"
import { Preset } from "lib/dates/presets"
import { classNames } from "lib/classNames"

interface PresetButtonProps {
  preset: Preset
  onSelectPreset: (interval: Preset) => void
  isSelected: boolean
}

export function PresetButton({
  preset,
  onSelectPreset,
  isSelected,
}: PresetButtonProps) {
  return (
    <div className="flex w-28 justify-start">
      <Button
        onMouseDown={() => onSelectPreset(preset)}
        mode={"secondary"}
        className={classNames(
          "border-none text-xs font-semibold",
          isSelected &&
            "bg-accent-button-secondary-light dark:bg-accent-button-secondary-dark text-white"
        )}
        tabIndex={-1}
      >
        {preset.displayText}
      </Button>
    </div>
  )
}
