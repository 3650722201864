import { Field, FieldProps, Label } from "@headlessui/react"

import { Checkbox } from "components/Checkbox"
import { PropsWithChildren } from "react"
import { classNames } from "lib/classNames"

export interface CheckboxCardProps
  extends PropsWithChildren<Omit<FieldProps, "className" | "onChange">> {
  className?: string
  disabled?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
}

export function CheckboxCard({
  children,
  className,
  checked,
  onChange,
  ...otherProps
}: CheckboxCardProps) {
  return (
    <Field
      className={classNames(
        "text-primary surface-accent flex items-center justify-between gap-2 rounded p-4 text-sm",
        className
      )}
      {...otherProps}
    >
      <Label className="cursor-pointer select-none data-[disabled]:cursor-not-allowed">
        {children}
      </Label>
      <Checkbox checked={checked} onChange={onChange} />
    </Field>
  )
}
