import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react"

import { selectLastUsedSignInMethod } from "./authSlice"
import { useAppSelector } from "app/hooks"

type Step =
  | "key"
  | "key-id"
  | "text-message"
  | "text-message-code"
  | "session-timeout"

export interface SignInContextValue {
  step: Step
  setStep: (step: Step) => void
  goBack: () => void
  redirectPath?: string
}

export const SignInContext = createContext<SignInContextValue | null>(null)

interface SignInProviderProps {
  initialStep?: Step
  redirectPath?: string
}

interface SignInState {
  step: Step
  prevStep: Step | null
}

type SignInAction = { type: "SET_STEP"; payload: Step } | { type: "GO_BACK" }

function reducer(state: SignInState, action: SignInAction): SignInState {
  switch (action.type) {
    case "SET_STEP": {
      return {
        ...state,
        prevStep: state.step,
        step: action.payload,
      }
    }
    case "GO_BACK": {
      if (!state.prevStep) return state

      return {
        ...state,
        step: state.prevStep,
        prevStep: null,
      }
    }
  }
}

export function SignInProvider(props: PropsWithChildren<SignInProviderProps>) {
  const { initialStep, redirectPath, children } = props

  const lastUsedSignInMethod = useAppSelector(selectLastUsedSignInMethod)

  const [state, dispatch] = useReducer(reducer, {
    step: initialStep ?? lastUsedSignInMethod ?? "key",
    prevStep: null,
  })

  const setStep = useCallback((step: Step) => {
    dispatch({ type: "SET_STEP", payload: step })
  }, [])

  const goBack = useCallback(() => {
    dispatch({ type: "GO_BACK" })
  }, [])

  return (
    <SignInContext.Provider
      value={{ step: state.step, setStep, goBack, redirectPath }}
    >
      {children}
    </SignInContext.Provider>
  )
}

export function useSignInContext(): SignInContextValue {
  const context = useContext(SignInContext)
  if (!context) {
    throw new Error("useSignInContext must be used within a SignInProvider")
  }
  return context
}
