import type { AppDispatch, AppState } from "./store"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { useEffect, useRef } from "react"

import { selectColorScheme } from "features/theme/themeSlice"

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const handler = () => savedCallback.current?.()

    if (delay !== null) {
      const id = setInterval(handler, delay)
      return () => clearInterval(id)
    }

    return () => {}
  }, [delay])
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export function useColorScheme(): "light" | "dark" {
  const colorScheme = useAppSelector(selectColorScheme)

  return colorScheme ?? "light"
}
