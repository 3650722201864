import { AppDispatch, AppState } from "app/store"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type ColorScheme = "light" | "dark"

type ThemeState = {
  colorScheme: ColorScheme | null
}

const initialState: ThemeState = {
  colorScheme: "light",
}

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setColorScheme: (state, action: PayloadAction<ColorScheme>) => {
      state.colorScheme = action.payload
    },
    toggleColorScheme: (state) => {
      state.colorScheme = state.colorScheme === "light" ? "dark" : "light"
    },
  },
})

export const { setColorScheme, toggleColorScheme } = themeSlice.actions

export const selectColorScheme = (state: AppState) => state.theme.colorScheme

export const toggleColorSchemeAndUpdateClassList =
  () => (dispatch: AppDispatch, getState: () => AppState) => {
    dispatch(toggleColorScheme())

    const state = getState()

    if (state.theme.colorScheme === "light") {
      document.documentElement.classList.remove("dark", "bg-gray-1000")
      document.documentElement.classList.add("bg-gray-50")
      localStorage.setItem("rundooColorScheme", "light")
      if (typeof window.Intercom === "function") {
        window.Intercom("update", {
          background_color: "#0E1FC1",
          action_color: "#0E1FC1",
        })
      }
    } else {
      document.documentElement.classList.add("dark", "bg-gray-1000")
      document.documentElement.classList.remove("bg-gray-50")
      localStorage.setItem("rundooColorScheme", "dark")
      if (typeof window.Intercom === "function") {
        window.Intercom("update", {
          background_color: "#535FCF",
          action_color: "#535FCF",
        })
      }
    }
  }
