import { Button } from "components/Button"
import { ErrorMessage } from "./ErrorMessage"
import Router from "next/router"
import { SingleCharacterInput } from "components/SingleCharacterInput"
import { TextButton } from "components/TextButton"
import { selectLastUsedStaffExternalID } from "./authSlice"
import { useAppSelector } from "app/hooks"

interface StepSessionTimeoutProps {
  onClickScanKey: (staffExternalID: string) => void
  onClickSendTextMessage: (staffExternalID: string) => void
  isSubmitting: boolean
  errorMessage: string | null
}

export function StepSessionTimeout(props: StepSessionTimeoutProps) {
  const { onClickScanKey, onClickSendTextMessage, isSubmitting, errorMessage } =
    props

  const staffExternalID = useAppSelector(selectLastUsedStaffExternalID)

  function scanKey() {
    if (!staffExternalID) return
    onClickScanKey(staffExternalID)
  }

  function sendTextMessage() {
    if (!staffExternalID) return
    onClickSendTextMessage(staffExternalID)
  }

  function signOut() {
    Router.push("/auth/sign-in")
  }

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <SingleCharacterInput
          className="self-center"
          value={staffExternalID ?? ""}
          isLoading={isSubmitting}
          disabled
        />
        <ErrorMessage message={errorMessage} />
      </div>
      <div className="flex flex-col gap-y-2">
        <Button mode="primary" onClick={scanKey}>
          Scan key
        </Button>
        <Button mode="secondary" onClick={sendTextMessage}>
          Send text message
        </Button>
      </div>
      <TextButton onClick={signOut}>Sign out</TextButton>
    </>
  )
}
