import { ComponentPropsWithRef, KeyboardEvent, forwardRef } from "react"

import { classNames } from "lib/classNames"
import { useClose } from "@headlessui/react"

export type PopoverTextInputProps = ComponentPropsWithRef<"input">
export const PopoverTextInput = forwardRef<
  HTMLInputElement,
  PopoverTextInputProps
>(({ className, onKeyDown: onKeyDownProp, ...otherProps }, ref) => {
  // close the nearest parent popover
  const close = useClose()
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      close()
    }
    onKeyDownProp?.(e)
  }

  return (
    <input
      ref={ref}
      className={classNames(
        "w-full",
        "h-8 px-2",
        "text-secondary text-sm",
        "surface-primary",
        "border-accent border",
        "placeholder:text-secondary",
        "focus:surface-accent focus:border-transparent",
        "rounded",
        className
      )}
      onKeyDown={onKeyDown}
      {...otherProps}
    />
  )
})
