import { useHotkeys } from "react-hotkeys-hook"
import { useRouter } from "next/router"

export default function useKeyboardShortcut(
  keys: string | string[],
  callback: (event: KeyboardEvent) => void,
  options?: {
    disableOnModalOpen?: boolean
    enableOnFormTags?: boolean
  }
) {
  const { disableOnModalOpen = true, enableOnFormTags = false } = options ?? {}

  const router = useRouter()

  const isModalOpen = !!router.query.action
  const isDisabled = isModalOpen && disableOnModalOpen

  return useHotkeys(keys, callback, {
    enabled: !isDisabled,
    enableOnFormTags,
  })
}
