import { parseISO } from "date-fns"

// whitelist of rundoo cookie names
const whitelist = ["rundoo_sessionexpiresat", "rundoo_sessionupdatedat"]

// Cookies is the class that should be used any time we are
// trying to read a cookie from our frontend code.
export class Cookies {
  private cookies: Record<string, string> = {}

  private constructor(cookiesStr: string) {
    if (cookiesStr === "") {
      return
    }

    for (const cookieStr of cookiesStr.split("; ")) {
      const [name, value] = cookieStr.split("=")

      if (!whitelist.includes(name)) {
        continue
      }

      this.cookies[name] = value
    }
  }

  // fromDocument constructs a Cookies from a Document.
  static fromDocument(document: Document) {
    return new Cookies(document.cookie)
  }

  // sessionExpiresDate returns the Date the current session will expire.
  get sessionExpiresDate(): Date | null {
    const expiresAtRFC3339 = this.cookies.rundoo_sessionexpiresat

    if (!expiresAtRFC3339) {
      return null
    }

    return parseISO(expiresAtRFC3339)
  }

  // sessionUpdatedDate returns the Date the current session was last updated.
  get sessionUpdatedDate(): Date | null {
    const updatedAtRFC3339 = this.cookies.rundoo_sessionupdatedat

    if (!updatedAtRFC3339) {
      return null
    }

    return parseISO(updatedAtRFC3339)
  }
}
