import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Sale_Status as SaleStatus } from "gen/txn/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function sale(id?: PBUUID, status?: SaleStatus): string {
  if (!status) {
    return `/pos/sales/${UUID.idString(id)}`
  }
  const statusString = cleanStatus(SaleStatus[status ?? SaleStatus.SOLD])
  return `/pos/sales/${UUID.idString(id)}?status=${statusString}`
}
