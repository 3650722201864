/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "catalog/models.proto" (package "rd.catalog", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Money } from "../proto/money/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.catalog.Product
 */
export interface Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string upc = 4;
     */
    upc: string; // unique
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
    /**
     * @generated from protobuf field: string image_url = 6;
     */
    imageUrl: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money msrp = 7;
     */
    msrp?: Money;
}
// @generated message type with reflection information, may provide speed optimized methods
class Product$Type extends MessageType<Product> {
    constructor() {
        super("rd.catalog.Product", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "upc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "msrp", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<Product>): Product {
        const message = { upc: "", name: "", imageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product): Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string upc */ 4:
                    message.upc = reader.string();
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* string image_url */ 6:
                    message.imageUrl = reader.string();
                    break;
                case /* rd.proto.money.Money msrp */ 7:
                    message.msrp = Money.internalBinaryRead(reader, reader.uint32(), options, message.msrp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string upc = 4; */
        if (message.upc !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.upc);
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* string image_url = 6; */
        if (message.imageUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.imageUrl);
        /* rd.proto.money.Money msrp = 7; */
        if (message.msrp)
            Money.internalBinaryWrite(message.msrp, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.catalog.Product
 */
export const Product = new Product$Type();
