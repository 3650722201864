import { TextButton } from "components/TextButton"
import { useSignInContext } from "./SignInContext"

export function SignInViaTextMessageButton() {
  const { setStep } = useSignInContext()

  function onClickSignInViaTextMessage() {
    setStep("text-message")
  }

  return (
    <TextButton onClick={onClickSignInViaTextMessage}>
      Sign in via text message
    </TextButton>
  )
}
