import { HTMLAttributes } from "react"
import { classNames } from "lib/classNames"

type CheckboxLabelProps = HTMLAttributes<HTMLLabelElement>

export function CheckboxLabel({
  className,
  ...otherProps
}: CheckboxLabelProps) {
  return (
    <label
      className={classNames("cursor-pointer select-none", className)}
      {...otherProps}
    />
  )
}
