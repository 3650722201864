import { FractionalMoney, Money } from "money"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { OrderShipment_CostAdjustment as CostAdjustment } from "gen/txn/models_pb"
import { FractionalMoney as FractionalMoneyPB } from "gen/proto/money/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Timestamp as TimestampPB } from "gen/google/protobuf/timestamp_pb"
import { UUID } from "uuid-rd"
import { useCallback } from "react"

export interface OrderShipmentState {
  receiveNotes: string
  costsByProductId: Record<string, FractionalMoneyPB>
  costAdjustments: CostAdjustment[]
  invoiceDate: TimestampPB | null
  dueDate: TimestampPB | null
  invoiceNumber: string
}

export interface OrderShipmentFormState {
  original: OrderShipmentState
  current: OrderShipmentState
  isInitialized: boolean
}

export function emptyState(): OrderShipmentState {
  return {
    receiveNotes: "",
    costsByProductId: {},
    costAdjustments: [],
    invoiceDate: null,
    dueDate: null,
    invoiceNumber: "",
  }
}

export function emptyFormState(): OrderShipmentFormState {
  return {
    original: emptyState(),
    current: emptyState(),
    isInitialized: false,
  }
}

export const orderShipmentsSlice = createSlice({
  name: "orderShipments",
  initialState: emptyFormState(),
  reducers: {
    resetForm: (state) => {
      state.current = emptyState()
      state.original = emptyState()
      state.isInitialized = false
    },
    setInitialState: (
      state,
      { payload: initialState }: PayloadAction<OrderShipmentState>
    ) => {
      state.original = initialState
      state.current = initialState
      state.isInitialized = true
    },
    setReceiveNotes: (
      { current },
      { payload: receiveNotes }: PayloadAction<string>
    ) => {
      current.receiveNotes = receiveNotes
    },
    setProductCost: (
      { current },
      {
        payload: { productId, cost },
      }: PayloadAction<{
        productId: UUID
        cost: FractionalMoney
      }>
    ) => {
      const productIdStr = productId.toString()
      if (!(productIdStr in current.costsByProductId)) {
        return
      }
      current.costsByProductId[productIdStr] = cost.toPB()
    },
    setCostAdjustments: (
      { current },
      { payload: costAdjustments }: PayloadAction<CostAdjustment[]>
    ) => {
      current.costAdjustments = costAdjustments
    },
    setInvoiceDate: (
      { current },
      { payload: invoiceDate }: PayloadAction<TimestampPB | null>
    ) => {
      current.invoiceDate = invoiceDate
    },
    setDueDate: (
      { current },
      { payload: dueDate }: PayloadAction<TimestampPB | null>
    ) => {
      current.dueDate = dueDate
    },
    setInvoiceNumber: (
      { current },
      { payload: invoiceNumber }: PayloadAction<string>
    ) => {
      current.invoiceNumber = invoiceNumber
    },
  },
})

export const {
  setInitialState,
  setReceiveNotes,
  setProductCost,
  setCostAdjustments,
  setInvoiceDate,
  setDueDate,
  setInvoiceNumber,
  resetForm,
} = orderShipmentsSlice.actions

export const selectIsInitialized = (state: AppState) =>
  state.orderShipments.isInitialized

export function selectIsEdited({
  orderShipments: { original, current },
}: AppState) {
  return [
    current.receiveNotes === original.receiveNotes,
    TimestampPB.equals(
      current.dueDate ?? undefined,
      original.dueDate ?? undefined
    ),
    TimestampPB.equals(
      current.invoiceDate ?? undefined,
      original.invoiceDate ?? undefined
    ),
    current.invoiceNumber === original.invoiceNumber,
    isProductCostsEqual(current.costsByProductId, original.costsByProductId),
    isCostAdjustmentsEqual(current.costAdjustments, original.costAdjustments),
  ].some((isEdited) => !isEdited)
}

export const selectReceiveNotes = (state: AppState) =>
  state.orderShipments.current.receiveNotes

export const useSelectProductCost = (productId?: PBUUID) => {
  const productIdStr = UUID.idString(productId)

  return useCallback(
    (state: AppState): FractionalMoneyPB | undefined =>
      state.orderShipments.current.costsByProductId[productIdStr],
    [productIdStr]
  )
}
export function selectCostAdjustments({
  orderShipments: { current },
}: AppState) {
  return current.costAdjustments
}

export function selectCostsByProductId(state: AppState) {
  return state.orderShipments.current.costsByProductId
}

export function selectInvoiceDate(state: AppState) {
  return state.orderShipments.current.invoiceDate
}

export function selectDueDate(state: AppState) {
  return state.orderShipments.current.dueDate
}

export function selectInvoiceNumber(state: AppState) {
  return state.orderShipments.current.invoiceNumber
}

function isProductCostsEqual(
  a: Record<string, FractionalMoneyPB>,
  b: Record<string, FractionalMoneyPB>
) {
  return (
    Object.keys(a).length === Object.keys(b).length &&
    Object.keys(a).every(
      (productId) =>
        productId in a &&
        productId in b &&
        FractionalMoney.fromPB(a[productId]).eq(
          FractionalMoney.fromPB(b[productId])
        )
    )
  )
}

function isCostAdjustmentsEqual(a: CostAdjustment[], b: CostAdjustment[]) {
  return (
    a.length === b.length &&
    a.every((_, i) => isCostAdjustmentEqual(a[i], b[i]))
  )
}

function isCostAdjustmentEqual(a: CostAdjustment, b: CostAdjustment) {
  if (a.name !== b.name) {
    return false
  }

  if (!Money.fromPB(a.amount).eq(Money.fromPB(b.amount))) {
    return false
  }

  if (!UUID.eqFromPB(a.chartOfAccountId, b.chartOfAccountId)) {
    return false
  }

  return true
}
