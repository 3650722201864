// @ts-strict-ignore
import {
  InventoryCount_Status,
  InventoryCount_Type,
} from "gen/product/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { GetInventoryCountRes } from "gen/api/inventorycount/service_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Product } from "gen/product/models_pb"
import { UUID } from "uuid-rd"
import { checkProductsEquality } from "./helpers"

export default interface InventoryCountState {
  name?: string
  locationId?: PBUUID
  status?: InventoryCount_Status
  type?: InventoryCount_Type
  newProducts?: Product[]
  removeProduct?: Product
  icpGenerationKey?: string
}

export default interface InventoryCountState {
  initialState?: InventoryCountState
  isEdited?: boolean
}

export const initialState: InventoryCountState = {
  name: "",
  locationId: null,
  status: InventoryCount_Status.UNDEFINED,
  type: InventoryCount_Type.UNDEFINED,
  newProducts: null,
  removeProduct: null,
  icpGenerationKey: "",
}

const checkIsEdited = (state: InventoryCountState) => {
  if (state.initialState?.name !== state.name) {
    return true
  }
  if (!UUID.eqFromPB(state.initialState?.locationId, state.locationId)) {
    return true
  }
  if (state.initialState?.status !== state.status) {
    return true
  }
  if (state.initialState?.type !== state.type) {
    return true
  }
  if (
    !checkProductsEquality(state.initialState?.newProducts, state.newProducts)
  ) {
    return true
  }
  if (
    !checkProductsEquality(
      [state.initialState?.removeProduct],
      [state.removeProduct]
    )
  ) {
    return true
  }
  return false
}

export const countSlice = createSlice({
  name: "count",
  initialState,
  reducers: {
    setCountInitialState: (
      state,
      action: PayloadAction<GetInventoryCountRes> // also include the search response
    ) => {
      state.initialState = {
        name: action.payload.inventoryCount?.name,
        status: action.payload.inventoryCount?.status,
        locationId: action.payload.inventoryCount?.locationId,
        type: action.payload.inventoryCount?.type,
        newProducts: action.payload.newProducts,
      }
      state.isEdited = false
      state.removeProduct = null
    },
    setCountName: (state, action: PayloadAction<string | undefined>) => {
      state.name = action.payload
      state.isEdited = checkIsEdited(state as InventoryCountState)
    },
    setCountStatus: (
      state,
      action: PayloadAction<InventoryCount_Status | undefined>
    ) => {
      state.status = action.payload
      state.isEdited = checkIsEdited(state as InventoryCountState)
    },
    setRemoveProduct: (state, action: PayloadAction<Product | undefined>) => {
      state.removeProduct = action.payload
      state.isEdited = checkIsEdited(state as InventoryCountState)
    },
    resetCountState: (state) => {
      state.initialState = initialState
      state.isEdited = false
      state.removeProduct = null
    },
  },
})

export const {
  setCountInitialState,
  setCountName,
  setCountStatus,
  setRemoveProduct,
  resetCountState,
} = countSlice.actions

export const selectCountInitialState = (state: AppState) =>
  state.count.initialState
export const selectIsEdited = (state: AppState) => !!state.count.isEdited
export const selectCountName = (state: AppState) => state.count.name
export const selectCountStatus = (state: AppState) => state.count.status
export const selectCountLocationId = (state: AppState) => state.count.locationId
export const selectNewProducts = (state: AppState) => state.count.newProducts
export const selectRemoveProduct = (state: AppState) =>
  state.count.removeProduct
