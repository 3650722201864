import { AppState } from "app/store"
import { UUID } from "uuid-rd"
import { createSlice } from "@reduxjs/toolkit"

interface SearchSliceState {
  externalTransactionKey: string
  financialTransactionKey: string
}

const generateRandomKey = () => UUID.newRandom().toString()

const initialState: SearchSliceState = {
  externalTransactionKey: generateRandomKey(),
  financialTransactionKey: generateRandomKey(),
}

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    reloadExternalTransactions: (state) => {
      state.externalTransactionKey = generateRandomKey()
    },
    reloadFinancialTransactions: (state) => {
      state.financialTransactionKey = generateRandomKey()
    },
  },
})

export const { reloadExternalTransactions, reloadFinancialTransactions } =
  searchSlice.actions

export const selectExternalTransactionKey = (state: AppState) =>
  state.searchSlice.externalTransactionKey

export const selectFinancialTransactionKey = (state: AppState) =>
  state.searchSlice.financialTransactionKey
