import { StepKey } from "./StepKey"
import { StepKeyID } from "./StepKeyID"
import { StepSessionTimeout } from "./StepSessionTimeout"
import { StepTextMessage } from "./StepTextMessage"
import { StepTextMessageCode } from "./StepTextMessageCode"
import { useKeySignIn } from "./useKeySignIn"
import { useSignInContext } from "./SignInContext"
import { useTextMessageSignIn } from "./useTextMessageSignIn"

export function Step() {
  const { step } = useSignInContext()

  const {
    signInWithKey,
    signInWithKeyID,
    errorMessage: keyAuthErrorMessage,
    isSigningInWithKeyID,
  } = useKeySignIn()

  const {
    sendTextMessage,
    isSendingTextMessage,
    sendTextMessageError,
    verifyTextMessage,
    isVerifyingTextMessage,
    verifyTextMessageError,
    staffExternalID,
    phoneLast4,
    resendTextMessage,
  } = useTextMessageSignIn()

  switch (step) {
    case "key":
      return <StepKey onClickScanKey={signInWithKey} />
    case "key-id":
      return (
        <StepKeyID
          onSubmit={signInWithKeyID}
          isSubmitting={isSigningInWithKeyID}
          errorMessage={keyAuthErrorMessage}
        />
      )
    case "text-message":
      return (
        <StepTextMessage
          onSubmit={sendTextMessage}
          errorMessage={sendTextMessageError}
          isSubmitting={isSendingTextMessage}
        />
      )
    case "text-message-code":
      return (
        <StepTextMessageCode
          staffExternalID={staffExternalID}
          phoneLast4={phoneLast4}
          onSubmit={verifyTextMessage}
          isSubmitting={isVerifyingTextMessage}
          onClickSendAnotherCode={resendTextMessage}
          isSendingAnotherCode={isSendingTextMessage}
          errorMessage={verifyTextMessageError}
        />
      )
    case "session-timeout":
      return (
        <StepSessionTimeout
          onClickScanKey={signInWithKeyID}
          onClickSendTextMessage={sendTextMessage}
          isSubmitting={isSendingTextMessage}
          errorMessage={keyAuthErrorMessage || sendTextMessageError}
        />
      )
    default:
      return null
  }
}
