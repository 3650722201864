/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "proto/timestamprange/models.proto" (package "rd.proto.timestamprange", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * TimestampRange represents a date/time range.
 *
 * @generated from protobuf message rd.proto.timestamprange.TimestampRange
 */
export interface TimestampRange {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 1;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 2;
     */
    end?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class TimestampRange$Type extends MessageType<TimestampRange> {
    constructor() {
        super("rd.proto.timestamprange.TimestampRange", [
            { no: 1, name: "start", kind: "message", T: () => Timestamp },
            { no: 2, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimestampRange>): TimestampRange {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimestampRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimestampRange): TimestampRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 1:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 2:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimestampRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 1; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 2; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.proto.timestamprange.TimestampRange
 */
export const TimestampRange = new TimestampRange$Type();
