import {
  AuthenticateNoltReq,
  AuthenticateNoltRes,
  AuthenticateOneTimePasswordReq,
  AuthenticateOneTimePasswordRes,
  AuthenticateWebAuthnMultiUserStartReq,
  AuthenticateWebAuthnMultiUserStartRes,
  AuthenticateWebAuthnReq,
  AuthenticateWebAuthnRes,
  AuthenticateWebAuthnStartReq,
  AuthenticateWebAuthnStartRes,
  ExtendSessionReq,
  ExtendSessionRes,
  RegisterWebAuthnReq,
  RegisterWebAuthnRes,
  RegisterWebAuthnStartReq,
  RegisterWebAuthnStartRes,
  RevokeSessionReq,
  RevokeSessionRes,
  SendOneTimePasswordSMSReq,
  SendOneTimePasswordSMSRes,
} from "gen/api/auth/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { AuthServiceClient } from "gen/api/auth/service_pb.client"

const c = new AuthServiceClient(transport)

export const authApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ["Staff"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      registerWebAuthnStart: builder.mutation<
        RegisterWebAuthnStartRes,
        RegisterWebAuthnStartReq
      >({
        query: (r) => c.registerWebAuthnStart({ ...r }),
      }),
      registerWebAuthn: builder.mutation<
        RegisterWebAuthnRes,
        RegisterWebAuthnReq
      >({
        query: (r) => c.registerWebAuthn(r),
        invalidatesTags: ["Staff"],
      }),
      authenticateWebAuthnStart: builder.mutation<
        AuthenticateWebAuthnStartRes,
        AuthenticateWebAuthnStartReq
      >({
        query: (r) => c.authenticateWebAuthnStart({ ...r }),
      }),
      authenticateWebAuthnMultiUserStart: builder.mutation<
        AuthenticateWebAuthnMultiUserStartRes,
        AuthenticateWebAuthnMultiUserStartReq
      >({
        query: (r) => c.authenticateWebAuthnMultiUserStart({ ...r }),
      }),
      authenticateWebAuthn: builder.mutation<
        AuthenticateWebAuthnRes,
        AuthenticateWebAuthnReq
      >({
        query: (r) => c.authenticateWebAuthn(r),
      }),
      sendOneTimePasswordSMS: builder.mutation<
        SendOneTimePasswordSMSRes,
        SendOneTimePasswordSMSReq
      >({
        query: (r) => c.sendOneTimePasswordSMS(r),
      }),
      authenticateOneTimePassword: builder.mutation<
        AuthenticateOneTimePasswordRes,
        AuthenticateOneTimePasswordReq
      >({
        query: (r) => c.authenticateOneTimePassword(r),
      }),
      extendSession: builder.mutation<ExtendSessionRes, ExtendSessionReq>({
        query: (r) => c.extendSession(r),
      }),
      revokeSession: builder.mutation<RevokeSessionRes, RevokeSessionReq>({
        query: (r) => c.revokeSession(r),
      }),
      authenticateNolt: builder.query<AuthenticateNoltRes, AuthenticateNoltReq>(
        {
          query: (r) => c.authenticateNolt(r),
        }
      ),
    }),
  })

export const {
  useRegisterWebAuthnStartMutation,
  useRegisterWebAuthnMutation,
  useAuthenticateWebAuthnStartMutation,
  useAuthenticateWebAuthnMultiUserStartMutation,
  useAuthenticateWebAuthnMutation,
  useSendOneTimePasswordSMSMutation,
  useAuthenticateOneTimePasswordMutation,
  useExtendSessionMutation,
  useRevokeSessionMutation,
  useAuthenticateNoltQuery,
} = authApiSlice
