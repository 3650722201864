import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react"

import { SignInCard } from "./SignInCard"
import { SignInProvider } from "./SignInContext"

export function SessionTimeoutSignIn() {
  return (
    <Dialog open onClose={noOp} className="z-50">
      <DialogBackdrop className="dark:bg-gray-1000 fixed inset-0 z-50 bg-gray-500 bg-opacity-75 dark:bg-opacity-75" />

      <div className="fixed inset-0 z-50 flex w-screen items-center justify-center">
        <SignInProvider initialStep="session-timeout">
          <DialogPanel as={SignInCard} />
        </SignInProvider>
      </div>
    </Dialog>
  )
}

// noOp is a no-op function we use above to satisfy the Dialog props.
function noOp() {}
