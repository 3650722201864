import { ComponentPropsWithoutRef, FunctionComponent } from "react"

import { classNames } from "lib/classNames"

type KeyboardTileColor = "blue" | "white" | "gray" | "light-blue"

interface KeyboardTileProps extends ComponentPropsWithoutRef<"div"> {
  color?: KeyboardTileColor
  keyboardKey?: string
}

function getClassNamesForColor(color: KeyboardTileColor) {
  switch (color) {
    case "blue":
      return "bg-cobalt-500 text-cobalt-100 dark:bg-gray-700 dark:text-gray-300"
    case "light-blue":
      return "bg-cobalt-100 text-cobalt-400 dark:bg-cobalt-500 dark:text-cobalt-100"
    case "white":
      return "bg-white text-gray-500 dark:bg-gray-700 dark:text-gray-300 shadow"
    case "gray":
      return "bg-gray-100 text-gray-500 dark:bg-gray-700 dark:text-gray-300"
    default:
      throw new Error("unexpected keyboard tile color")
  }
}

export const KeyboardTile: FunctionComponent<KeyboardTileProps> = (props) => {
  const { color, keyboardKey, className, children, ...otherProps } = props
  return (
    <div
      className={classNames(
        "flex min-h-[20px] min-w-[20px] items-center justify-center rounded",
        getClassNamesForColor(color ?? "white"),
        className
      )}
      {...otherProps}
    >
      {children || <p className="mx-1 text-xs">{keyboardKey}</p>}
    </div>
  )
}
