import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/outline"
import {
  UseIntervalPickerProps,
  useIntervalPicker,
} from "components/TimestampRangePicker/TimestampRangePicker"
import {
  daysOfWeek,
  getDisplayDays,
} from "components/TimestampRangePicker/TimestampRangePickerCalendar"
import { eachDayOfInterval, format } from "date-fns"

import { CalendarCell } from "./CalendarCell"
import { IconButton } from "../Filter/FilterHeaderRow"
import { Preset } from "lib/dates/presets"
import { PresetButton } from "./PresetButton"
import { TextDateInput } from "./TextDateInput"
import { classNames } from "lib/classNames"

interface IntervalPickerProps extends UseIntervalPickerProps {
  containerClassName?: string
  presetsToDisplay: Preset[]
}

export function IntervalPicker({
  containerClassName,
  presetsToDisplay,
  ...useIntervalPickerProps
}: IntervalPickerProps) {
  const {
    monthDisplayed,
    onClickPreviousMonth,
    onClickNextMonth,
    currentDateField,
    displayRange,
    onDateClicked,
    onSelectPreset,
    onDelete,
    startInputProps,
    endInputProps,
    disabledMode,
  } = useIntervalPicker(useIntervalPickerProps)

  return (
    <div
      className={classNames(
        "divide-primary flex flex-row gap-2 divide-x",
        containerClassName
      )}
    >
      <div className="flex flex-col gap-2 p-4">
        {presetsToDisplay.map((preset) => (
          <PresetButton
            key={preset.id}
            preset={preset}
            onSelectPreset={onSelectPreset}
            isSelected={!!displayRange && preset.matches(displayRange)}
          />
        ))}
      </div>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-1">
            <div className="text-sm">Date</div>
          </div>
          <IconButton onClick={onDelete} Icon={TrashIcon} tabIndex={-1} />
        </div>
        <div className="flex flex-row gap-3">
          <TextDateInput {...startInputProps} />
          <TextDateInput {...endInputProps} />
        </div>
        <div className="flex flex-row justify-between">
          <div>{format(monthDisplayed, "MMMM yyyy")}</div>
          <div className="flex flex-row gap-2">
            <IconButton
              Icon={ChevronLeftIcon}
              onMouseDown={onClickPreviousMonth}
              tabIndex={-1}
            />
            <IconButton
              Icon={ChevronRightIcon}
              onMouseDown={onClickNextMonth}
              tabIndex={-1}
            />
          </div>
        </div>
        <div className="flex w-56 flex-wrap">
          {daysOfWeek.map((day) => (
            <div key={day.id} className="h-8 w-8 text-center text-sm">
              {day.Dd}
            </div>
          ))}
          {eachDayOfInterval(getDisplayDays(monthDisplayed)).map((day) => (
            <CalendarCell
              key={day.toDateString()}
              date={day}
              range={displayRange}
              displayMonth={monthDisplayed}
              currentlySelecting={currentDateField}
              onDateClicked={onDateClicked}
              disabledMode={disabledMode}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
