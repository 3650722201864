import { abs, numberWithCommas } from "./helpers"

import { Decimal } from "../decimal"
import { Money as PBMoney } from "../gen/proto/money/models_pb"

export enum Format {
  DEFAULT = "-$9,999.99",
  ACCOUNTING = "($9,999.99)",
  NO_DOLLAR_SIGN = "-9,999.99",
  NO_CENTS = "-$10,000",
  ABSOLUTE = "$9,999.99",
  DEFAULT_WITH_POSITIVE_SIGN = "+$9,999.99",
}

// Money is the class that should represent all instances of monetary values
// in our frontend code. There are a few reasons to do this:
//
// 1) Avoid common pitfalls from representing money with floating-point
//    numbers (such as rounding errors).
// 2) Collect all money logic in one place to improve maintainability.
// 3) Ensure a consistent convention is used when dealing with currencies.
//
// A Money can be created from numbers originally in units of cents or
// dollars. From then on, the Money object can be treated as a black box,
// and the various methods can be used to operate on them. We should strive to
// convert all incoming money values (e.g. from user input, backend
// responses) immediately and keep this representation until the value is
// finally either displayed to the user with format() or sent out of the
// frontend with toPB.
export class Money {
  public cents: bigint

  private constructor(cents: bigint) {
    this.cents = cents
  }

  // zero returns a zero-valued Money.
  static zero(): Money {
    return new Money(0n)
  }

  // fromCents constructs a Money from a number in units of cents.
  static fromCents(cents: bigint) {
    return new Money(cents)
  }

  // fromDollars constructs a Money from a number or string in units of dollars.
  static fromDollars(dollars: number | string) {
    switch (typeof dollars) {
      case "number":
        return new Money(
          BigInt(
            Decimal.fromNumber(dollars)
              .mult(Decimal.fromNumber(100))
              .round(0)
              .toFixed()
          )
        )
      case "string":
        // Convert the money string to a Decimal first because we need to construct a Money in terms of cents, so we need to multiply by 100.
        return new Money(
          BigInt(
            Decimal.fromString(dollars)
              .mult(Decimal.fromNumber(100))
              .round(0)
              .toFixed()
          )
        )
    }
  }

  // fromPB constructs a Money from our protobuf Money message defined
  // in rd/proto/money/models.proto. If the input is undefined, fromPB will
  // return zero.
  static fromPB(pbMoney?: PBMoney) {
    if (!pbMoney) {
      return Money.zero()
    }
    return new Money(pbMoney.cents)
  }

  // sum is a helper function to return a sum of Moneys.
  static sum(...elems: Money[]): Money {
    return elems.reduce((prev, curr) => prev.add(curr), new Money(0n))
  }

  // eqFromPB is a helper function to check if two possibly undefined PBMoneys are equal
  static eqFromPB(moneyA?: PBMoney, moneyB?: PBMoney): boolean {
    if (!moneyA && !moneyB) {
      return true
    }

    if (!!moneyA && !!moneyB) {
      return Money.fromPB(moneyA).eq(Money.fromPB(moneyB))
    }

    return false
  }

  // calculateGrossMargin 100(price - cost)/price
  static calculateGrossMargin(price: Money, cost: Money): string {
    return price.sub(cost).mult(Decimal.fromNumber(100)).div(price).toString()
  }

  // calculatePriceFromGrossMargin cost/(1 - margin); rounds up to next highest $0.99 if `roundUpToNext99` arg passed
  static calculatePriceFromGrossMargin(
    cost: Money,
    margin: Decimal,
    roundUpToNext99 = false
  ): Money {
    if (margin.eq(Decimal.fromNumber(1))) {
      return Money.zero()
    }

    const basePrice = new Money(
      BigInt(
        Decimal.fromString(cost.cents.toString())
          .div(Decimal.fromNumber(1).sub(margin))
          .round(0)
          .toString()
      )
    )

    return roundUpToNext99 ? basePrice.roundUpToNext99() : basePrice
  }

  static calculateDecimalMargin(price: Money, cost: Money) {
    if (this.isGrossMarginInfinite(price)) {
      return Decimal.fromNumber(-9999.99).toPB()
    }

    return Decimal.fromString(Money.calculateGrossMargin(price, cost))
      .div(Decimal.fromNumber(100))
      .toPB()
  }

  // calculatePriceFromDiscount this * (1 - discountPercent); rounds up to next highest $0.99 if `roundUpToNext99` arg passed
  static calculatePriceFromDiscount(
    tierOnePrice: Money,
    discountPercent: Decimal,
    roundUpToNext99 = false
  ): Money {
    const basePrice = new Money(
      BigInt(
        Decimal.fromString(tierOnePrice.cents.toString())
          .mult(Decimal.fromNumber(1).sub(discountPercent))
          .round(0)
          .toString()
      )
    )

    return roundUpToNext99 ? basePrice.roundUpToNext99() : basePrice
  }

  // calculateNonDiscountedPrice price / (1 - discountPercent)
  static calculateNonDiscountedPrice(
    price: Money,
    discountPercent: Decimal
  ): Money {
    const fullPrice = new Money(
      BigInt(
        Decimal.fromString(price.cents.toString())
          .div(Decimal.fromNumber(1).sub(discountPercent))
          .round(0)
          .toString()
      )
    )
    return fullPrice
  }

  static calculateDecimalDiscount(tierOnePrice: Money, price: Money) {
    if (tierOnePrice.eq(Money.zero())) {
      return Decimal.fromNumber(0).toPB()
    }
    return Decimal.fromNumber(tierOnePrice.sub(price).div(tierOnePrice)).toPB()
  }

  // isPriceInfinite takes a margin and returns whether
  // or not the price would be infinite after converting to price.
  static isPriceInfinite = (margin: Decimal) => {
    return margin.eq(Decimal.fromNumber(1))
  }

  // isGrossMarginInfinite takes a price and returns whether
  // or not the gross margin would be infinite after converting to gross margin.
  static isGrossMarginInfinite = (price: Money) => {
    return price.isZero()
  }

  // formatGrossMargin calculates gross margin from the given price and cost and rounds
  // it to 2 decimal places. `showSign` can be set to false to exclude the percent sign
  // (it's included by default).
  static formatGrossMargin = (price: Money, cost: Money, showSign = true) => {
    return price.gte(this.zero()) && cost.gte(this.zero())
      ? this.isGrossMarginInfinite(price)
        ? "inf"
        : `${Decimal.fromString(
            Money.calculateGrossMargin(price, cost)
          ).toFixed(2)}${showSign ? "%" : ""}`
      : ""
  }

  // formatGrossMarginPrice calculates price from the given margin and cost and rounds
  // it to 2 decimal places. It will round up if isRoundedUp is true.
  // `showSign` can be set to false to exclude the dollar sign (it's
  // included by default).
  static formatGrossMarginPrice = (
    margin: Decimal,
    cost: Money,
    isRoundedUp: boolean,
    showSign = true
  ) => {
    if (this.isPriceInfinite(margin)) {
      return "inf"
    }
    const price = this.calculatePriceFromGrossMargin(cost, margin)
    const priceRoundedUp = price.roundUpToNext99()
    const format = showSign ? Format.DEFAULT : Format.NO_DOLLAR_SIGN

    if (isRoundedUp) {
      return `(+${priceRoundedUp.sub(price).format()}) ${priceRoundedUp.format(
        format
      )}`
    }

    return price.format(format)
  }

  // formatDiscountPrice discount price from tierOnePrice
  // and discountPercent, rounding to 2 decimal places. It will
  // round up if isRoundedUp is true
  static formatDiscountPrice = (
    tierOnePrice: Money,
    discountPercent: Decimal,
    isRoundedUp: boolean,
    showSign = true
  ) => {
    const price = this.calculatePriceFromDiscount(tierOnePrice, discountPercent)
    const priceRoundedUp = price.roundUpToNext99()
    const format = showSign ? Format.DEFAULT : Format.NO_DOLLAR_SIGN

    if (isRoundedUp) {
      return `(+${priceRoundedUp.sub(price).format()}) ${priceRoundedUp.format(
        format
      )}`
    }

    return price.format(format)
  }

  format(f?: Format): string {
    const isNegative = this.cents < 0
    let prefix = isNegative ? "-$" : "$"
    let suffix = ""
    let decimalPlaces = 2
    switch (f) {
      case Format.ACCOUNTING:
        prefix = isNegative ? "($" : "$"
        suffix = isNegative ? ")" : ""
        break
      case Format.NO_DOLLAR_SIGN:
        prefix = isNegative ? "-" : ""
        break
      case Format.NO_CENTS:
        decimalPlaces = 0
        break
      case Format.ABSOLUTE:
        prefix = "$"
        break
      case Format.DEFAULT_WITH_POSITIVE_SIGN:
        prefix = isNegative ? "-$" : "+$"
        break
    }

    return `${prefix}${numberWithCommas(
      Decimal.fromString(abs(this.cents).toString())
        .div(Decimal.fromNumber(100))
        .toFixed(decimalPlaces)
    )}${suffix}`
  }

  formatAccounting(): string {
    const amountString = `$${numberWithCommas(
      Decimal.fromString(abs(this.cents).toString())
        .div(Decimal.fromNumber(100))
        .toFixed(2)
    )}`

    if (this.cents < 0) {
      return `(${amountString})`
    }

    return amountString
  }

  // add returns a new Money representing the sum of this and other. The
  // original values are unmodified.
  add(other?: Money): Money {
    return new Money(this.cents + (other?.cents ?? 0n))
  }

  // sub returns a new Money representing the difference of this and
  // other. The original values are unmodified.
  sub(other?: Money): Money {
    return new Money(this.cents - (other?.cents ?? 0n))
  }

  // mult multiplies by the given Decimal. The original values are unmodified.
  mult(d: Decimal): Money {
    return new Money(
      BigInt(
        d.mult(Decimal.fromString(this.cents.toString())).round(0).toString()
      )
    )
  }

  // div returns this divided by other. The returned value is unitless.
  div(other: Money): number {
    return Number(this.cents) / Number(other.cents)
  }

  // cmp compares the two Moneys. The return value is:
  // >  0 if this > other
  // == 0 if this == other
  // <  0 if this < other
  // This function is intended for use in sorting; to compare
  // two currencies, use lt, le, ge, or gt.
  cmp(other: Money): number {
    if (this.cents === other.cents) {
      return 0
    }
    if (this.cents > other.cents) {
      return 1
    }
    return -1
  }

  // lt returns whether a money is strictly less than another money.
  lt(other: Money): boolean {
    return this.cents < other.cents
  }

  // lte returns whether a money is less than or equal to another money.
  lte(other: Money): boolean {
    return this.cents <= other.cents
  }

  // gt returns whether a money is strictly greater than another money.
  gt(other: Money): boolean {
    return this.cents > other.cents
  }

  // gte returns whether a money is greater than or equal to another money.
  gte(other: Money): boolean {
    return this.cents >= other.cents
  }

  // eq returns whether a Money is equal to another Money.
  eq(other: Money): boolean {
    return this.cents === other.cents
  }

  // isZero returns whether this Money is equal to zero.
  isZero(): boolean {
    return this.cents === 0n
  }

  // isNonZero returns whether this Money is not equal to zero.
  isNonZero(): boolean {
    return !this.isZero()
  }

  // roundUpToNext99 rounds up this Money to the next highest $0.99
  // applies to positive Money value
  roundUpToNext99(): Money {
    if (this.cents < 0) {
      return this
    }

    const dollars = Math.floor(Number(this.cents) / 100)
    const roundedUpCents = dollars * 100 + 99
    return new Money(BigInt(roundedUpCents))
  }

  // toPB returns the protobuf Money message representation defined in
  // rd/proto/money/models.proto.
  toPB(): PBMoney {
    return {
      cents: this.cents,
    }
  }

  // absolute returns the absolute value in the Money class
  absolute(): Money {
    return new Money(abs(this.cents))
  }

  // negative returns the negative value in the Money class
  negative(): Money {
    return new Money(-this.cents)
  }
}
